import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal } from '../main/store/actionCreators';
import RegionAndLang from './regionAndLang';
import RegionSelect from './regionSelect';
import LanguageSelect from './languageSelect';
// import LocationSelect from 'com/locationSelect';
// import ScheduleFilterPop from 'com/scheduleFilterPop';
import TeacherModal from './teacherModal';
import ClassModal from './classModal';
import LocationSelect from './locationSelect';
import ScheduleFilterPop from './scheduleFilterPop';
import MoodSelect from './moodSelect';
import LocationModal from './locationModal';
// import Notice from 'com/notice';


import './modal.scss';

const getPopContent = (type) => {
    switch (type) {
        case 'regionLanguage':
            return <RegionAndLang />;
        case 'region':
            return <RegionSelect />;
        case 'language':
            return <LanguageSelect />;
        case 'locations':
            return <LocationSelect />;
        case 'scheduleFilter':
            return <ScheduleFilterPop />;
        case 'teachers':
            return <TeacherModal />;
        case 'classes':
            return <ClassModal />;
        case 'moods':
            return <MoodSelect />;
        case 'locationModal':
            return <LocationModal />;
        // case 'confirmEmail':
        // case 'autoWaitlist':
        //     return <Notice />;
        default:
            break;
    }
};

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closeLoading: false
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        //关闭弹窗动画
        if (this.props.showModal && !nextProps.showModal) {
            this.setState({closeLoading: true});
            setTimeout(()=>{
                this.setState({closeLoading: false});
            },500);
        }
        return true;
    }
    closeModal = () => {
        let remember = JSON.parse(localStorage.getItem('remember'));
        if (!remember) {
            localStorage.removeItem('u');
            localStorage.removeItem('p');
        }
        this.props.changeModal(false,'');
    }

    render() {
        return (
            <div className={`modal-wrapper ${this.props.showModal ? 'show':''} ${this.state.closeLoading ? 'close' : ''}`}>
                <div className="modal_content">
                    <div className="closeContent" onClick={this.closeModal}></div>;
                    {getPopContent(this.props.modalType)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.getIn(['main','showModal']),
        modalType: state.getIn(['main','modalType']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //设置弹窗
    changeModal(opera,type){
        dispatch(setModal(opera,type));
    },
});

export default connect(mapStateToProps, mapDispathToProps)(Modal);