import React, { Component } from 'react';
import { intl } from '../../config/util';
import { Icon, Affix } from 'antd';

class LocaTions extends Component {


    render() {

        return (
            <tr>
                <th width="100%" colSpan="8">
                    <Affix className="location_box" offsetTop={75}>
                        <div className="location_list"></div>
                        <div className="location_list" width="33%">
                            <div className="lt_box" >
                                {
                                    this.props.list.map((item, index) => {
                                        return (
                                            <div className="lt_item" key={index}>{item}</div>
                                        );
                                    })
                                }
                                {
                                    this.props.list.size < 3 ? <div className="lt_item">{intl('model', 'others', this.props.language_id)}</div> : null
                                }
                            </div>
                        </div>
                        <div className="location_list" width="33%">
                            <div className="lt_box">
                                {
                                    this.props.list.map((item, index) => {
                                        return (
                                            <div className="lt_item" key={index}>{item}</div>
                                        );
                                    })
                                }
                                {
                                    this.props.list.size < 3 ? <div className="lt_item">{intl('model', 'others', this.props.language_id)}</div> : null
                                }
                            </div>
                        </div>
                        <div className="location_list" width="33%">
                            <div className="lt_box">
                                {
                                    this.props.list.map((item, index) => {
                                        return (
                                            <div className="lt_item" key={index}>{item}</div>
                                        );
                                    })
                                }
                                {
                                    this.props.list.size < 3 ? <div className="lt_item">{intl('model', 'others', this.props.language_id)}</div> : null
                                }
                            </div>
                        </div>
                    </Affix>
                </th>
            </tr>
        );
    }
}
export default LocaTions;