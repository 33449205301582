import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeTeacherModal } from '../../main/store/actionCreators';
import { formatDate, intl } from '../../../config/util';
import { fromJS } from 'immutable';
import { flags } from '../../../config/unleash';

import './teacherModal.scss';
import { ClockCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { judgeBooking, toggleFavourite, updateTeacherInfo } from '../../../schedule/store/actionCreators';
import ScheduleButton from '../../../schedule/component/scheduleButton/scheduleButton';
import HtmlContent from '../../htmlContent/htmlContent';
let teacherDefaultAvatar = require('../../../assets/images/ic_default_image.png');

class TeacherModal extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    //日期展示
    formatDateTitle(date) {
        let { language_id } = this.props;
        let dateTitle = formatDate(date, language_id);
        if (dateTitle.day === 'today') {
            return intl('schedule', 'today', language_id) + ' ' + dateTitle.month + ' ' + dateTitle.dayTimeShort;
        } else {
            return dateTitle.title;
        }
    }

    locations(id) {
        let { location_list, filter, lang, location_list_ungrouped } = this.props;
        filter = filter.toJS();
        if (!filter.sector) {
            return '';
        }
        // let data = location_list ? location_list[filter.sector] : [];
        let data = location_list_ungrouped ? location_list_ungrouped : [];

        for (var i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                return data[i].names;
            }
        }
    }

    updateSchedule() {
        // refresh on cancel class
        let { teacherModalInfo, region_id, language_id } = this.props;
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        this.props.updateClassInfo(teacherModalInfo.id, region_id, jwt, language_id, teacherModalInfo);
    }

    scrollToSchedule() {
        const el = document.getElementById("classSchedule");
        if (el) {
            el.scrollIntoView({ behavior: "smooth" });
        }
    }
    //课程列表
    getScheduleItemMobile = (list) => {
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let { mood_flag } = this.props;
        return (
            <ul>
                {list.map(item => {
                    let info = [
                        (<p key="time" className="timeContent">{item.duration_min} min</p>),
                        (<p key="info" className="infoContent teacherName">{item.teacher.name}</p>)
                    ];
                    if (mood_flag && item.mood) {
                        info.push(
                            <div key="mood" className="mood">
                                <p className="moodName infoContent" style={{ color: item.mood.color }}> {item.mood.name} </p>
                            </div>
                        );
                    }
                    return <li key={item.id} className={item.class_type.is_fuze ? 'black' : ''}>
                        <div className="date">{this.formatDateTitle(item.start_date)}</div>
                        <span
                            className="line"
                            style={{ backgroundColor: item.class_type.is_fuze ? '#e06b26' : item.class_type.pillar ? item.class_type.pillar.color : '#e06b26' }}
                        >
                        </span>
                        <div className="class_info">
                            <div className='time-name'>
                                <h4 className='start-time'>
                                    {item.start_time_display}
                                </h4>
                                <h4 className='class-name'>
                                    {item.class_type.name}
                                </h4>
                            </div>
                            <div className="down">
                                <div className="left">
                                    <div className="leftInfo">
                                        {
                                            info.map(t => t)
                                                .reduce((prev, curr, i) => [prev, <p key={i}>&nbsp;|&nbsp;</p>, curr])
                                        }
                                    </div>
                                    <p>
                                        <i className="iconfont icon-marker1"></i> &nbsp;
                                        {this.locations(item.location_id)}
                                    </p>
                                </div>
                                {
                                    (item.button_status !== 0 && this.props.isLogin) &&
                                    <div className="opera book">
                                        <ScheduleButton
                                            item={fromJS(item)}
                                            language_id={this.props.language_id}
                                            booking={this.props.booking}
                                            jwt={jwt}
                                            filter={this.props.filter}
                                            region_id={this.props.region_id}
                                            cancel_class_callback={this.updateSchedule.bind(this)}
                                            booking_class_callback={this.updateSchedule.bind(this)}
                                            blurBtn="true"
                                        >
                                        </ScheduleButton>
                                    </div>
                                }
                            </div>
                        </div>
                    </li>;
                }
                )}
            </ul>
        );
    }
    render() {
        let { teacherModalInfo, lang, language_id, favourite_teacher_list } = this.props;
        let scheduleList = teacherModalInfo.schedule;
        let is_favourite = favourite_teacher_list.indexOf(teacherModalInfo.id) >= 0;
        return (
            <div className="teachersInfo">
                <div className="content">
                    <div className="avatar">
                        <img src={teacherModalInfo.image_link ? teacherModalInfo.image_link : teacherDefaultAvatar}  alt=""/>
                    </div>
                    <h4 className="name title">{teacherModalInfo.names[lang]}</h4>
                    <HtmlContent content={teacherModalInfo.descriptions[lang]} />
                    {/* <pre className="disc">{teacherModalInfo.descriptions[lang]}</pre> */}

                    <div className="locations">
                        <div className="title">{intl('schedule', 'locations', language_id)}</div>
                        <ul>
                            {
                                teacherModalInfo.locations.map((item, index) =>
                                    <li key={`${item.id}_${item.index}`}>{item.names}</li>
                                )
                            }
                        </ul>
                    </div>

                    {
                        scheduleList.length > 0 &&
                        <div className="schedule" id="classSchedule">
                            <div className="title">{intl('schedule', 'schedule', language_id)}</div>
                            <div className="filter">
                                <div className="filterTitle">{intl('schedule', 'allLocation', language_id)}</div>
                                <div className="filterTitle"> ・ </div>
                                <div className="filterTitle">{intl('schedule', 'next14Days', language_id)}</div>
                            </div>
                            {this.getScheduleItemMobile(scheduleList)}
                        </div>
                    }

                </div>
                <div className="buttonSection">
                    <button onClick={() => this.props.toggleFavouriteTeacher(is_favourite, favourite_teacher_list, teacherModalInfo.id)}>
                        {is_favourite ?
                            <StarFilled style={{ color: '#ffaC33' }} /> :
                            <StarOutlined />
                        }
                        &nbsp; {intl('schedule', 'favourite', language_id)}
                    </button>
                    <button onClick={this.scrollToSchedule}><ClockCircleOutlined /> &nbsp; {intl('schedule', 'schedule', language_id)}</button>
                </div>
                <div className="opera">
                    <button className="close" onClick={this.props.closeModal}>{intl('model', 'close', language_id)}</button>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.getIn(['header', 'lang']),
        teacherModalInfo: state.getIn(['main', 'teacherModalInfo']).toJS(),
        language_id: state.getIn(['header', 'language_id']),
        favourite_teacher_list: state.getIn(['schedule', 'favourite_teacher']),
        user: state.getIn(['main', 'user']),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        location_list_ungrouped: state.getIn(['schedule', 'location_list_ungrouped']).toJS(),
        filter: state.getIn(['schedule', 'filter']),
        isLogin: state.getIn(['main', 'isLogin']),
        region_id: state.getIn(['header', 'region_id']),
        mood_flag: state.getIn(['main', 'flags', flags.mood]),

    };
};

const mapDispathToProps = (dispatch) => ({
    //关闭弹窗
    closeModal(){
        dispatch(closeTeacherModal());
    },
    booking(id, status, booking_id) {
        let bookinfo = {
            class_id: id,
            status: status,
            jwt: this.jwt,
            filter: this.filter,
            booking_id,
            language_id: this.language_id,
            region_id: this.region_id,
            cancel_callback: this.cancel_class_callback,
            booking_callback: this.booking_class_callback,
        };
        dispatch(judgeBooking(bookinfo));
    },

    updateClassInfo(id, region_id, jwt, language_id, teacherModalInfo) {
        dispatch(updateTeacherInfo(id, region_id, jwt, language_id, teacherModalInfo));
    },

    toggleFavouriteTeacher(is_favourite, favourite_ids, favourite_id) {
        dispatch(toggleFavourite(is_favourite, favourite_ids, 'T', favourite_id, this.region_id));
    }
});

export default connect(mapStateToProps,mapDispathToProps)(TeacherModal);