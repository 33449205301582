//课程筛选（弹窗）
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../../config/util';
import { changeFilterChoose, resetFilter } from '../../../schedule/store/actionCreators';
import { setModal } from '../../main/store/actionCreators';
import { fromJS } from 'immutable';
import './scheduleFilterPop.less';

class ScheduleFilterPop extends Component {
    constructor(props) {
        super(props);
        let filter = this.props.filter;
        this.state = {
            selectType: 'default',   //筛选场景
            teacher_id: filter.teacher_id || '', //筛选项-老师
            level_id: filter.level_id || '', //筛选项-程度（瑜伽独有）
            pillar_id: filter.pillar_id || '', //筛选项-支柱/元素
            class_type_id: filter.class_type_id || '', //筛选项-课程
            filter_type: filter.filter_type || '', //筛选项-分类(健身独有)
        };
    }

    //列表选择监听
    handleSelectChange = (type, val) => {
        if (type === 'filterTypes') {
            val !== this.state.filter_type && this.setState({ filter_type: val, selectType: 'default' });
        } else {
            val !== parseInt(this.state[type]) && this.setState({ [type]: val, selectType: 'default' });
        }
    }
    //关闭按钮点击
    handleChange = () => {
        this.state.selectType === 'default' ? this.props.changeModal(false, '') : this.setState({ selectType: 'default' });
    }
    //reset监听
    handleResetClick = () => {
        this.setState({
            teacher_id: '',
            level_id: '',
            pillar_id: '',
            class_type_id: '',
            filter_type: ''
        });
        let { user } = this.props;
        let jwt = user.get('jwt') ? user.get('jwt') : '';
        this.props.resetScheduleFilter(jwt);
    }

    //apply监听
    handleApplyClick = () => {
        let { teacher_id, level_id, pillar_id, class_type_id, filter_type } = this.state;
        let stateFilter = { teacher_id, level_id, pillar_id, class_type_id, filter_type };
        let { user } = this.props;
        let jwt = user.get('jwt') ? user.get('jwt') : '';
        this.props.changeFilter({ ...this.props.filter, ...stateFilter }, jwt);
    }

    //获取筛选列表
    getSelect = () => {
        const { selectType, teacher_id, level_id, pillar_id, class_type_id, filter_type } = this.state;
        const { lang, filter, teachers, pillars, levels, classTypes, filterTypes, language_id } = this.props;
        // 选中老师（教练）
        let chooseTeacher = teacher_id ? teachers[filter.sector].find(item => item.id === parseInt(teacher_id)).names[lang] : intl('other', 'all', language_id);
        // 支柱(元素)
        let choosePillar = pillar_id ? pillars[filter.sector].find(item => item.id === parseInt(pillar_id)).names[lang] : intl('other', 'all', language_id);
        //程度
        let chooseLevel = level_id ? levels[filter.sector].find(item => item.id === parseInt(level_id)).names[lang] : intl('other', 'all', language_id);
        //课程
        let chooseClassType = class_type_id ? classTypes[filter.sector].find(item => item.id === parseInt(class_type_id)).names[lang] : intl('other', 'all', language_id);
        //fitness/cycling/SGT
        let filterType = filter_type ? filterTypes.find(item => item.names.en === filter_type).names[lang] : intl('other', 'all', language_id);

        if (selectType === 'default') {
            return (
                <div className="pop_content">
                    {
                        filter.sector === 'F' &&
                        <div className="panel long_name" onClick={() => this.setState({ selectType: 'filterTypes' })}>
                            <span className="name">{intl('schedule', 'fitnessCyclingSGT', language_id)} :</span>
                            <span className="active">{filterType}</span>
                            <i className="iconfont icon-right"></i>
                        </div>
                    }
                    <div className="panel" onClick={() => this.setState({ selectType: 'teachers' })}>
                        <span className="name">{intl('schedule', filter.sector === 'Y' ? 'teachers' : 'instructors', language_id)} :</span>
                        <span className="active">{chooseTeacher}</span>
                        <i className="iconfont icon-right"></i>
                    </div>

                    <div className="panel" onClick={() => this.setState({ selectType: 'pillars' })}>
                        <span className="name">{intl('schedule', filter.sector === 'Y' ? 'pillars' : 'categories', language_id)} :</span>
                        <span className="active">{choosePillar}</span>
                        <i className="iconfont icon-right"></i>
                    </div>

                    {
                        filter.sector === 'Y' &&
                        <div className="panel" onClick={() => this.setState({ selectType: 'levels' })}>
                            <span className="name">{intl('schedule', 'levels', language_id)} :</span>
                            <span className="active">{chooseLevel}</span>
                            <i className="iconfont icon-right"></i>
                        </div>
                    }

                    <div className="panel" onClick={() => this.setState({ selectType: 'classTypes' })}>
                        <span className="name">{intl('schedule', 'classTypes', language_id)} :</span>
                        <span className="active">{chooseClassType}</span>
                        <i className="iconfont icon-right"></i>
                    </div>

                    <div className="opera">
                        <button className="reset" onClick={this.handleResetClick}>{intl('schedule', 'reset', language_id)}</button>
                        <button className="apply" onClick={this.handleApplyClick}>{intl('schedule', 'apply_filter', language_id)}</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="pop_content">
                    <div className="select_title">
                        <button className="back" onClick={() => { this.setState({ selectType: 'default' }); }}>
                            <i className="iconfont icon-left"></i>
                        </button>
                        {intl('schedule', this.state.selectType === 'filterTypes' ? 'fitnessCyclingSGT' : selectType, language_id)}
                    </div>
                    {this.getList(selectType)}
                </div>
            );
        }
    }

    //获取筛选项
    getList = type => {
        let { filter, lang, filterTypes, } = this.props;
        const { teacher_id, level_id, pillar_id, class_type_id, filter_type } = this.state;
        let activeKeyName;
        let stateValue;
        switch (type) {
            case 'teachers':
                activeKeyName = 'teacher_id';
                stateValue = teacher_id;
                break;
            case 'levels':
                activeKeyName = 'level_id';
                stateValue = level_id;
                break;
            case 'pillars':
                activeKeyName = 'pillar_id';
                stateValue = pillar_id;
                break;
            case 'classTypes':
                activeKeyName = 'class_type_id';
                stateValue = class_type_id;
                break;
            case 'filterTypes':
                activeKeyName = 'filter_type';
                stateValue = filter_type;
                break;
            default:
                break;
        }
        if (type === 'filterTypes') {
            return (
                <ul className="list">
                    {
                        filterTypes.map(item =>
                            <li key={item.id} onClick={() => { this.handleSelectChange(activeKeyName, item.names.en); }}>
                                <span className="name">{item.names[lang]}</span>
                                {stateValue === item.names.en && <i className="iconfont icon-check"></i>}
                            </li>
                        )
                    }
                </ul>
            );
        } else {
            return (
                <ul className="list">
                    <li onClick={() => { this.handleSelectChange(activeKeyName, ''); }}>
                        <span className="name">{intl('other', 'all', this.props.language_id)}</span>
                        {this.state[activeKeyName] === '' && <i className="iconfont icon-check"></i>}
                    </li>
                    {
                        this.props[type][filter.sector].map(item =>
                            <li key={item.id} onClick={() => { this.handleSelectChange(activeKeyName, item.id); }}>
                                <span className="name">{item.names[lang]}</span>
                                {parseInt(stateValue) === item.id && <i className="iconfont icon-check"></i>}
                            </li>
                        )
                    }
                </ul>
            );
        }

    }

    render() {
        const { language_id } = this.props;
        return (
            <div className="schedule-filter-pop-wrapper">
                <div className="title">{intl('schedule', 'filter', language_id)}</div>
                {this.getSelect()}
                <div className="opera">
                    <button className="true" onClick={this.handleChange}>{intl('model', 'close', language_id)}</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.getIn(['header', 'languages']),
        language_id: state.getIn(['header', 'language_id']),
        region_id: state.getIn(['header', 'region_id']),
        regions: state.getIn(['header', 'regions']),
        lang: state.getIn(['header', 'lang']),
        filter: state.getIn(['schedule', 'filter']).toJS(),
        teachers: state.getIn(['schedule', 'teachers_all']).toJS(),
        pillars: state.getIn(['schedule', 'pillars_all']).toJS(),
        levels: state.getIn(['schedule', 'levels_all']).toJS(),
        classTypes: state.getIn(['schedule', 'classtypes_all']).toJS(),
        filterTypes: state.getIn(['schedule', 'filterTypes']).toJS(),
        user: state.getIn(['main', 'user']),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
    };
};

const mapDispathToProps = (dispatch) => ({
    //设置弹窗
    changeModal(opera, type) {
        dispatch(setModal(opera, type));
    },
    //重置筛选
    resetScheduleFilter(jwt) {
        dispatch(resetFilter(fromJS(this.filter), jwt));
        dispatch(setModal(false, ''));
    },
    //提交筛选
    changeFilter(filter, jwt) {
        dispatch(changeFilterChoose(filter, 'apply', fromJS(this.filter), this.weeks, jwt, this.location_list));
        dispatch(setModal(false, ''));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(ScheduleFilterPop);