import { combineReducers } from 'redux-immutable';
import { reducer as mainReducer } from '../common/main/store';
import { reducer as headerReducer } from '../common/header/store';
import { reducer as scheduleReducer } from '../schedule/store';
import { reducer as profileReducer } from '../profile/store';
import { reducer as historyReducer } from '../history/store';
import { reducer as bookingReducer } from '../booking/store';

const reducer = combineReducers({
    main: mainReducer,
    header: headerReducer,
    schedule: scheduleReducer,
    profile: profileReducer,
    history: historyReducer,
    booking: bookingReducer
});


export default reducer;