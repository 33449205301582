import * as constants from './constants';
import { fromJS } from 'immutable';
import { commonConfig } from '../../../config';
import { signOut } from '../../main/store/actionCreators';
import { reloadPage } from '../../../config/util';

//获取当前是瑜伽还是健身(无用)
export const getType = () => {
    return (dispatch) => {
        // let type = window.location.host.indexOf('fitness') >= 0 ? 'F': 'Y';
        let type = commonConfig.sector;
        localStorage.setItem('type', type);
        dispatch(changeType(type));
    };
};

//获取选中语言
export const getLanguage = (language_id) => {
    return (dispatch) => {
        localStorage.setItem('language_id', language_id);
        dispatch(changeLanguage(language_id));
    };
};

//下拉改变选中语言
export const selectChangeLanguage = (language_id, reload = true) => {
    return (dispatch) => {
        localStorage.setItem('language_id', language_id);
        dispatch(changeLanguage(language_id));
        if (reload) {
            reloadPage();
        }
    };
};

//改变loading状态
export const changeLoading = (value)=>({
    type: constants.CHANGE_LOADING,
    value
});

//下拉框改变选中地区
export const changeRegion = (region_id, region, reload = true) => {
    return (dispatch) => {
        localStorage.setItem('region_id', region_id);
        let newRegion = region.map(item=>{
            item.checked = item.id === parseInt(region_id) ? true : false;
            return item;
        });
        dispatch(changeRegions(newRegion));
        dispatch(changeRegionId(region_id));
        // if no reload page, then maybe need remove filter also
        localStorage.removeItem('mood_ids');
        if (reload) {
            reloadPage();
        }
    };
};

//修改城市和语言(mobile)
export const setRegionAndLanguage = (region_id,language_id,languages,regions) => {
    return dispatch => {
        dispatch(signOut(false));
        dispatch(changeRegion(region_id, regions, false));
        dispatch(selectChangeLanguage(language_id, false));
        reloadPage();
        return;
    };
};

//修改城市
export const setRegion = (region_id) => {
    return dispatch => {
        localStorage.setItem('region_id', region_id);
        dispatch(changeRegionId(region_id));
        //修改城市退出登录状态
        dispatch(signOut());
    };
};

//登录后修改地址的弹窗信息
export const setChangeRegionModel = (value) => ({
    type: constants.CHANGE_REGION_MODEL_INFO,
    value: fromJS(value)
});

//登陆后修改地点的弹窗控制
export const setRegionTipModel = (value) => ({
    type: constants.SET_REGION_TIP_MODEL,
    value: fromJS(value)
});


//保存外部链接
export const saveLinks = (value)=>({
    type: constants.CHANGE_LINKS,
    value: fromJS(value)
});


//改变选中地区
export const changeRegionId = (region_id) => ({
    type: constants.CHANGE_REGION,
    value: region_id
});

//改变登录表单默认选中地区
export const changeRegionPlaceHolder = (id, regions) => {
    return (dispatch) => {
        let regionList = regions.map(item => {
            item.checked = parseInt(id) == parseInt(item.id);
            return item;
        });
        // dispatch(updateRegionPlaceHolder({regions: regionList,regionPlaceHolder: parseInt(id)}))
        console.log(id, regions);
        dispatch(updateRegionTemporary({regions: regionList,regionTemporary: parseInt(id)}));
    };
};

export const triggerChangeRegionByUrl = (regionId) => {
    return (dispatch) => {
        dispatch(changeRegionByUrl(regionId));
    };
};

export const updateRegionPlaceHolder = (data) => ({
    type: constants.CHANGE_REGION_PLACEHOLDER,
    value: fromJS(data)
});

export const updateRegionTemporary = (data) => ({
    type: constants.CHANGE_REGION_TEMPORARY,
    value: fromJS(data)
});


//改变网站类型
export const changeType = (type) => ({
    type: constants.CHANGE_TYPE,
    value: type
});

//改变地区列表
export const changeRegions = (regions) => ({
    type: constants.GET_REGIONS,
    regions: fromJS(regions)
});


//改变选中语言
export const changeLanguage = (language_id) => ({
    type: constants.CHANGE_LANGUAGE,
    value: language_id
});

//登录后修改城市列表和选中城市
export const loginAfterChangeRegion = (value) => ({
    type: constants.LOGION_AFTER_CHANGE_REGION,
    value: fromJS(value)
});

export const changeUnleashUserId = (value) => ({
    type: constants.CHANGE_UNLEASH_USER_ID,
    value: fromJS(value)
});

export const changeRegionByUrl = (value) => ({
    type: constants.CHANGE_REGION_BY_URL,
    value: value
});

