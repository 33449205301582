import { Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../config/util';
import { changeMoudle } from '../../common/main/store/actionCreators';
import MyProfile from './MyProfile';
import MySetting from './MySetting';
import MyFavourite from './MyFavourite';
import { FavouriteTabWrapper } from '../style';

class ProfileTab extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { TabPane } = Tabs;
        let { language_id } = this.props;
        return (
            <>
                <div>
                    <button className="back" onClick={this.props.back}><i className="iconfont icon-left"></i></button>
                </div>
                <Tabs>
                    <TabPane tab={intl('other', 'profile', language_id)} key="profile">
                        <MyProfile />
                    </TabPane>
                    <TabPane tab={intl('other', 'favourite', language_id)} key="favourite">
                        <MyFavourite />
                    </TabPane>
                    <TabPane tab={intl('other', 'settings', language_id)} key="settings">
                        <MySetting />
                    </TabPane>
                </Tabs>
            </>
        );

    }
}
const mapStateToProps = (state) => {
    return {
        language_id: state.getIn(['header', 'language_id']),
    };
};

const mapDispathToProps = (dispatch) => ({
    back() {
        let hideFooter = document.getElementById("footerTop");
        hideFooter.style.display = "block";
        dispatch(changeMoudle('Schedule'));
    },
});

export default connect(mapStateToProps, mapDispathToProps)(ProfileTab);