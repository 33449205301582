export const GET_REGIONS = 'header/GET_REGIONS';
export const CHANGE_TYPE = 'header/CHANGE_TYPE';
export const CHANGE_REGION = 'header/CHANGE_REGION';
export const CHANGE_LANGUAGE = 'header/CHANGE_LANGUAGE';
export const CHANGE_LOADING = 'header/CHANGE_LOADING';
export const CHANGE_LINKS = 'header/CHANGE_LINKS';
export const LOGION_AFTER_CHANGE_REGION = 'header/LOGION_AFTER_CHANGE_REGION';
export const CHANGE_REGION_MODEL_INFO = 'header/CHANGE_REGION_MODEL_INFO';
export const SET_REGION_TIP_MODEL = 'header/SET_REGION_TIP_MODEL';
export const CHANGE_REGION_PLACEHOLDER = 'header/CHANGE_REGION_PLACEHOLDER';
export const CHANGE_REGION_TEMPORARY = 'header/CHANGE_REGION_TEMPORARY';
export const CHANGE_UNLEASH_USER_ID = 'header/CHANGE_UNLEASH_USER_ID';
export const CHANGE_REGION_BY_URL = 'header/CHANGE_REGION_BY_URL';







