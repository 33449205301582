import React, { Component, useEffect } from 'react';
import { HeaderWrapper, Logo } from './style';
import { Button, Dropdown, Icon, Menu, Modal } from 'antd';
import { connect } from 'react-redux';
import { actionCreators } from './store';
import { actionCreators as mainAction } from '../main/store';
import { intl, getChangeRegionTipsInfo } from '../../config/util';
import { trial_link, region_link} from '../../config/link';
import { isMobile } from 'react-device-detect';
import { openRegionSelectModal, setModal } from '../main/store/actionCreators';
import { commonConfig } from '../../config';
import Loading from '../loading/index';
import { useFlagsStatus } from '@unleash/proxy-client-react';

class Header extends Component {
    ref = React.createRef();
    previousHeaderHeight = '';

    openTrialLink() {
        let { type, region_id } = this.props;

        window.open(trial_link(type, region_id));
    }

    languageMenu = () => {
        return (
            <Menu onClick={(e) => this.props.changeLanguage(e)}>
                { this.props.languages.map((item) => <Menu.Item key={item.id}>{item.description}</Menu.Item>)}
            </Menu>
        );
    }
    showModal = () => {
        // this.props.setRegionTipModel(true);
        if (isMobile) {
            this.props.showRegionLanguageModal();
        } else {
            this.props.setRegionTipModel(true);
        }
    };

    handleCancel = () => {
        this.props.setRegionTipModel(false);
    }

    handleModelClick(e) {
        if (e == 'login') {
            this.props.setRegionTipModel(false);
            this.props.changeRegionPlaceHolder(this.props.chooseRegion);
            this.props.handleShowLogin();
        }

        if (e == 'logout') {
            this.props.handleLoginOut();
            this.props.changeRegion(this.props.chooseRegion);
        }
    }

    triggerChangeRegionByUrl = (regionId) => {
        if (isMobile) {
            this.props.triggerChangeRegionByUrlMobile(regionId);
        } else {
            this.changeRegionModel(regionId);
        }
    }

    changeRegionModel = (regionId) => {
        if (this.props.isLogin) {
            if (this.props.region_id != regionId) {
                this.props.setChangeRegionModel({
                    visible: true,
                    chooseRegion: { key: regionId },
                    changeRegionTips: getChangeRegionTipsInfo(this.props.region_id, regionId, this.props.language_id)
                });
            }
        } else {
            this.props.changeRegion({ key: regionId });
        }
    }

    openRegionLink = () => {
        let { type, region_id } = this.props;
        window.open(region_link(type, region_id));
    }

    //开启语言地区弹窗
    showModalMobile = () => {
        this.props.showRegionLanguageModal();
    }

    componentDidUpdate() {
        if (isMobile) {
            //dynamic body height (to show full content)
            if (this.ref.current) {
                let currentHeaderHeight = this.ref.current.getBoundingClientRect().height;
                if (this.props.onHeightChange && this.ref.current) {
                    if (this.previousHeaderHeight !== currentHeaderHeight) {
                        this.previousHeaderHeight = currentHeaderHeight;
                        this.props.onHeightChange();
                    }
                }
            }
        }
    }

    render() {
        const { language_id, loading, flagsReady } = this.props;
        const show = loading || !flagsReady;
        return (
            <div>
                <Loading
                    language_id={language_id}
                    show={show}
                />
                {isMobile ? this.renderMobile() : this.renderDesktop()}
            </div>
        );
    }

    renderDesktop() {
        const { regions, region_id, language_id, loading, type, lang, changeRegionTips, visible } = this.props;
        let logo = type === 'Y' ? require('./../../assets/images/pure-yoga-logo.png') : require('./../../assets/images/pure-fitness-logo.png');
        let location = regions.length ? regions.find(item => item.checked).short_name : '';
        //variable region_link() replace by openRegionLink function

        if (loading) {
            return (
                <div></div>
            );
        } else {

        const regionMenu = (
            <Menu onClick={(e) => this.changeRegionModel(e.key)}>
                { regions.map((item) => <Menu.Item key={item.id}>{item.names[lang]}</Menu.Item>)}
            </Menu>
        );

            return (
                <HeaderWrapper>
                    <Logo >
                        <img onClick={this.openRegionLink.bind(this)} target="_blank" src={logo} alt="" />
                    </Logo>
                    <div className="headerRight">
                        <Dropdown overlay={regionMenu} placement="bottomCenter" overlayClassName="header_dropdown">
                            <div className="ant-dropdown-link" href="#">
                                {location} <Icon type="caret-down" />
                            </div>
                        </Dropdown>

                        <Dropdown overlay={this.languageMenu} placement="bottomCenter" overlayClassName="header_dropdown">
                            <div className="ant-dropdown-link" href="#">
                                { intl('other', 'lang-'+this.props.language_id, this.props.language_id)} <Icon type="caret-down" />
                            </div>
                        </Dropdown>

                        <Button type="primary" size="large" onClick={this.openTrialLink.bind(this)}>
                            {intl('login', 'tryClass', language_id)}
                        </Button>
                    </div>

                    <Modal
                        visible={visible}
                        // title="Title"
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        wrapClassName="changeRegionWrap"
                        width="400px"
                        title={intl('other', 'tips', language_id)}
                        footer={[
                            <div className="model_line" key="submit">
                                <Button type="primary" onClick={() => this.handleModelClick('logout')}>
                                    {changeRegionTips.logout}
                                </Button>
                            </div>,
                            <div className="model_line" key="cancel">
                                <Button type="primary" onClick={this.handleCancel}>
                                    {changeRegionTips.cancel}
                                </Button>
                            </div>
                        ]}
                    >
                        <div style={{}}>{changeRegionTips.title}</div>
                    </Modal>

                </HeaderWrapper>
            );
        }
    }

    renderMobile() {
        let { regions, type, lang, visible, language_id, changeRegionTips } = this.props;
        const sector = commonConfig.sector;
        let logo = sector === 'Y' ? require('./../../assets/images/pure-yoga-logo.png') : require('./../../assets/images/pure-fitness-logo.png');
        let location = regions.length ? regions.find(item => item.checked).short_name : '';

        return (
            <HeaderWrapper id="header" ref={this.ref}>
                <div className="header">
                    <Logo >
                        <img onClick={this.openRegionLink.bind(this)} target="_blank" src={logo} alt="" />
                    </Logo>
                    <div className="headerRight" onClick={this.showModal.bind(this)}>
                        {location} | { intl('other', 'lang-'+language_id, language_id) }
                    </div>
                </div>
            </HeaderWrapper>
        );
    }
}



const mapStateToProps = (state) => ({
    isLogin: state.getIn(['main', 'isLogin']),
    regions: state.getIn(['header', 'regions']).toJS(),
    languages: state.getIn(['header', 'languages']).toJS(),
    region_id: state.getIn(['header', 'region_id']),
    language_id: state.getIn(['header', 'language_id']),
    user: state.getIn(['main', 'user']),
    type: state.getIn(['header', 'type']),
    loading: state.getIn(['header', 'loading']),
    lang: state.getIn(['header', 'lang']),
    visible: state.getIn(['header', 'visible']),
    chooseRegion: state.getIn(['header', 'chooseRegion']).toJS(),
    changeRegionTips: state.getIn(['header', 'changeRegionTips']).toJS(),
    lang: state.getIn(['header', 'lang']),
    change_region_by_url: state.getIn(['header', 'change_region_by_url']),
});

const mapDispathToProps = (dispatch) => ({
    //获取类型
    getType() {
        dispatch(actionCreators.getType());
    },
    //获取地区
    getRegion(list) {
        if (list.size === 0) {
            dispatch(actionCreators.getRegions());
        }
    },

    //改变语言列表
    changeChooseLanguage(language_id) {
        //改变语言id
        dispatch(actionCreators.getLanguage(language_id));
    },

    //下拉选择改变语言
    changeLanguage({ key }) {
        dispatch(actionCreators.selectChangeLanguage(key));
    },

    //下拉选择改变地区
    changeRegion({ key }) {
        let region = this.regions;
        dispatch(actionCreators.changeRegion(key, region));
    },

    setChangeRegionModel(data) {
        dispatch(actionCreators.setChangeRegionModel(data));
    },

    setRegionTipModel(status) {
        dispatch(actionCreators.setRegionTipModel(status));
    },

    changeRegionPlaceHolder({ key }) {
        dispatch(actionCreators.changeRegionPlaceHolder(key, this.regions));
    },

    //打开登录弹窗
    handleShowLogin() {
        dispatch(mainAction.showLogin());
    },

    //退出登录
    handleLoginOut() {
        dispatch(mainAction.signOut());
    },

    //设置弹窗
    showRegionLanguageModal() {
        dispatch(setModal(true, 'regionLanguage'));
    },

    triggerChangeRegionByUrlMobile(regionId) {
        dispatch(openRegionSelectModal({
            silentTrigger: true,
            regionId,
        }));
    }

});

const HeaderHOC = (props) => {
    const { flagsReady } = useFlagsStatus();
    const myProps = Object.assign({}, props);
    const headerRef = React.createRef();

    useEffect(() => {
        if (props.change_region_by_url && headerRef.current) {
            /** @type Header */
            const current = headerRef.current;
            current.triggerChangeRegionByUrl(props.change_region_by_url);
        }
    }, [props.change_region_by_url]);

    return (<Header {...myProps} flagsReady={flagsReady} ref={headerRef}></Header>);
};

export default connect(mapStateToProps, mapDispathToProps)(HeaderHOC);