

interface LocationList {
    Y: any[];
    F: any[];
    yoga: any[];
    fitness: any[];
};

type LocationListKey = keyof LocationList;

export function formatLocations(locationList: LocationList, favouriteLocationIds: number[]) {

    if (favouriteLocationIds.length === 0) {
        return locationList;
    }

    (Object.keys(locationList) as LocationListKey[]).forEach((key) => {
        const list = locationList[key];
        const favList: any[] = [];
        const nonFavList: any[] = [];
        const ids = [...favouriteLocationIds];
        list.forEach((element, index) => {
            if (ids.indexOf(element.id) > -1) {
                element.is_favourite = true;
                favList.push(element);
            } else {
                element.is_favourite = false;
                nonFavList.push(element);
            }
        });

        if (favList.length > 0) {
            // sort by favourite order
            list.sort(function (a, b) {
                return favouriteLocationIds.indexOf(a.id) - favouriteLocationIds.indexOf(b.id)
            });
        }

        const newList = [...favList, ...nonFavList];
        locationList[key] = newList;
    })


    return locationList;
};

interface TeacherList {
    Y: any[];
    F: any[];
};

type TeacherListKey = keyof TeacherList;

export function formatTeacher(teacherList: TeacherList, favouriteTeacherIds: number[]) {

    if (favouriteTeacherIds.length === 0) {
        return teacherList;
    }

    // teacherList.Y.forEach(element => {
    //     if (favouriteTeacherIds.indexOf(element.id) > -1) {
    //         element.is_favourite = true;
    //     } else {
    //         element.is_favourite = false;
    //     }
    // })

    // (Object.keys(teacherList) as TeacherListKey[]).forEach((key) => {
    //     const list = teacherList[key];
    //     const ids = [...favouriteTeacherIds];
    //     let favouriteTeacher: any[] = [];
        // list.forEach((element, index) => {
        //     if (ids.indexOf(element.id) > -1) {
        //         element.is_favourite = true;
        //     } else {
        //         element.is_favourite = false;
        //     }

        //     if (element.is_favourite) {
        //         favouriteTeacher.push(...list.splice(index, 1));
        //     }
        // });

        // if (favouriteTeacher.length > 0) {
        //     list.unshift(...favouriteTeacher);
        //     teacherList[key] = list;
        // }
    // })


    return teacherList;
};
