import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRegionNotice, changeMoudle } from '../../../common/main/store/actionCreators';
import { List } from 'antd-mobile';
import { intl } from '../../../config/util';
import './locationAlerts.less';
import HtmlContent from '../../../common/htmlContent/htmlContent';
const Item = List.Item;

class LocationNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scene: 'alerts', //alerts || locationAlerts
            locationId: 0
        };
        this.props.getLocationNoticeList();
    }

    getList = (list) => {
        let redirectButton = this.props.regionNotice.redirect_button_name;
        return (
            <div className="down">
                <List className="alertList">
                    {
                        list.map(
                            item =>
                            <Item
                                arrow="horizontal"
                                key={item.id}
                                onClick={() => {this.toDetails(item.id);}}
                            >
                                {item.location.name} <i className="iconfont icon-warning"></i>
                            </Item>
                        )
                    }
                </List>
                { redirectButton &&
                  <a href={this.props.regionNotice.redirect_url} className="more_btn" >{redirectButton}</a>}
                {/* <a href={this.props.regionNotice.redirect_url} className="more_btn" >{this.props.regionNotice.redirect_button_name}</a> */}
            </div>
        );
    }
    back = () => {
        if (this.state.scene === 'alerts') {
            this.props.back();
        } else {
            this.setState({
                scene: 'alerts',
                locationId: 0
            });
        }
    }
    toDetails = id => {
        this.setState({
            scene: 'locationAlerts',
            locationId: id
        });
    }
    getDetails = (locationId) => {
        let locationNotice = this.props.notice_all.find(item => item.id === parseInt(locationId));
        return (
            <div className="location_alerts">
                <h4 className="title">{locationNotice.location.name}</h4>
                <h4 className="title">{locationNotice.title}</h4>
                <div className="alerts_content">
                    <HtmlContent content={locationNotice.content}></HtmlContent>
                </div>
            </div>
        );
    }
    hidenav() {
        let hidenav = document.getElementById('footerNav');
        if (hidenav) {
            hidenav.style.display = "none";
        }
    }
    render() {
        let { regionNotice, notice, language_id, notice_all } = this.props;
        return (
            <div className="alerts">
                {this.hidenav()}
                <div className="alerts_head">
                    <button className="back" onClick={this.back}><i className="iconfont icon-left"></i></button>
                    <div className="item active">
                        <span>{intl('other', this.state.scene, language_id)}</span>
                    </div>
                </div>
                <div className="alerts_content">
                    <div className="user_notice">
                        <h4 className="title">{regionNotice.title}</h4>
                        <div className="user_notice_content">
                            <HtmlContent content={regionNotice.content}></HtmlContent>
                        </div>
                    </div>
                    {
                        this.state.scene === 'alerts' ? this.getList(notice_all) : this.getDetails(this.state.locationId)
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language_id: state.getIn(['header', 'language_id']),
        region_id: state.getIn(['header', 'region_id']),
        notice: state.getIn(['schedule', 'notice']).toJS(),
        regionNotice: state.getIn(['main', 'regionNotice']).toJS(),
        notice_all: state.getIn(['schedule', 'notice_all']).toJS(),
    };
};

const mapDispathToProps = (dispatch) => ({
    //获取地点通知列表
    getLocationNoticeList(){
        dispatch(getRegionNotice({language_id: this.language_id, region_id: this.region_id, with_notice: 1}));
    },
    //返回
    back(){
        let hidenav = document.getElementById('footerNav');
        hidenav.style.display = "block";
        dispatch(changeMoudle('Schedule'));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(LocationNotice);