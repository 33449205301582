if (parseInt(localStorage.getItem('region_id')) === 4) {
    var intl = 'cn';
} else if (
    parseInt(localStorage.getItem('region_id')) === 2) {
    var intl = 'sg';
} else {
    var intl = 'default';
}

let yogadomainUrl = {
    cn:  'https://www.pure-yoga.cn/shanghai',
    default:  'https://www.pure-yoga.com/hongkong',
    sg:  'https://www.pure-yoga.com/singapore',
};

let fitnessdomainUrl = {
    cn:  'https://www.pure-fitness.cn/shanghai',
    default:  'https://www.pure-fitness.com/hongkong',
    sg:  'https://www.pure-fitness.com/singapore',
};

let YogaDomainHost = yogadomainUrl[intl];
let FitnessDomainHost = fitnessdomainUrl[intl];

export const urlList = {
    //homeurl
    yoga_home_url:`${YogaDomainHost}`,
    fitness_home_url:`${FitnessDomainHost}`,
    //trial link
    yoga_trial_cn:`${YogaDomainHost}/trial/join/`,
    yoga_trial_default:`${YogaDomainHost}/trial/join/`,
    yoga_trial_sg:`${YogaDomainHost}/trial/join/`,

    fitness_trial_cn:`${FitnessDomainHost}/trial/join/`,
    fitness_trial_default:`${FitnessDomainHost}/trial/join/`,
    fitness_trial_sg:`${FitnessDomainHost}/trial/join/`,
    //privacy link
    yoga_privacy_cn: `${YogaDomainHost}/page/view/privacy_policy`,
    yoga_privacy_default: `${YogaDomainHost}/page/view/privacy_policy`,
    yoga_privacy_sg: `${YogaDomainHost}/page/view/privacy_policy`,

    fitness_privacy_cn:`${FitnessDomainHost}/page/view/privacy_policy`,
    fitness_privacy_default:`${FitnessDomainHost}/page/view/privacy_policy`,
    fitness_privacy_sg:`${FitnessDomainHost}/page/view/privacy_policy`,
    //terms link
    yoga_terms_cn: `${YogaDomainHost}/page/view/terms`,
    yoga_terms_default: `${YogaDomainHost}/page/view/terms`,
    yoga_terms_sg: `${YogaDomainHost}/page/view/terms`,

    fitness_terms_cn:`${FitnessDomainHost}/page/view/terms`,
    fitness_terms_default:`${FitnessDomainHost}/page/view/terms`,
    fitness_terms_sg:`${FitnessDomainHost}/page/view/terms`,


};


export const trial_link = (sector, regionId) => {
    let trialLinks = {
        "Y": {
            "1": urlList.yoga_trial_default,
            "2": urlList.yoga_trial_sg,
            "4": urlList.yoga_trial_cn,
        },
        "F": {
            "1": urlList.fitness_trial_default,
            "2": urlList.fitness_trial_sg,
            "4": urlList.fitness_trial_cn,
        },
    };
    return trialLinks[sector][regionId];

};

export const region_link = (sector, regionId) => {
    let regionLinks = {
        "Y": urlList.yoga_home_url,
        "F": urlList.fitness_home_url
    };
    return regionLinks[sector];
};

export const privacy_link = (sector, regionId) => {
    let privacyLinks = {
        "Y": {
            "1": urlList.yoga_privacy_default,
            "2": urlList.yoga_privacy_sg,
            "4": urlList.yoga_privacy_cn,
        },
        "F": {
            "1": urlList.fitness_privacy_default,
            "2": urlList.fitness_privacy_sg,
            "4": urlList.fitness_privacy_cn,
        },
    };
    return privacyLinks[sector][regionId];
};

export const terms_link = (sector, regionId) => {
    let termsLinks = {
        "Y": {
            "1": urlList.yoga_terms_default,
            "2": urlList.yoga_terms_sg,
            "4": urlList.yoga_terms_cn,
        },
        "F": {
            "1": urlList.fitness_terms_default,
            "2": urlList.fitness_terms_sg,
            "4": urlList.fitness_terms_cn,
        },
    };
    return termsLinks[sector][regionId];
};


