import styled from 'styled-components';
import { device } from '../assets/css/device';
import { mobileStyle, desktopStyle } from '../config/style';
let camera = require('../assets/images/camera.png');
const arrowIcon = require('../assets/images/arrow.png');

export const Content = styled.div`
    padding:0 60px 100px 30px;
    .signInContent{
        overflow:hidden;
        .skip{float: right;color:#2478BE;font-size: 16px;border:none;background: none;outline:none;cursor: pointer;}
        .components_title{
            font-size:20px;font-weight:bold;overflow:hidden;line-height:28px;padding: 50px 0 20px;
            button{border:none;background: none;outline:none;float:left;cursor: pointer;}
        }
        .filter{
            margin-bottom:30px;
            .ant-select{
                margin-right: 10px;
                .ant-select-selection{
                    height: 40px;border:none;background: #F2F2F2;border-radius:10px;
                }
                .ant-select-selection__rendered{
                    line-height: 40px;font-size: 16px;
                }
            }
        }
        table{
            width:100%;text-align: left;margin-bottom:40px;
            .ant-table-tbody{
                tr{
                    td{
                    padding: 5px;
                    }
                }
            }
            .ant-table-thead{
                tr{
                    th{
                        color: rgb(153, 153, 153);
                        font-weight: normal;
                        background: unset;
                        white-space: nowrap;
                        padding: 5px;
                        // word-break: normal;

                        &.break_spaces {
                            white-space: break-spaces;
                            line-height: 1rem;
                        }
                    }
                }
            }
            thead{
                tr{color: #999;}
            }
            tr{
                color: #666;border-bottom: 1px solid #d5d5d5;
                &.loading{border-bottom:none;}
            }
            tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
                td {
                    background: unset !important;
            } 
            }
            td,th{
                height: 60px;vertical-align: middle;color: #999;word-break:break-word;position:relative;
                &.name{
                    color: #333;font-weight: bold;position: relative;
                }
                em{font-style:normal}
                a{color: #666;}
                &.method{
                    max-width:150px;
                }
                .line{
                    width: 6px;height: 100%;position: absolute;top:0;
                }
                .classTime {
                    display: flex;
                    justify-content: space-between;
                }
                &.no_wrap {
                    white-space: nowrap;
                }
                .classTitle {
                    font-weight: 700;
                    color: black;
                }
            }
        }
        .cancelList{
            text-align: center;font-size:14px;
            td,th{
                &:first-child{padding-left: 10px;text-align: left;}
                &:nth-child(2){padding-left: 20px;text-align: left;}
            }
            td:nth-child(2){font-size: 16px;padding-left:20px}
        }
        .orderBy{
            float:right;line-height:40px;padding:0 34px 0 15px;background:#f2f2f2 url(${arrowIcon})no-repeat;background-position:156px 11px;background-size:14px;cursor:pointer;border-radius:10px;width:180px;
            &.desc{background-position:156px -28px;}
        }
    }
    ${mobileStyle(`{
        padding: unset;
        height: 100%;
        overflow: scroll;
        .signInContent{
            height: 100%;
            overflow: scroll;
        }
        .nav{
            margin:0;
            position: sticky;
            background: white;
            z-index: 6;
            top: 0px;
        }
        .back{
            width: auto;
            height: 1.28rem;
            width: 1rem;
            text-align: center;
            position: absolute;
            left: 0px;
            background: none;
            z-index: 3;
        }
    
        .navbar-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            height: 1.28rem;
            line-height: 1.28rem;
            margin-bottom: 0;
            border-bottom: 1px solid #ddd;
    
                a{
                line-height: 1.28rem;
                display: inline-block;
                position: relative;
                color: #bbb;
                transition: 0.5s;
                padding: 0 0.2667rem;
                font-size: 0.3733rem;
    
                    &:after{
                        content: '';
                        width: 100%;
                        height: 0.1333rem;
                        background: #404040;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                        transition: 0.5s;
                        opacity: 0;
                    }
                }
                .active{
                    color: #000;
                    &:after{opacity: 1;}
                }
    
        }
        .components_title{
            button{
                border: none;
                background: none;
                outline: none;
                float: left;
                line-height: 1;
                cursor: pointer;
                line-height: 0.7rem;
            }
        }
    }`)}
`
export const FavouriteTabWrapper = styled.div`
.favourite-inner-tab {
    padding: 0 8px;
    ${desktopStyle(`{
        padding: 8px;
        text-align: center;

        .ant-row {
            margin: auto !important;
            width: 40%;
        }
        .ant-list {
            margin: auto !important;
            width: 40%;
        }
        .ant-tabs-tab{
            font-size: 16px;
        }
    }`)}
    .ant-tabs-nav {
        padding-bottom: 1px;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        font-weight: normal;
        color: unset;
        &.ant-tabs-tab-active {
            color: #fff;
        }
    }
    .ant-tabs-tab {
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        padding: 8px;
        margin-right: 8px;
        transition: none;

        &.ant-tabs-tab-active {
            background: black;
            color: white;
            padding: 8px;
            margin-right: 8px;
            font-weight: normal;
        }
    }
    .ant-tabs-ink-bar {
        display: none !important;
    }
    .ant-tabs-bar {
        border: 0;
    }
    .ant-tabs-nav .ant-tabs-tab:last-child {
        margin-right: unset;
    }
}`;
export const ProfileContentWrapper = styled.div`
    overflow:hidden;padding-top:110px;
    ${mobileStyle(`{
        padding-top: 0;
        height: 100%;
        // display: flex;
        // padding-top: 0.76rem;
        // flex-direction: column;
        // overflow: scroll;
    }`)}
    .ant-tabs {
        height: 100%;
        .ant-tabs-content {
            height: 100%;
            .ant-tabs-tabpane{
                height: calc(100% - 44px);
                overflow: scroll;
            }
        }
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #000;
        font-weight: 600;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #000;
        font-weight: 600;
    }
    .ant-tabs-ink-bar{
        height: 3px;
        background-color: rgb(0, 0, 0);
    }
    .ant-tabs-nav-container {
        text-align: center;
    }
`;

export const ProfileContent = styled.div`
    // overflow:hidden;padding-top:110px;
    ${mobileStyle(`{
        display: flex;
        padding-top: 0;
        // padding-top: 0.76rem;
        flex-direction: column;
        overflow: scroll;
        height: 100%;
        #myProfile {
            padding-top: 0.76rem;
        }

        .myProfileTitle {
            display: none;
        }
    }`)}
`

export const ContentLeft = styled.div`
    width:50%;float:left;overflow:hidden;
    .content{max-width:600px;float:right;padding-right:7.5%;margin:auto}
    ${mobileStyle(`{
        width: 100%;
        float: none;
        overflow:unset;
        .content{
            float: none;
            padding-right: 0;
            .infoDiv{
                display: flex;
                justify-content: space-evenly;
                width: 95%;
                margin: auto;
            }
        }
    }`)}
`
export const AvatorInfo = styled.div`
    position:relative;margin:20px auto;width:194px;height:194px;margin:0 auto;
    .ant-avatar{overflow: hidden;border:1px solid #ddd;border-radius:20px;}
    .imagesfile{
        position: absolute;right: -12px;bottom: -12px;background: #333 url(${camera}) no-repeat center;color:#fff;width:32px;height:32px;border-radius:50%;background-size:18px;border:2px solid #fff;cursor:pointer;overflow:hidden;z-index:10;font-size:0;
        input{opacity:0;position:relative;width:32px;height:32px;overflow:hidden;cursor:pointer;z-index:10;}
    }
    ${mobileStyle(`{
        width: 3.4667rem !important;
        height: 3.4667rem !important;
        .ant-avatar{
            width: 3.4667rem !important;
            height: 3.4667rem !important;
        }
        .imagesfile{
            width:1.1467rem;height:1.1467rem;background-size: 0.6rem;
            input{opacity:0;position:relative;width:32px;height:32px;overflow:hidden;cursor:pointer;z-index:10;}
        }
    }`)}
`
export const UserName = styled.div`
    font-size:18px;font-weight:bold;text-align:center;margin:16px 0 24px;
    ${mobileStyle(`{
        margin-bottom: 0;
    }`)}
`
export const InfoBtn = styled.div`
    width:385px;height:96px;border-radius:20px;margin:10px auto 0;text-align:center;line-height:1.4em;padding:27px 0 20px;background:#F2F2F2;position:relative;
    p{margin-top:10px;font-size:16px;color:#666}
    b{font-size:24px;}
    strong{font-size:18px;color:#2378bd;}
    .anticon{font-size: 24px;position: absolute;right: 10px;bottom:10px;color: #A6A6A6;}
    ${mobileStyle(`{
        margin: 10px 5px 0;
        p{
            margin-top: 0.1333rem;
            font-size: 0.32rem;
            color: #a6a6a6;
        }
        b{
            font-size: 0.4267rem;
            color: #000;
            line-height: 1;
            margin-bottom: 0.1333rem;
        }
        strong{
            font-size: 0.4267rem;
            color: #000;
            line-height: 1;
            margin-bottom: 0.1333rem;
        }
    }`)}
`

export const ContentRight = styled.div`
    width:50%;float:right;overflow:hidden;
    .content{width:600px;float:left;paddding-left:7.5%;}
    ${mobileStyle(`{
        width: 100%;
        float: none;
        overflow:unset;
        .content{
            width: auto;
            float: none;
            padding-right: 0;
            height:70%;
        }
    }`)}
`
export const ContentRightItem = styled.div`
    padding:0 20px;
    .title{
        line-height: 60px;font-weight: bold;font-size: 20px;color: #000;padding-left: 10px;border-bottom:1px solid #A6A6A6;
    }
    &.mt100{margin-top: 50px;}
    ${mobileStyle(`{
        // padding:0 20px .2rem;
        padding: 20px;
        &.mt100{
            margin-top: unset;
            padding-top: 0.2rem;
        }
    }`)}
    .anticon-question-circle{color: #A6A6A6;cursor:pointer;display: block !important}
`
export const InfoItem = styled.div`
    overflow:hidden;line-height:70px;border-bottom:1px solid #A6A6A6;padding:0 10px;
    span{
        font-size: 18px;
        &.info{float: right;color: #333;}
        &.name{color: #666;float: left;}
        .anticon{color: #A6A6A6;}
    }
    &.skip{cursor: pointer;}
    ${mobileStyle(`{
        line-height: 44px;
        min-height: 44px;
        display: flex;
        border-bottom: 1px solid #ddd;
        span{
            font-size: 0.3733rem;
            &.info{
                width: 60%;
                text-align: right;
                color: #555;
                // text-overflow: ellipsis;
                overflow-y: scroll;
                white-space: nowrap;
                float: none;
            }
            &.name{
                color: #8c8c8c;
                width: 40%;
                float: none;
            }
            .anticon{color: #A6A6A6;}
        }
    }`)}
`

export const ControlItem = styled.div`
    overflow:hidden;line-height:70px;border-bottom:1px solid #A6A6A6;padding:0 10px;
    span{color: #666;float: left;font-size: 18px;color: #666;display:flex;}
    .switch{float: right;}
    .ant-switch-checked{background-color: #4BD865;}
    .anticon{margin: auto 8px;}
    ${mobileStyle(`{
        .anticon{
            margin: auto 8px;
        }
        line-height: 44px;
        min-height: 44px;
        border-bottom: 1px solid #ddd;
        span{
            font-size: 0.3733rem;
            &.name{
                color: #8c8c8c;
            }
        }
    }`)}
`

export const Nav = styled.ul`
    height: 64px; width: 100%;padding:20px 30px 0 50px;overflow:hidden;
    ${mobileStyle(`{
        justify-content: space-evenly;
        display: flex;
        padding:20px 30px 0 30px;
        margin-bottom: 0;
        text-align: center;
    }`)}
    &:after{display:block;clear:both;content:"";visibility:hidden;height:0}
    a{
        float: left;height:44px;position:relative;margin:0 50px;color: #BFBFBF;cursor:pointer;font-size: 18px;height: 44px;position: relative;transition:color 0.5s;line-height: 1;
        ${mobileStyle(`{
            width: 50%;
            margin: auto;
        }`)}
        &:first-child{margin-left: 0;}
        &:after{content:'';width: 100px;height: 6px;background: #404040;position: absolute;left:50%;margin-left: -50px;bottom:0;opacity: 0;transition: all 0.5s;}
        &.active{
            color:#000;font-weight:bold;
            &:after{opacity: 1;}
        }
        &:hover{color:#000;font-weight:bold;}
    }
`