export const LOGIN_OUT = 'main/LOGIN_OUT';
export const SHOW_LOGIN = 'main/SHOW_LOGIN';
export const CLOSE_LOGIN = 'main/CLOSE_LOGIN';
export const GET_USERNAME = 'main/GET_USERNAME';
export const GET_PASSWORD = 'main/GET_PASSWORD';
export const CHANGE_STAY_LOGIN = 'main/CHANGE_STAY_LOGIN';
export const SET_USER_INFO = 'main/SET_USER_INFO';
export const CHANGE_MOUDLE = 'main/CHANGE_MOUDLE';
export const CHANGE_REGION_NOTICE = 'main/CHANGE_REGION_NOTICE';
export const CHANGE_MODAL = 'main/CHANGE_MODAL';
export const OPEN_TEACHER_MODAL = 'main/OPEN_TEACHER_MODAL';
export const CLOSE_TEACHER_MODAL = 'main/CLOSE_TEACHER_MODAL';
export const OPEN_TEACHER_MODAL_SCHEDULE = 'main/OPEN_TEACHER_MODAL_SCHEDULE';

export const OPEN_LOCATION_MODAL = 'main/OPEN_LOCATION_MODAL';
export const CLOSE_LOCATION_MODAL = 'main/CLOSE_LOCATION_MODAL';
export const OPEN_LOCATION_MODAL_SCHEDULE = 'main/OPEN_LOCATION_MODAL_SCHEDULE';

export const OPEN_CLASS_MODAL = 'main/OPEN_CLASS_MODAL';
export const OPEN_CLASS_MODAL_SCHEDULE = 'main/OPEN_CLASS_MODAL_SCHEDULE';
export const CLOSE_CLASS_MODAL = 'main/CLOSE_CLASS_MODAL';
export const OPEN_REGION_SELECT_MODAL = 'main/OPEN_REGION_SELECT_MODAL';
export const CLOSE_REGION_SELECT_MODAL = 'main/CLOSE_REGION_SELECT_MODAL';
export const CHANGE_UNLEASH_FLAG = 'schedule/CHANGE_UNLEASH_FLAG';


