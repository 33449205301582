import styled from 'styled-components';
import { device } from '../../assets/css/device';
import { mobileStyle, desktopStyle } from '../../config/style';

export const LoginMain = styled.div`
    padding-bottom:40px;
    ${mobileStyle(`{
        padding-bottom: unset;
        min-height: unset !important;
        overflow-y: scroll;
        position: relative;
        z-index: 2;
        }`)}
`

export const LoginBefore = styled.div`
    background-color:#F2F2F2;padding:80px 0 80px;
    .content{
        margin:0 auto;overflow:hidden;width: 420px;
        .call{
            text-align: center;margin:12px 0 0;width: 340px;float:right;font-size: 14px;
            .pop{
                color:#555;cursor:pointer;
                &:hover{color:#333}
            }
        }
    }
    ${mobileStyle(`{
        position: unset;
        width: 100%;
        padding: 10px 0.2667rem;
        .callBooking {
            transform: translateY(0.1rem);
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0.7rem;
            text-align: center;
            font-size: 0.2144rem;
            a {
                color: #888;
                font-size: 0.4144rem;
            }
        }
        .content{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .try_now {
                text-align: center;
                height: 1.3333rem;
                display: flex;
                align-items: center;
                color: #373a3d;
                font-weight: bold;
                white-space: pre-line;
                cursor: pointer;
                font-size: 0.32rem;
            }
        }
        }`)}
        ${desktopStyle(`{
            position: relative;
            }`)}
    .download_btn{
        height: 64px;line-height: 60px;background:#fff;border:1px solid #a6a6a6;border-radius: 10px;padding:0 6px;margin-top:10px;cursor: pointer;transition:all 0.5s;
        &:hover{background:#f1f1f1;box-shadow:0 2px 10px rgba(0,0,0,0.1)}
        .ant-avatar{
            border:1px solid #ccc;margin-right:20px;background:#fff;vertical-align: middle;
        }
    }
`

export const LoginBtn = styled.div`
    width:340px;height:64px;color: #fff;background:#000; border-radius: 20px;text-align:center;font-size:16px;transition:all 0.5s; cursor: pointer;line-height: 64px;float:right;
    &:hover{background:#222222;box-shadow:0 2px 10px rgba(35, 120, 189, 0.2);}
    .anticon{font-size:14px;}
    ${mobileStyle(`{
        height: 1.333rem;
        font-size: 0.32rem;
        flex: 1 1;
        line-height: 1.33rem;
        margin: 0 0.2667rem;
        border-radius: 0.2667rem;
        }`)}
`
export const DownloadBtn = styled.div`
    cursor: pointer;transition:all 0.5s;display:inline-block;width:64px;height:64px;border-radius:10px;float:left;
    ${mobileStyle(`{
        float: none;
        width: 1.333rem;
        height: 1.333rem;
        .ant-avatar{
            width: 1.333rem !important;
            height: 1.333rem !important;
            line-height: 64px;
        }
        }`)}
    &:hover{background:#f1f1f1;box-shadow:0 2px 10px rgba(0,0,0,0.1);}
    .ant-avatar{
        background:#fff;
        vertical-align: middle;
        border:1px solid #ccc;
        border-radius:10px;
    }
    a{color:#333}
`

export const LoginAfter = styled.div`
    position:relative;border-bottom:1px solid #BFBFBF;height:108px;
    ${mobileStyle(`{
        height: auto;border-bottom:none;
        .status_top{
            display:none;
        }
        }`)}
    .status_top{
        height: 44px;background: #F2F2F2;padding:0 30px 0 30px;overflow: hidden;line-height: 44px;
        .left{float: left;color:#555;font-size: 16px;}
        .right{
            float: right;
            .btn{
                outline:none;border:none;background: none;font-size: 16px;padding: 0 15px;position: relative;cursor: pointer;color: #999;transition:all 0.5s;
                &:hover{color: #000;}
                &:after{content:'';height: 16px;width: 1px;background: #999;position: absolute;right:0;top:50%;margin-top: -8px;}
                &:last-child{
                    padding-right: 0;
                    &:after{display: none;}
                }
            }
        }
    }
`

export const Nav = styled.ul`
    height: 64px; width: 100%;padding:20px 30px 0 50px;overflow:hidden;
    ${mobileStyle(`{
        height:auto;
        justify-content: space-evenly;
        display: flex;
        padding:20px 30px 0 30px;
        margin-bottom: 0;
        text-align: center;
        .to_booking {
            flex: 1;
            height: 1.3333rem;
            background: #f2f2f2;
            border-radius: 0.2667rem;
            color: #9d9d9d;
            font-size: 0.4267rem;
            position: relative;
            margin-right: 0.2667rem;

            .book_num {
                position: absolute;
                width: 0.8rem;
                height: 0.8rem;
                line-height: 0.8rem;
                right: 0.2667rem;
                top: 50%;
                margin-top: -0.4rem;
                background: #fff;
                border-radius: 0.2rem;
            }
        }

        .to_user {
            width: 1.0667rem;
            height: 1.3333rem;
            background: none;

            .userIcon {
                font-size: 0.64rem;
                color: #9d9d9d;
            }
        }
        }`)}
    &:after{display:block;clear:both;content:"";visibility:hidden;height:0}
`
export const NavItem = styled.li`
    float: left;height:44px;position:relative;margin:0 50px;color: #BFBFBF;cursor:pointer;font-size: 18px;height: 44px;position: relative;transition:color 0.5s;line-height: 1;
    ${mobileStyle(`{
        width: 33%;
        margin: auto;
    }`)}
    &:first-child{margin-left: 0;}
    &:after{content:'';width: 100px;height: 6px;background: #404040;position: absolute;left:50%;margin-left: -50px;bottom:0;opacity: 0;transition: all 0.5s;}
    &.active{
        color:#000;font-weight:bold;
        &:after{opacity: 1;}
    }
    &:hover{color:#000;font-weight:bold;}
`

export const LoginForm = styled.div`
    @keyframes am-fade {
        0% {opacity: 0;}
        100% {opacity: 1;}
        }
        @keyframes slide-bottom {
        0% {opacity: 0;-webkit-transform: translateY(100%);transform: translateY(100%);}
        100% {opacity: 1;-webkit-transform: translateY(0);transform: translateY(0);}
    }
    .shadow{
        width: 100%;height: 100%;background:rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index:999; display:none;animation-name: am-fade;animation-duration: 0.5s;animation-timing-function: linear;
        &.show{display:block;}
    }
    .shadow_content{
        display: none;width: 100%;height: 100%;
        &.show{
            display: block;
            .login_box{animation-duration: 0.5s;animation-timing-function: ease-out;animation-fill-mode: both;animation-name: slide-bottom;animation-delay: 0.3s;}
        }
        .close_shadow{width: 100%;height: 100%;z-index: 10;}
        .login_box{
            width: 430px;height: 540px;position: absolute;top:50%;left: 50%;margin: -295px 0 0 -180px;background: #fff;border-radius: 20px;box-shadow:0 0 20px rgba(255,255,255,0.1);z-index: 11;
            .title{font-weight: bold;font-size: 20px;color: #000;text-align:center;padding-top:50px;}
            .logo{
                width: 175px;margin: 50px auto 30px;
                img{width: 100%;}
            }
            .disc{text-align: center;color: #808080;line-height: 16px;}
            .login_form{
                padding:0 20px;margin-top: 40px;
                .input_item{position: relative;margin-bottom: 20px;}
                .anticon-global{position: absolute;top:50%;transform:translateY(-50%); left: 6px;color: rgba(0,0,0,0.25);}
                .ant-select {width: 100%;}
                .ant-select-selection{
                    border:none;border-bottom:none;border-radius:0;outline: none;height: 64px;width: 100%;background: #F2F2F2;border-radius:16px;
                    &.ant-select-selection,&:focus{box-shadow: none!important;}
                    .ant-select-selection__rendered{line-height:64px;}
                    .ant-select-selection__placeholder,.ant-select-selection-selected-value{width:100%;text-align:center;font-size: 18px;height:64px;line-height:64px;top:0;margin-top:0;color:#8D8D8D;}
                }
                .ant-input-affix-wrapper .ant-input-prefix{left: 6px;}
                .ant-input{
                    height: 50px;border:none;border-bottom: 1px solid #d9d9d9;border-radius:0;outline: none;line-height: 50px;font-size: 18px;padding:0 11px;background:#fff;
                    &.ant-select-selection,&:focus{box-shadow: none!important;background:#fff;}
                    &:-webkit-autofill {
                        z-index: 100;
                        -webkit-box-shadow: 0 0 0px 1000px white inset;
                    }
                }
                .ant-checkbox-inner{border-color: #cecece;width: 24px;height: 24px;border-radius:5px;}
                .ant-checkbox-checked .ant-checkbox-inner{
                    border-color: #f2f2f2;background: #f2f2f2;
                    &:after{transform: rotate(45deg) scale(1.5);border-color:#8d8d8d;margin: -5px 0 0 4px;z-index:10;}
                }
                .stay_loggin{
                    text-align: center;margin: 30px 0;
                    .ant-checkbox-wrapper{font-size: 16px;color: #8d8d8d;}
                    .ant-checkbox-input{border:#8d8d8d;}
                    .ant-checkbox-checked:after{border-color:#f2f2f2;background: #f2f2f2;}
                }
                .submit,.register{
                    text-align: center;
                    .ant-btn{width: 100%;height:64px;line-height:64px;background: #000;color:#fff;font-size: 18px;border-radius:16px;border:none;}
                    .anticon{transform:translateY(2px)}
                }
                .link{
                    text-align: center;
                    .forget{
                        color: #AFB1B1;font-size: 16px;padding:0 20px;position: relative;cursor:pointer;display:inline-block;
                        &:first-child:after{content:'';width: 2px;height: 12px;position: absolute;top:50%;margin-top: -4px;background: #AFB1B1;right:0}
                    }
                }
            }
        }
    }
    ${mobileStyle(`{
        .shadow_content {
            &.show{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
            }
            .login_box {
                width: 95%;
                height: unset;
                top: unset;
                left: unset;
                padding: 1rem 0.2667rem 0.6rem;
                margin: unset;
                border-radius: 0.2667rem;
                .title{
                    padding-top: unset;
                    font-size: 0.48rem;
                }
                .login_form{
                    padding:0;
                    margin-top: 0.8rem;
                    .input_item{
                        position: relative;
                        margin-bottom: 0.35rem;
                    }
                    .anticon-global{position: absolute;top:50%;transform:translateY(-50%); left: 6px;color: rgba(0,0,0,0.25);}
                    .ant-select {
                        width: 100%;
                        height: 100%;
                    }
                    .ant-input{
                        height: 0.85rem;font-size: 0.3733rem;line-height:1;
                        &.ant-select-selection,&:focus{box-shadow: none!important;background:#fff;}
                        &:-webkit-autofill {
                            z-index: 100;
                            -webkit-box-shadow: 0 0 0px 1000px white inset;
                        }
                    }
                    .ant-select-selection{
                        width: 100%;
                        height: 100%;
                        line-height: 1.3333rem;
                        background: #f2f2f2 !important;
                        border-radius: 0.2667rem;
                        &.ant-select-selection,&:focus{box-shadow: none!important;}
                        .ant-select-selection__rendered{
                            line-height: inherit;
                        }
                        .ant-select-selection__placeholder,.ant-select-selection-selected-value{
                            font-size: 0.4rem;
                            line-height: inherit;
                            height: 100%;
                        }
                        .ant-select-arrow-icon{
                            color: #888!important;
                            font-size: 0.313rem !important;
                        }
                    }
                    .ant-checkbox-inner{
                        border-color: transparent;
                        background-color: #f2f2f2;
                    }
                    .ant-checkbox-checked .ant-checkbox-inner{
                        border-color: transparent;background: #f2f2f2;
                        &:after{transform: rotate(45deg) scale(1.5);border-color:#8d8d8d;margin: -5px 0 0 4px;z-index:10;}
                    }
                    .stay_loggin{
                        text-align: center;
                        margin: 0.7333rem 0;
                        .ant-checkbox-wrapper{
                            font-size: 0.3733rem;
                            color: #8d8d8d;
                        }
                        .ant-checkbox-input{background-color: #f2f2f2;}
                        .ant-checkbox-checked:after{border-color:#f2f2f2;background: #f2f2f2;}
                    }
                    .submit,.register{
                        text-align: center;
                        .ant-btn{
                            height: 1.33rem;
                            line-height: 1.33rem;
                            border-radius: 0.2667rem;
                            font-size: 0.4rem;
                        }
                        .anticon{
                            font-size: 0.4rem !important;
                            margin-left: 0px !important;
                        }
                    }
                    .link{
                        text-align: center;
                        margin-top: 0;
                        font-size: 0.3733rem;
                        display: flex;
                        justify-content: center;
                        color: #a6a6a6;
                        .forget{
                            color: #AFB1B1;font-size: inherit;padding:0 20px;position: relative;cursor:pointer;display:inline-block;
                            &:first-child:after{content:'';width: 2px;height: 12px;position: absolute;top:50%;margin-top: -4px;background: #AFB1B1;right:0}
                        }
                    }
                }
            }
        }
    }`)}
`