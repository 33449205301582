import styled from 'styled-components';
const arrowIcon = require('../assets/images/arrow.png');
const next = require('../assets/images/down.png');
const prev = require('../assets/images/upward.png');
import { mobileStyle, platformClasses } from '../config/style';

export const BookContent = styled.div`
    padding:0 30px 0 30px;
    .filter{
        margin-bottom:30px;
        .ant-select{
            margin-right: 10px;
            .ant-select-selection{
                height: 40px;border:none;background: #F2F2F2;border-radius:10px;
            }
            .ant-select-selection__rendered{
                line-height: 40px;font-size: 16px;margin-left:20px;
            }
        }
    }
    table{
        font-size: 16px;
        width:100%;
        text-align: left;
        margin-bottom:40px;
        .ant-table-tbody{
            tr{
                td{
                padding: 5px;
                }
            }
        }
        .ant-table-thead{
            tr{
                th{
                    color: rgb(153, 153, 153);
                    font-weight: normal;
                    padding: 5px !important;
                }
            }
        }
        thead{
            tr{color: #999;}
        }
        tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
            td {
                background: unset !important;
        } 
        }
        tr{
            color: #666;border-bottom: 1px solid #d5d5d5;
            &.loading{border-bottom:none;}
        }
        td,th{
            height: 60px;
            vertical-align: middle;
            color: #999;
            word-break:break-word;
            position:relative;
            background: unset !important;
            font-weight: normal;
            &.name{
                color: #333;font-weight: bold;position: relative;
            }
            .line{
                width: 6px;height: 100%;position: absolute;top:0;
            }
            em{font-style:normal}
            a{color: #666;}
            .classTime {
                display: flex;
                justify-content: space-between;
            }
            .classTitle {
                font-weight: 700;
                color: black;
            }
            }
        }
    }
    .bookingList{
        text-align: left;font-size:16px;
        td,th{
            padding-left: 30px;
            &:nth-last-child(-n+3){text-align:center;padding-left:0;}
        }
        td span.date{
            width: 120px;
            display: inline-block;
            &.en{
                width: 100px;
            }
        }
    }
    .cancelList{
        text-align: center;font-size:14px;
        td,th{
            &:first-child,&:nth-child(2){padding-left: 30px;text-align: left;}
        }
        td span.date{
            width: 100px;
            display: inline-block;
        }
        td:nth-child(2){font-size: 16px;}
    }
`  

export const OrderBy = styled.div`
    float:right;line-height:40px;padding:0 34px 0 20px;background:#f2f2f2 url(${arrowIcon})no-repeat;background-position:156px 11px;background-size:14px;cursor:pointer;border-radius:10px;width:180px;
    &.desc{background-position:156px -28px;}
`

export const ContentTop = styled.div`
    overflow:hidden;padding: 50px 0 20px;
    .skip{float: right;color:#14E1DC;font-size: 16px;border:none;background: none;outline:none;cursor: pointer;}
`  

export const ColumnTitle = styled.div`
    font-size:20px;font-weight:bold;float:left;overflow:hidden;line-height:28px;
    button{border:none;background: none;outline:none;float:left;line-height:1;cursor: pointer;} 
`   

export const MyBooking= styled.div`
    ${mobileStyle(`{
        padding: unset;
        height: 100%;
        overflow: scroll;
        .nav{
            margin:0;
            position: sticky;
            background: white;
            z-index: 6;
            top: 0;
        }
        .back{
            width: auto;
            height: 1.28rem;
            width: 1rem;
            text-align: center;
            position: absolute;
            left: 0px;
            background: none;
            z-index: 3;
        }

        .navbar-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            height: 1.28rem;
            line-height: 1.28rem;
            margin-bottom: 0;
            border-bottom: 1px solid #ddd;

            li{
                a{
                line-height: 1.28rem;
                display: inline-block;
                position: relative;
                color: #bbb;
                transition: 0.5s;
                padding: 0 0.2667rem;
                font-size: 0.3467rem;

                    &:after{
                        content: '';
                        width: 100%;
                        height: 0.1333rem;
                        background: #404040;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                        transition: 0.5s;
                        opacity: 0;
                    }
                }
                .active{
                    color: #000;
                    &:after{opacity: 1;}
                }
            }

        }
    }`)}

    .xcalendar{
        width: 100%;
        border: none;
        position: relative;
        &.hide .react-calendar__month-view {
            height: 0;
            overflow: hidden;
        }

        .react-calendar__navigation button {
            background: none;
            min-width: 1.1rem;
            min-height: 1.1rem;
            opacity: 0.65;
        }
        .react-calendar button {
            margin: 0;
            border: 0;
            outline: none;
            background: white;
        }
        .react-calendar__navigation{
            margin-bottom: 0;
            font-size: 0.3733rem;
            height: 1.1rem;
            display: flex;
        }
        .react-calendar__month-view__weekdays,.react-calendar__navigation__prev2-button,.react-calendar__navigation__next2-button{
            display: none!important;
        }
        .react-calendar__navigation__prev-button{
            position: absolute;
            right: 44px;
            height: 44px;
            z-index: 3;
        }
        .react-calendar__navigation__next-button{
            position: absolute;
            right: 0;
            height: 44px;
            z-index: 3;
        }
        .calendarIconPrev {
            background-image:url(${prev})!important;
        }
        .calendarIconNext {
            background-image:url(${next})!important;
        }
        .calendarIcon{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat !important;
            background-size: 50% 50% !important;
            background: center;
        }
        .react-calendar__month-view {
            font-size: 0.3733rem;
            transition: 0.5s;
        }
        .react-calendar__tile{
            padding: 0.85em 0.5em;
            background: none;
        }
        .react-calendar__month-view__days{
            margin-bottom: 0.2667rem;
        }
        .react-calendar__month-view__days__day--weekend{
            color: #000;
        }
        .react-calendar__month-view__days__day--neighboringMonth{
            color: #757575;
        }
        .react-calendar__tile--active{
            position: relative;
            color: #fff;
            background: none;
            abbr{
                z-index: 3;
                position: relative;
            }
            &:before{
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background: #000;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                pointer-events: none;
            }
        }
        .react-calendar__navigation__label{
            pointer-events: none;
        }
        .hasClass{
            position: relative;
            &:after{
                content: '';
                width: 0.1333rem;
                height: 0.1333rem;
                background: #2478BE;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 5;
            }
        }
        .tile-container{
            position: relative;
            width: 100%;
            height: 100%;
            .has-class{
                display: flex;
                flex-direction: row;
                gap: 4px;
                justify-content: center;
                position: absolute;
                width: 100%;
                bottom: 0.12rem;
                .has-yoga{
                    content: '';
                    width: 0.12rem;
                    height: 0.12rem;
                    background: ${platformClasses.yoga};
                    border-radius: 50%;
                    bottom: 0;
                    z-index: 5;
                }
                .has-fitness{
                    content: '';
                    width: 0.12rem;
                    height: 0.12rem;
                    background: ${platformClasses.fitness};
                    border-radius: 50%;
                    bottom: 0;
                    z-index: 5;
                }
        }
        }
    }
    .bookList{
        .date{
            color: #000;
            background: #f2f2f2;
            padding: 0 0.4rem;
            height: 0.8rem;
            line-height: 0.8rem;
            font-weight: normal;
            font-size: .32rem
        }
        li{
            &.nodata{
                padding: 0;
                color: #f2f2f2;
                font-size: 0.48rem;
                font-weight: bold;
                border:none;
                justify-content: center;
                height: 1.5rem;
            }
        }
    }
`