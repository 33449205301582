import React, { Component } from 'react';
import { connect } from 'react-redux';
import './cancelDetails.scss';
import { intl, showMsg } from '../../../config/util';
import { handleMoudleChange } from '../../../common/main/store/actionCreators';
import { Icon } from 'antd';
import { isMobile } from 'react-device-detect';

class CancelDetails extends Component {
    ref = React.createRef();
    previousHeaderHeight = '';
    //获取名字
    getName = (data, language_id) => {
        return Array.isArray(data.detail) ? data.detail.find(item => item.language_id === parseInt(language_id)).name : data.name;
    }
    //calculate height for cancelContent area
    componentDidMount() {
        if (isMobile) {
            let currentHeaderHeight = this.ref.current.clientHeight;
            const cancelContent = document.getElementById("cancelContent");

            cancelContent.style.height = `calc(100% - ${currentHeaderHeight}px)`;
        }
    }
    render() {
        let { cancelDetails, language_id } = this.props;
        return (
            <div className="cancelDetails">
                <div className="tab" id="tab" ref={this.ref}>
                    <button className="back" onClick={()=>this.props.goBack()}><i className="iconfont icon-left"></i></button>
                    <div className="item black">
                        <span>{intl('cancel', 'cancelDetails', language_id)}</span>
                    </div>
                </div>
                <div className="cancelContent" id="cancelContent">
                    <ul className="item">
                        <li>
                            <div className="name">{intl('cancel', 'cancelDate', language_id)}</div>
                            <div className="info">{cancelDetails.cancel_date}</div>
                        </li>
                        <li>
                            <div className="name">{intl('cancel', 'cancelTime', language_id)}</div>
                            <div className="info">{cancelDetails.cancel_time_display}</div>
                        </li>
                        <li>
                            <div className="name">{intl('cancel', 'cancelledBy', language_id)}</div>
                            <div className="info">{cancelDetails.cancelled_by}</div>
                        </li>
                    </ul>
                    <hr />
                    <ul className="item">
                        <li>
                            <div className="name">{intl('cancel', 'classDate', language_id)}</div>
                            <div className="info">{cancelDetails.class.start_date}</div>
                        </li>
                        <li>
                            <div className="name">{intl('cancel', 'classTime', language_id)}</div>
                            <div className="info">{cancelDetails.class.start_time_display}</div>
                        </li>
                        <li>
                            <div className="name">{intl('cancel', 'className', language_id)}</div>
                            <div className="info">{this.getName(cancelDetails.class.class_type, language_id)}</div>
                        </li>
                        <li>
                            <div className="name">{intl('cancel', 'classLocation', language_id)}</div>
                            <div className="info">{this.getName(cancelDetails.class.location, language_id)}</div>
                        </li>
                        <li>
                            <div className="name">{intl('cancel', 'teacherInstructor', language_id)}</div>
                            <div className="info">{this.getName(cancelDetails.class.teacher, language_id)}</div>
                        </li>
                    </ul>
                    <hr />
                    <ul className="item">
                        <li>
                            <div className="name">{intl('cancel', 'latecancel', language_id)}</div>
                            <div className="info"><Icon type={cancelDetails.button_status === 8 ?'check':'close' }/></div>
                        </li>
                        <li>
                            <div className="name">{intl('cancel', 'bookedId', language_id)}</div>
                            <div className="info">{cancelDetails.booking_id}</div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cancelDetails: state.getIn(['booking','cancelDetails']).toJS(),
        language_id: state.getIn(['main','language_id']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //返回按钮判断
    goBack(){
        dispatch(handleMoudleChange('MyCancellations'));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(CancelDetails);