// 日期筛选+排序
import React, { Component } from 'react';
import { getMonthDate, get_month_name, formatDate } from '../../../config/util';
import { intl } from '../../../config/util';
import DatePicker from 'react-mobile-datepicker';

import './listFilter.scss';

const defaultDate = formatDate(getMonthDate().end_date);
const monthMap = {
    '1': {'en': 'Jan', 'ch':'一月'},
    '2': {'en': 'Feb', 'ch':'二月'},
    '3': {'en': 'Mar', 'ch':'三月'},
    '4': {'en': 'Apr', 'ch':'四月'},
    '5': {'en': 'May', 'ch':'五月'},
    '6': {'en': 'Jun', 'ch':'六月'},
    '7': {'en': 'Jul', 'ch':'七月'},
    '8': {'en': 'Aug', 'ch':'八月'},
    '9': {'en': 'Sep', 'ch':'九月'},
    '10': {'en': 'Oct', 'ch':'十月'},
    '11': {'en': 'Nov', 'ch':'十一月'},
    '12': {'en': 'Dec', 'ch':'十二月'}
};

class ListFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date(Date.now()),//选中时间
		    isOpen: false,
        };
    }
    handleClick = () => {
		this.setState({ isOpen: true });
	}

	handleCancel = () => {
		this.setState({ isOpen: false });
	}

	handleSelect = (time) => {
        this.state.time !== time && this.props.updateList(time);
		this.setState({ time, isOpen: false });
    }

    timeFormat = (time) => {
        return `${get_month_name(time.getMonth(),this.props.language_id)} ${time.getFullYear()}`;
    }

    render() {
        const dateConfig = {
            'month': {
                format: value => monthMap[value.getMonth() + 1][this.props.language_id.toString() === '1' ? 'en' : 'ch'],
                caption: 'Mon',
                step: 1,
            },
            'year': {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            },
        };

        return (
            <div className="listfilter">
                <div className="select-btn" onClick={this.handleClick}>{this.timeFormat(this.state.time)}</div>
                <DatePicker
                    theme='ios'
                    dateConfig={dateConfig}
                    headerFormat="YYYY-MM"s
                    value={this.state.time}
                    isOpen={this.state.isOpen}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}
                    confirmText={intl('other','ok',this.props.language_id)}
                    cancelText={intl('schedule','cancel',this.props.language_id)}
                />

                <div className="order">
                    <button className="top" onClick={()=>this.props.changeOrder('asc')}><i className="iconfont icon-top2"></i></button>
                    <button className="down" onClick={()=>this.props.changeOrder('desc')}><i className="iconfont icon-down"></i></button>
                </div>
            </div>
        );
    }
}

export default ListFilter;