import { message, Modal } from 'antd';
import EN from '../assets/lang/en';
import ZH_HK from '../assets/lang/zh-hk';
import ZH_CN from '../assets/lang/zh-cn';
import ZH_TW from '../assets/lang/zh-tw';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import toast from './toast';
import { Toast } from 'antd-mobile';
import HtmlContent from '../common/htmlContent/htmlContent';
import { commonConfig } from '.';
import { bookingFun } from '../schedule/store/actionCreators';
import { platformClasses } from './style';
// 月日补零
function zero(num) {
    return num < 10 ? '0' + num : num + '';
}

/**
 * 获取语言描述
 * @param {*} module 所在组件模块
 * @param {*} key 对应key值
 * @param {*} language_id 语言id
 * @param { { [key: string]: string }= } interpolateParams
 */
export const intl = (module, key, language_id, interpolateParams) => {
    let translatedStr;
    switch (parseInt(language_id)) {
        case 2:
            translatedStr = ZH_HK[module][key];
            break;
        case 3:
            translatedStr = ZH_CN[module][key];
            break;
        case 4:
            translatedStr = ZH_TW[module][key];
            break;
        default:
            translatedStr = EN[module][key];
            break;
    }

    if (interpolateParams) {
        for (const key of Object.keys(interpolateParams)) {
            const regex = new RegExp(`{{${key}}}`, 'g');
            translatedStr = translatedStr.replace(regex, interpolateParams[key]);
        }
    }

    return translatedStr;
};

/**
 * 全局提醒
 * @param {'info','success','error','warning'} type 类型
 * @param {string} value 内容
 */

message.config({
    top: 300,
    duration: 2,
    maxCount: 3,
});
let currentLoadingCount = 0;
export const showMsg = (type='info', value, duration) => {
    switch (type) {
        case 'success':
            if (isMobile) {
                if (duration = undefined) {
                    duration = 1.5;
                }
                Toast.success(value, duration);
            } else {
                message.success(value, duration);
            }
            break;
        case 'error':
            if (isMobile) {
                if (duration = undefined) {
                    duration = 2;
                }
                Toast.fail(value, duration);
            } else {
                message.error(value, duration);
            }
            break;
        case 'warning':
            if (isMobile) {
                if (duration = undefined) {
                    duration = 3;
                }
                Toast.info(value, duration);
            } else {
                message.warning(value, duration);
            }
            break;
        case 'loading':
            if (isMobile) {
                currentLoadingCount++;
                if (duration = undefined) {
                    duration = 10;
                }
                Toast.loading('Loading...', duration);
            }
            break;
        case 'hide':
            if (isMobile) {
                // hide loading only when all loading is done
                currentLoadingCount--;
                if (currentLoadingCount <= 0) {
                    if (currentLoadingCount < 0) {
                        currentLoadingCount = 0;
                    }
                    Toast.hide();
                }
            }
            break;
        default:
            if (isMobile) {
                if (duration = undefined) {
                    duration = 3;
                }
                Toast.info(value, duration);
            } else {
                message.info(value, duration);
            }
            break;
    }
};

/**
 * Model提示
 * @param {'info','success','error','warning'} type 类型
 * @param {string} title 标题
 * @param {string} value  内容
 */
export const showModel = (
    value,
    type = 'info',
    title = "" || intl('model', 'done', localStorage.getItem('language_id') || 1),
    dispatch, data, waitlist) => {
    if (type=='error') {
        title = title ? title : intl('other','tips',localStorage.getItem('language_id') || 1);
    }
    // let localtype = localStorage.getItem('type') ? localStorage.getItem('type') : 'Y';
    let localtype = commonConfig.sector;
    let mainClass = localtype === 'Y' ? 'pure_yoga' : 'pure_fitness';

    let modalContent = {
        title,
        content: (<HtmlContent content={value}></HtmlContent>),
        getContainer: () => document.getElementById(mainClass),
    };
    if (waitlist) {
        modalContent['icon'] = false;
        modalContent['okText'] = intl('other','back',localStorage.getItem('language_id'));
        modalContent['className'] = 'waitlist';
    }
    if (type == 'confirm') {
        modalContent['onOk'] = () => {bookingFun(dispatch, data).finally();};
    }
    if (type == 'error') {
        modalContent['okText'] = intl('model', 'ok', localStorage.getItem('language_id'));
    }

    return Modal[type]({...modalContent});
};

export const showCustomModal = (
    dispatch, data, modalData
) => {
    let modal;
    const closeModal = () => {
        // show next modal after current modal is closed
        if (modal) {
            modal.destroy();
        }
        showCustomModal(dispatch, data, modalData);
    };

    const showModal = (element) => {
        let buttons = element.buttons || [];
        let localtype = commonConfig.sector;
        let mainClass = localtype === 'Y' ? 'pure_yoga' : 'pure_fitness';
        const sectorColor = platformClasses[localtype === 'Y' ? 'yoga' : 'fitness'];
        let modalContent = {
            title: (<strong>{element.title}</strong>),
            content: (
                <>
                    <HtmlContent content={element.message}></HtmlContent>
                    <div className='btnGp'>
                        {buttons.map((btn, index) => {
                            let color = btn.color || '#888';
                            let backgroundColor = btn.background_color || '#EFEFF4';
                            if (btn.is_primary_color) {
                                color = sectorColor;
                            }
                            if (btn.is_primary_background) {
                                backgroundColor = sectorColor;
                            }
                            if (btn.type === 'submit') {
                                return (<button
                                    key={'modalBtn_' + index}
                                    className='ant-btn'
                                    style={{ color: color, backgroundColor: backgroundColor }}
                                    onClick={async () => {
                                        data.book_type = btn.book_type;
                                        await bookingFun(dispatch, data).finally();
                                        closeModal();
                                        // Modal.des
                                        // setTimeout(() => {
                                        //     dispatch(getSchedule(data.filter.toJS(), data.jwt, data));
                                        // }, 1000);
                                    }}
                                >
                                    {btn.text}
                                </button>);
                            } else {
                                return (<button
                                    key={'modalBtn_' + index}
                                    onClick={() => { closeModal(); }}
                                    className='ant-btn'
                                    style={{ color: color, backgroundColor: backgroundColor }}
                                >
                                    {btn.text}
                                </button>);
                            }
                        })}
                    </div>
                </>
            ),
            getContainer: () => document.getElementById(mainClass),
            icon: false,
            className: 'customModel',
            cancelButtonProps: { style: { display: 'none' } },
            okButtonProps: { style: { display: 'none' } }
        };

        modal = Modal[(modalData.type ? modalData.type : 'confirm')]({ ...modalContent });
    };

    if (modalData.length > 0) {
        // show modal starting from index 0
        const element = modalData.shift();
        showModal(element);
    }
};

/**
 * confirm提示
 * @param {obj} confirmInfo antd confirm基本信息
 * @param {func} callback ok回调
 * @param {func} cancel cancel回调
 */
export const showConfirm = (confirmInfo, callback = () => { console.log('ok'); }, cancel=()=>{console.log('cancel');}) => {
    // let localtype = localStorage.getItem('type') ? localStorage.getItem('type') : 'Y';
    let localtype = commonConfig.sector;
    let mainClass = localtype === 'Y' ? 'pure_yoga' : 'pure_fitness';
    let language_id = localStorage.getItem('language_id') || 1;
    Modal.confirm({
        title: confirmInfo.title,
        content:(<HtmlContent content={confirmInfo.content}></HtmlContent>),
        okText: intl('model','yes',language_id),
        cancelText: intl('model', 'no', language_id),
        className: 'reverse-button',
        getContainer: () => document.getElementById(mainClass),
        onOk() {
            callback();
        },
        onCancel() {
            cancel();
        },
    });
};

/**
 * 接口报错处理，争对有jwt的数据请求
 * @param {obj} error axios catch返回的数据
 * @param {num} language_id 语言id
 * @param {func} callback 点击确认后的回调函数
 */
export const catchError = (error, language_id, callback)=>{
    let errorCode = error.code;
    if (errorCode !== undefined && errorCode !== 0) {
        if (errorCode === 401) {
            //掉线处理
            Modal.error({
                title: intl('other', 'tips', language_id),
                content: bookMsg(error, language_id),
                okText: parseInt(language_id) === 3 ? '确定' : 'OK',
                onOk() {
                    callback();
                }
            });
        } else {
            showMsg('error', error.message);
        }
    } else {
        showMsg('error', intl('other', 'errorTryAgain', language_id));
    }

};

/**
 * 时间格式化
 * @param {string} time 当前时间
 * @param {Number} language_id 语言id
 * @param {*} format 返回格式（无用）
 */
export const formatDate = (time, language_id, format = 'MMDD')=>{
    var newDate = {};
    var date = new Date(time);
    let nowDate = new Date();
    if (date.getDate() === nowDate.getDate()){
        newDate.day = 'today';
    }
    if (date.getDate() === nowDate.getDate() + 1){
        newDate.day = 'tomorrow';
    }
    var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    var weekday_en = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    // var weekday_hk = ["週日", "週一", "週二", "週三", "週四", "週五", "週六"];
    var weekday_hk = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];

    var weekdayShort = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    var weekdayShort_en = ["S", "M", "T", "W", "T", "F", "S",];
    var weekdayShort_hk = ["七", "一", "二", "三", "四", "五", "六"];

    var year = date.getFullYear(),
        month = date.getMonth() + 1,//月份是从0开始的
        day = date.getDate(),
        week = date.getDay();
    const lang = parseInt(language_id);

    if (lang === 2 || lang === 4) {
        newDate.week = weekday_hk[week];
        newDate.weekShort = weekdayShort_hk[week];
        newDate.title = `${weekday_hk[week]} ${month}月 ${day}日`;
        newDate.month = `${month}月`;
        newDate.year = `${year}`;
    } else if (lang === 3) {
        newDate.week = weekday[week];
        newDate.weekShort = weekdayShort[week];
        newDate.title = `${weekday[week]} ${month}月 ${day}日`;
        newDate.month = `${month}月`;
        newDate.year = `${year}`;
    } else {
        var monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        newDate.week = weekday_en[week];
        newDate.weekShort = weekdayShort_en[week];
        newDate.title = `${weekday_en[week]} ${monArr[date.getMonth()]} ${day}`;
        newDate.month = `${monArr[date.getMonth()]}`;
        newDate.year = `${year}`;
    }
    let newMonth = month < 10 ? '0' + month : month;
    let newDay = day < 10 ? '0' + day : day;
    newDate.dayTime = newDay;
    newDate.dayTimeShort = day;
    newDate.value = year + '-' + newMonth + '-' + newDay;
    return newDate;
};


/**
 * 预约报错文案返回
 * @param {obj} error 接口返回的error
 * @param {num} language_id 语言id
 */
export const bookMsg = (error, language_id) => {
    let messages = {
        '1': {
            '401': 'Your session has expired or you might have logged in on another device. Please kindly login again.',
            '405': 'Missing Parameters',
            '407': 'Either the user does not exist or the password provided is incorrect.',
            '409': 'This class is full. Would you like to be put on the waitlist?',
            '410': 'Both the class and the waitlist are full.',
            '412': 'The class has been cancelled.',
            '413': 'This class is no longer available for booking.',
            '414': 'Your Cardholder contract is not valid, so you cannot book any classes.',
            '415': 'This booking does not exist or has been cancelled already.',
            '416': 'Hi, this is past the allowed cancellation window and will be counted as a late cancel. Would you still like to proceed?',
            '419': 'The booking has been made already.',
            '424': 'The class time overlaps another class that you have booked.',
            '425': 'Your ability to book in advance has been suspended.',
            '426': 'Scheduling is currently closed. SCHEDULING HOURS: 9:00am To 11:00pm',
            '427': 'Online Booking is not allowed. Please contact our team for assistance. ',
            '428': 'System busy. Please try again soon.',
            '431': 'This class is no longer available for booking.',
            '442': 'You do not have an active service plan.',
            '443': 'There are no more classes remaining in your service plan.',
            '447': 'Your account is not binded with WeChat. Please login with user email and password first.',
            '448': 'Your bookings of this class type have already exceeded the daily limit. ',
            '449': 'Staff cannot book classes in advance.',
            '451': 'Sorry, booking is unavailable due to overdue payment please contact our team.',
            '999': 'System Error The network connection is unstable. Please try again later.',
            '10000': 'Your bookings of this class type have already exceeded the daily limit. ',     
            '10004': 'Students must attend the Aerial Yoga Beginners\' Workshop before taking the Aerial Yoga 1 class. For any questions, reach out to our team by calling us or email info@pure-international.com. Feel free to also check with reception at our locations. Thanks!\n',
            '10005': 'We require students to attend 20 Aerial Yoga 1 classes before attending the Aerial Yoga 2 class. If  you have any questions, please talk to the teacher before the class or send us a message via info@pure-international.com\n',
            '10007': 'You’ve already booked this class on the same day.\nWhy not mix it up and give other classes a go?',
            '20011': 'Please try again later. Your bookings have already reached the daily limit, but you can still try standby',
            'default': error.message
        },
        '2': {
            '401': '登入時限已過，或您正以另一裝置登入。請重新登入。',
            '405': '缺少參數',
            '407': '帳戶或密碼不正確',
            '409': '此課程已額滿。需要把您加入候補名單？',
            '410': '課堂與候補名單均已額滿',
            '412': '課程已取消',
            '413': '此課程現不再可供預約',
            '414': '您的帳戶已逾期，您不能預約任何課堂。',
            '415': '此預約現已不存在或經已被取消',
            '416': '您好！您已超過取消預約時段，故是次將計算為一次臨時取消。是否繼續？',
            '419': '您已經預約所選課程',
            '424': '此課堂的上課時間，與您已預約的課堂時間重疊。',
            '425': '已暫停此賬戶的預約課堂功能',
            '426': '目前無法進行預約,預約時間為: 9:00am To 11:00pm',
            '427': '預約功能暫時被關閉，請聯絡我們的團隊。',
            '428': '系統繁忙，請稍後再試。',
            '431': '此課堂已不接受預約',
            '442': '你沒有可用的服務計劃',
            '443': '你的服務計劃已沒有剩餘堂數',
            '447': '您的微信並未綁定帳戶，請先以帳戶電郵及密碼登入，再作微信綁定。',
            '448': '此類課程的預約已超出您每天可預約的上限',
            '449': '員工不能預約課堂',
            '451': '很抱歉，由於您的會籍費用逾期尚未繳付，暫時無法預約，如有任何疑問，請聯絡我們',
            '999': '系統錯誤 網絡連接不穩定，請稍後再試。',
            '10000': '此類課程的預約已超出您每天可預約的上限',
            '10004': '很抱歉，為了您的安全著想，只有曾參加空中瑜伽基礎工作坊的學員，才可預約空中瑜伽1課程。如有任何疑問，歡迎致電我們或電郵至 info@pure-international.com，或向接待處查詢。',
            '10005': '我們要求學員們在參加空中瑜伽2課堂前，要先完成20堂空中瑜伽1課堂。如有任何疑問，歡迎於課前詢問我們的老師，或電郵至info@pure-international.com查詢。',
            '10007': '您已預約了同一日的相同課堂，\n何不給其他課堂一個機會，\n試試預約其他？',
            '20011': '您已使用了每日只限兩次的提早預約，但您仍可加入現場候補名單',
            'default': error.message
        },
        '3': {
            '401': '登入时限已过，或您正以另一装置登入。请重新登入。',
            '405': '缺少參數',
            '407': '帐户或密码不正确',
            '409': '此课堂已额满。需要把您加入候补名单？',
            '410': '课程与候补名单均已额满',
            '412': '课程已取消',
            '413': '此课程现不再可供预约',
            '414': '您的帐户暂停中，您不能预约任何课堂。',
            '415': '此预约现已不存在或经已被取消',
            '416': '您好！您已超过取消预约时段，故是次将计算为一次临时取消。是否继续？',
            '419': '您已经预约所选课程',
            '424': '此课堂的上课时间，与您已预约的课堂时间重叠。',
            '425': '此账户的预约功能已被暂停',
            '426': '目前无法进行预约,预约时间为: 9:00am To 11:00pm',
            '427': '预约功能被临时关闭，请联系我们的团队。',
            '428': '系统繁忙，请稍后再试。',
            '431': '此课堂已不接受预约',
            '442': '你没有可用的服务或产品',
            '443': '你的服务或产品中已没有剩余课程',
            '447': '您的微信并未绑定帐户，请先以帐户电邮及密码登入，再作微信绑定。',
            '448': '您此类课程的预约已超出每日可预约上限',
            '449': '员工不能预约课堂',
            '999': '系统错误 网络连接不稳定，请稍後再试。',
            '10000': '您此类课程的预约已超出每日可预约上限',
            '10004': '抱歉地通知您，为了您的安全着想，只有参加过空中瑜伽基础工作坊的学员，方可预约空中瑜伽1课程。如有任何疑问，欢迎在课前向我们的老师咨询，或是致电前台了解更多信息。',
            '10005': '我们要求学员们在参加空中瑜伽2课堂前，要先完成20堂空中瑜伽1课堂。如有任何疑问，欢迎于课前询问我们的老师，或电邮至info@pure-international.com查询。',
            '10007': '您已预约了同一日的相同课堂，\n何不给其他课堂一个机会，\n试试预约其他？',
            '20011': '您已使用了每日只限两次的提早预约，但您仍可加入现场候补名单',
            'default': error.message
        },
        '4': {
            '401': '登入時限已過，或您正以另一裝置登入。請重新登入。',
            '405': '缺少參數',
            '407': '帳戶或密碼不正確',
            '409': '此課程已額滿。需要把您加入候補名單？',
            '410': '課堂與候補名單均已額滿',
            '412': '課程已取消',
            '413': '此課程現不再可供預約',
            '414': '您的帳戶已逾期，您不能預約任何課堂。',
            '415': '此預約現已不存在或經已被取消',
            '416': '您好！您已超過取消預約時段，故是次將計算為一次臨時取消。是否繼續？',
            '419': '您已經預約所選課程',
            '424': '此課堂的上課時間，與您已預約的課堂時間重疊。',
            '425': '已暫停此賬戶的預約課堂功能',
            '426': '目前無法進行預約,預約時間為: 9:00am To 11:00pm',
            '427': '預約功能暫時被關閉，請聯絡我們的團隊。',
            '428': '系統繁忙，請稍後再試。',
            '431': '此課堂已不接受預約',
            '442': '你沒有可用的服務計劃',
            '443': '你的服務計劃已沒有剩餘堂數',
            '447': '您的微信並未綁定帳戶，請先以帳戶電郵及密碼登入，再作微信綁定。',
            '448': '此類課程的預約已超出您每天可預約的上限',
            '449': '員工不能預約課堂',
            '451': '很抱歉，由于您的会籍费用逾期未缴付，暂时无法进行预约，如有任何疑问，请联系我们',
            '999': '系統錯誤 網絡連接不穩定，請稍後再試。',
            '10000': '此類課程的預約已超出您每天可預約的上限',
            '10004': '很抱歉，為了您的安全著想，只有曾參加空中瑜伽基礎工作坊的學員，才可預約空中瑜伽1課程。如有任何疑問，歡迎致電我們或電郵至 info@pure-international.com，或向接待處查詢。',
            '10005': '我們要求學員們在參加空中瑜伽2課堂前，要先完成20堂空中瑜伽1課堂。如有任何疑問，歡迎於課前詢問我們的老師，或電郵至info@pure-international.com查詢。',
            '10007': '您已預約了同一日的相同課堂，\n何不給其他課堂一個機會，\n試試預約其他？',
            '20011': '您已使用了每日只限兩次的提早預約，但您仍可加入現場候補名單',
            'default': error.message
        }
    };
    let bookCode = error.code || 'default';
    if (messages[language_id][bookCode]) {
        return messages[language_id][bookCode];
    } else {
        return error.message;
    }
    return messages[language_id][bookCode];
};

/**
 * 预约提示文字
 * @param {num} language_id 语言id
 * @param {num} language_id botton_status 100为自定义的超时取消预约
 */
export const bookingContent = (language_id, status) => {
    let content = {
        '1': {
            '1': {
                title:'Booking Class',
                content:'Are you sure booking this class？'
            },
            '2': {
                title: '預約課堂',
                content: '你確定預約這堂課嗎？'
            },
            '3': {
                title: '预约课程',
                content: '你确定预约这堂课吗？'
            },
            '4': {
                title: '預約課堂',
                content: '你確定預約這堂課嗎？'
            }
        },
        '2': {
            '1': {
                title: 'Cancel the "Waitlist"',
                content: 'Are you sure to cancel the “Waitlist” of this class?'
            },
            '2': {
                title: '取消預約',
                content: '取消“預約”課程？'
            },
            '3': {
                title: '取消预约',
                content: '取消“已预订”课程？'
            },
            '4': {
                title: '取消預約',
                content: '取消“預約”課程？'
            }
        },
        '3': {
            '1': {
                title: 'Waitting Class',
                content: 'Are you sure waitting this class？'
            },
            '2': {
                title: '加入候補',
                content: '你確定將這堂課加入候補嗎？'
            },
            '3': {
                title: '加入候补',
                content: '取消已预订课程？'
            },
            '4': {
                title: '加入候補',
                content: '你確定將這堂課加入候補嗎？'
            }
        },
        '4': {
            '1': {
                title: 'Cancel Waitting',
                content: 'Are you sure cancel waitting this class？'
            },
            '2': {
                title: '取消候補',
                content: '你確定取消候補這堂課嗎？'
            },
            '3': {
                title: '取消候补',
                content: '你确定取消候补这堂课吗？'
            },
            '4': {
                title: '取消候補',
                content: '你確定取消候補這堂課嗎？'
            }
        },
        '15' :{
            '1': {
                title: 'Cancel Class',
                content: 'Are you sure you want to cancel this class？'
            },
            '2': {
                title: '取消預約',
                content: '你確定取消預約這堂課嗎？'
            },
            '3': {
                title: '取消预约',
                content: '你确定取消预约这堂课吗？'
            },
            '4': {
                title: '取消預約',
                content: '你確定取消預約這堂課嗎？'
            }
        },
        '100': {
            '1': {
                title: 'Note!',
                content: 'Hi, this is past the allowed cancellation window and will be counted as a late cancel. Would you still like to proceed?'
            },
            '2': {
                title: '注意！',
                content: '您好！您已超過取消預約時段，故是次將計算為一次臨時取消。是否繼續？'
            },
            '3': {
                title: '注意！',
                content: '您好！您已超过取消预约时段，故是次将计算为一次临时取消。是否继续？'
            },
            '4': {
                title: '注意！',
                content: '您好！您已超過取消預約時段，故是次將計算為一次臨時取消。是否繼續？'
            }
        }
    };
    return content[status][language_id];
};

/**
 * 预约，取消，候补，超时取消成功内容返回
 * @param {num} language_id 语言id
 * @param {num} type 类型button_status
 */
export const bookingSuccessMsg = (language_id,type)=>{
    const successMsg = {
        '1':{
            '1': 'Booking Success',
            '2': 'Successfully Cancelled',
            '3': 'Waitlist Success',
            '4': 'Waitlist Cancelled',
            '16': 'Last Chance Booking Success',
            '100': 'Late Cancel Success',
        },
        '2':{
            '1': '預約成功',
            '2': '取消成功',
            '3': '候補成功',
            '4': '取消候補成功',
            '16': '最後機會預約成功',
            '100': '延時取消成功'
        },
        '3': {
            '1': '预约成功',
            '2': '取消成功',
            '3': '候补成功',
            '4': '取消候补成功',
            '16': '最后机会预约成功',
            '100': '取消成功'
        },
        '4': {
            '1': '預約成功',
            '2': '取消成功',
            '3': '候補成功',
            '4': '取消候補成功',
            '16': '最後機會預約成功',
            '100': '延時取消成功'
        }
    };
    return successMsg[language_id][type];
};
/**
 * booking组件日期格式化
 * @param {string} time 当前时间
 * @param {Number} language_id 语言id
 * @param {*} format 返回格式（无用）
 */
export const bookingFormatDate = (time, language_id, format = 'MMDD') => {
    var newDate = {};
    var date = new Date(Date.parse(time.replace(/-/g, "/")));
    let nowDate = new Date();
    if (date.getDate() === nowDate.getDate()) {
        newDate.day = 'Today';
    }
    if (date.getDate() === nowDate.getDate() + 1) {
        newDate.day = 'Tomorrow';
    }
    var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    var weekday_en = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var weekday_hk = ["週日", "週一", "週二", "週三", "週四", "週五", "週六"];
    var monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var year = date.getFullYear(),
        month = date.getMonth() + 1,//月份是从0开始的
        day = date.getDate(),
        week = date.getDay(),
        hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
        return '0' + index;
    });
    let newMonth,newDay;
    if (parseInt(language_id) === 2) {
        newDate.week = weekday_hk[week];
        newDate.title = `${month}月${day}日`;
        newDate.value = `${year}年${month}月${day}日`;
    } else if (parseInt(language_id) === 3) {
        newDate.week = weekday[week];
        newDate.title = `${month}月${day}日`;
        newDate.value = `${year}年${month}月${day}日`;
    } else if (parseInt(language_id) === 4) {
        newDate.week = weekday_hk[week];
        newDate.title = `${month}月${day}日`;
        newDate.value = `${year}年${month}月${day}日`;
    } else {
        newDate.week = weekday_en[week];
        newDate.title = day + ' ' + monArr[date.getMonth()];
        newMonth = month < 10 ? '0' + month : month;
        newDay = day < 10 ? '0' + day : day;
        newDate.value = year + '-' + newMonth + '-' + newDay;
    }
    return newDate;
};

/**
 * 预约时间和取消预约时间格式化
 * 1 Thu 2018 18:00格式
 * 2018-01-01 18:00
 * @param {} date
 * @param {} time
 * @param {*} language_id
 */
export const bookedTimeFormatDate = (date, time, language_id) => {
    var date = new Date(Date.parse(date.replace(/-/g, "/")));
    var monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var year = date.getFullYear(),
        month = date.getMonth() + 1,//月份是从0开始的
        day = date.getDate();
    var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
        return '0' + index;
    });

    if (parseInt(language_id) === 1) {
        return `${day} ${monArr[date.getMonth()]} ${year} ${time}`;
    } else {
        return `${year}-${month}-${day}${time}`;
    }
};
// export const bookedTimeFormatDate = (time, language_id) => {
//     var date = new Date(Date.parse(time.replace(/-/g, "/")));
//     var monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//     var year = date.getFullYear(),
//         month = date.getMonth() + 1,//月份是从0开始的
//         day = date.getDate(),
//         hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
//         minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
//     var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
//         return '0' + index;
//     });

//     if (parseInt(language_id) === 1) {
//         return `${day} ${monArr[date.getMonth()]} ${year} ${hour}:${minute}`;
//     } else {
//         return `${year}-${month}-${day} ${hour}:${minute}`;
//     }
// };

/**
 * history列表时间格式化
 * @param {*} time
 * @param {*} language_id
 */
export const historyFormatDate = (time, language_id) => {
    var date = new Date(Date.parse(time));
    var monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var year = date.getFullYear(),
        month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,//月份是从0开始的
        day = date.getDate(),
        hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    //let newDay = day < 10 ? '0' + day : day;
    switch (language_id) {
        case 2:
        case 3:
        case 4:
            return year + '年' + month + '月' + day + '日 ' + hour + ":" + minute;
        default:
            return day +' '+ monArr[month-1] +' '+ year + ' ' + hour + ":" + minute;
    }
};

/**
 * 根据某个时间字段排序
 * dataArr.sort(sortByTime('asc','datetime'));
 * @param {string} order 排序 asc || desc
 * @param {*} time
 */
export const sortByTime = (order,time) => {
    return function (o, p) {
        var a, b;
        if (typeof o === "object" && typeof p === "object" && o && p) {
            a = o[time];
            b = p[time];
            if (a === b) {
                return 0;
            } if (typeof a === typeof b) {
                if (order === 'desc'){
                    return a > b ? -1 : 1;
                } else {
                    return a < b ? -1 : 1;
                }
            } else {
                if (order === 'desc') {
                    return typeof a > typeof b ? -1 : 1;
                } else {
                    return typeof a < typeof b ? -1 : 1;
                }
            }
        } else {
            console.log("error");
        }
    };
};

/**
 * 根据language_id返回对应代码
 * @param {num} language_id
 */
export const getLangTitle = (language_id) => {
    switch (parseInt(language_id)) {
        case 2:
            return 'zh-hk';
        case 3:
            return 'zh-cn';
        case 4:
            return 'zh-tw';
        default:
            return 'en';
    }
};

/**
 * 获取url地址中某个参数的值
 * @param {*} url
 * @param {*} name
 */
export const getQueryByName = (url, name) => {
    var reg = new RegExp('[?&]' + name + '=([^&#]+)');
    var query = url.match(reg);
    return query ? query[1] : null;
};

/**
 * 获取当前时间，格式YYYY-MM-DD
 */
export const getNowFormatDate = () => {
    var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
};

/**
 * 获取月份的第一天和最后一天，不传参数获取本月的
 * exp:2019-5
 */
export const getMonthDate = (date) => {
    return { start_date: getCurrentMonthFirst(date), end_date:getCurrentMonthLast(date) };
};

/**
 *
 * schedule 页面获取打印时页头的时间显示格式
 */
export const getPrintDate = (language_id) => {
    var date = new Date();
    var monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var weekLang = {
        '1': ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        '2': ["週日", "週一", "週二", "週三", "週四", "週五", "週六"],
        '3': ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
    };

    var year = date.getFullYear(),
        month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,//月份是从0开始的
        day = date.getDate(),
        hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
        week = date.getDay();

    //let newDay = day < 10 ? '0' + day : day;
    switch (language_id) {
        case 2:

        case 3:
        case 4:
            return weekLang[language_id][week] + year + '年' + month + '月' + day + '日 ' + hour + ":" + minute;
        default:
            return `${weekLang[language_id][week]} · ${day} ${monArr[month-1]} ${year} · ${hour}:${minute}`;
    }
};

/**
 * 根据book_status返回对应的文本
 * @param {number} status
 */
export const getStatusName = (status, language_id) => {
    let statusName = {
        '0': ['Booked','已預約','已预约'],
        '1': ['Book','預約','预约'],
        '2': ['Booked','已預約','已预约'],
        '3': ['Waitlist','候補名單','候补名单'],
        '4': ['Waitlisted','等待候補','等待候补'],
        '5': ['Full','滿額','满额'],
        '7': ['Signed In','已簽到','已签到'],
        '8': ['Late Cancel','逾時取消','逾时取消'],
        '9': ['Absent','缺席','缺席'],
        '10': ['Complete','結束','结束'],
        '11': ['Completed','結束','结束'],
        '12': ['Cancelled','已取消','已取消'],
    };
    return statusName[status][language_id - 1];
};

/**
 * 获取月份的第一天
 */
function getCurrentMonthFirst(dayTime){
    var date = dayTime ? new Date(dayTime) : new Date();
    date.setDate(1);
    var month = parseInt(date.getMonth()+1);
    var day = date.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
}

/**
 * 获取月份的最后一天
 */
function getCurrentMonthLast(dayTime){
    var date = dayTime ? new Date(dayTime) : new Date();
    var currentMonth=date.getMonth();
    var nextMonth=++currentMonth;
    var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
    var oneDay=1000*60*60*24;
    var lastTime = new Date(nextMonthFirstDay-oneDay);
    var month = parseInt(lastTime.getMonth()+1);
    var day = lastTime.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
}

/**
 * 获取n天前后的日期
 * @param {Date} date 日期
 * @param {Number} num 天数
 */
export const getDayAfterN = (startDay, num) => {
    // let startDay = new Date(date.replace(/-/g,'/'));
    var weekday = [
        { 'zh-cn': '周日', 'en': 'Sun', 'zh-hk': '星期日', 'zh-tw': 'Sun' },
        { 'zh-cn': '周一', 'en': 'Mon', 'zh-hk': '星期一', 'zh-tw': 'Mon' },
        { 'zh-cn': '周二', 'en': 'Tues', 'zh-hk': '星期二', 'zh-tw': 'Tues' },
        { 'zh-cn': '周三', 'en': 'Wed', 'zh-hk': '星期三', 'zh-tw': 'Wed' },
        { 'zh-cn': '周四', 'en': 'Thur', 'zh-hk': '星期四', 'zh-tw': 'Thur' },
        { 'zh-cn': '周五', 'en': 'Fri', 'zh-hk': '星期五', 'zh-tw': 'Fri' },
        { 'zh-cn': '周六', 'en': 'Sat', 'zh-hk': '星期六', 'zh-tw': 'Sat' },
    ];
    var targetday_milliseconds= startDay.getTime() + 1000 * 60 * 60 * 24 * num;
    startDay.setTime(targetday_milliseconds);
    var tYear = startDay.getFullYear();
    var tMonth = startDay.getMonth();
    var tDate = startDay.getDate();
    var tWeek = startDay.getDay();
    tMonth = addZero(tMonth + 1);
    tDate = addZero(tDate);
    return { y: tYear, m: parseInt(tMonth), d: parseInt(tDate), w: weekday[tWeek], date: tYear + '-' + tMonth + '-' + tDate};
};

// 日/月补0
const addZero = (month) => {
    return month.toString().length === 1 ? "0" + month : month;
};


export function getChangeRegionTipsInfo(region_id, next_region_id, language_id){
    let info = {};
    const regionName = {
        '1': { '1':"Hong Kong", '2':"香港", '3':"香港" },
        '2': { '1':"Singapore", '2':"新加坡", '3':"新加坡" },
        '4': { '1':"Shanghai/Beijing", '2':"上海/北京", '3':"上海/北京" }
    };
    const nowRegion = regionName[region_id][language_id];
    const nextRegion = regionName[next_region_id][language_id];
    switch (language_id) {
        case '1':
            info = {
                title: `For security reasons, you will need to re-login to book in ${nextRegion}`,
                loginAnotherRegion: `Login to ${nextRegion}`,
                logout: `Logout. Go to ${nextRegion}`,
                cancel: `Stay in ${nowRegion}`
            };
            break;
        case '2':
            info = {
                title: `基於網絡安全理由，您需要重新登錄才能在${nextRegion}預約課堂`,
                loginAnotherRegion: `登入${nextRegion}`,
                logout: `登出並進入${nextRegion}`,
                cancel: `留在${nowRegion}`
            };
            break;
        default:
            info = {
                title: `基于网络安全理由，您需要重新登录才能在${nextRegion}预约课程`,
                loginAnotherRegion: `登入${nextRegion}`,
                logout: `登出并进入${nextRegion}`,
                cancel: `留在${nowRegion}`
            };
            break;
    }
    return info;
}

export function getDefaultWeekType() {
    // return 2;
    return isMobile ? 3 : 1;
}

//获取指定日期后n天的日期
export const getNextDays = (date, num) => {
    var arr = [];
    let startDay = date.replace(/-/g, '/');
    for (var i = 0; i <= num; i++) {
        arr.push(getDayAfterN(new Date(startDay), i));
    }
    return arr;
};

// 今天
export const nowDate = () => {
    let now = new Date();
    var y = now.getFullYear(); //获取完整的年份(4位,1970-????)
    var m = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    var d = now.getDate();
    m = zero(m);
    d = zero(d);
    return y + "-" + m + "-" + d;
};

// 获取月份简写
export const getMonth = (month) => {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month - 1];
};

//获取月份名
export const get_month_name = (month,language_id) => {
    let monthArr = {
        'en':["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        'ch':["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
    };
    return monthArr[parseInt(language_id) === 1 ? 'en' : 'ch'][month];
};

//mobile download link
export const getAppLink = (region_id) => {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let appLink = '';
    if (isAndroid) {
        appLink = parseInt(region_id) === 4 ? 'http://18082162792.fx.sj.360.cn/qcms/view/t/detail?id=4002187' : 'https://play.google.com/store/apps/details?id=com.pure.international.pure360';
    }

    if (isIOS) {
        appLink = parseInt(region_id) === 4 ? 'https://apps.apple.com/cn/app/id1409539898' : 'https://geo.itunes.apple.com/tw/app/pure-360-lifestyle/id1416392411';
    }
    return appLink;
};

//月份加减
export const addMonths = function (v, n) {
    v = new Date(v);
    if (v) {
        var t = new Date(v.getFullYear(), v.getMonth(), v.getDate(), v.getHours(), v.getMinutes(), v.getSeconds(), v.getMilliseconds());
        t.setMonth(v.getMonth() + n);
        if (t.getDate() !== v.getDate()) { t.setDate(0); }
        return t;
    }
};

/**
 * 对象根据日期的key排序
 * sortByTime('obj','asc'));
 */
export const objSortByDate = (arr,order) => {
    let keyArr = Object.keys(arr).sort(function(a,b){
        return order === 'asc' ? get_time(a) - get_time(b) : get_time(b) - get_time(a);
    });
    // let newList = [];
    // for(var i = 0; i < keyArr.length; i++){
    //     var date = keyArr[i];
    //     newList[date] = arr[date];
    // }
    return keyArr;
};

//日期字符串转时间戳
export const get_time = time => {
    return new Date(time.replace(/-/g,'/')).getTime();
};

// 获取星期简写
export const get_week = (day,language_id) => {
    let en = ['Sun','Mon','Tues','Wed','Thur','Fri','Sat'];
    let ch = '日一二三四五六';
    let week = parseInt(language_id) === 1 ? en[day] :'周' + ch.charAt(parseInt(day));
    return week;
};

export const reloadPage = () => {
    /**
     * go to root page, let app self navigate to correct language & region to prevent forever refresh loop
     */
    window.location.replace('/');
};

export function nl2br(str, is_xhtml = true) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export function getCurrentLanguageId() {
    return localStorage.getItem('language_id') || 1;
}
