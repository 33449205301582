/*
 * @Author: your name
 * @Date: 2020-11-27 13:56:18
 * @LastEditTime: 2021-01-21 13:38:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-booking\web_booking_mobile\src\components\dateList\index.js
 */
import React from "react";
import { connect } from 'react-redux';
import { actionCreators } from '../../store'; //!update
import { Link, scroller } from 'react-scroll';
import { intl } from "../../../config/util";

import './dateList.less';

class DateList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            initClassName : true,//用于给初始化时第一个日期加上选中样式
            weekType: 3,
        };
    }
    //切换星期
    changeWeek = () => {
        let week = this.props.list.toJS();
        // let week = weeks.find(item => item.checked).list;
        let element = `item-${week[0].value}`;
        this.setState({ initClassName: true });
        // this.props.changeWeekType();

        scroller.scrollTo(element, {
            duration: 250,
            delay: 100,
            smooth: true,
            containerId: 'scheduleScrollView',
        });
    }

    toPrevWeek = () => {
        this.props.ChangeWeekType({ type: 3, jwt: this.props.jwt });
        this.changeWeek();
    }
    toNextWeek = () => {
        this.props.ChangeWeekType({ type: 4, jwt: this.props.jwt });
        this.changeWeek();
    }

    //week发生变化时执行
    handleSetActive = () => {
        this.setState({ initClassName: false });
    }
    render(){
        let { weeks, list, lang, isThisWeek } = this.props;
        isThisWeek = this.props.weekType === 3;
        let week = list.size > 0 ? list : null;
        if (!week) {
            return (
                <div></div>
            );
        }
        // let week = this.props.list;
        return (
            <div className={`date_list ${isThisWeek ? 'pr':'pl'}`}>
                {
                    !isThisWeek && <button className="weekToggle" onClick={this.toPrevWeek}>
                        <i className="iconfont icon-left"></i>
                    </button>
                }
                <div className="list">
                    {
                        week.map((item, index) => {
                            return (
                                <Link
                                    className={index === 0 && this.state.initClassName ? 'active' : '' }
                                    key={item.get('value')}
                                    activeClass="active"
                                    to={`item-${item.get('value')}`}
                                    spy={true}
                                    smooth={true}
                                    duration={250}
                                    containerId="scheduleScrollView"
                                    onSetActive={this.handleSetActive}
                                >
                                    <div className="week">{item.get('day') === 'today' ? intl('schedule', 'today', this.props.language_id) : item.get('weekShort')}</div>
                                    {/* <div className="week">{item.getIn(['w',lang])}</div> */}
                                    <div className="num">{item.get('dayTimeShort')}</div>
                                </Link>
                            );
                        })
                    }
                </div>
                {
                    isThisWeek && <button className="weekToggle" onClick={this.toNextWeek}>
                        <i className="iconfont icon-right"></i>
                    </button>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.getIn(['main', 'user']),
        language_id: state.getIn(['header', 'language_id']),
        filter: state.getIn(['schedule','filter']).toJS(),
        weeks: state.getIn(['schedule', 'weeks']),
        isThisWeek: state.getIn(['schedule','isThisWeek']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //修改星期
    changeWeekType(){
        dispatch(actionCreators.changeScheduleFilter('date',this.weeks.toJS(),this));
    },

});

export default connect(mapStateToProps,mapDispathToProps)(DateList);