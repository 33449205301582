import styled from 'styled-components';

export const ScheduleContent = styled.div`
    .scheduleContent{
        padding:0 30px 0 30px;
    }
    .scheduleMany_wra {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .scheduleContent .location_many{
        display:flex;
        align-items:top;
        height: 100%;
    }
    .scheduleContent .no_class{
        display: flex;
        min-height: 150px;
        align-items: center;
        justify-content: center;
        background-color: white;
        text-align: center;
    }
    .location_many .many_item{
        width:33.3%;
    }
`

export const ScheduleTop = styled.div`
    padding: 30px 0;overflow:hidden;
    .l{
        width:183px;line-height: 36px;font-size:20px;cursor: pointer;
        .ant-dropdown-trigger .anticon-caret-down{font-size:16px;}
    }
`

export const SchduleChoose = styled.div`
   line-height: 36px;font-size:18px;float:left;font-weight:bold;cursor:pointer;
    .anticon-caret-down{font-size:16px;padding-left:10px;}
`

export const Printer = styled.div`
    float:right;color:#A8A69C;
    .anticon{
        color:#A8A69C;
    }
    &:hover .anticon{color:${props => (props.hoverColor)}}
    .ant-btn{border:none;box-shadow:none;font-size:16px;}
`

export const FilterClass = styled.div`
    .ant-select-focused{
        .ant-select-selection--single{border-color:#F2F2F2;}
    }
    .ant-select-selection--single{
        margin-right:12px;background:#F2F2F2;color: #555;height: 50px;border:none;border-radius:10px;
        &:hover{border-color:#F2F2F2;box-shadow:none;}
        .ant-select-selection__rendered{
            line-height: 50px;
        }
        .ant-select-selection__placeholder, .ant-select-search__field__placeholder,.ant-select-arrow-icon,.ant-select-arrow{color: #ffffff;}
    }
    .ant-select-focused,.ant-select-open.ant-select-enabled{
        .ant-select-selection--single{border-color:#666;}
    }
    span{cursor:pointer;transition:all 0.5s linear;color:#bfbfbf;&:hover{color:#14E1DC}}
    // same width length for schedule filter
    // .filterSelect {
    //     display: flex;
    //     flex-direction: row;
    // }

    .filterDiv {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .filterSelect .select_tag_box {
        // display: flex;
        vertical-align: middle;
        position: relative;
        // flex: 1 1;
    }
    .filterSelect .select_tag_box .ant-select {
        vertical-align: middle;
        flex: 1 1;
    }
    
    .filter_toggle{
        display: inline-block;
        cursor: pointer;
        color: #bfbfbf;
        // margin: auto;
        flex: none;
    }
    .filter_list{
        height: 0px;overflow: hidden;transition:all 0.5s; width:fit-content;
        &.show{height: 62px; width: fit-content;}
    }
`

export const ClassListTop = styled.div`
    overflow:hidden;padding:15px 0 10px;font-size:14px;color:#555;
    .explain{float: left;}
    .disc{float:right;}
`

export const ClassListDown = styled.div`
    overflow:hidden;padding:10px 0 60px;line-height:1.5em;
    .disc{float:left;font-size:14px;color:#555;}
`

export const WeekChoose = styled.div`
    float:right;color:#666;font-size:14px;
    span{
        padding:0 8px;position: relative;color: #666;transition:all 0.5s;cursor:pointer;
        &:hover,&.on{color: #000;}
        &.on{font-weight: bold;}
        &:last-child{
            padding-right: 0;
            &:after{display: none;}
        }
        &:after{
            content:'';width: 1px;height: 12px;position: absolute;right: 0;top: 50%;margin-top: -6px;background: #666;
        }
    }
`

export const ClassList = styled.div`
    &.loading{min-height:140px;}
    p{margin:0}
    table{
        background: #d9d9d9;border-collapse:collapse;
        &.fixed{
            padding-top: 90px;
            thead{width: 100%;position: fixed;top:0;}
        }
    }
    thead tr{
        height:75px;font-size:14px;font-weight: bold;line-height: 1.2em;background: #fff;color: #555;border-top:1px solid #ddd;
        th{border-bottom:1px solid #ddd;color:#BFBFBF;position: relative;};
        .toPrevWeek{
            position: absolute;left:0;top:50%;transform:translateY(-50%);border:none;background: none;padding:0;z-index: 2;cursor: pointer;color: #999;transition:all 0.5s;outline:none;
            &:hover{color:#000}
        }
        .toNextWeek{
            position: absolute;right:0;top:50%;transform:translateY(-50%);border:none;background: none;padding:0;z-index: 2;cursor: pointer;color: #999;transition:all 0.5s;outline:none;
            &:hover{color:#000}
        }
        .ant-affix{box-shadow:0 1px 0 0 rgba(0,0,0,0.1)}
    }
    tr.showLineF td{
        border-bottom:2px solid #ED1C24;
    }
    tr.showLineY td{
        border-bottom:2px solid #3A7EBB;
    }
    tr.loading{
        text-align:center;background: none;
        td{background: #fff;}
    }
    th{
        text-align:center;height:75px;vertical-align:middle;position: relative;
        .dateLine > div{width: 100%;display:table;background: #fff;}
        .date{
            width: 13%;height:75px;vertical-align:middle;display:table-cell;user-select: none;
            &:first-child{width: 9%;}
        }
        .date3{
            &:first-child{width: 5.65%;}
        }
        .today{
            color: #000;position:relative;
            &:after{content:'';width: 50%;height: 6px;background: #000;position: absolute;bottom:0;left:50%;transform:translateX(-50%);}
            .day{font-weight: bold;}
        }
        .week{font-size: 18px;margin-bottom: 10px;}
        .day{font-size: 20px;font-weight: normal;}
    }
    td{
        .bookBtn {
            min-width:78px;
        }
        background: #fff;border-bottom:1px solid #ddd;font-size: 16px;padding:0;color:#000;border-top:none;background:#F2F2F2; vertical-align: middle; height: 1px;
        .schedule_wra{
            // padding: 10px;min-height:133px;width: 100%;position: relative;background:#fff;vertical-align:middle;
            padding: 0px;min-height:133px;width: 100%;position: relative;background:#fff; height: 100%;
            display: flex;
            flex-direction: column;
        }
        .schedule_wrapper{
            padding: 5px 5px 10px 20px;
            position: relative;
        }
        .schedule_wrapper:last-child {
            flex: 1;
        }
        &.past{
            color:#A6A6A6;border-bottom:1px solid #ddd;
            .schedule_wra{background: #F2F2F2;}
            span.line{display:none}
        }
        &:first-child{
            width: 9%;
            text-align: center;color: #000;font-size:22px;font-weight: bold;background: #f2f2f2;border-color:#ddd;
            p{height: 3px; line-height:0;opacity:0;font-size:0;}
        }
    }
    &.isLogin td .schedule_wra{min-height:133px;}
    .line{
        position: absolute;height: 100%;left: 0;width: 8px;top:0;
        &.green{background: #77923b;}
        &.blue{background: #548ed4;}
        &.red{background: #943735;}
        &.yellow{background: #ffc000;}
        &.violet{background: #5f497a;}
    }
    .className{
        font-size:18px;font-weight: bold;display: block;width: 100%;cursor:pointer;
    }
    .teacher{
        font-size:14px;cursor:pointer;padding-top:3px;color:#999;line-height:1.3;
    }
    .during{
        font-size:14px;margin-bottom:5px;color:#999;line-height:1.3;
        // display: flex;
    }
    .location{
        font-size: 14px;
        margin-bottom: 5px;
        color: #999;
        line-height: 1.3;
        .iconfont{
            font-size: 12px;
        }
    }
    .classDuration{
        margin: auto;
    }
    .book{
        text-align:left;padding-right:5px;margin-top: 20px; display: flex;
    }
    .special{
        width: 1.4rem;
        height: 1.4rem;
    }
    .location_box{min-height:75px;}
    .location_box> div{width: 100%;min-height:75px;display:table;background: #fff;}
    .location_list{
            width: 13%;vertical-align:middle;display:table-cell;
        }
    .location_list{
        &:first-child{width: 5%;}
    }
    .lt_box{
        display:flex;
        align-items:center;
        .lt_item{
            width:33.3%;
            font-size:16px;
            color:#000000;
            padding:10px 15px;
            line-height:1.3;
        }
    }
    .vaccinated{
        width: 1.8rem;
        height: 1.8rem;
        .vacIcon{
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .classFeature{
        display:flex;
    }
    .iconRight{
        justify-content: flex-end;
        display: flex;
        flex: 1 1;
        margin: auto;
        vertical-align: middle;
    }
    .dull{
        // filter: grayscale(.5);
    }
`

export const Notice = styled.div`
    .noticeCard{
        background: #f2dede;border: none;color: #000;border-radius:0;text-align:center;
        &.line .ant-card-body{
            border-top: 1px solid #fff;
        }
        .title{font-weight:bold;color:#000;margin-bottom:15px;font-size:18px;line-height:1.3}
        .anticon{margin-right:10px}
        .notice_article{color:#000;font-size:15px;line-height:1.4;padding:0 10%;}
        .ant-card-body{
            width:600px;margin: 0 auto;padding:20px 0;min-height:150px; text-align: left;word-break: normal;
        }
    }
`

