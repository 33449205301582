import React, { Component } from 'react';
import { connect } from 'react-redux';
import GetTeacherAvatar from '../getTeacherAvatar';
import ScheduleButton from '../scheduleButton/scheduleButton';
import { showClassInfo, judgeBooking } from '../../store/actionCreators';
import { getBookingList } from '../../../booking/store/actionCreators';
import { fromJS } from 'immutable';
import './classDetails.scss';
import { CLASS_CATEGORY } from '../../../config/constant';

class ClassDetails extends Component {
    ref = React.createRef();
    constructor(props) {
        super(props);
        this.state = {  };
    }

    locations(id, is_fuze, sector) {
        let { location_list, filter, lang , button_status} = this.props;
        filter = filter.toJS();
        if (!filter.sector) {
            return '';
        }
        // let data = location_list ? location_list[filter.sector] : [];
        let data = location_list ? location_list[sector] : [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                if (is_fuze) {
                    return `FUZE @ ${data[i].names}`;
                } else {
                    return data[i].names;
                }
            }
        }
    }
    cancelClassCallback(bookingParams) {
        // refresh on cancel class
        if (isMobile) {
            this.props.getBooking(bookingParams);
        }
    }



    render() {
        let { language_id, item , jwt, button_item, location_id, teacher_avatar, timeShown, sector, showVibe, showSpecial, category = undefined} = this.props;
        return (
            <li  key={item.id} className={'class_list_wrapper' + (item.class_type.is_fuze ? ' black' : '')}>
                <GetTeacherAvatar
                    id={teacher_avatar.id}
                    teacherId={teacher_avatar.teacherId}
                    teacherAvatar={teacher_avatar.teacherAvatar}
                    mood={teacher_avatar.mood}
                    vaccinatedClass={teacher_avatar.vaccinatedClass}
                    special={teacher_avatar.special}
                    showSpecial={showSpecial}
                ></GetTeacherAvatar>
                <div className="class_info">
                    <div className='classTitle' onClick={() => { category !== CLASS_CATEGORY.PRIVATE && this.props.showClassInfo(item.class_type.id, item.special, item.mood, jwt, language_id) }}>
                        <h4 className='time-display'>
                            {item.start_time_display}
                        </h4>
                        <h4 className='class-name'>
                            {item.class_type.name}
                        </h4>
                    </div>
                    <div className="down">
                        <div className="left">
                                <div className = "leftInfo">
                                    <p className="timeContent">{item.duration / 60000}min</p>
                                    <p>&nbsp;|&nbsp;</p>
                                    <p className="infoContent teacherName">{item.teacher.name}</p>
                                    {showVibe && item.mood &&
                                        <div className="mood">
                                            <p>&nbsp;|&nbsp;</p>
                                            <p className="moodName infoContent" style={{color:item.mood.color}}> {item.mood.name} </p>
                                        </div>
                                    }
                                </div>
                            <p>
                                <i className="iconfont icon-marker1"></i> &nbsp;
                                {this.locations(location_id, item.class_type.is_fuze, sector)}
                            </p>
                        </div>
                        {
                            (button_item.button_status !== 0 && this.props.isLogin) &&
                            <div className="opera book">
                                <ScheduleButton
                                    item={fromJS(button_item)}
                                    language_id={this.props.language_id}
                                    booking={this.props.booking}
                                    jwt={jwt}
                                    filter={this.props.filter}
                                    region_id={this.props.region_id}
                                    cancel_class_callback={this.props.cancel_class_callback}
                                    is_fuze={item.class_type.is_fuze}
                                >
                                </ScheduleButton>
                                {/* {bookingParams&&this.cancelClassCallback(bookingParams)} */}
                            </div>
                        }
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        region_id: state.getIn(['header', 'region_id']),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        filter: state.getIn(['schedule', 'filter']),
        pillars_all: state.getIn(['schedule', 'pillars_all']),
        isLogin: state.getIn(['main', 'isLogin']),
        language_id: state.getIn(['header', 'language_id']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //获取预约数据
    getBooking(bookingParams, page = 1, order=-1) {
        dispatch(getBookingList({...bookingParams, region_id: this.region_id }, order, page));
    },
    //课程详情
    showClassInfo(id, special, mood, jwt, language_id) {
        dispatch(showClassInfo(id, this.region_id, this.location_list, this.pillars_all.toJS(), special, mood, jwt, language_id));
    },
    //课程状态判断
    booking(id, status, booking_id) {
        let bookinfo = {
            class_id: id,
            status: status,
            jwt: this.jwt,
            filter: this.filter,
            booking_id,
            language_id: this.language_id,
            region_id: this.region_id,
            cancel_callback: this.cancel_class_callback,
            booking_callback: this.booking_class_callback,
        };
        dispatch(judgeBooking(bookinfo));
    },
});
export default connect(mapStateToProps, mapDispathToProps)(ClassDetails);