
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { MyState } from '../../../store/reducer.interface';
import { formatDate, intl, nl2br } from '../../../config/util';
import { StarFilled, StarOutlined, ClockCircleOutlined, RightOutlined, CheckOutlined, ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined } from '@ant-design/icons';
import { closeLocationModal } from '../../main/store/actionCreators';
import { flags } from '../../../config/unleash';
import { fromJS } from 'immutable';
import ScheduleButton from '../../../schedule/component/scheduleButton/scheduleButton';
import { judgeBooking, toggleFavourite, updateLocationInfo } from '../../../schedule/store/actionCreators';
import './locationModal.scss';
import HtmlContent from '../../htmlContent/htmlContent';
import { Anchor, Button, Carousel, Col, Row, Typography } from 'antd';
import { isDesktop } from 'react-device-detect';
let defaultAvatar = require('../../../assets/images/ic_default_image.png');
let virtualTour = require('../../../assets/images/ic_virtual.png');
let phone = require('../../../assets/images/ic_phone.png');

const mapStateToProps = (state: MyState) => {
    return {
        locationModalInfo: state.getIn(['main', 'locationModalInfo']).toJS(),
        lang: state.getIn(['header', 'lang']),
        language_id: state.getIn(['header', 'language_id']),
        isLogin: state.getIn(['main', 'isLogin']),
        user: state.getIn(['main', 'user']),
        mood_flag: state.getIn(['main', 'flags', flags.mood]),
        region_id: state.getIn(['header', 'region_id']),
        filter: state.getIn(['schedule', 'filter']),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        location_list_ungrouped: state.getIn(['schedule', 'location_list_ungrouped']).toJS(),
        favourite_location: state.getIn(['schedule', 'favourite_location'])

    };
};

const mapDispathToProps = (dispatch: any) => ({
    closeModal() {
        dispatch(closeLocationModal());
    },

    booking(id: any, status: any, booking_id: any, user: any, filter: any, language_id: any, region_id: any, cancel_class_callback: any, booking_class_callback: any) {
        let bookinfo = {
            class_id: id,
            status: status,
            jwt: user.get('jwt'),
            filter,
            booking_id,
            language_id,
            region_id,
            cancel_callback: cancel_class_callback,
            booking_callback: booking_class_callback,
        };
        dispatch(judgeBooking(bookinfo));
    },

    toggleFavouriteLocation(is_favourite: any, favourite_ids: any, favourite_id: any, region_id: any, location_list: any) {
        dispatch(toggleFavourite(is_favourite, favourite_ids, 'L', favourite_id, region_id, location_list));
    },

    updateClassInfo(id: any, region_id: any, jwt: any, language_id: any, locationModalInfo: any) {
        dispatch(updateLocationInfo(id, region_id, jwt, language_id, locationModalInfo));
    },
});

const connector = connect(mapStateToProps, mapDispathToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export interface LocationModalProps extends PropsFromRedux {

}
function LocationModal(props: LocationModalProps) {
    const { locationModalInfo, lang, language_id, isLogin, region_id, user, mood_flag, filter, location_list,
        favourite_location, location_list_ungrouped } = props;
    let scheduleList = locationModalInfo.schedule;
    let is_favourite = favourite_location.indexOf(locationModalInfo.id) >= 0;
    let imageLink = locationModalInfo.image_link ? locationModalInfo.image_link : defaultAvatar;
    let otherInfo = locationModalInfo.other;
    const onClose = () => {
        props.closeModal();
    };

    const onToggleFavourite = (is_favourite: any, favourite_id: any) => {
        props.toggleFavouriteLocation(is_favourite, favourite_location, favourite_id, region_id, location_list);
    };

    function scrollToSchedule() {
        const el = document.getElementById("classSchedule");
        if (el) {
            el.scrollIntoView({ behavior: "smooth" });
        }
    }

    //日期展示
    function formatDateTitle(date: any) {
        let dateTitle = formatDate(date, language_id);
        if (dateTitle.day === 'today') {
            return intl('schedule', 'today', language_id) + ' ' + dateTitle.month + ' ' + dateTitle.dayTimeShort;
        } else {
            return dateTitle.title;
        }
    }

    function locations(id: any) {
        let filters = filter.toJS();
        if (!filters.sector) {
            return '';
        }
        // let data = location_list ? location_list[filters.sector] : [];
        let data = location_list_ungrouped ? location_list_ungrouped : [];

        for (var i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                return data[i].names;
            }
        }
    }

    function updateSchedule() {
        // refresh on cancel class
        let jwt = user.get('jwt') ? user.get('jwt') : '';
        props.updateClassInfo(locationModalInfo.id, region_id, jwt, language_id, locationModalInfo);
    }

    const onBook = (item: any) => {
        props.booking(item.id, item.button_status, item.booking_id, user, filter, language_id, region_id, updateSchedule, updateSchedule)
    };

    //课程列表
    const getScheduleItemMobile = (list: any) => {
        let jwt = user.get('jwt') ? user.get('jwt') : '';
        return (
            <ul>
                {list.map((item: any) => {
                    let info = [
                        (<p key="time" className="timeContent">{item.duration_min} min</p>),
                        (<p key="info" className="infoContent teacherName">{item.teacher.name}</p>)
                    ];
                    if (mood_flag && item.mood) {
                        info.push(
                            <div key="mood" className="mood">
                                <p className="moodName infoContent" style={{ color: item.mood.color }}> {item.mood.name} </p>
                            </div>
                        );
                    }
                    return <li key={item.id} className={item.class_type.is_fuze ? 'black' : ''}>
                        <div className="date">{formatDateTitle(item.start_date)}</div>
                        <span
                            className="line"
                            style={{ backgroundColor: item.class_type.is_fuze ? '#e06b26' : item.class_type.pillar ? item.class_type.pillar.color : '#e06b26' }}
                        >
                        </span>
                        <div className="class_info">
                            <div className='time-name'>
                                <h4 className='start-time'>
                                    {item.start_time_display}
                                </h4>
                                <h4 className='class-name'>
                                    {item.class_type.name}
                                </h4>
                            </div>
                            <div className="down">
                                <div className="left">
                                    <div className="leftInfo">
                                        {
                                            info.map(t => t)
                                                .reduce((prev, curr, i) => {
                                                    return (
                                                        <>
                                                            {prev}<p key={i}>&nbsp;|&nbsp;</p> {curr}
                                                        </>
                                                    );
                                                })
                                        }
                                    </div>
                                    <p>
                                        <i className="iconfont icon-marker1"></i> &nbsp;
                                        {locations(item.location_id)}
                                    </p>
                                </div>
                                {
                                    (item.button_status !== 0 && isLogin) &&
                                    <div className="opera book">
                                        <ScheduleButton
                                            item={fromJS(item)}
                                            language_id={language_id}
                                            booking={() => onBook(item)}
                                            jwt={jwt}
                                            filter={filter}
                                            region_id={region_id}
                                            cancel_class_callback={updateSchedule}
                                            booking_class_callback={updateSchedule}
                                            blurBtn="true"
                                        >
                                        </ScheduleButton>
                                    </div>
                                }
                            </div>
                        </div>
                    </li>;
                }
                )}
            </ul>
        );
    }

    return (
        <div className="location-info">
            <div className='content-wrapper'>
                {
                    otherInfo.images.length > 0 ?
                        <Carousel arrows={isDesktop}
                            prevArrow={<LeftOutlined />}
                            nextArrow={<RightOutlined />}
                        >
                            {
                                otherInfo.images.map((element: any, index: any) => {
                                    return (
                                        <div key={`carousel_${index}`} >
                                            <div className='background-image-cover pb-16-9' style={{ backgroundImage: `url("${element}")` }} />
                                        </div>
                                    );
                                })
                            }
                        </Carousel>
                        :
                        <div className='background-image-cover pb-16-9' style={{ backgroundImage: `url("${imageLink}")` }} />

                }
                <div className="content">
                    <Typography.Text className="name title">{locationModalInfo.names[lang]}</Typography.Text>
                    <Typography.Text className="district">{locationModalInfo.district[lang]}</Typography.Text>
                    {
                        locationModalInfo.virtual_tour_link &&
                        <div className='virtual-tour'>
                            <Button type="default">
                                <a href={locationModalInfo.virtual_tour_link} target="_blank">
                                    <img src={virtualTour} />
                                    {/* <div className='background-image-cover pb-16-5' style={{ backgroundImage: `url("${vitualTour}")` }} /> */}
                                    {intl('other', 'takeTour', language_id)}
                                </a>
                            </Button>
                        </div>
                    }
                    {
                        locationModalInfo.contact_no &&
                        <div className='phone'>
                            <Button type="default">
                                <a href={`tel:${locationModalInfo.contact_no}`} target="_blank">
                                    <img src={phone} />
                                    {/* <div className='background-image-cover pb-16-5' style={{ backgroundImage: `url("${vitualTour}")` }} /> */}
                                    {locationModalInfo.contact_no}
                                </a>
                            </Button>
                        </div>
                    }
                    <div className="opening-hour">
                        <div className="title-wrapper">
                            <Typography.Text className='title'>{intl('other', 'openingHours', language_id)}</Typography.Text>
                            <Typography.Text className='is-open' style={{ color: otherInfo.is_open ? '#34c759' : '#F00F64', }}>
                                {otherInfo.is_open ? intl('other', 'nowOpen', language_id) + ' ● ' : intl('other', 'nowClose', language_id) + ' ● '}

                            </Typography.Text>
                        </div>
                        <HtmlContent content={otherInfo.opening_hrs}></HtmlContent>
                        {/* <pre className="disc">{otherInfo.opening_hrs}</pre> */}
                    </div>
                    <div className="address">
                        <div className="title-wrapper">
                            <Typography.Text className='title'>{intl('schedule', 'address', language_id)}</Typography.Text>
                            <a href={otherInfo.address_info.map_url} target="_blank" style={{ color: '#000' }}>
                                {intl('other', 'openMap', language_id)}
                                <RightOutlined />
                            </a>
                        </div>
                        <HtmlContent content={otherInfo.address_info.address}></HtmlContent>
                        {/* <pre className="disc">{otherInfo.address_info.address}</pre> */}
                    </div>
                    {
                        otherInfo.about_this_studio &&
                        <div className="about">
                            <div className="title-wrapper">
                                <Typography.Text className='title'>{intl('other', 'aboutThisLocation', language_id)}</Typography.Text>
                            </div>
                            {/* <pre className="disc">{otherInfo.about_this_studio}</pre> */}
                            <HtmlContent content={otherInfo.about_this_studio}></HtmlContent>
                        </div>
                    }

                    {
                        otherInfo.features && otherInfo.features.length > 0 &&
                        <div className="about">
                            <div className="title-wrapper">
                                <Typography.Text className='title'>{intl('other', 'features', language_id)}</Typography.Text>
                            </div>
                            <Row gutter={[16, 16]} style={{ margin: "0" }}>
                                {
                                    otherInfo.features.map((element: any, index: any) => {
                                        return (
                                            <Col span={12} key={element.id} className='about-col'>
                                                <CheckOutlined />
                                                {element.name}
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </div>
                    }
                    {scheduleList && scheduleList.length > 0 &&
                        <div className="schedule" id="classSchedule">
                            <div className="title">{intl('schedule', 'schedule', language_id)}</div>
                            <div className="filter">
                                <div className="filterTitle">{intl('schedule', 'allLocation', language_id)}</div>
                                <div className="filterTitle"> ・ </div>
                                <div className="filterTitle">{intl('schedule', 'next14Days', language_id)}</div>
                            </div>
                            {getScheduleItemMobile(scheduleList)}
                        </div>
                    }
                </div>
            </div>
            <div className="buttonSection">
                <button onClick={() => onToggleFavourite(is_favourite, locationModalInfo.id)}>
                    {is_favourite ?
                        <StarFilled style={{ color: '#ffaC33' }} /> :
                        <StarOutlined />
                    }
                    &nbsp; {intl('schedule', 'favourite', language_id)}
                </button>
                <button onClick={() => scrollToSchedule()}><ClockCircleOutlined /> &nbsp; {intl('schedule', 'schedule', language_id)}</button>
                
            </div>
            <div className="opera">
                <button className="close" onClick={() => onClose()}>{intl('model', 'close', language_id)}</button>
            </div>
        </div>
    );
}

export default connector(LocationModal);