import * as constants from './constants';
import { fromJS } from 'immutable';
import { catchError, bookingFormatDate, getDayAfterN,objSortByDate,formatDate,showMsg, getCurrentLanguageId, intl, showModel } from '../../config/util';
import { get_my_purchase, get_visit_history, get_client_info, update_client_info, update_profile } from '../../config/request'; 
import { signOut } from '../../common/main/store/actionCreators';
import { changeLoading } from '../../common/header/store/actionCreators';
import { isMobile } from 'react-device-detect';

//获取服务计划
export const getServicePlans = (region_id, jwt) => {
    showMsg('loading');
    return (dispatch) => {
        get_my_purchase({region_id},jwt).then(res => {
            showMsg('hide');
            if (res.data.error.code === 200) {
                let clientServices = res.data.data.clientServices.map(item => {
                    let startDate = item.service_startDate.split(' ')[0];
                    let endDate = item.service_endDate.split(' ')[0];
                    let month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                    let [start_year,start_month,start_day] = startDate.split('-');
                    let [end_year,end_month,end_day] = endDate.split('-');
                    item.startDateInfo = {
                        date: `${start_year}年${start_month.replace(/^0/g,'')}月${start_day.replace(/^0/g,'')}日`,
                        enDate: startDate.split('-')[2] +' '+ month[parseInt(startDate.split('-')[1]) -1] +' '+ startDate.split('-')[0]
                    };
                    item.endDate = {
                        date: `${end_year}年${end_month.replace(/^0/g,'')}月${end_day.replace(/^0/g,'')}日`,
                        enDate: endDate.split('-')[2] +' '+ month[parseInt(endDate.split('-')[1]) -1] +' '+ endDate.split('-')[0]
                    };
                    return item;
                });
                dispatch(saveClientServices(clientServices));
            } else {
                catchError(res.data, localStorage.getItem('language_id'), () => {
                    dispatch(signOut());
                });
            }
        });
    };
};
//获取到场记录
export const getSignList = (formData) => {
    showMsg('loading');
    return (dispatch) => {
        let { language_id,region_id, jwt, order, start_date, end_date } = formData;
        //api的end_date需要多增加一天
        let nextDay = getDayAfterN(new Date(end_date.replace(/-/g, '/')), 1).date;
        dispatch(getListLoading(true));
        get_visit_history({
            page: 1,
            per_page: 100,
            type: 'visit',
            region_id,
            language_id,
            start_date,
            end_date: nextDay
        },jwt).then(res => {
            showMsg('hide');
            dispatch(getListLoading(false));
            if (res.data.error.code === 200) {
                let data = res.data.data.bookings;
                let signInsByDate = {};
                let signList = data.map(item => {
                    let startDate = item.class.start_date;

                    if (signInsByDate[startDate]) {
                        signInsByDate[startDate].list.push(item);
                    } else {
                        signInsByDate[startDate] = {
                            dateInfo:formatDate(startDate, language_id),
                            list: [item]
                        };
                    }

                    item.orderTime = startDate +' '+item.class.start_time;
                    let classtime = bookingFormatDate(item.orderTime, language_id);
                    item.classtime = classtime.week +' '+ classtime.title;
                    item.sign_in_time = item.sign_in_time ? item.sign_in_time.replace(/:\d{2}$/g,'') : '';
                    item.bookingNumber = item.booked_date ? item.booked_date + ' ' + item.booked_time_display : '';

                    return item;
                });
                signList.sort(function (a, b) {
                    if (order === 'desc') {
                        return Date.parse(a.orderTime.replace(/-/g, "/")) - Date.parse(b.orderTime.replace(/-/g, "/"));
                    } else {
                        return Date.parse(b.orderTime.replace(/-/g, "/")) - Date.parse(a.orderTime.replace(/-/g, "/"));
                    }
                });
                dispatch(saveSignHistoryALL({
                    signList,
                    signInsByDate,
                }));
            } else {
                console.log('错误:'+res);
            }
        }).catch(error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};
//修改列表排序
export const changeOrder = (list,order) => {
    return (dispatch) => {
        let cancelList = list.toJS();
        cancelList.sort(function (a, b) {
            if (order === 'desc'){
                return Date.parse(a.orderTime.replace(/-/g, "/")) - Date.parse(b.orderTime.replace(/-/g, "/"));
            } else {
                return Date.parse(b.orderTime.replace(/-/g, "/")) - Date.parse(a.orderTime.replace(/-/g, "/"));
            }
        });
        dispatch(changeListOrder(order));
        dispatch(saveSignHistory(cancelList));
    };
};
//获取客户信息
export const getClientInfo = (jwt) => {
    return (dispatch) => {
        showMsg('loading');
        //dispatch(changeLoading(true))
        const region_id = localStorage.getItem('region_id') || '1';
        get_client_info(jwt, region_id).then(res => {
            showMsg('hide');
            dispatch(changeLoading(false));
            dispatch(saveClientInfo(res.data.data.user));
        }).catch(error => {
            catchError(error, localStorage.getItem('language_id'),()=>{
                dispatch(signOut());
            });
        });
    };
};
//修改邮件订阅和自动排队
export const changeUserInfo = (type, jwt, value) => {
    showMsg('loading');
    return (dispatch) => {
        const language_id = getCurrentLanguageId();
        const region_id = localStorage.getItem('region_id') || '1';
        update_client_info({ [type]: value, region_id, language_id }, jwt).then(res => {
            showMsg('hide');
            const errorCode = res.data.error.code;
            if (errorCode && errorCode !== 200) {
                const message = res.data.error.message || intl('other', 'errTryAgain', language_id);
                const title = intl('other', 'oops', language_id);
                showModel(message, 'error', title);
            } else {
                dispatch(changeClientInfo(value, type));
            }
        }).catch(error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

//修改用户头像
export const changeAvatar = (picture,jwt) => {
    showMsg('loading');
    return (dispatch) => {
        const region_id = localStorage.getItem('region_id') || '1';
        update_profile({ picture, region_id }, jwt).then(res => {
            showMsg('hide');
            dispatch(getClientInfo(jwt));
        }).catch(error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

//修改打卡记录排序
export const changeSignInsOrder = (order) => {
    return dispatch => {
        dispatch(changeListOrder(order));
    };
};

//保存服务计划
export const saveClientServices = (value) => ({
    type: constants.Save_Client_SERVICES,
    value: fromJS(value)
});

//保存用户信息
export const saveClientInfo = (value) => ({
    type: constants.SAVE_CLIENT_INFO,
    value: fromJS(value)
});
//保存修改用户信息
export const changeClientInfo = (value, changeType) => ({
    type: constants.CHANGE_CLIENT_INFO,
    changeType,
    value
});

//修改资料页的场景
export const changeProfileScene = (value) => ({
    type: constants.CHANGE_PROFILE_SCENE,
    value
});

//修改loading状态
export const getListLoading = (value) => ({
    type: constants.CHANGE_LOADING,
    value
});

//修改签到列表的分页信息
export const saveSignListPage = (value) => ({
    type: constants.SAVE_SIGN_LIST_PAGE,
    value: fromJS(value)
});

//保存签到列表
export const saveSignHistory = (value) => ({
    type: constants.SAVE_SIGN_HISTORY,
    value: fromJS(value)
});

//保存签到列表 (ALL)
export const saveSignHistoryALL = (value) => ({
    type: constants.SAVE_SIGN_HISTORY_ALL,
    value: fromJS(value)
});

//保存签到列表 (By Date)
export const saveSignHistoryByDate = (value) => ({
    type: constants.SAVE_SIGN_HISTORY_BY_DATE,
    value: fromJS(value)
});
//修改排序
export const changeListOrder = (value) => ({
    type: constants.CHANGE_LIST_ORDER,
    value: value
});
