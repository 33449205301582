import React, { Component } from 'react';
import * as constants from './constants';
import { fromJS } from 'immutable';
import { formatDate as getDates, showModel, showConfirm, bookMsg, bookingSuccessMsg, catchError, bookingContent, intl, getLangTitle, getQueryByName, getDefaultWeekType, showMsg, showCustomModal } from '../../config/util';
import {
    get_location_list,
    get_class_type,
    get_teacher_list,
    get_level_list,
    get_pillar_list,
    get_schedule,
    get_location_notice,
    class_booking,
    cancel_booking,
    get_teacher_details,
    get_class_mood,
    get_popup_modal,
    get_favourite_class_type,
    get_favourite_location,
    get_favourite_teacher,
    add_favourite,
    remove_favourite,
    get_location_opening_hour,
} from '../../config/request';
import { openLocationModal, openLocationModalSchedule, openTeacherModalSchedule, signOut } from '../../common/main/store/actionCreators';
import { changeLoading } from '../../common/header/store/actionCreators';
import { message } from 'antd';
import { getUpcomingClasses } from '../../booking/store/actionCreators';
import { openClassModal, openTeacherModal, openClassModalSchedule } from '../../common/main/store/actionCreators';
import { isMobile } from 'react-device-detect';
import { getNextDays, nowDate } from '../../config/util';
import { changeType } from '../../common/header/store/actionCreators';
import { BUTTON_STATUS } from '../../config/constant';
import { formatLocations, formatTeacher } from '../../config/favouriteHelper';
import { getClassTypeById } from '../../config/classHelper';

//获取所有筛选
export const getFilter = (language_id, region_id, type, user, weekType = getDefaultWeekType()) => {
    showMsg('loading');
    return (dispatch) => {
        let loggedInListPromises = [];
        if (user) {
            loggedInListPromises = [
                get_favourite_class_type(region_id),
                get_favourite_location(region_id),
                get_favourite_teacher(region_id),
            ];
        }

        Promise.all([
            get_location_list({ language_id, region_id }),
            get_class_type({ language_id, region_id }),
            get_teacher_list({
                region_id,
                language_id,
                needs: ['descriptions', 'image_link', 'location_ids', 'names', 'short_names']
            }),
            get_level_list(),
            get_pillar_list(),
            Promise.all(loggedInListPromises),
            // get_favourite_class_type(region_id),
            // get_favourite_location(region_id),
            // get_favourite_teacher(region_id)
        ]).then(result => {
            showMsg('hide');
            let [locations, classtypeList, teacherList, levelList, pillarList,
                loggedInList] = result;

            //场所列表
            let location_list = { yoga: [], fitness: [], Y: [], F: [] };
            const location_list_ungrouped = [];
            locations.data.data.locations.map(item => {
                item.names = getFirstAttr(item.names);
                if (item.is_yoga) {
                    location_list.yoga.push(item);
                    location_list.Y.push(item);
                }
                if (item.is_fitness) {
                    location_list.fitness.push(item);
                    location_list.F.push(item);
                }
                location_list_ungrouped.push(item);
                return item;
            });
            const locationListRaw = { ...location_list };

            //设置选中场所
            let location_id='';
            //url地址判断有没有输入location_ids
            let urlLocationID = getQueryByName(window.location.href, 'location_ids') || localStorage.getItem('location_id');

            if (urlLocationID) {
                const locationList = location_list[type == 'Y' ? 'yoga' : 'fitness'];
                const locationIdsAll = urlLocationID.split(',');
                const validLocationIds = locationIdsAll.filter((id) => {
                    const result = locationList.find((item) => item.id == id);
                    return result;
                });
                location_id = validLocationIds.join(',');
            }

            // get first location as default location
            if (!location_id) {
                location_id = location_list[type == 'Y' ? 'yoga' : 'fitness'][0].id;
            }

            localStorage.setItem('location_id', location_id);

            // let localLocation_id = localStorage.getItem('location_id');

            //判断url中的location_ids是否在location_list中
            // if (urlLocationID && location_list[type == 'Y' ? 'yoga' : 'fitness'].find(item => item.id === parseInt(urlLocationID))) {
            //     location_id = urlLocationID;
            // } else {
            //     location_id = location_list[type == 'Y' ? 'yoga' : 'fitness'][0].id;
            // }

            // if (urlLocationID && location_list[type == 'Y' ? 'yoga' : 'fitness'].find(item => item.id === parseInt(urlLocationID))) {
            //     location_id = urlLocationID;
            // } else if ( localLocation_id && location_list[type == 'Y' ? 'yoga' : 'fitness'].find(item => item.id === parseInt(localLocation_id))) {

            //     location_id = localLocation_id;
            // } else {
            //     location_id = location_list[type == 'Y' ? 'yoga' : 'fitness'][0].id;
            // }


            //设置课程类型
            let classtypes = [{ id: 0, names: intl('schedule', 'allClassType', language_id) }];
            let classtypes_all = { Y: [], F: [] };
            const classtypes_ungrouped = [];
            classtypeList.data.data.classtypes.forEach(item => {
                if (item.is_public) {
                    if (item.sector === type) {
                        let classItem = {
                            id: item.id,
                            names: getFirstAttr(item.names),
                            descriptions: getFirstAttr(item.descriptions),
                            location_ids: item.location_ids,
                            level_id: item.level_id,
                            pillar_id: item.pillar_id
                        };
                        classtypes.push(classItem);
                    }
                    item.sector === 'Y' && classtypes_all.Y.push(item);
                    item.sector === 'F' && classtypes_all.F.push(item);
                    classtypes_ungrouped.push(item);
                }
            });

            //设置老师列表
            // let teacher_list = teacherList.data.data.teachers.filter(item => item.is_public && item.sector === type);
            let teachers = [{ id: 0, names: { [getLangTitle(language_id)]: intl('schedule', 'allTeacher', language_id) } }];
            let teachers_all = { Y: [], F: [] };
            let teachers_ungrouped = [];
            teacherList.data.data.teachers.forEach(item => {
                if (item.is_public) {
                    if (item.sector === type) {
                        teachers.push(item);
                    }
                    item.sector === 'Y' && teachers_all.Y.push(item);
                    item.sector === 'F' && teachers_all.F.push(item);
                    teachers_ungrouped.push(item);
                }
            });

            //等级
            // let levels_list = levelList.data.data.levels.filter(item => item.sector === type);
            let levels = [{ id: 0, sector: type, names: { [getLangTitle(language_id)]: intl('schedule', 'alllevel', language_id) } }];
            let levels_all = { Y: [], F: [] };
            levelList.data.data.levels.forEach(item => {
                if (item.sector === type) {
                    levels.push(item);
                }
                item.sector === 'Y' && levels_all.Y.push(item);
                item.sector === 'F' && levels_all.F.push(item);
            });

            //支柱
            // let pillars_raw = pillarList.data.data.pillars;
            let pillars_all = { Y: [], F: [] };
            const pillars_by_id = {};
            let pillars = [{ id: 0, names: { [getLangTitle(language_id)]: intl('schedule', 'allPillar', language_id) } }];
            pillarList.data.data.pillars.forEach(item => {
                if (item.sector === type) {
                    pillars.push(item);
                }
                item.sector === 'Y' && pillars_all.Y.push(item);
                item.sector === 'F' && pillars_all.F.push(item);
                pillars_by_id[item.id] = item;
            });

            //星期
            let weeks = {
                thisWeek: getFirstDayOfWeek(new Date(), language_id),
                nextWeek: getNextDayOfWeek(new Date(), language_id),
                thisDay: formatDate(new Date(), language_id),
                nextWeekDay: formatDate(new Date().setDate(new Date().getDate() + 7), language_id),
            };

            //如果类型是fitness获取筛选type
            let types = [];
            if (type == 'F') {
                types = [
                    {
                        id: 0,
                        value: '',
                        names: { '1': 'All', '2': '所有', '3': '所有', '4': '所有' }
                    },
                    {
                        id: 1,
                        value: 'Fitness',
                        names: { '1': 'Fitness', '2': '健身', '3': '健身', '4': '健身' }
                    }, {
                        id: 2,
                        value: 'Cycling',
                        names: { '1': 'Cycling', '2': '單車', '3': '单车', '4': '單車' }
                    }, {
                        id: 3,
                        value: 'SGT',
                        names: { '1': 'SGT', '2': '小型團體訓練', '3': '小型团体训练', '4': '小型團體訓練' }
                    }, {
                        id: 4,
                        value: 'FUZE',
                        names: { '1': 'FUZE', '2': 'FUZE', '3': 'FUZE', '4': 'FUZE' }
                    }
                ];
            }

            let mood_ids = localStorage.getItem('mood_ids') ? localStorage.getItem('mood_ids') : '';

            //日期列表
            const weekType = getDefaultWeekType();
            dispatch(getWeek(weekType, language_id));

            let favouriteClassType = [];
            let favouriteLocation = [];
            let favouriteTeacher = [];

            if (user) {
                const [favoriteClassTypeList, favouriteLocationList, favouriteTeacherList] = loggedInList;
                favouriteClassType = favoriteClassTypeList.data.data.favourite_class_type;
                favouriteLocation = favouriteLocationList.data.data.favourite_location;
                favouriteTeacher = favouriteTeacherList.data.data.favourite_teacher;
            }

            location_list = formatLocations(location_list, favouriteLocation);
            // teachers_all = formatTeacher(teacher_all, favouriteTeacher);
            //
            dispatch(initData({
                location_list,
                location_list_ungrouped,
                location_list_raw: locationListRaw,
                classtypes,
                classtypes_all,
                classtypes_ungrouped,
                teachers,
                teachers_all,
                levels,
                levels_all,
                pillars,
                pillars_all,
                pillars_by_id,
                weeks,
                types,
                favourite_class_type: favouriteClassType,
                favourite_location: favouriteLocation,
                favourite_teacher: favouriteTeacher,
                teachers_ungrouped,
            }));

            let week;
            switch (weekType) {
                case 1:
                    week = weeks.thisWeek;
                    break;
                case 2:
                    week = weeks.nextWeek;
                    break;
                case 3:
                    week = weeks.thisDay;
                    break;
                default:
                    week = weeks.nextWeekDay;
            }

            let filter = {
                location_ids: location_id,
                region_id,
                start_date: week.value,
                sector: type,
                days: 7,
                language_id: language_id,
                // mood_ids
            };

            if (mood_ids) {
                filter.mood_ids = mood_ids;
            }
            //获取课程
            dispatch(getSchedule(filter, user ? user.jwt : ''));


        }).catch(error => {
            //改变loading
            dispatch(changeLoading(false));
            catchError(error, language_id, () => {
                dispatch(signOut());
            });
        });
    };
};



//获取三种日期列表
/**
 *
 * @param {number} type 1=this week; 2=next week; 3=next 7 days; 4=next week 7 days
 * @param {number} language_id
 * @returns
 */
export const getWeek = (type, language_id) => {
    return (dispatch) => {
        let dateList = [];
        let date = new Date();
        var day = date.getDay() || 7;
        for (var i = 0; i < 7; i++) {
            let newDate;
            if (type === 1) {
                newDate = getDates(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - day + i), language_id);
            } else if (type === 2) {
                newDate = getDates(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 8 - day + i), language_id);
            } else if (type === 3) {
                newDate = getDates(new Date(date.getFullYear(), date.getMonth(), date.getDate() + i), language_id);
            } else {
                newDate = getDates(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7 + i), language_id);
            }
            dateList.push(newDate);
        }
        dispatch(saveWeek(dateList));
    };
};


//获取课程列表  start_date 现在地点和场所是写死的，到时候要调回来
export const getSchedule = (filter, jwt, data) => {
    showMsg('loading');
    return (dispatch) => {
        let language_id = localStorage.getItem('language_id') || 1;
        // dispatch(changeScheduleLoading(true))
        get_schedule(filter, jwt).then(res => {
            showMsg('hide');
            let errorCode = res.data.error.code;
            let classesData = res.data.data.classes;
            if (filter.location_ids && filter.location_ids.toString().indexOf(',') != -1) {

                filter.location_ids.split(',');
                dispatch(locationNum(filter.location_ids.split(',').length));
            } else if (!filter.location_ids) {
                dispatch(locationNum(0));
            } else {
                dispatch(locationNum(1));
            }

            // if (filter.mood && filter.mood.toString().indexOf('|') != -1) {

            //     filter.mood.split('|');
            //     dispatch(saveMood(filter.mood.split('|').length));
            // } else if (!filter.mood) {
            //     dispatch(saveMood(0));
            // } else {
            //     dispatch(saveMood(1));
            // }

            dispatch(changeLoading(false));
            dispatch(changeScheduleLoading(false));
            if (errorCode === 200) {
                //获取所有课程开始的时间
                let classTimeList = [];
                classesData.map(item => classTimeList.findIndex(x => x.start_time === item.start_time) < 0 && classTimeList.push({ start_time: item.start_time, start_time_display: item.start_time_display }));
                classTimeList.sort((a, b) => (a.start_time < b.start_time) ? -1 : ((b.start_time > a.start_time) ? 1 : 0))
                //根据时间段建立json数组[{time:***,nextTime:***,list:[]}]
                let classList = [];
                classTimeList.map((x, index) => {
                    const item = x.start_time;
                    let name = item.replace(/:00$/g, '');
                    let nextTime = '';
                    if (index === classTimeList.length - 1) {
                        nextTime = x.start_time.split(':')[0] + ':' + parseInt(x.start_time.split(':')[1]) + 15;
                    } else {
                        nextTime = classTimeList[index + 1].start_time;
                    }
                    classList.push({ name, nextTime: nextTime.replace(/:00$/g, ''), start_time_display: x.start_time_display, list: [] });
                    return item;
                });

                //二次遍历课程数据，先由开始时间对比获取索引值，然后插入到classList中
                classesData.map(item => {
                    let index = classTimeList.findIndex(x => x.start_time === item.start_time);
                    classList[index].list.push(item);
                    return item;
                });

                const classesMobile = convertClassesToMobile(dispatch, classList, filter.start_date);

                //更新schedule组件
                dispatch(changeSchedule({
                    classes: classList,
                    classes_mobile: classesMobile,
                    filter,
                    location_ids: filter.location_ids,
                    class_total: classesData.length,
                }));
                if (data && data.class_id) {
                    var btnid = 'btn_' + data.class_id;
                    var oBtn = document.getElementById(btnid);
                    // var btn_status = oBtn.getAttribute('data-status');
                    // var waiting_number = oBtn.getAttribute('data-waiting_number');
                    // oBtn.innerText = window.$trans(getStatusName(parseInt(btn_status)))+waiting_number
                    if (oBtn) {
                        oBtn.blur();
                    }
                }

                if (jwt) {
                    dispatch(getUpcomingClasses(filter.region_id, language_id, jwt));
                }

            }
        }).catch(error => {
            //改变loading
            dispatch(changeLoading(false));
            catchError(error, language_id, () => {
                dispatch(signOut());
            });
        });
    };
};

//获取通告
export const getNotice = (location_id, language_id, region_id) => {
    showMsg('loading');
    return (dispatch) => {

        get_location_notice({
            language_id,
            region_id,
            location_id
        }).then(res => {
            showMsg('hide');
            let errorCode = res.data.error.code;

            if (errorCode === 200) {
                if (isMobile) {
                    let noticeMobile = res.data.data.locationAlerts || [];
                    dispatch(saveNoticeAll(noticeMobile));
                } else {
                    let notice = res.data.data.locationAlerts[0] || {};
                    dispatch(saveNotice(notice));
                }
            }
        }).catch(error => {
            catchError(error, language_id, () => {
                console.log(error.msg);
            });
        });
    };
};


//筛选课程
export const changeFilterChoose = (value, filterType, filter, weeks, jwt, location_list) => {
    return (dispatch) => {
        let newFilter = filter ? Object.assign({}, filter.toJS()) : {};
        newFilter.sector = localStorage.getItem('type');
        newFilter[filterType] = value;
        let updateNotice = false;

        if (weeks) {
            let start_date;
            if (value === 2) {
                start_date = weeks.getIn(['nextWeek', 'value']);
            } else if (value === 3) {
                start_date = weeks.getIn(['thisDay', 'value']);
            } else {
                start_date = weeks.getIn(['thisWeek', 'value']);
            }
            newFilter.start_date = start_date;
            dispatch(saveWeekType(value));
        }

        if (filterType === 'sector') {
            localStorage.setItem('type', value);
            dispatch(changeType(value));

            let locations = location_list;
            let newLocationId = locations[value].find((item, index) => index === 0).id;
            localStorage.setItem('location_id', newLocationId);
            newFilter.location_ids = newLocationId;
            updateNotice = true;

            if (newFilter.hasOwnProperty('class_type_id')) {
                delete newFilter.class_type_id;
            }
            if (newFilter.hasOwnProperty('teacher_id')) {
                delete newFilter.teacher_id;
            }
            if (newFilter.hasOwnProperty('pillar_id')) {
                delete newFilter.pillar_id;
            }
            if (newFilter.hasOwnProperty('level_id')) {
                delete newFilter.level_id;
            }
            if (newFilter.hasOwnProperty('filter_type')) {
                delete newFilter.filter_type;
            }
            dispatch(getSchedule(newFilter, jwt));
        }

        if (filterType === 'apply') {
            newFilter = value;
            if (newFilter.filter_type == 'All') {
                newFilter.filter_type = '';
            }
            updateNotice = true;
        }

        if (filterType === 'location_ids') {
            localStorage.setItem('location_id', newFilter.location_ids);
            if (newFilter.location_ids) {
                newFilter.location_ids = newFilter.location_ids.toString();
            }
            updateNotice = true;
        }

        if (filterType === 'mood_ids') {
            localStorage.setItem('mood_ids', newFilter.mood_ids);
            if (newFilter.mood_ids) {
                newFilter.mood_ids = newFilter.mood_ids.toString();
                if (newFilter.mood_ids == '0') {
                    delete newFilter.mood_ids;
                    // localStorage.setItem('mood_ids','');
                    localStorage.removeItem('mood_ids');
                }
            }
            // updateNotice = true;
        }

        if (updateNotice) {
            dispatch(getNotice(newFilter.location_ids, localStorage.getItem('language_id'), newFilter.region_id));
        }

        dispatch(saveFilterChoose(fromJS(newFilter)));
        dispatch(getSchedule(newFilter, jwt));
    };
};

//清除筛选
export const resetFilter = (filter, jwt) => {
    return (dispatch) => {
        let newFilter = filter.toJS();
        if (newFilter.hasOwnProperty('class_type_id')) {
            delete newFilter.class_type_id;
        }
        if (newFilter.hasOwnProperty('teacher_id')) {
            delete newFilter.teacher_id;
        }
        if (newFilter.hasOwnProperty('pillar_id')) {
            delete newFilter.pillar_id;
        }
        if (newFilter.hasOwnProperty('level_id')) {
            delete newFilter.level_id;
        }
        if (newFilter.hasOwnProperty('filter_type')) {
            delete newFilter.filter_type;
        }
        if(!isMobile)
        {
            if (newFilter.hasOwnProperty('mood_ids')) {
                delete newFilter.mood_ids;
                localStorage.removeItem('mood_ids');
            }
        }
        dispatch(getSchedule(newFilter, jwt));
    };
};

//获取老师详情
export const showTeacherInfo = (id, region_id, locations, language_id, jwt) => {
    return dispatch => {
        showMsg('loading');
        Promise.all([
            get_teacher_details({ id, region_id }),
            getScheduleRequestForTeacherInfo(id, region_id, language_id, jwt),
        ]).then(res => {
            showMsg('hide');
            let [teacherDetails, schedule] = res;
            let teacherInfo = teacherDetails.data.data.teachers[0];
            let scheduleList = schedule.data.data.classes;

            if (teacherInfo) {
                let locationList = Array.from(new Set([...locations.Y, ...locations.F]));
                let teacherLocations = teacherInfo.location_ids.map(item => {
                    return locationList.find(it => it.id === item);
                });
                teacherInfo.locations = teacherLocations;
                teacherInfo.schedule = scheduleList;

            }
            dispatch(openTeacherModal(teacherInfo));
        }, error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

export const showLocationInfo = (location_id, region_id, language_id, jwt) => {
    return (dispatch) => {
        showMsg('loading');
        Promise.all([
            get_location_list({ language_id, region_id }),
            getScheduleRequestForLocationInfo(location_id, region_id, language_id, jwt),
            get_location_opening_hour(location_id, region_id, language_id),
        ]).then(res => {
            showMsg('hide');
            let [locationDetails, schedule, openingHour] = res;
            let other = openingHour.data.data;
            let scheduleInfo = [];
            let scheduleList = schedule.data.data.classes;
            scheduleList.forEach(element => {
                if (element.location_id === location_id) {
                    scheduleInfo.push(element);
                }
            });

            let locationInfo;
            let locationList = locationDetails.data.data.locations;
            locationList.forEach(element => {
                if (element.id === location_id) {
                    locationInfo = element;
                    locationInfo.schedule = scheduleInfo;
                    locationInfo.other = other;
                }
            });

            dispatch(openLocationModal(locationInfo));
        }, error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

//获取课程详情
export const showClassInfo = (id, region_id, locations, pillars, special, mood, jwt, language_id, scroll = false) => {
    return dispatch => {
        showMsg('loading');
        Promise.all([
            // replace with getClassTypeById function (save server resource)
            // get_class_details({ region_id, id }),

            Promise.resolve(getClassTypeById(id)),
            getScheduleRequestForClassInfo(id, region_id, language_id, jwt),
        ]).then(result => {
            showMsg('hide');
            if (!result) {
                return;
            }
            let [classtypes, schedule] = result;

            let scheduleList = schedule.data.data.classes;
            if (classtypes) {
                //获取场所列表
                let locationList = Array.from(new Set([...locations.Y, ...locations.F]));
                let classLocations = classtypes.location_ids.map(item => {
                    return locationList.find(it => it.id === item);
                });
                classtypes.locations = classLocations;
                //获取支柱信息
                let pillarsList = Array.from(new Set([...pillars.Y, ...pillars.F]));
                classtypes.pillarInfo = pillarsList.find(item => item.id === classtypes.pillar_id);

                classtypes.special = special;
                classtypes.mood = mood;
                classtypes.schedule = scheduleList;
                classtypes.scroll = scroll;
                dispatch(openClassModal(classtypes));
            } else {
                showMsg('error', intl('other', 'errorNoInfo', language_id.language_id));
            }

        }, error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

export const updateClassInfo = (id, region_id, jwt, language_id, scheduleModalInfo) => {
    return (dispatch) => {
        showMsg('loading');
        getScheduleRequestForClassInfo(
            id,
            region_id,
            language_id,
            jwt
        ).then(res =>{
            showMsg('hide');
            let scheduleList = res.data.data.classes;
            scheduleModalInfo.schedule = scheduleList;
            dispatch(openClassModalSchedule(scheduleModalInfo));
        }, error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };

};

export const updateTeacherInfo = (id, region_id, jwt, language_id, teacherModalInfo) => {
    return (dispatch) => {
        showMsg('loading');
        getScheduleRequestForTeacherInfo(
            id,
            region_id,
            language_id,
            jwt
        ).then(res =>{
            showMsg('hide');
            let scheduleList = res.data.data.classes;
            teacherModalInfo.schedule = scheduleList;
            dispatch(openTeacherModalSchedule(teacherModalInfo));
        }, error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };

};

export const updateLocationInfo = (id, region_id, jwt, language_id, locationModalInfo) => {
    return (dispatch) => {
        showMsg('loading');
        getScheduleRequestForLocationInfo(
            id,
            region_id,
            language_id,
            jwt
        ).then(res =>{
            showMsg('hide');
            let scheduleList = res.data.data.classes;
            locationModalInfo.schedule = scheduleList;
            dispatch(openLocationModalSchedule(locationModalInfo));
        }, error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };

};

//判断事件（预约、取消、排单...）
export const judgeBooking = (data) => {
    return (dispatch) => {
        switch (data.status) {
            case BUTTON_STATUS.BOOK://预约
            case BUTTON_STATUS.WAITLIST:
            case BUTTON_STATUS.LAST_CHANCE:
                dispatch(booking(data));
                break;
            case BUTTON_STATUS.JOIN_ZOOM:
            case BUTTON_STATUS.BOOKED://取消预约
            case BUTTON_STATUS.IN_WAITLIST://取消候补
                dispatch(cancelBooking(data));
                break;
            case 100://强制取消预约
                let confirmInfo = bookingContent(data.language_id, data.status);
                showConfirm(confirmInfo, () => {
                    dispatch(cancelBooking(data));
                });
                break;
            default:
                break;
        }
    };
};

export function bookingFun(dispatch, data) {
    let language_id = localStorage.getItem('language_id') || 1;
    showMsg('loading');
    dispatch(changeScheduleProcessIds(data.class_id, true));
    return class_booking({
        language_id: language_id,
        class_id: data.class_id,
        book_type: data.book_type !== undefined ? data.book_type : data.status === 1 ? 1 : 2,
        booked_from: 'WEB',
        region_id: data.region_id
    }, data.jwt).then(res => {
        showMsg('hide');
        let errorCode = res.data.error.code;
        let modalData = res.data.error.modal_data;
        let errormsg = res.data.error.message;
        if (modalData) {
            showCustomModal(dispatch, data, modalData);
            return;
        }

            switch(errorCode){
                case 200:
                    let wait = 0;
                    if (isMobile) {
                        wait = 1000;
                    }
                    let modalData = res.data.data.modal_data;
                    if (modalData) {
                        showCustomModal(dispatch, data, modalData);
                    } else {
                        showMsg('success', bookingSuccessMsg(language_id, data.status));
                    }

                    setTimeout(() => {
                        dispatch(getSchedule(data.filter.toJS(), data.jwt, data));
                        if (data.booking_callback) {
                            data.booking_callback(res);
                        }
                    }, wait);
                    break;
                case 20011: //overbooked
                    showModel(errormsg, 'error', intl('other', 'oops', language_id));
                    break;
                case 449: // no allow booking access
                    showModel(errormsg, 'error', intl('other', 'oops', language_id));
                    break;
                case 409:
                    let newData = data;
                    newData.status = 2;
                    showModel(errormsg, 'confirm', intl('other', 'tips', language_id), dispatch, newData);
                    break;
                default:
                    showModel(bookMsg(res.data.error, language_id), 'error', intl('other', 'tips', language_id));
                    break;
            }
    }).catch(error => {
        catchError(error, language_id, () => {
            dispatch(signOut());
        });
    }).finally(() => {
        dispatch(changeScheduleProcessIds(data.class_id, false));
    });
}

function getScheduleRequestForClassInfo(class_type_id, region_id, language_id, jwt) {
    return get_schedule(
        {
            class_type_id,
            days: 14,
            start_date: nowDate(),
            region_id,
            language_id,
            jwt,
        }
    );
}

function getScheduleRequestForLocationInfo(location_id, region_id, language_id, jwt) {
    return get_schedule(
        {
            location_id,
            days: 14,
            start_date: nowDate(),
            region_id,
            language_id,
            jwt,
        }
    );
}

function getScheduleRequestForTeacherInfo(teacher_id, region_id, language_id, jwt) {
    return get_schedule(
        {
            teacher_id,
            days: 14,
            start_date: nowDate(),
            region_id,
            language_id,
            jwt,
        }
    );
}


export const booking = (data) => {
    let language_id = localStorage.getItem('language_id') || 1;
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let book = bookingFun(dispatch, data);
            if (book) {
                book.finally(() => resolve());
            }

            // bookingFun(dispatch, data).finally(() => resolve());

            // if (data.auto_waitlist || data.status != 3) {
            //     bookingFun(dispatch, data).finally(() => resolve());
            // } else {
            //     showConfirm({
            //         title: intl('schedule', 'addWaiting', language_id),
            //         content: intl('schedule', 'bookingConfir', language_id)},
            //         () => {bookingFun(dispatch, data).finally(() => resolve());},
            //         () => resolve(),
            //     );
            // }
        });
    };
};

//课程预约
// export const booking = (data) => {
//     return (dispatch) => {
//         let language_id = localStorage.getItem('language_id') || 1;
//         class_booking({
//             class_id: data.class_id,
//             book_type: data.status === 1 ? 1 : 2,
//             booked_from: 'WEB',
//             region_id: data.region_id
//         }, data.jwt).then(res => {
//             if (res.data.error.code === 200){
//                 message.success(bookingSuccessMsg(language_id, data.status));
//                 //重新请求数据
//                 dispatch(getSchedule(data.filter.toJS(), data.jwt))
//             }else{
//                 showModel(bookMsg(res.data.error, language_id), 'error');
//             }
//         }).catch(error => {
//             catchError(error, language_id, () => {
//                 dispatch(signOut())
//             })
//         })
//     }
// }

//取消预约
export const cancelBooking = (data) => {
    showMsg('loading');
    return (dispatch) => {
        dispatch(changeScheduleProcessIds(data.class_id, true));
        let cancel = cancel_booking({
            booking_id: data.booking_id,
            late_cancel: data.status === 100 ? 1 : 0,
            cancelled_from: 'WEB',
            region_id: data.region_id
        }, data.jwt).then(res => {
            showMsg('hide');
            let errorCode = res.data.error.code;
            if (errorCode === 200) {
                let wait = 0;
                if (isMobile) {
                    wait = 1000;
                }
                showMsg('success', bookingSuccessMsg(data.language_id, data.status));
                // message.success(bookingSuccessMsg(data.language_id, data.status));
                //重新请求数据
                setTimeout(() => {
                    dispatch(getSchedule(data.filter.toJS(), data.jwt, data));
                    // call fn if has callback
                    if (data.cancel_callback) {
                        data.cancel_callback(res);
                    }
                }, wait);
            } else if (errorCode === 416) {
                //超时取消预约
                let newData = data;
                newData.status = 100;
                dispatch(judgeBooking(newData));
            } else {
                showMsg('error', res.data.error.message);
            }

        }).catch(error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
        if (cancel) {
            cancel.finally(() => dispatch(changeScheduleProcessIds(data.class_id, false)));
        }
    };
};

//初始化schedule数据
export const initData = (value) => ({
    type: constants.SCHEDULE_INIT_DATA,
    value: fromJS(value)
});

//更新schedule列表、筛选、场所
export const changeSchedule = (value) => ({
    type: constants.CHANGE_SCHEDULE,
    value: fromJS(value)
});

//改变筛选项中的场所
export const changeLocationIds = (value) => ({
    type: constants.CHANGE_LOCATION_IDS,
    value
});

//保存课程筛选
export const saveFilterChoose = (value) => ({
    type: constants.CHANGE_FILTER_CHOOSE,
    value
});

//保存课程筛选
// export const saveCmFilterChoose = (value) => ({
//     type: constants.CHANGE_CM_FILTER_CHOOSE,
//     value
// });

//保存通知
export const saveNotice = (value) => ({
    type: constants.CHANGE_NOTICE,
    value: fromJS(value)
});

//保存通知 (mobile)
export const saveNoticeAll = (value) => ({
    type: constants.CHANGE_NOTICE_ALL,
    value: fromJS(value)
});

//清除筛选同步
export const removeFilter = () => ({
    type: constants.RESET_FILTER
});

//改变星期展示类型并重新请求数据
export const changeWeekType = (weekType, filter, weeks, jwt) => {
    return (dispatch) => {
        let newFilter = filter.toJS();
        newFilter.sector = localStorage.getItem('type');

        if (weeks) {
            let start_date;
            if (weekType === 1) {
                start_date = weeks.getIn(['thisWeek', 'value']);
            } else if (weekType === 2) {
                start_date = weeks.getIn(['nextWeek', 'value']);
            } else if (weekType === 3) {
                start_date = weeks.getIn(['thisDay', 'value']);
            } else {
                start_date = weeks.getIn(['nextWeekDay', 'value']);
            }
            newFilter.start_date = start_date;
            dispatch(saveWeekType(weekType));
        }
        dispatch(saveFilterChoose(fromJS(newFilter)));
        dispatch(getSchedule(newFilter, jwt));
    };
};

export const getPopupModal = (data, button_status, language_id, region_id) => {
    return (dispatch) => {
        showMsg('loading');
        dispatch(changeScheduleProcessIds(data.class_id, true));
        get_popup_modal({
            language_id,
            button_status,
            region_id
        }).then(res => {
            showMsg('hide');
            showCustomModal(dispatch, data, res.data.data.modal_data);
        }).finally(() => {
            dispatch(changeScheduleProcessIds(data.class_id, false));
        });
    };
};

export const getMoodList = (language_id, region_id) => {
    return(dispatch) => {
        showMsg('loading');
        get_class_mood({
            language_id,
            region_id
        }).then(res => {
            showMsg('hide');
            let class_mood = res.data.data.class_moods;
            let class_mood_list;
            if (isMobile){
                class_mood_list = [];
            } else {
                class_mood_list = [{ id: '0', color:"#000000", names: { [getLangTitle(language_id)]: intl('schedule', 'allMood', language_id) } }];
            }
            class_mood.map(item => {
                class_mood_list.push(item);
                return item;
            });

            dispatch(saveMood(class_mood_list));

        }).catch(error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

/**
 *
 * @param {boolean} is_favourite
 * @param {'C' | 'T' | 'L'} favourite_type
 * @param {number} favourite_id
 * @param {number} region_id
 * @param {number[]} favourite_ids
 * @param {any[]=} location_list_raw
 * @returns
 */
export const toggleFavourite = (is_favourite, favourite_ids, favourite_type, favourite_id, region_id, location_list_raw) => {
    return (dispatch) => {
        showMsg('loading');
        /** @type {Promise<any>} */
        let promise;
        if (is_favourite) {
            promise = remove_favourite(
                {favourite_type, id: favourite_id, region_id}
            ).then(res => {
                favouriteFunc('remove', favourite_type, favourite_ids, favourite_id, dispatch, location_list_raw);
            });
        } else {
            promise = add_favourite(
                {favourite_type, id: favourite_id, region_id}
            ).then(res => {
                favouriteFunc('add', favourite_type, favourite_ids, favourite_id, dispatch, location_list_raw);
            });
        }

        promise.finally(() => showMsg('hide'));
    };
};

/**
 *
 * @param {'remove' | 'add'} action
 * @param {'C' | 'T' | 'L'} favourite_type
 * @param {number} favourite_id
 * @param {any[]=} location_list_raw
 * @param {*} favourite_ids
 * @returns
 */
export const favouriteFunc = (action, favourite_type, favourite_ids, favourite_id, dispatch, location_list_raw) => {
    let new_favourite_ids;

    if (action === 'add') {
        new_favourite_ids = favourite_ids.push(favourite_id);
    } else {
        new_favourite_ids = favourite_ids.splice(favourite_ids.indexOf(favourite_id), 1);
    };

    switch (favourite_type) {
        case 'L':
            const newLocationList = formatLocations(location_list_raw, new_favourite_ids);
            dispatch(saveLocations(newLocationList));
            dispatch(saveFavouriteLocation(new_favourite_ids));
            break;
        case 'C':
            dispatch(saveFavouriteClass(new_favourite_ids));
            break;
        case 'T':
            dispatch(saveFavouriteTeacher(new_favourite_ids));
        default:
            break;
    }
};

export const saveFavouriteClass = (value) => ({
    type: constants.SAVE_FAVOURITE_CLASS_TYPE,
    value: fromJS(value)
});
export const saveFavouriteTeacher = (value) => ({
    type: constants.SAVE_FAVOURITE_TEACHERS,
    value: fromJS(value)
});
export const saveFavouriteLocation = (value) => ({
    type: constants.SAVE_FAVOURITE_LOCATIONS,
    value: fromJS(value)
});

export const changeScheduleProcessIds = (id, value)=>({
    type: constants.CHANGE_SCHEDULE_PROCESS_IDS,
    id,
    value,
});

//改变课程列表
export const changeClass = (value) => ({
    type: constants.CHANGE_CLASS,
    value: fromJS(value)
});

//保存所有星期列表
export const saveWeek = (value) => ({
    type: constants.SAVE_WEEK,
    value: fromJS(value)
});

//保存星期展示类型weektype
export const saveWeekType = (value) => ({
    type: constants.CHANGE_WEEK_TYPE,
    value: value
});

//保存所有筛选
export const saveFilter = (value) => ({
    type: constants.CHANGE_FILTER,
    value: fromJS(value)
});

//保存上课场所
export const saveLocations = (value) => ({
    type: constants.CHANGE_LOCATIONS,
    value: fromJS(value)
});

//mood_class
export const saveMood = (value) => ({
    type: constants.CHANGE_MOOD,
    value: fromJS(value)
});

//保存课程类型
export const saveClassTypes = (value) => ({
    type: constants.CHANGE_CLASS_TYPES,
    value: fromJS(value)
});

//保存全部课程类型
export const saveClassTypesAll = (value) => ({
    type: constants.CHANGE_CLASS_TYPES_ALL,
    value: fromJS(value)
});

//保存老师列表
export const saveTeachers = (value) => ({
    type: constants.CHANGE_TEACHERS,
    value: fromJS(value)
});

//保存全部老师列表
export const saveTeachersAll = (value) => ({
    type: constants.CHANGE_TEACHERS_ALL,
    value: fromJS(value)
});

//保存等级
export const saveLevels = (value) => ({
    type: constants.CHANGE_LEVELS,
    value: fromJS(value)
});

//保存全部等级
export const saveLevelsAll = (value) => ({
    type: constants.CHANGE_LEVELS_ALL,
    value: fromJS(value)
});

//保存支柱
export const savePillars = (value) => ({
    type: constants.CHANGE_PILLARS,
    value: fromJS(value)
});

//保存全部支柱
export const savePillarsAll = (value) => ({
    type: constants.CHANGE_PILLARS_ALL,
    value: fromJS(value)
});

//保存筛选类型（健身）
export const saveFilterType = (value) => ({
    type: constants.SAVE_FILTER_TYPES,
    value: fromJS(value)
});

//保存星期
export const saveWeekDay = (value) => ({
    type: constants.CHANGE_WEEKS,
    value: fromJS(value)
});
//地点筛选数量
export const locationNum = (value) => ({
    type: constants.CHANGE_LOCATION_NUM,
    value: fromJS(value)
});
//地点名字列表
export const locationNames = (value) => {
    return ({
        type: constants.CHANGE_LOCATION_NAMES,
        value: fromJS(value)
    });
};
//多地点日期状态
export const dayType = (value) => {
    return ({
        type: constants.CHANGE_DAY_TYPE,
        value: fromJS(value)
    });
};

//课程列表loading改变
export const changeScheduleLoading = (value) => ({
    type: constants.CHANGE_LOADING,
    value
});

export const changeListSection = value => ({
    type: constants.CHANGE_LIST_SECTION,
    value: fromJS(value)
});


//获取json中的第一项
function getFirstAttr(obj) {
    for (var k in obj) return obj[k];
}

//返回本周一的日期
function getFirstDayOfWeek(date, language_id) {
    var day = date.getDay() || 7;
    let newDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - day);
    return formatDate(newDay, language_id);
}

//返回下周一的日期
function getNextDayOfWeek(date, language_id) {
    var day = 7 - date.getDay() + 1;
    date.setDate(date.getDate() + day);
    let newDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return formatDate(newDay, language_id);
}

//时间格式化
function formatDate(time, language_id, format = 'WW MMDD') {
    var date = new Date(time);
    var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    var weekday_en = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    var weekday_hk = ["週日", "週一", "週二", "週三", "週四", "週五", "週六"];
    // var weekday_hk = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    var monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var year = date.getFullYear(),
        month = date.getMonth() + 1,//月份是从0开始的
        day = date.getDate(),
        week = date.getDay();
    var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
        return '0' + index;
    });////开个长度为10的数组 格式为 00 01 02 03
    var newDate = {};
    if (parseInt(language_id) === 1) {
        newDate.title = format.replace(/WW/g, weekday_en[week] || week).replace(/MM/g, monArr[month - 1] + ' ').replace(/DD/g, preArr[day] || day);
    } else if (parseInt(language_id) === 3) {
        newDate.title = format.replace(/WW/g, weekday[week] || week).replace(/MM/g, (preArr[month] || month) + '月').replace(/DD/g, preArr[day] || day) + '日';
    } else {
        newDate.title = format.replace(/WW/g, weekday_hk[week] || week).replace(/MM/g, (preArr[month] || month) + '月').replace(/DD/g, preArr[day] || day) + '日';
    }
    let newMonth = month < 10 ? '0' + month : month;
    let newDay = day < 10 ? '0' + day : day;
    newDate.value = year + '-' + newMonth + '-' + newDay;
    return newDate;
}

// convert desktop classes to mobile format
function convertClassesToMobile(dispatch, classes, startDate) {
    //获取筛从选项开始7天日期
    let dateArr = getNextDays(startDate, 6);
    //把课程根据日期分成7组
    let classesMobile = dateArr.map(item => {
        return {
            dateInfo: item,
            isToday: item.date === nowDate(),
            list: []
        };
    });

    if (classes && classes.length) {
        classes.forEach(classItem => {
            classItem.list.forEach(item => {
                // item = item.toJS();
                let index = classesMobile.findIndex(it => it.dateInfo.date === item.start_date);
                if (index > -1) {
                }
                index > -1 && classesMobile[index].list.push(item);
            });
        });
    }

    //计算每个课程所在的位置区间
    let fontSize = document.body.clientWidth / 10;
    let listSection = [];
    let oTop = 0;
    classesMobile.map((item, index) => {
        let oH = Math.floor((2 * item.list.length * fontSize) + (0.8 * fontSize));
        let info = {
            startTop: oTop,
            endTop: oTop + oH
        };
        listSection.push(info);
        oTop = oTop + oH;
        return item;
    });

    dispatch(changeListSection(listSection));

    return classesMobile;
    // return fromJS(classesMobile);
}
