import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from './store';
import { actionCreators as headerActionCreators } from '../common/header/store';
import { changeMoudle } from '../common/main/store/actionCreators';
import { Icon, Button, Card, Spin, Dropdown, Menu } from 'antd';
import { ScheduleContent, ScheduleTop, SchduleChoose, Printer, FilterClass, ClassList, ClassListTop, ClassListDown, WeekChoose, Notice } from './style';
import { fromJS } from 'immutable';
import SelectItem from './component/select';
import DateItem from './component/date';
import ScheduleItem from './component/scheduleItem';
import './schedule-mobile.scss';
// import scheduleMany from './component/scheduleMany';
import ScheduleButton from './component/scheduleButton/scheduleButton';
import GetTeacherAvatar from './component/getTeacherAvatar';
import ClassDetails from './component/classDetails/index';

import LocaTions from './component/locations';
import {
    intl,
    getPrintDate,
    getChangeRegionTipsInfo,
    getDefaultWeekType,
    getNextDays,
    nowDate,
    getMonth,
} from '../config/util';
import ScheduleMany from './component/scheduleMany';

import { isMobile } from 'react-device-detect';
import DateList from './component/dateList';
import ScheduleFilter from './component/scheduleFilter';
import HtmlContent from '../common/htmlContent/htmlContent';
import { flags } from '../config/unleash';

let teacherDefaultAvatar = require('../assets/images/ic_default_image.png');
let iconFuze = require('../assets/images/fuze.png');

class Schedule extends Component {
    ref = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            getScheduleControl: true,
            showFilter: false,//展示筛选项
            dateType: 'basic', //print || basic 判断是否打印模式，修改日期显示

            // mobile
            showNotice: true,//显示location notice框
            isScroll: false,//page滑动，滑动的时候让fixed Date变成absolute，挺下来的时候变成fixed

            // listSection: [],
            // classesMobile: null,
            // rectTop: null,
            scrollDateIndex: 0,
            // focusBtn: '',//聚焦的按钮
        };
    }

    componentDidMount() {
        // let { flags } = this.props;



        // refresh schedule
        if (this.props.data_inited) {
            let {user, filter} = this.props;
            let jwt = user.get('jwt') || '';
            this.props.getSchedule(filter.toJS(), jwt);
        }
        /*----------  for mobile only  ----------*/
        if (!isMobile) {
            //same width length for schedule filter

            // let currentfilterListWidth = this.ref.current.clientWidth;
            // let filterSelect = document.getElementById('filterSelect');
            // filterSelect.style.width = currentfilterListWidth + 'px';
            return;
        }
        // document.addEventListener("scroll", this.setFixedDateTop);
        document.getElementById("scheduleScrollView").addEventListener("scroll", this.handleScrollViewChange);

    }

    componentWillUnmount() {
        /*----------  for mobile only  ----------*/
        if (!isMobile) {
            return;
        }
        // document.removeEventListener("scroll", this.setFixedDateTop);
        document.getElementById("scheduleScrollView").removeEventListener("scroll", this.handleScrollViewChange);
        localStorage.removeItem('scrollDateIndex');
    }

    componentDidUpdate(prevProps, prevState) {
        let { weekType, lang, location_ids, history, isLogin, regions, language_id, region_id, mood_flag} = this.props;

        if (mood_flag && (mood_flag !== prevProps.mood_flag || region_id !== prevProps.region_id)) {
            if (mood_flag) {
                this.props.getMoodList(language_id, region_id);
            }
        }

        //same width length for schedule filter

        // if (flags.mood){
        //     if(!isMobile){
        //         let currentfilterListWidth = this.ref.current.clientWidth;
        //         let filterSelect = document.getElementById('filterSelect');
        //         filterSelect.style.width = currentfilterListWidth + 'px';
        //     }
        // }

        //监听场所改变
        if (prevProps.location_ids !== location_ids) {
            history.push(`/${lang}/${regions.find(item => item.checked).code}?location_ids=${location_ids || ''}`);
            this.props.getNotice(location_ids);

            if (isMobile) {
                this.setState({ showNotice: true });
            }
        }

        //监听登录状态重新请求课程数据
        if (prevProps.isLogin !== isLogin && prevProps.classes.size > 0) {
            this.getClasses(localStorage.getItem('location_id'));
        }

        //监听场景改变
        if (prevProps.activeMoudle !== 'Schedule' && this.props.activeMoudle === 'Schedule') {
            this.getClasses(localStorage.getItem('location_id'));
        }

        //改变日期显示模式
        if (weekType !== prevProps.weekType) {
            // this.setState({ weekType });
            this.props.getWeek(weekType);
        }

        /*----------  for mobile only  ----------*/
        if (!isMobile) {
            return;
        }
        // if (prevState.showNotice !== this.state.showNotice) {
        //     this.setFixedDateTop();
        // }
        // const { classes, weekList, classes_mobile } = this.props;
        // let startDate = nowDate();
        // if (weekList.size > 0) {
        //     const todayItem = weekList.find((item) => item.get('day') === 'today');
        //     if (todayItem) {
        //         startDate = todayItem.get('value');
        //     } else {
        //         startDate = weekList.first().get('value');
        //     }
        // }

        // let classesMobile;
        // if (!this.state.classesMobile || (prevProps.classes !== this.props.classes)) {
        //     classesMobile = this.convertClassesToMobile(classes, startDate);
        //     this.setState({ classesMobile });
        // } else {
        //     classesMobile = this.state.classesMobile;
        // }

        const classesMobile = this.props.classes_mobile;

        //监听classes滚动条
        if (classesMobile.length > 0 && prevProps.classes_mobile !== classesMobile) {
            const classFir = classesMobile[0];
            let scrollDateIndex = localStorage.getItem('scrollDateIndex');
            if (parseInt(scrollDateIndex) === 0 && classFir.isToday && classFir.list.length > 1) {
                let firstCanBook = classFir.list.findIndex(item => item.button_status !== 0);
                let itemHeight = document.body.clientWidth / 10;
                if (firstCanBook > -1) {
                    document.getElementById("scheduleScrollView").scrollTop = Math.floor((2 * firstCanBook) * itemHeight);
                } else {
                    document.getElementById("scheduleScrollView").scrollTop = Math.floor((2 * classFir.list.length + 0.8) * itemHeight);
                }
            }
            if (prevProps.location_id !== this.props.location_id && this.state.scrollDateIndex > 0) {
                let oH;
                for (let i = 0; i <= this.state.scrollDateIndex; i++) {
                    oH += classesMobile[i].list.length;
                }
            }
        }

        // if (prevProps.location_id !== this.props.location_id) {
        //     this.props.history.push(`/${getLang()}/${getRegionCode()}?location_ids=${prevProps.location_id}`);
        //     this.setState({ showNotice: true });
        //     //this.props.getLocationNotice()
        // }

        // if (prevProps.upcoming_classes.length !== this.props.upcoming_classes.length && this.state.focusBtn) {
        //     document.getElementById(this.state.focusBtn).blur();
        // }

    }

    //请求课程
    getClasses = (id) => {
        let { location_list, region_id, type, weeks, user } = this.props;
        let jwt = user.get('jwt') ? user.get('jwt') : '';
        let classType = type === 'Y' ? 'yoga' : 'fitness';
        let location_id = id ? parseInt(id) : location_list[classType][0].id;
        let filter = {
            location_ids: location_id,
            region_id,
            start_date: weeks.getIn(['thisWeek', 'value']),
            sector: type,
            days: 7
        };
        this.props.getSchedule(filter, jwt);
    }

    //课程打印
    printSchedule = () => {
        this.setState({
            dateType: 'print'
        });
        setTimeout(() => {
            let { type, location_list, location_ids, language_id } = this.props;
            let bdhtml = window.document.getElementById('scheduleContent').innerHTML;
            let pdftitle = document.title;
            document.title = ".";
            bdhtml = bdhtml.replace(/<button(.*?)<\/button>/g, " ");
            let locationName = '';
            if (location_list[type === 'Y' ? 'yoga' : 'fitness'].length > 0) {
                locationName = location_list[type === 'Y' ? 'yoga' : 'fitness'].find(item => item.id === parseInt(location_ids)).names || '';
            }
            let topHtml = `
                <style>
                    table,table tr td{ border:1px solid #666!important; padding:0.5% 1% !important;line-height:1.3;}
                    table tr td{width: calc(100% / 8);}
                    td:first-child{width:9%;}
                    table tr th{padding:0;line-height:1.3;}
                    table{width: 100vmax;}
                    table tr th p,table tr td p{margin-bottom:0;}
                    .dateLine > div{width: 100%;display:table;}
                    .date{height:60px;vertical-align:middle;display:table-cell;width:calc(100% / 8);;text-align:center;border-left:1px solid #666;}
                    .date:first-child{width:9%;border:none;}
                    .toNextWeek,.toPrevWeek{display:none;}
                    table .schedule_wra{padding:10px 0 !important;}
                    h4,h3{margin:0;}
                    body{
                        background:#ffffff;
                        -webkit-print-color-adjust: exact;
                    }
                    .className{
                        font-weight:bold
                    }
                    .vaccinated{
                        width: 1.8rem;
                        height: 1.8rem;
                    }
                    .vacIcon{
                        width: 1.5rem;
                        height: 1.5rem;
                        float: right;
                    }
                    .iconRight{
                        height: 25px;
                        justify-content: end;
                        display: flex;
                        margin: .5rem 0 0 0;
                    }
                    .special {
                        width: 1.5rem;
                        height: 1.5rem;
                        // display: inline-block;
                    }
                    .classFeaturePrint {
                        margin: 0.5rem 0 0 0;
                        // display: flex;
                        // flex-direction: column;
                    }
                    .iconRightPrint {
                        height: 25px;
                        justify-content: end;
                        display: flex;
                        flex: 1 1;
                        margin: auto;
                        vertical-align: middle;
                    }
                </style>
                <div style='font-size: 30px; font-weight:bold;'>${pdftitle}</div>
                <h2 style='font-size: 20px;'>${locationName}</h2>
                <h2 style='font-size: 20px;'>${getPrintDate(language_id)}</h2>
            `;
            bdhtml = topHtml + bdhtml;
            window.document.body.innerHTML = bdhtml;
            window.print();
            window.location.reload();
        }, 300);
    }

    //筛选项Toggle展示
    filterListToggle = () => {
        this.setState({ showFilter: !this.state.showFilter });
    }

    //日期的左右切换
    toggleChangeWeek = (event) => {
        this.props.changeWeekType(event.type, event.jwt);
    }

    //修改城市
    changeRegion(e) {
        if (this.props.isLogin) {
            if (this.props.region_id != e.key) {
                this.props.setChangeRegionModel({
                    visible: true,
                    chooseRegion: { key: e.key },
                    changeRegionTips: getChangeRegionTipsInfo(this.props.region_id, e.key, this.props.language_id)
                });
            }
        } else {
            this.props.changeRegion(e);
        }
    }

    //hide_printer
    hide_printer = () => {
        if ( this.props.location_num === 1 ){
            const hoverColor = this.props.type === 'Y' ? '#14E1DC' : '#F00F64';
            return(
                <Printer hoverColor={hoverColor}>
                    <Button theme="filled" onClick={this.printSchedule}><Icon style={{ fontSize: '24px' }} type="printer" theme="filled" /></Button>
                </Printer>
            );
        }
    }

    render() {
        // return this.renderMobile();
        // return this.renderDesktop();
        if (isMobile) {
            return this.renderMobile();
        } else {
            return this.renderDesktop();
        }
    }

    renderDesktop() {
        const props = { ...this.props };
        const type = props.type === 'Y' ? 'yoga' : 'fitness';

        let weekList = [];
        props.weeks.size > 0 && props.weeks.map(item => {
            weekList.push({ id: item.get('value'), names: item.get('title') });
            return item;
        });

        let bookingPhone, bookingNames;
        if (props.location_ids && props.location_list[type].length) {
            let locationInfo = props.location_list[type].find(item => item.id === parseInt(props.location_ids));
            if (locationInfo) {
                bookingPhone = locationInfo.contact_no || 'N/A';
                bookingNames = locationInfo.names || 'N/A';
            } else {
                bookingPhone = 'N/A';
                bookingNames = 'N/A';
            }
        }
        let jwt = props.user.get('jwt') ? props.user.get('jwt') : '';
        let location = props.regions.length > 0 ? props.regions.find(item => item.checked).names[props.lang] : '';
        const regionMenu = (
            <Menu onClick={(e) => this.changeRegion(e)}>
                { props.regions.map((item) => <Menu.Item key={item.id}>{item.names[props.lang]}</Menu.Item>)}
            </Menu>
        );
        return (
            <ScheduleContent>
                {/* 通告 */}
                <Notice>
                    {
                        props.regionNotice.id &&
                        <Card className="noticeCard">
                            <h4 className="title">
                                <Icon type="info-circle" theme="filled" style={{ 'color': '#ED1C24', 'fontSize': '22px' }} />
                                {props.regionNotice.title}
                            </h4>
                            <div className="notice_article">
                                <HtmlContent content={props.regionNotice.content}></HtmlContent>
                            </div>
                        </Card>
                    }
                    {
                        props.notice.id &&
                        <Card className={`noticeCard ${props.regionNotice.id ? 'line' : ''}`}>
                            <h4 className="title">
                                <Icon type="info-circle" theme="filled" style={{ 'color': '#ED1C24', 'fontSize': '22px' }} />
                                {props.notice.location.name} - {props.notice.title}
                            </h4>
                            <div className="notice_article">
                                <HtmlContent content={props.notice.content}></HtmlContent>
                            </div>
                        </Card>
                    }
                </Notice>

                <div className="scheduleContent">
                    {/* 标题栏 */}
                    <ScheduleTop>
                        <SchduleChoose>
                            <Dropdown overlay={regionMenu}>
                                <div className="ant-dropdown-link">{location} {intl('schedule', 'schedule', props.language_id)}<Icon type="caret-down" /></div>
                            </Dropdown>
                        </SchduleChoose>

                        {/* <Printer>
                            <Button className={`${this.props.location_num > 1 ? 'hide' : ''}`} theme="filled" onClick={this.printSchedule}><Icon style={{ fontSize: '24px' }} type="printer" theme="filled" /></Button>
                        </Printer> */}

                        {
                            this.hide_printer()
                        }

                    </ScheduleTop>

                    {/* 筛选 */}
                    <FilterClass>
                        <div className='filterDiv'>
                            <div id="filterSelect" className={`filterSelect ${this.props.location_num > 1 || this.props.location_num == 0 ? 'hide_tag' : ''}`}>
                                {/* location */}
                                <SelectItem
                                    mode='tags'
                                    type='location_ids'
                                    style={{ width: 337 }}
                                    language_id={props.language_id}

                                    list={props.location_list[type] || []}
                                    filter={props.filter}
                                    jwt={jwt}
                                    lang={props.lang}

                                    tokenSeparators={[',']}
                                >
                                </SelectItem>

                                {/* week */}
                                <SelectItem
                                    type='week'
                                    style={{ width: 337 }}
                                    language_id={props.language_id}
                                    list={fromJS(weekList)}
                                    filter={props.filter}
                                    weeks={props.weeks}
                                    jwt={jwt}
                                    placeholder={`${intl('schedule', 'oneWeek', props.language_id)} - ${weekList.length && weekList[0].names}`}
                                    lang={props.lang}
                                >
                                </SelectItem>
                                <div className="filter_toggle" onClick={this.filterListToggle} >
                                    {intl('schedule', 'filter', props.language_id)}　<Icon type={this.state.showFilter ? 'caret-up' : 'caret-down'} style={{ color: '#999', fontSize: 16 }} />
                                </div>
                            </div>
                            <div className={`filter_list ${this.state.showFilter ? 'show' : ''}`} id='filter_list' ref={this.ref}>
                                {/* teacher */}
                                <SelectItem
                                    type='teacher_id'
                                    placeholder={intl('schedule', 'allTeacher', props.language_id)}
                                    style={{ width: 168 }}
                                    language_id={props.language_id}
                                    list={props.teachers}
                                    filter={props.filter}
                                    jwt={jwt}
                                    name='allTeacher'
                                    lang={props.lang}
                                >
                                </SelectItem>

                                {/* classType */}
                                <SelectItem
                                    type='class_type_id'
                                    placeholder={intl('schedule', 'allClassType', props.language_id)}
                                    language_id={props.language_id}
                                    style={{ width: 168 }}
                                    list={props.classtypes}
                                    filter={props.filter}
                                    jwt={jwt}
                                    name='allClassType'
                                    lang={props.lang}
                                >
                                </SelectItem>

                                {/* Pillar */}
                                <SelectItem
                                    type='pillar_id'
                                    placeholder={intl('schedule', 'allPillar', props.language_id)}
                                    style={{ width: 168 }}
                                    language_id={props.language_id}
                                    list={props.pillars}
                                    filter={props.filter}
                                    jwt={jwt}
                                    name='allPillar'
                                    lang={props.lang}
                                >
                                </SelectItem>
                                {/* Level */}
                                <SelectItem
                                    type='level_id'
                                    placeholder={intl('schedule', 'alllevel', props.language_id)}
                                    style={{ width: 168 }}
                                    language_id={props.language_id}
                                    list={props.levels}
                                    filter={props.filter}
                                    jwt={jwt}
                                    name='alllevel'
                                    lang={props.lang}
                                >
                                </SelectItem>
                                {/* type */}
                                {
                                    props.type === 'F' &&
                                    <SelectItem
                                        type='filter_type'
                                        placeholder={intl('schedule', 'type', props.language_id)}
                                        style={{ width: 168 }}
                                        language_id={props.language_id}
                                        list={props.types}
                                        filter={props.filter}
                                        jwt={jwt}
                                        name='All Type'
                                        lang={props.lang}
                                    >
                                    </SelectItem>

                                }
                                {/* Mood */}
                                {
                                    props.mood_flag && <SelectItem
                                    type='mood_ids'
                                    placeholder={intl('other', 'moodTips', props.language_id)}
                                    style={{ width: 230 }}
                                    language_id={props.language_id}
                                    list={props.class_mood_list}
                                    filter={props.filter}
                                    jwt={jwt}
                                    name='allMood'
                                    lang={props.lang}
                                >
                                </SelectItem>}
                                <span onClick={() => this.props.resetFilter(jwt)}>{intl('schedule', 'resetFilter', props.language_id)}</span>
                            </div>
                        </div>
                    </FilterClass>

                    {/* 课程表 */}
                    <ClassList>
                        <ClassListTop>
                            {props.isLogin && <div className="explain">{intl('schedule', 'explain', props.language_id)}</div>}
                            <div className="disc">{intl('schedule', 'telephoneBooking', props.language_id)}: {bookingPhone}</div>
                        </ClassListTop>
                        <ClassList id={'scheduleContent'} className={`${props.isLogin ? 'isLogin' : ''} ${props.loading ? 'loading' : ''}`}>

                            <table width="100%" >
                                <thead>
                                    <DateItem
                                        list={props.weekList}
                                        language_id={props.language_id}
                                        jwt={jwt}
                                        weekType={this.props.weekType}
                                        ChangeWeekType={this.toggleChangeWeek.bind(this)}
                                        dateType={this.state.dateType}
                                        // location_num={props.location_num}
                                        day_type={props.day_type}
                                    />
                                    {/* {props.location_num && props.location_num > 1 ?
                                        <LocaTions
                                            list={props.location_names}
                                            language_id={props.language_id}
                                        />
                                        : false
                                    } */}
                                </thead>
                            </table>
                            <table width="100%">
                                {
                                    props.loading ? <tbody><tr className="loading"><td colSpan="8"><Spin /></td></tr></tbody>
                                        : <ScheduleItem
                                            type={props.type}
                                            classes={props.classes}
                                            language_id={props.language_id}
                                            weekList={props.weekList}
                                            booking={props.booking}
                                            region_id={props.region_id}
                                            jwt={jwt}
                                            filter={props.filter}
                                            isLogin={props.isLogin}
                                            teachers={props.teachers}
                                            weekType={this.props.weekType}
                                            dateType={this.state.dateType}
                                            showVibe={props.mood_flag}
                                            showSpecial={props.special_flag}
                                            locationNum={props.location_num}
                                    />
                                        // : props.location_num > 1 ?
                                        //     <ScheduleMany
                                        //         type={props.type}
                                        //         classes={props.classes}
                                        //         language_id={props.language_id}
                                        //         weekList={props.weekList}
                                        //         booking={props.booking}
                                        //         region_id={props.region_id}
                                        //         jwt={jwt}
                                        //         filter={props.filter}
                                        //         isLogin={props.isLogin}
                                        //         teachers={props.teachers}
                                        //         weekType={this.props.weekType}
                                        //         dateType={this.state.dateType}
                                        //         day_type={props.day_type}
                                        //         location_ids={props.location_ids}
                                        //         showVibe={props.mood_flag}
                                        //         showSpecial={props.special_flag}
                                        //     />
                                        //     : <ScheduleItem
                                        //         type={props.type}
                                        //         classes={props.classes}
                                        //         language_id={props.language_id}
                                        //         weekList={props.weekList}
                                        //         booking={props.booking}
                                        //         region_id={props.region_id}
                                        //         jwt={jwt}
                                        //         filter={props.filter}
                                        //         isLogin={props.isLogin}
                                        //         teachers={props.teachers}
                                        //         weekType={this.props.weekType}
                                        //         dateType={this.state.dateType}
                                        //         showVibe={props.mood_flag}
                                        //         showSpecial={props.special_flag}
                                        //     />
                                }
                            </table>


                        </ClassList>
                        <ClassListDown>
                            <div className="disc">
                                <p>{intl('schedule', 'teachingLanguage', props.language_id)}</p>
                                {
                                    props.language_id === 1 ?
                                        <p>{intl('schedule', 'phoneBookingFor', props.language_id)} {bookingNames} : {bookingPhone}</p>
                                        :
                                        <p>{bookingNames} {intl('schedule', 'phoneBookingFor', props.language_id)}: {bookingPhone}</p>
                                }
                                {
                                    <p>{intl('schedule', 'waitlist_refresh_notice', props.language_id)}</p>
                                }
                            </div>
                            <WeekChoose className="changeWeek">
                                <span
                                    className={this.props.weekType === 1 ? 'on' : null}
                                    onClick={() => { this.props.changeWeekType(1, jwt); }} >
                                    {intl('schedule', 'oneWeek', props.language_id)}
                                </span>

                                <span
                                    className={this.props.weekType === 2 ? 'on' : null}
                                    onClick={() => { this.props.changeWeekType(2, jwt); }} >
                                    {intl('schedule', 'nextWeek', props.language_id)}
                                </span>

                                <span
                                    className={this.props.weekType === 3 ? 'on' : null}
                                    onClick={() => { this.props.changeWeekType(3, jwt); }}>
                                    {intl('schedule', 'nextSevenDays', props.language_id)}
                                </span>

                                {/* <span
                                    className={this.props.weekType === 4 ? 'on' : null}
                                    onClick={() => { this.props.changeWeekType(4, jwt); }}>
                                    {intl('schedule', 'nextWeekSevenDays', props.language_id)}
                                </span> */}
                            </WeekChoose>
                        </ClassListDown>
                    </ClassList>
                </div>
            </ScheduleContent>
        );
    }

    // #region mobile
    /*----------  mobile  ----------*/

    //监听日历滚动view
    handleScrollViewChange = (e) => {
        // this.setFixedDateTop();
        const listSection = this.props.listSection;
        const scrollTop = e.target.scrollTop;
        let scrollDateIndex = listSection.findIndex((item) => scrollTop >= item.startTop && scrollTop < item.endTop);
        if (scrollDateIndex !== this.state.scrollDateIndex) {
            this.setState({ scrollDateIndex });
            localStorage.setItem('scrollDateIndex', scrollDateIndex);
        }
    }

    //设置悬浮日期的高度
    // setFixedDateTop = () => {
    //     var rectTop = document.getElementById("scheduleScrollView").getBoundingClientRect().top;
    //     rectTop !== this.state.rectTop && this.setState({ rectTop });
    // }

    //日期展示
    getDateInfo = (dateInfo, lang) => {
        let date = lang === 'en' ? `${dateInfo.d} ${getMonth(dateInfo.m)} ${dateInfo.y}` : `${dateInfo.date}`;
        return date;
    }

    //场所提示
    getNotice = (notice) => {
        return (
            // notice.toolbar ?
            // <div className="notice">
            //     <div className="title">
            //         {notice.toolbar}
            //         <span onClick={()=>{this.setState({showNotice:false})}}><i className="iconfont icon-uncheck"></i></span>
            //     </div>
            //     <div className="notice_wra">
            //         <span className="line"></span>
            //         <div className="headLine">{notice.headline}</div>
            //         <div className="words">{notice.content}</div>
            //         <div className="opera">
            //             <button>{notice.button}</button>
            //         </div>
            //     </div>
            // </div> :
            // null
            notice.id &&
            <div className="notice">
                <div className="title">
                    {notice.header}
                    <span onClick={() => { this.setState({ showNotice: false }); }}><i className="iconfont icon-uncheck"></i></span>
                </div>
                <div className="notice_wra">
                    <span className="line"></span>
                    <div className="headLine">{notice.title}</div>
                    <div className="words">
                        <HtmlContent content={notice.content}></HtmlContent>
                    </div>
                    <div className="opera">
                        <button onClick={() => this.props.toAlerts()}>{notice.button_name}</button>
                    </div>
                </div>
            </div>
        );
    }

    locations(id, is_fuze) {
        let { location_list, filter, lang } = this.props;
        filter = filter.toJS();
        if (!filter.sector) {
            return '';
        }
        let data = location_list ? location_list[filter.sector] : [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                if (is_fuze) {
                    return `FUZE @ ${data[i].names}`;
                } else {
                    return data[i].names;
                }
            }
        }
    }

    isVaccinated = (vaccinatedClass) => {
        let vac = require('../assets/images/vaccinatedIcon.png');
        if (vaccinatedClass) {
            return (
                <>
                    <div className='vaccinated'><img className='vacIcon' src={vac} alt="" /></div>
                </>
            );
        }
    }

    // getTeacherAvatar = (id, teacherId, teacherAvatar, mood, vaccinatedClass, special) => {
    //     let vac = require('../assets/images/vaccinatedIcon.png');
    //     let strokeColor = "#CCCCCC";
    //     let strokeWidth = "10px";
    //     let transform = "scale(0.91) translate(22 14)";
    //     if(special){
    //         strokeColor = special.color;
    //         strokeWidth = "30px";
    //         transform = "scale(0.85) translate(30 18)";
    //     }
    //     return (
    //         <div className="avatar" onClick={() => this.props.showTeacherInfo(teacherId)}>
    //             <svg viewBox='0 0 200 200'>
    //                 <defs>
    //                     <pattern id={id} height="100%" width="100%" patternContentUnits="objectBoundingBox">
    //                         <image height="1" width="1" preserveAspectRatio="xMidYMid meet" xlinkHref={teacherAvatar ? teacherAvatar : teacherDefaultAvatar} alt="" />
    //                     </pattern>
    //                     <path
    //                         id={`svg-path${id}`}
    //                         transform={transform}
    //                         // fill={`url(#${id})`}
    //                         // strokeWidth={strokeWidth}
    //                         // stroke={strokeColor}
    //                         // paintOrder="stroke"
    //                         d='M69.28203230275508 9.999999999999998Q86.60254037844386 0 103.92304845413264 9.999999999999998L155.88457268119896 40Q173.20508075688772 50 173.20508075688772 70L173.20508075688772 130Q173.20508075688772 150 155.88457268119896 160L103.92304845413264 190Q86.60254037844386 200 69.28203230275508 190L17.320508075688775 160Q0 150 0 130L0 70Q0 50 17.320508075688775 40Z' />
    //                 </defs>
    //                 <use
    //                     xlinkHref={`#svg-path${id}`}
    //                     strokeWidth={strokeWidth}
    //                     stroke={strokeColor}
    //                     fill="#fff"
    //                     paintOrder="stroke"
    //                 >
    //                 </use>
    //                 <use
    //                     xlinkHref={`#svg-path${id}`}
    //                     fill={`url(#${id})`}
    //                 >
    //                 </use>
    //             </svg>
    //             {
    //                 vaccinatedClass &&
    //                 <div className='vaccinated'><img className='vacIcon' src={vac} alt="" /></div>
    //             }
    //         </div>
    //     );
    // }

    //课程列表
    getScheduleItemMobile = (list) => {
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let {mood_flag, special_flag} = this.props;
        return (
            <ul>
                {list.map(item => {
                    let buttonItem = {
                        booking_id: item.booking_id,
                        button_status: item.button_status,
                        id: item.id,
                        sector: item.sector,
                        waiting_number: item.waiting_number,
                        view_url: item.view_url,
                    };
                    let teacherAvatarItem = {
                        id:item.id,
                        teacherId:item.teacher.id,
                        teacherAvatar:item.teacher.image_link,
                        mood:item.mood,
                        vaccinatedClass:item.is_vaccinated,
                        special:item.special,
                    };
                    return (<ClassDetails
                                key={item.id}
                                item={item}
                                jwt={jwt}
                                // button_status={item.button_status}
                                button_item={buttonItem}
                                location_id={item.location_id}
                                teacher_avatar={teacherAvatarItem}
                                sector={item.sector}
                                showVibe={mood_flag}
                                showSpecial={special_flag}
                            >
                            </ClassDetails>);
                }
                )}
            </ul>
        );
    }

    renderMobile() {
        let { lang, regionNotice, weekList, user, mood_flag, class_total } = this.props;
        let jwt = user.get('jwt') ? user.get('jwt') : '';

        const classesMobile = this.props.classes_mobile;
        // let fixedDate = classesMobile.find((item, index) => index === this.state.scrollDateIndex);
        return (
            <>
                {this.state.showNotice && this.getNotice(regionNotice)}
                <div className="schedule-mobile" id="schedule">
                    {/* {this.state.showNotice && this.getNotice(regionNotice)} */}
                    <div id="scheduleTop">
                        <DateList
                            list={weekList}
                            jwt={jwt}
                            weekType={this.props.weekType}
                            ChangeWeekType={this.toggleChangeWeek.bind(this)}
                        />
                        <ScheduleFilter
                            jwt={jwt}
                            mood={mood_flag}
                        />
                    </div>
                    <div className="scheduleList" id="scheduleScrollView">
                        {/* {
                            fixedDate && <div className='fixedDate' style={{ 'top': this.state.rectTop }}>
                                {fixedDate.isToday ? intl('schedule', 'today', this.props.language_id) : `${fixedDate.dateInfo.w[lang]} · ${this.getDateInfo(fixedDate.dateInfo, lang)}`}
                            </div>
                        } */}
                        {
                            class_total > 0 ? classesMobile.map((date, index) => {
                            return <div className="item" key={date.dateInfo.date} name={`item-${date.dateInfo.date}`}>
                                    <div className="date">{date.isToday ? intl('schedule', 'today', this.props.language_id) : `${date.dateInfo.w[lang]} · ${this.getDateInfo(date.dateInfo, lang)}`}</div>
                                    {this.getScheduleItemMobile(date.list)}
                                </div>;
                            })
                                :
                                <div className='no-class'>
                                    <HtmlContent content={intl('schedule', 'no_result_found', this.props.language_id, { break: '<br>' })} />
                                </div>
                        }
                    </div>
                </div>
            </>
        );
    }

    // #endregion mobile
}


const mapStateToProps = (state) => {
    return {
        type: state.getIn(['header', 'type']),
        isLogin: state.getIn(['main', 'isLogin']),
        languages: state.getIn(['header', 'languages']),
        language_id: state.getIn(['header', 'language_id']),
        lang: state.getIn(['header', 'lang']),
        region_id: state.getIn(['header', 'region_id']),
        regions: state.getIn(['header', 'regions']).toJS(),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        classtypes: state.getIn(['schedule', 'classtypes']),
        teachers: state.getIn(['schedule', 'teachers']),
        levels: state.getIn(['schedule', 'levels']),
        pillars: state.getIn(['schedule', 'pillars']),
        pillars_all: state.getIn(['schedule', 'pillars_all']),
        weeks: state.getIn(['schedule', 'weeks']),
        filter: state.getIn(['schedule', 'filter']),
        weekList: state.getIn(['schedule', 'weekList']),
        weekType: state.getIn(['schedule', 'weekType']),
        user: state.getIn(['main', 'user']),
        classes: state.getIn(['schedule', 'classes']),
        types: state.getIn(['schedule', 'types']),
        notice: state.getIn(['schedule', 'notice']).toJS(),
        loading: state.getIn(['schedule', 'loading']),
        location_ids: state.getIn(['schedule', 'location_ids']),
        activeMoudle: state.getIn(['main', 'activeMoudle']),
        regionNotice: state.getIn(['main', 'regionNotice']).toJS(),
        location_num: state.getIn(['schedule', 'location_num']),
        location_names: state.getIn(['schedule', 'location_names']),
        day_type: state.getIn(['schedule', 'day_type']),
        upcoming_classes: state.getIn(['booking', 'upcoming_classes']).toJS(),
        classes_mobile: state.getIn(['schedule', 'classes_mobile']).toJS(),
        class_total: state.getIn(['schedule', 'class_total']),
        listSection: state.getIn(['schedule', 'listSection']).toJS(),
        data_inited: state.getIn(['schedule', 'data_inited']),
        class_mood_list: state.getIn(['schedule', 'class_mood_list']),
        mood_flag: state.getIn(['main', 'flags', flags.mood]),
        special_flag: state.getIn(['main', 'flags', flags.specialClass]),
    };
};

const mapDispathToProps = (dispatch) => ({
    //获取筛选
    getFilter(language_id, region_id, type, user, weekType) {
        dispatch(actionCreators.getFilter(language_id, region_id, type, user, weekType));
    },
    //获取通知
    getNotice(location_id) {
        dispatch(actionCreators.getNotice(location_id, this.language_id, this.region_id));
    },
    //获取本周，下周，本日三周开始日期
    getWeek(type) {
        dispatch(actionCreators.getWeek(type, this.language_id));
    },
    //改变日期显示类型
    changeWeekType(weekType, jwt) {
        if (this.weekType !== weekType) {
            dispatch(actionCreators.changeWeekType(weekType, this.filter, this.weeks, jwt));
        }
    },
    //获取课程
    getSchedule(filter, jwt) {
        dispatch(actionCreators.getSchedule(filter, jwt));
    },
    //清除筛选
    resetFilter(jwt) {
        dispatch(actionCreators.resetFilter(this.filter, jwt));
    },
    //课程状态判断
    booking(id, status, booking_id) {
        let bookinfo = {
            class_id: id,
            status: status,
            jwt: this.jwt,
            filter: this.filter,
            booking_id,
            language_id: this.language_id,
            region_id: this.region_id,
        };
        dispatch(actionCreators.judgeBooking(bookinfo));
    },
    //修改城市
    changeRegion({ key }) {
        let region = this.regions;
        dispatch(headerActionCreators.changeRegion(key, region));
    },
    //修改城市弹窗
    setChangeRegionModel(data) {
        dispatch(headerActionCreators.setChangeRegionModel(data));
    },
    //老师详情
    showTeacherInfo(id) {
        let jwt = this.user.get('jwt');
        dispatch(actionCreators.showTeacherInfo(id, this.region_id, this.location_list, this.language_id, jwt));
    },
    //课程详情
    showClassInfo(id, special, mood, jwt) {
        dispatch(actionCreators.showClassInfo(id, this.region_id, this.location_list, this.pillars_all.toJS(), special, mood, jwt, this.filter.toJS()));
    },
     //跳转场所提示
     toAlerts(){
        dispatch(changeMoudle('LocationAlerts'));
    },
    getMoodList (language_id,region_id) {
        dispatch(actionCreators.getMoodList(language_id, region_id));
    }

});

export default connect(mapStateToProps, mapDispathToProps)(Schedule);