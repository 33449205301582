import React,{ Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Foot, Footer_wrapper, FooterNav } from './style';
import { connect } from 'react-redux';
import { intl, getAppLink } from '../../config/util';
import { privacy_link, terms_link, trial_link } from '../../config/link';
import { commonConfig } from '../../config/index';
import { isMobile } from 'react-device-detect';
import { LoginBefore, LoginBtn, DownloadBtn} from '../main/style';
import { Avatar, Icon } from 'antd';
import { actionCreators as profileActionCreators } from '../../profile/store';
import { actionCreators as bookingActionCreators } from '../../booking/store';
import { actionCreators } from "../main/store";
import { Modal } from 'antd-mobile';
import { setModal } from '../main/store/actionCreators';
class Footer extends Component {
    ref = React.createRef();
    previousFooterHeight = '';

    //privacy() and Terms() replace by openPrivacyLink and openTermsLink
    openPrivacyLink = () => {
        let { type, region_id } = this.props;
        window.open(privacy_link(type, region_id));
    }
    openTermsLink = () => {
        let { type, region_id } = this.props;
        window.open(terms_link(type, region_id));
    }
    //Sign Up for a Trial
    openTrialLink() {
        let { type, region_id } = this.props;
        window.open(trial_link(type, region_id));
    }

    getVersion() {
        let version = commonConfig.version;
        if (commonConfig.env !== "prod") {
            version += ` | ${commonConfig.env}`;
        }
        return version;
    }
    downLoadApp = () => {
        window.open(getAppLink(this.props.region_id));
    }
     //登出confirm
     logOutConfirm = () => {
         let { language_id } = this.props;
        Modal.alert(intl('login', 'sign_out', language_id), intl('login', 'signout_title', language_id), [
            { text: intl('schedule', 'cancel', language_id), onPress: null },
            { text: intl('model', 'confirm', language_id), onPress: () => this.props.handleLoginOut() },
        ]);
    }
    mobileFooterNav = () =>{
        let { location_ids, language_id, location_list, isLogin, region_id, upcoming_classes} = this.props;
        const type = this.props.type === 'Y' ? 'yoga' : 'fitness';
        let bookingPhone = '', bookingNames = '';
        if (location_ids && location_list[type].length) {
            let locationInfo = location_list[type].find(item => item.id === parseInt(location_ids));
            if (locationInfo) {
                bookingPhone = locationInfo.contact_no || 'N/A';
                bookingNames = locationInfo.names || 'N/A';
            } else {
                bookingPhone = 'N/A';
                bookingNames = 'N/A';
            }
        }

        var telTxt = () => {
            if (bookingPhone == '') {
                return '#';
            } else {
                return 'tel:' + bookingPhone;
            }
        };
        var telPhone = () => {
            if (bookingPhone == '') {
                Modal.alert(intl('other', 'tips', language_id), intl('other', 'telNull', language_id), [
                    { text: intl('model', 'close', language_id) }
                ]);
            }
        };
        if (isMobile) {
            if (isLogin) {
                return(
                    <FooterNav className="footernav" id="footerNav">
                        <div id="footerTop">
                            <div className = "foot_top">
                                <button className="to_booking" onClick={() => { this.props.changeMoudle('Booking'); }}>
                                    {
                                        intl('schedule', 'myClassSchedule', language_id)} {
                                        upcoming_classes.length > 0 && < span className = "book_num" > { upcoming_classes.length } </span>
                                    }
                                </button>
                                <button className="to_user" onClick={() => { this.props.changeMoudle('Profile'); }}>
                                    <Icon type="user" className="userIcon"/>
                                </button>
                            </div>
                        </div>
                        <div className = "opera" >
                            <div className = "item" onClick = { this.downLoadApp.bind(region_id) } > {  intl('other', 'downloadAppMobile', language_id) } </div>
                            <span className = "line" > </span>
                            <div className = "item" onClick = { this.logOutConfirm } > { intl('login', 'sign_out', language_id) } </div>
                        </div>
                    </FooterNav>
                );
            } else {
                return(
                    <LoginBefore className="login_before">
                        <div className="content">
                            <DownloadBtn>
                                <a onClick={ this.downLoadApp.bind(region_id) }>
                                    <Avatar shape="square" size={64} src={require('../../assets/images/app_logo.png')} icon="user"/>
                                </a>
                            </DownloadBtn>
                            <LoginBtn onClick={this.props.handleShowLogin.bind(this,this.props.staylogin)} className="login_btn">
                                <font>{intl('login', 'loginMobile', parseInt(language_id))}</font>
                                <Icon type="caret-right" theme="outlined" />
                            </LoginBtn>
                            <div className="try_now" onClick={this.openTrialLink.bind(this)}>
                                {intl('other', 'tryNow', language_id)}
                            </div>
                        </div>
                        <div className = "callBooking" >
                            <a  href = { telTxt() }  onClick = { telPhone }> {intl('schedule', 'callToBook', language_id)} </a>
                        </div>
                    </LoginBefore>
                );
            }
        }
    }
    componentDidUpdate() {
        if (isMobile) {
            //dynamic body height (to show full content)
            let currentHeaderHeight = this.ref.current.getBoundingClientRect().height;
             if (this.props.onHeightChange && this.ref.current) {
                 if (this.previousHeaderHeight !== currentHeaderHeight) {
                     this.previousHeaderHeight = currentHeaderHeight;
                     this.props.onHeightChange();
                    }
                }
            }
        }

    render() {
    return (
        <Footer_wrapper ref={this.ref} id="footer" className="footer_wrapper">
            {this.mobileFooterNav()}

            <Foot className="footer">
                <p><a onClick={this.openPrivacyLink.bind()} target="_blank">{intl('other', 'other2s', this.props.language_id)}</a> | <a onClick={this.openTermsLink.bind()} target="_blank"> {intl('other', 'other3s', this.props.language_id)} </a></p>
                <p>{intl('other', 'other1s', this.props.language_id)}</p>
                <p className="unbold">{`${this.getVersion()}`}</p>
            </Foot>

            <Switch>
                    {this.props.moudle === 'Schedule' && <Route exact path="/:lang?/:region?" component={Schedule} />}
                    {this.props.moudle === 'Booking' && <Route exact path="/:lang?/:region?" component={Booking} />}
                    {this.props.moudle === 'Profile' && <Route exact path="/:lang?/:region?" component={Profile} />}
            </Switch>
        </Footer_wrapper>
    );
    }
}

const mapStateToProps = (state) => ({
    isLogin: state.getIn(['main', 'isLogin']),
    regions: state.getIn(['header', 'regions']).toJS(),
    languages: state.getIn(['header', 'languages']).toJS(),
    region_id: state.getIn(['header', 'region_id']),
    language_id: state.getIn(['header', 'language_id']),
    user: state.getIn(['main','user']),
    type: state.getIn(['header', 'type']),
    loading:state.getIn(['header','loading']),
    lang:state.getIn(['header','lang']),
    visible:state.getIn(['header','visible']),
    chooseRegion:state.getIn(['header','chooseRegion']).toJS(),
    changeRegionTips:state.getIn(['header','changeRegionTips']).toJS(),
    location_list: state.getIn(['schedule', 'location_list']).toJS(),
    location_ids: state.getIn(['schedule', 'location_ids']),
    upcoming_classes: state.getIn(['booking', 'upcoming_classes']).toJS(),
});

const mapDispathToProps = (dispatch) => ({
    //内容tab切换
    changeMoudle(module){
        if (module === 'Profile') {
            if (isMobile) {
                let hideFooter = document.getElementById("footerTop");
                hideFooter.style.display = "none";
            }
            dispatch(profileActionCreators.changeProfileScene('myProfile'));
        }
        if (module === 'Booking') {
            if (isMobile) {
                let hideFooter = document.getElementById("footerNav");
                hideFooter.style.display = "none";
            }
            dispatch(bookingActionCreators.changeShowBookList(true));
        }
        dispatch(actionCreators.handleMoudleChange(module));
    },
    //显示登录框
    handleShowLogin(staylogin){
        if (!staylogin) {
            localStorage.removeItem('u');
            localStorage.removeItem('p');
            dispatch(actionCreators.listenUserName(''));
            dispatch(actionCreators.listenPassword(''));
        }
        dispatch(actionCreators.showLogin());
    },
    //退出登录
    handleLoginOut(){
        dispatch(actionCreators.signOut());
    },
    //弹窗设置
    changeModal(opera,type){
        dispatch(setModal(opera, type));
    }
});


export default connect(mapStateToProps, mapDispathToProps)(Footer);
