export const CHANGE_CLASS_TYPES = 'schedule/CHANGE_CLASS_TYPES';
export const CHANGE_CLASS_TYPES_ALL = 'schedule/CHANGE_CLASS_TYPES_ALL';
export const CHANGE_TEACHERS = 'schedule/CHANGE_TEACHERS';
export const CHANGE_TEACHERS_ALL = 'schedule/CHANGE_TEACHERS_ALL';
export const CHANGE_LOCATIONS = 'schedule/CHANGE_LOCATIONS';
export const CHANGE_LEVELS = 'schedule/CHANGE_LEVELS';
export const CHANGE_LEVELS_ALL = 'schedule/CHANGE_LEVELS_ALL';
export const CHANGE_PILLARS = 'schedule/CHANGE_PILLARS';
export const CHANGE_PILLARS_ALL = 'schedule/CHANGE_PILLARS_ALL';
export const CHANGE_WEEKS = 'schedule/CHANGE_WEEKS';
export const CHANGE_CLASS = 'schedule/CHANGE_CLASS';
export const SAVE_WEEK = 'schedule/SAVE_WEEK';
export const CHANGE_WEEK_TYPE = 'schedule/CHANGE_WEEK_TYPE';
export const CHANGE_FILTER_CHOOSE = 'schedule/CHANGE_FILTER_CHOOSE';
// export const CHANGE_CM_FILTER_CHOOSE = 'schedule/CHANGE_CM_FILTER_CHOOSE';
export const RESET_FILTER = 'schedule/RESET_FILTER';
export const SAVE_FILTER_TYPES = 'schedule/SAVE_FILTER_TYPES';
export const CHANGE_NOTICE = 'schedule/CHANGE_NOTICE';
export const CHANGE_NOTICE_ALL = 'schedule/CHANGE_NOTICE_ALL';
export const CHANGE_LOADING = 'schedule/CHANGE_LOADING';
export const CHANGE_FILTER = 'schedule/CHANGE_FILTER';
export const CHANGE_LOCATION_IDS = 'schedule/CHANGE_LOCATION_IDS';

export const SCHEDULE_INIT_DATA = 'schedule/SCHEDULE_INIT_DATA';
export const CHANGE_SCHEDULE = 'schedule/CHANGE_SCHEDULE';
export const CHANGE_LOCATION_NUM = 'schedule/CHANGE_LOCATION_NUM';
export const CHANGE_LOCATION_NAMES = 'schedule/CHANGE_LOCATION_NAMES';
export const CHANGE_DAY_TYPE = 'schedule/CHANGE_DAY_TYPE';
export const CHANGE_LIST_SECTION = 'schedule/CHANGE_LIST_SECTION';
export const CHANGE_SCHEDULE_PROCESS_IDS = 'schedule/schedule_process_ids';
export const CHANGE_MOOD = 'schedule/CHANGE_MOOD';

export const SAVE_FAVOURITE_CLASS_TYPE = 'schedule/FAVOURITE_CLASS_TYPE';
export const SAVE_FAVOURITE_TEACHERS = 'schedule/FAVOURITE_TEACHERS';
export const SAVE_FAVOURITE_LOCATIONS = 'schedule/FAVOURITE_LOCATIONS';
export const ADD_FAVOURITE = 'schedule/ADD_FAVOURITE';
export const REMOVE_FAVOURITE = 'schedule/REMOVE_FAVOURITE';






