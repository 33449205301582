export const url = process.env.REACT_APP_UNLEASH_URL;
export const userId = {
    '1': process.env.REACT_APP_UNLEASH_APP_NAME_HK,
    '2': process.env.REACT_APP_UNLEASH_APP_NAME_SG,
    '4': process.env.REACT_APP_UNLEASH_APP_NAME_CN,
};

export const clientKey = process.env.REACT_APP_UNLEASH_CLIENT_KEY;

export const flags = {
    mood: 'app-mood',
    signInAndRun: 'sign-in-and-run',
    specialClass: 'special-class',
    testingFlag: 'testing-flag',
    tinymceEmoji: 'tinymce-emoji',
    tinymceImageUpload: 'tinymce-image-upload',
    envTest: 'env-test',
};
