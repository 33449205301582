import { ClassType } from '../interfaces/class.interface';
import store from '../store';

export function getClassTypeById(id: string) {
    let classList: ClassType[] = store.getState().getIn(['schedule', 'classtypes_ungrouped']).toJS();
    let found = classList.find((x: any) => x.id === id);

    return found;
}

export function getPillarById(id: string, type: string) {
    let pillarList = store.getState().getIn(['schedule', 'pillars_all']).toJS();
    let found = pillarList[type].find((x: any) => x.id === id);

    return found;
}
export function getLevelById(id: string, type: string) {
    let levelList = store.getState().getIn(['schedule', 'levels_all']).toJS();
    let found = levelList[type].find((x: any) => x.id === id);

    return found;
}