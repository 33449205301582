import { configAxios, apiList, requestHeader } from './index';

//获取城市
export const get_region = () => {
    return configAxios.get(apiList.view_region);
};

//获取所有场馆列表
export const get_location_list = (params) => {
    return configAxios.get(apiList.get_location_list,{
        params
    });
};

//获取所有场馆列表
export const get_class_mood = (params) => {
    return configAxios.get(apiList.get_class_mood,{
        params
    });
};

//获取场所通告
export const get_location_notice = (params) => {
    return configAxios.get(apiList.get_location_notice,{
        params
    });
};

//获取课程类型
export const get_class_type = (params) => {
    params.all = 1;
    return configAxios.get(apiList.get_class_type,{
        params
    });
};

//获取课程详情
export const get_class_details = ({ region_id, id }) => {
    return configAxios.get(apiList.get_class_type, {
        params: {
            region_id,
            id,
            all: 1,
        }
    });
};

//获取教师列表
export const get_teacher_list = (params) => {
    return configAxios.get(apiList.get_teacher_list,{
        headers: requestHeader,
        params
    });
};

//获取教师详情
export const get_teacher_details = ({ id, region_id }) => {
    return configAxios.get(apiList.get_teacher_list, {
        params: {
            region_id,
            id
        }
    });
};

//获取等级列表
export const get_level_list = (params) => {
    return configAxios.get(apiList.get_level_list,{
        params
    });
};

//获取支柱列表
export const get_pillar_list = (params) => {
    return configAxios.get(apiList.get_pillar_list,{
        params: {
            ...params,
            api_version: 3,

        },
    });
};

//获取课程
export const get_schedule = (params,jwt) => {
    let headers = getHeaders();
    params.api_version = 3;
    return configAxios.get(apiList.get_schedule,{
        headers,
        params,
    });
};

//獲取服务计划
export const get_my_purchase = (params,jwt) => {
    let headers = getHeaders();
    return configAxios.get(apiList.get_my_purchase,{
        headers,
        params
    });
};

//获取用户信息
export const get_client_info = (jwt, region_id) => {
    let headers = getHeaders();
    return configAxios.get(apiList.get_client_info,{
        headers,
        params:{is_mobile: 1, region_id}
    });
};

//用户登录
export const sign_in = (params) => {
    return configAxios.post(apiList.sign_in,{
        ...params
    });
};

//国内用户登录
export const sign_in_cn = (params) => {
    return configAxios.post(apiList.cn_login,{
        ...params
    });
};

//国外用户登录
export const sign_in_defaule = (params) => {
    return configAxios.post(apiList.default_login,{
        ...params
    });
};

//获取记录
export const get_booking_history = (params,jwt) => {
    let headers = getHeaders();
    return configAxios.get(apiList.view_booking_history,{
        headers,
        params,
    });
};

export const get_upcoming_classes = ({
    region_id,
    language_id,
    start_date,
    end_date
}, jwt) => {
    let headers = getHeaders();
    return configAxios({
        method: 'get',
        url: apiList.view_booking_history,
        headers,
        params: {
            region_id,
            language_id,
            start_date,
            end_date,
            page: 1,
            per_page: 999,
            type: 'upcoming'
        },
    });
};

//预约课程
export const class_booking = (data,jwt) => {
    return configAxios({
        method: 'post',
        url: apiList.booking,
        headers: getHeaders(),
        data
    });
};

//取消預約
export const cancel_booking = (data,jwt) => {
    return configAxios({
        method: 'post',
        url: apiList.cancel_booking,
        headers: getHeaders(),
        data
    });
};

//获取到场记录
export const get_visit_history = (params,jwt) => {
    let headers = getHeaders();
    return configAxios.get(apiList.view_booking_history,{
        headers,
        params
    });
};

//获取到场记录
export const get_popup_modal = (params,jwt) => {
    let headers = getHeaders();
    return configAxios.get(apiList.get_popup_modal,{
        headers,
        params
    });
};

//修改用户信息
export const update_client_info = (data,jwt) => {
    return configAxios({
        method: 'post',
        url: apiList.update_client_setting,
        headers: getHeaders(),
        data
    });
};

//修改用户头像
export const update_profile = (data,jwt) => {
    return configAxios({
        method: 'post',
        url: apiList.update_profile,
        headers: getHeaders(),
        data
    });
};

//获取用户通告
export const get_region_notice = (data, jwt) => {
    return configAxios({
        method: 'get',
        url: apiList.get_region_notice,
        headers: getHeaders(),
        params: data
    });
};

// add or remove favourite
export const add_favourite = (data, jwt) => {
    return configAxios({
        method: 'post',
        url: apiList.add_favourite,
        headers: getHeaders(),
        data
    });
};
export const remove_favourite = (data, jwt) => {
    return configAxios({
        method: 'post',
        url: apiList.remove_favourite,
        headers: getHeaders(),
        data
    });
};

// get opening hour
export const get_location_opening_hour = (location_id, region_id, language_id) => {
    let headers = getHeaders();
    return configAxios.get(apiList.get_location_opening_hour, {
        headers,
        params: {
            location_id,
            region_id,
            language_id,
            api_version: 3,
        }
    });
};

// get favourite
export const get_favourite_class_type = (region_id, jwt) => {
    let headers = getHeaders();
    return configAxios.get(apiList.get_favourite_class_type, {
        headers,
        params: {
            region_id,
            api_version: 3,
        }
    });
};
export const get_favourite_location = (region_id, jwt) => {
    let headers = getHeaders();
    return configAxios.get(apiList.get_favourite_location, {
        headers,
        params: {
            region_id,
            api_version: 3,
        }
    });
};
export const get_favourite_teacher = (region_id, jwt) => {
    let headers = getHeaders();
    return configAxios.get(apiList.get_favourite_teacher, {
        headers,
        params: {
            region_id,
            api_version: 3,
        }
    });
};

export const getHeaders = () => {
    let jwt;
    let userInfo;
    if (localStorage.getItem('user')) {
       userInfo = JSON.parse(localStorage.getItem('user'));
       jwt = userInfo['jwt'];
    }
    let header = Object.assign({}, requestHeader, jwt ? { 'X-JWT-Token': jwt } : {});
    return header;
};
