import React, { Component } from 'react';
import { Icon } from 'antd-mobile';
import { connect } from 'react-redux';
import { setModal } from '../../main/store/actionCreators';
import { changeFilterChoose } from '../../../schedule/store/actionCreators';
import { intl } from '../../../config/util';
import { fromJS } from 'immutable';
import './locationSelect.less';
import { REGION } from '../../../config/constant';
import { commonConfig } from '../../../config';
import { CheckOutlined, StarFilled } from '@ant-design/icons';
class LocationSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location_ids: [],
            filterList: []
        };
    }

    handleSelectChoose = (id, index) => {
        const { filter, region_id } = this.props;
        var filterList = this.state.filterList;
        var location_ids = this.state.location_ids;
        const currentRegion = REGION[region_id];
        if (filterList[index].choice) {
            filterList[index].choice = false;
            for (var i = 0; i < location_ids.length; i++) {
                if (location_ids[i] == id.toString()) {
                    location_ids.splice(i, 1);
                }
            }
            for (var i = 0; i < filterList.length; i++) {
                filterList[i].disabled = false;
            }
        } else {
            filterList[index].choice = true;
            location_ids.push(id.toString());

            if (location_ids.length >= commonConfig.location_limit[currentRegion]) {
                for (var i = 0; i < filterList.length; i++) {
                    filterList[i].disabled = true;
                    for (var t = 0; t < location_ids.length; t++) {

                        if (location_ids[t] == filterList[i].id) {
                            filterList[i].disabled = false;
                        }
                    }
                }
            }
        }
        filterList[index].disabled = false;

        this.setState({
            filterList: filterList,
            location_ids: location_ids
        });
    }

    componentDidMount() {
        const { locations, filter, region_id } = this.props;
        let data = locations.size > 0 ? locations.get(filter.sector) : [];
        var location_ids = [] = localStorage.getItem('location_id') ? localStorage.getItem('location_id').split(',') : [];
        var list = data.toJS();
        const currentRegion = REGION[region_id];
        for (var i = 0; i < list.length; i++) {
            list[i].choice = false;
            if (location_ids.length >= commonConfig.location_limit[currentRegion]) {
                list[i].disabled = true;
            } else {
                list[i].disabled = false;
            }
            for (var t = 0; t < location_ids.length; t++) {
                if (list[i].id == location_ids[t]) {
                    list[i].disabled = false;
                    list[i].choice = true;
                }
            }
        }
        this.setState({
            filterList: list,
            location_ids: location_ids
        });

    }

    ok = () => {
        if (this.state.location_ids.length == 0) {
            this.props.changeFilter(location_ids);
            this.props.changeModal(false, '');
            // return false;
        }
        var location_ids = this.state.location_ids.join(',');
        this.props.changeFilter(location_ids);
        this.props.changeModal(false, '');
    }

    render() {
        const { locations, filter, lang, language_id, region_id } = this.props;
        const currentRegion = REGION[region_id];
        return (
            <div className="pop_list_wra">
                <div className="titile">{intl('other', 'locationTips', language_id, { num: commonConfig.location_limit[currentRegion] })}</div>
                <div className="pop_content">
                    <ul>
                        {
                            this.state.filterList.map((item, index) => {
                                return (
                                    <li className={`panel ${item.id} ${item.disabled ? 'disabled' : ''} ${item.choice ? 'choice' : ''}`} key={item.id} onClick={() => this.handleSelectChoose(item.id, index)}>
                                        <span className="name">{item.short_name[lang]}</span>
                                        {/* {parseInt(filter.location_id) === item.id && <i className="iconfont icon-check"></i>} */}
                                        {item.is_favourite && <StarFilled className='favourite-icon' style={{ color: '#ffaC33' }} />}
                                        <Icon type="check" />
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                <div className="opera">
                    <button
                        className="true"
                        onClick={() => this.ok()}
                        disabled={this.state.location_ids.length == 0 ? true: false}
                    >
                        {intl('other', 'ok', language_id)}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.getIn(['header', 'lang']),
        locations: state.getIn(['schedule', 'location_list']),
        filter: state.getIn(['schedule', 'filter']).toJS(),
        user: state.getIn(['main', 'user']),
        language_id: state.getIn(['header', 'language_id']),
        region_id: state.getIn(['header', 'region_id']),
        favourite_location_list: state.getIn(['schedule', 'favourite_location'])
    };
};

const mapDispathToProps = (dispatch) => ({
    //设置弹窗
    changeModal(opera, type) {
        dispatch(setModal(opera, type));
    },
    //修改语言和城市
    changeFilter(id, jwt) {
        dispatch(changeFilterChoose(id, 'location_ids', fromJS(this.filter), this.weeks, jwt, this.locations));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(LocationSelect);