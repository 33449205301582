const lang = {
    'login': {
        'signout_title': 'Do you want to log out?',
        'loginDesktop':'Login to Book a Class',
        'loginMobile':'Login to Book / Check-in',
        'stayLogin': 'Remember Me',
        'sign_in': 'Login',
        'sign_out': 'Log Out',
        'noCardholder': 'I’m not yet a PURE Cardholder',
        'forget_password': 'Forgot Password?',
        'login_title': 'Login to Your Account',
        'login_btn': 'Login',
        'username': 'Username',
        'password': 'Password',
        'sign_up':'Sign Up for a Trial',
        'tryClass':'Try A Class',
        'name':'PURE 360 Lifestyle',
        'onlineBookingSystem':'Online Booking System'
    },
    'model': {
        'close': 'Close',
        'confirm': 'Confirm',
        'late_cancel': 'Late Cancel',
        'cancel_booking': 'Cancel booking',
        'confirmation': 'Are you sure?',
        'others': 'Select up to 3 locations',
        'done': 'Done！',
        'yes': 'Yes',
        'no': 'No',
        'ok': 'Ok',
    },
    'schedule': {
        'teachers': 'Teachers',
        'pillars': 'Pillars',
        'levels': 'Levels',
        'classTypes': 'Classes',
        'fitnessCyclingSGT': 'Fitness/ Cycling/ FUZE/ SGT',
        'instructors': 'Instructors',
        'categories': 'Categories',
        'reset': 'Reset',
        'apply_filter': 'Apply',
        'locations': 'Locations',
        'n_locations': 'Locations',
        'staff': 'Staff',
        'explain':'Please click on the Book button on your selected class to book. Hover the mouse over the class or teacher to see details',
        'schedule': 'Schedule',
        'dateOfWeek': 'Date Of Week',
        'printerFriendlyVersion': 'Printer Friendly Version',
        'classType': 'Class Type',
        'allClassType': 'All Classes',
        'teacher': 'Teacher',
        'allTeacher': 'All Teachers',
        'pillar': 'Pillar',
        'allPillar': 'All Pillars',
        'category': 'Category',
        'allCategory': 'All Categories',
        'level': 'Level',
        'alllevel': 'All Levels',
        'type': 'Type',
        'allType': 'All Type',
        'mood': 'Vibe',
        'allMood': 'All Vibes',
        'yoga': 'Yoga',
        'fitness': 'Fitness',
        'myMood': 'My Vibe',
        'n_moods': 'Vibes',
        'cycling': 'Cycling',
        'SGT': 'SGT',
        'others': 'Select up to 3 locations',
        'filter':'Filters',
        'resetFilter': 'Reset Filter',
        'telephoneBooking': 'Phone Booking',
        'callToBook': 'Call to book',
        'classSchedule': 'Class Schedule',
        'nextSevenDays': 'Next 7 Days',
        'next14Days': 'Next 14 Days',
        'oneWeek': 'This Week',
        'nextWeek': 'Next Week',
        'myClassSchedule': 'My Bookings',
        'myRecord': 'My Record',
        'checkInRecord': 'Sign In Record',
        'cancelRecord': 'Cancellation Records',
        'myProfile': 'My Profile',
        'date': 'Date',
        'time': 'Time',
        'classTime':'Class Time',
        'bookingStatus': 'Booked / Waitlisted',
        'class': 'Class',
        'location': 'Location',
        'allLocation': 'All Location',
        'instructor': 'Instructor',
        'cancel': 'Cancel',
        'status': 'Status',
        'paymentMethod': 'Payment Method',
        'onlineBooking': 'Online booking',
        'bookingTime': 'Booked ID',
        'checkInTime': 'Sign In Time',
        'paymentId': 'Payment ID',
        'lateCancel': 'Late Cancel',
        'cancelMethod': 'Cancellation By',
        'classLevel': 'Class Level',
        'classification': 'Classification',
        'fName': 'First Name',
        'lName': 'Last Name',
        'email': 'Email',
        'address': 'Address',
        'region': 'City',
        'postalCode': 'Postal Code',
        'country': 'Region',
        'dateOfBirth': 'Date Of Birth',
        'homePhone': 'Home Phone',
        'companyPhone': 'Company Phone',
        'extensionNumber': 'Extension Number',
        'mobilePhone': 'Mobile Phone',
        'subscribeAlert': 'Subscribe email alert and notifications',
        'subscribePromo': 'Subscribe to newsletters and promotions',
        'autoJoinWaitList': 'Auto Waitlist',
        'hasChecked': 'Yes',
        'notChecked': 'No',
        'description': 'Description',
        'teachingLanguageHK': 'MOST CLASSES ARE TAUGHT IN CHINESE THOSE INSTRUCTORS MARKED (E) TEACH IN ENGLISH.',
        'teachingLanguageSG': 'MOST CLASSES ARE TAUGHT IN CHINESE THOSE INSTRUCTORS MARKED (E) TEACH IN ENGLISH.',
        'teachingLanguageTW': 'MOST CLASSES ARE TAUGHT IN CHINESE THOSE INSTRUCTORS MARKED (E) TEACH IN ENGLISH.',
        'teachingLanguageCN': '',
        'currentTime': 'Current Time',
        'today': 'Today',
        'tomorrow': 'Tomorrow',
        'book': 'Book',
        'booked': 'Booked',
        'waitList': 'Waitlist',
        'inWaitList': 'Waitlist',
        'full': 'Full',
        'signedIn': 'Signed In',
        'absent': 'No Show',
        'complete': 'Complete',
        'completed': 'Completed',
        'cancelled': 'Cancelled',
        'mins': 'min',
        'downloadApp': 'Download the Booking App!',
        'pureCardholders':'Existing PURE Cardholders',
        'teachingLanguage': 'All classes are taught in English. Instructors marked with C (Cantonese) or M (Mandarin) can speak the indicated languages.',
        'phoneBookingFor': 'Phone Booking for',
        'bookedTime':'Booked Time',
        'cancelTime': 'Cancelled Time',
        'cancelBy': 'Cancelled By',
        'myCancellations':'My Cancellations',
        'bookingConfir':'Your booking is not confirmed yet. Two hours before the class starts, your status will be finalized and the waitlist will be removed. Be sure to check back for any status change<br/><br/><span style="font-weight:600;">Alternatively, let\'s explore our PureCast Live-stream classes by selecting the ""PureCast"" location on the schedule!</span>',
        'addWaiting':'Waitlisted!',
        'button_status13': 'Try Standby',
        'waitlist_refresh_notice': 'Please refresh the webpage to see your waitlist number after class booking.',
        'fullyBooked':'Both the class and the waitlist are full.',
        'favourite':'Favourite',
        'specialNotesPrefix': ' (S) - ',
        'specialClassNotes': 'Special Class',
        'lastChance': 'Last Chance',
        'class_on': 'Class On',
        'no_result_found': 'No result found. {{break}}Please try again with another selection.',
        'view': 'View',
    },
    'other': {
        'loading': 'loading',
        'all': 'All',
        'tryNow': 'Try\nNow',
        'telNull': 'Studio not available for phone booking',
        'chooseCityLangDisc': 'Please Select Your City and Language',
        'chooseCityDisc': 'Please Select Your City',
        'chooseLangDisc': 'Please Select Your Language',
        'locationTips':'Select up to {{num}} locations',
        'moodTips':'Select Your Vibe',
        'downloadApp':'Download the App',
        'downloadAppMobile':'Download App',
        'noData': 'noData',
        'welcome': 'Let’s Book a Class',
        'soonestFirst': 'Latest First',
        'soonestLast': 'Oldest First',
        'latestFirst': 'Latest First',
        'latestLast':'Latest Last',
        'action': 'Action',
        'noShows': 'No-Shows/Late Cancel',
        'userId':'User ID',
        'preferredLocation':'Preferred Location',
        'membershipType': 'Membership Type', 
        'confirmationEmail': 'Booking Confirmation Email',
        'autoWaitlist': 'Auto Waitlist',
        'classTime':'Class Time',
        'bookedTime':'Booked Time',
        'cancelTime': 'Cancelled Time',
        'lateCancellation':'Late Cancellation',
        'lateCancelled':'Late Cancelled', 
        'tips': 'Wait...',
        'lastChance': 'Last Chance?',
        'oops': 'Oops!',
        'history': 'History',
        'contact':'For more information, please visit our official website.',
        'language':'Language',
        'city': 'City',
        'callBooking': 'Phone Booking',
        'notice':'Notice',
        'alerts': 'Alerts',
        'locationAlerts': 'Location Alerts',
        'myPurchases': 'My Service Plans',
        'mySignIns': 'My Sign-ins',
        'settings': 'Settings',
        'activationDate': 'Activation Date',
        'expirationDate': 'Expiration Date',
        'paymentStartDate': 'Current Payment Cycle Starts',
        'paymentEndDate': 'Current Payment Cycle Ends',
        'realRemaining': 'Remaining bookable sessions',
        'bookedSessions': 'Booked Sessions',
        'completedSessions': 'Completed Sessions',
        'entitledSession': 'Total Sessions',
        'ok': 'OK',
        'back': 'Back',
        'confirmEmailNotice': 'Choose whether you want to receive system-generated confirmation emails regarding your status such as Booked, Waitlisted, Early Cancel, Late Cancel, Absent and Class Changes Notice.',
        'autoWaitlistNotice': 'Switch on this feature if you want the system to automatically put you on the waitlist for a full class. If you switch off this feature, the system will first ask if you wish to be put on the waitlist.',
        'other1s': '@ 2021 PURE International. All right reserved',
        'other2s': 'Privacy Policy',
        'other3s': 'Terms and Conditions',
        'lang-1': 'English',
        'lang-2': '繁體中文',
        'lang-3': '简体中文',
        'errorTryAgain': 'Unexpected error occurred. Please try again later.',
        'errorNoInfo': 'Class type information is not available for this class',
        'lastChanceBook': "I'd like to book",
        'maybeNextTime': 'Maybe next time',
        'profile': 'Profile',
        'favourite': 'Favourites',
        'pureTeam': 'PURE Team',
        'locations': 'Locations',
        'openingHours': 'Opening Hours',
        'nowOpen': 'Now Open',
        'nowClose': 'Now Closed',
        'openMap': 'Open Map',
        'aboutThisLocation': 'About this Location',
        'features': 'Features',
        'takeTour': 'Take A Tour',
        'clockView': 'Clock View 12hr/24hr',
    },
    'cancel': {
        'cancelDetails': 'Cancellation Details',
        'cancelledBy': 'Cancelled by',
        'latecancel': 'Late Cancel',
        'cancelDate': 'Cancel Date',
        'cancelTime': 'Cancel Time',
        'classDate': 'Class Date',
        'classTime': 'Class Time',
        'className': 'Class Name',
        'classLocation': 'Class Location',
        'teacherInstructor': 'Teacher/ Instructor',
        'bookedId': 'Booked ID',
    }
}

export default lang;