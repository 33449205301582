import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../../config/util';
import { setModal } from '../../main/store/actionCreators';
import { selectChangeLanguage } from '../../header/store/actionCreators';

class LanguageSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectRegionId: parseInt(localStorage.getItem('region_id')),
            selectLanguageId: localStorage.getItem('language_id'),
        };
    }

    //确定按钮点击
    handleChange = () => {
        if (parseInt(this.state.selectLanguageId) !== this.props.language_id) {
            this.props.changeLanguageM(this.state.selectLanguageId);
            this.props.changeModal(false, '');
        } else {
            this.props.changeModal(false, '');
        }
    }

    render() {
        const { selectLanguageId } = this.state;
        const { languages, language_id } = this.props;

        return (
            <div className = "pop_list_wra" >
                <div className = "titile" > { intl('other', 'chooseLangDisc', language_id) } </div>
                <div className = "pop_content" > 
                    {
                        languages.map(item => {
                            return (
                                <div className = "panel"
                                    key = { item.get('id') }
                                    onClick = {
                                        () => { this.setState({ selectLanguageId: item.get('id') }); }
                                    }>
                                    <span className = "name" > { item.get('description') } </span>
                                    {parseInt(selectLanguageId) === item.get('id') && <i className="iconfont icon-check"></i>}
                                </div>
                            );
                        })
                    }
                </div>
                <div className = "opera" >
                    <button className = "true" onClick = { this.handleChange } > { intl('other', 'ok', language_id) } </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.getIn(['header','languages']),
        language_id: state.getIn(['header','language_id']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //弹窗设置
    changeModal(opera,type){
        dispatch(setModal(opera, type));
    },
    //修改语言和城市
    changeLanguageM(language_id) {
        dispatch(selectChangeLanguage(language_id, false));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(LanguageSelect);