import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/reset.css';
import './assets/css/base.css';
import App from './App';
import FlagProvider from '@unleash/proxy-client-react';
import * as unleashConfig from './config/unleash';
import { commonConfig } from './config/index';
import smoothscroll from 'smoothscroll-polyfill';

//Safari smooth scroll polyfill
smoothscroll.polyfill();

const config = {
  url: unleashConfig.url,
  clientKey: unleashConfig.clientKey,
  refreshInterval: 15,
  appName: 'webbooking2',
  context: {
    userId : unleashConfig.userId[localStorage.getItem('region_id') || commonConfig.region]
  },
  // environment: 'dev',
};

ReactDOM.render(
    <FlagProvider config={config}>
      <App />
    </FlagProvider>,
  document.getElementById('root')
);

// ReactDOM.render(<App />, document.getElementById('root'));
