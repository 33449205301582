import * as constants from './constants';
import { fromJS } from 'immutable';
// import { isMobile } from 'react-device-detect';
import { getDefaultWeekType } from '../../config/util';

const defauleState = fromJS({
    class_mood_list:[],
    location_list: {},
    location_list_raw: {},
    location_list_ungrouped: [],
    location_ids:'',//当前选中的场所id
    classtypes:[],
    classtypes_all: {},             //课程类型
    classtypes_ungrouped: [],
    teachers:[],
    teachers_all: {},               //老师列表
    teachers_ungrouped: [],
    levels:[],
    levels_all: {},                 //程度
    pillars:[],
    pillars_all: {},             //支柱
    pillars_by_id: {},
    types:[],//filter type
    weeks:{},//三种日期开始时间
    weekList:[],//当前星期列表
    weekType: getDefaultWeekType(),//星期显示类型，1=>本周，2=>下周,3=>本日起七天内,4=>下周起七天内
    filter:{
        location_ids:'',
        mood_ids:'',
        week:1,
        days:7
    },
    schedule_process_ids: {},
    // classModalFilter:{
    //     location_ids:'',
    //     mood_ids:'',
    //     week:1,
    //     days:14,
    //     class_type_id:''
    // },
    classes:[],
    notice:{},
    notice_all:[],
    loading: true,//课程列表loading
    location_num:1, //地点数量
    location_names:[], //地点名字
    day_type:1, //日期状态
    classes_mobile: [], // classes for mobile format
    listSection: [], //scroll view 高度区间
    filterTypes: [              //健身列表单独筛选属性
        { id: 0, names: { 'en': 'All', 'zh-cn': '所有', 'zh-hk': '所有', 'zh-tw': '所有' } },
        { id: 1, names: { 'en': 'Fitness', 'zh-cn': '健身', 'zh-hk': '健身', 'zh-tw': '健身' } },
        { id: 2, names: { 'en': 'Cycling', 'zh-cn': '单车', 'zh-hk': '單車', 'zh-tw': '單車' } },
        { id: 3, names: { 'en': 'SGT', 'zh-cn': '小型团体训练', 'zh-hk': '小型團體訓練', 'zh-tw': '小型團體訓練' } },
        { id: 4, names: { 'en': 'FUZE', 'zh-cn': 'FUZE', 'zh-hk': 'FUZE', 'zh-tw': 'FUZE' } }
    ],
    data_inited: false,
    favourite_class_type: [],
    favourite_location: [],
    favourite_teacher: [],
});

export default (state = defauleState, action) => {
    switch (action.type) {
        case constants.SCHEDULE_INIT_DATA:
            return state.merge({
                'location_list': action.value.get('location_list'),
                'location_list_ungrouped': action.value.get('location_list_ungrouped'),
                'location_list_raw': action.value.get('location_list_raw'),
                'classtypes': action.value.get('classtypes'),
                'classtypes_all': action.value.get('classtypes_all'),
                'classtypes_ungrouped': action.value.get('classtypes_ungrouped'),
                'teachers': action.value.get('teachers'),
                'teachers_all': action.value.get('teachers_all'),
                'teachers_ungrouped': action.value.get('teachers_ungrouped'),
                'levels': action.value.get('levels'),
                'levels_all': action.value.get('levels_all'),
                'pillars': action.value.get('pillars'),
                'pillars_all': action.value.get('pillars_all'),
                'pillars_by_id': action.value.get('pillars_by_id'),
                'weeks': action.value.get('weeks'),
                'types': action.value.get('types'),
                'favourite_class_type': action.value.get('favourite_class_type'),
                'favourite_location': action.value.get('favourite_location'),
                'favourite_teacher': action.value.get('favourite_teacher'),
                'data_inited': true,
            });
        case constants.CHANGE_SCHEDULE:
            return state.merge({
                classes: action.value.get('classes'),
                classes_mobile: action.value.get('classes_mobile'),
                filter: action.value.get('filter'),
                location_ids: action.value.get('location_ids'),
                class_total: action.value.get('class_total'),
            });
        case constants.CHANGE_LOCATIONS:
            return state.set('location_list', action.value);
        case constants.CHANGE_MOOD:
            return state.set('class_mood_list', action.value);
        case constants.CHANGE_CLASS_TYPES:
            return state.set('classtypes', action.value);
        case constants.CHANGE_CLASS_TYPES_ALL:
            return state.set('classtypes_all', action.value);
        case constants.CHANGE_TEACHERS:
            return state.set('teachers', action.value);
        case constants.CHANGE_TEACHERS_ALL:
            return state.set('teachers_all', action.value);
        case constants.CHANGE_LEVELS:
            return state.set('levels', action.value);
        case constants.CHANGE_LEVELS_ALL:
            return state.set('levels_all', action.value);
        case constants.CHANGE_PILLARS:
            return state.set('pillars', action.value);
        case constants.CHANGE_PILLARS_ALL:
            return state.set('pillars_all', action.value);
        case constants.SAVE_FILTER_TYPES:
            return state.set('types', action.value);
        case constants.CHANGE_CLASS:
            return state.set('classes', action.value);
        case constants.CHANGE_WEEKS:
            return state.set('weeks', action.value);
        case constants.SAVE_WEEK:
            return state.set('weekList', action.value);
        case constants.CHANGE_WEEK_TYPE:
            return state.set('weekType', action.value);
        case constants.CHANGE_FILTER_CHOOSE:
            return state.set('filter', action.value);
        // case constants.CHANGE_CM_FILTER_CHOOSE:
        //     return state.set('classModalFilter', action.value);
        case constants.RESET_FILTER:
            let newFilter = {
                localtion: state.getIn(['filter','location_ids']),
                week: state.get('weekType')
            };
            return state.set('filter', newFilter);
        case constants.CHANGE_NOTICE:
            return state.set('notice', action.value);
        case constants.CHANGE_NOTICE_ALL:
            return state.set('notice_all', action.value);
        case constants.CHANGE_LOADING:
            return state.set('loading', action.value);
        case constants.CHANGE_LOCATION_IDS:
            return state.set('location_ids', action.value);
        case constants.CHANGE_LOCATION_NUM:
            return state.set('location_num', action.value);
        case constants.CHANGE_LOCATION_NAMES:
            return state.set('location_names', action.value);
        case constants.CHANGE_DAY_TYPE:
            return state.set('day_type', action.value);
        case constants.CHANGE_LIST_SECTION:
            return state.set('listSection', action.value);
        case constants.CHANGE_SCHEDULE_PROCESS_IDS:
            return state.setIn(['schedule_process_ids', action.id], action.value);
        case constants.SAVE_FAVOURITE_CLASS_TYPE:
            return state.set('favourite_class_type', action.value);
        case constants.SAVE_FAVOURITE_LOCATIONS:
            return state.set('favourite_location', action.value);
        case constants.SAVE_FAVOURITE_TEACHERS:
            return state.set('favourite_teacher', action.value);
        default:
            return state;
    }
};
