
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { List, Avatar, Button, Typography } from 'antd';
import { intl } from '../../../config/util';
import { MyState } from '../../../store/reducer.interface';
import { StarFilled } from '@ant-design/icons';
import { platformClasses } from '../../../config/style';
import { showClassInfo } from '../../../schedule/store/actionCreators';


let pillarsImg = {
    //疗愈瑜伽
    1: require('../../../assets/images/yoga_ly.png'),
    //静态瑜伽
    2: require('../../../assets/images/yoga_jt.png'),
    //动态瑜伽
    3: require('../../../assets/images/yoga_dt.png'),
    //高温瑜伽
    4: require('../../../assets/images/yoga_gw.png'),
    //特色瑜伽
    5: require('../../../assets/images/yoga_ts.png'),
    //力量与训练班
    6: require('../../../assets/images/ft_ll.png'),
    //舞蹈班
    7: require('../../../assets/images/ft_dance.png'),
    //高能量训练
    8: require('../../../assets/images/ft_gn.png'),
    //身心训练
    9: require('../../../assets/images/ft_sx.png'),
    //健身车班
    10: require('../../../assets/images/ft_js.png'),
    //高强度间歇训练
    11: require('../../../assets/images/ft_gq.png'),
    // Fuze
    12: require('../../../assets/images/fuze.png'),
};

const mapStateToProps = (state: MyState) => {
    return {
        lang: state.getIn(['header', 'lang']),
        type: state.getIn(['header', 'type']),
        favourite_class_list: state.getIn(['schedule', 'favourite_class_type']),
        language_id: state.getIn(['header', 'language_id']),
        classtypes_ungrouped: state.getIn(['schedule', 'classtypes_ungrouped']),
        region_id: state.getIn(['header', 'region_id']),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        pillars_all: state.getIn(['schedule', 'pillars_all']),
        pillars_by_id: state.getIn(['schedule', 'pillars_by_id']).toJS(),
        user: state.getIn(['main', 'user']),
    };
};

const mapDispathToProps = (dispatch: any) => ({
    showClassInfo(id: number, regionId: number, locationList: any[], pillars: any[], special: any, mood: any, jwt: string, languageId: string, scroll: boolean) {
        dispatch(showClassInfo(id, regionId, locationList, pillars, special, mood, jwt, languageId, scroll));
    },
});

const connector = connect(mapStateToProps, mapDispathToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export interface FavouriteClassProps extends PropsFromRedux {

}
function FavouriteClass(props: FavouriteClassProps) {
    let { lang, type, favourite_class_list, classtypes_ungrouped, language_id, pillars_by_id } = props;
    let defaultImage = require('../../../assets/images/ic_default_image.png');
    let favClass: any[] = [];
    let list: any[] = classtypes_ungrouped.toJS();
    list.forEach((element: any) => {
        if (favourite_class_list.indexOf(element.id) >= 0) {
            favClass.push(element);
        }
    });
    // sort by favourite order
    list.sort(function (a, b) {
        return favourite_class_list.indexOf(a.id) - favourite_class_list.indexOf(b.id)
    });

    const onClassClick = (item: any, schedule = false) => {
        const jwt = props.user.get('jwt');
        props.showClassInfo(item.id, props.region_id, props.location_list, props.pillars_all.toJS(), null, null, jwt, props.language_id, schedule);
    }

    return (
        <div className='favourite-content-wrapper'>
            <List
                className='favourite-class-list'
                itemLayout="horizontal"
                dataSource={favClass}
                renderItem={item => {
                    let avatar = (parseInt(item.pillar_id) as keyof typeof pillarsImg);
                    let boxShadow = platformClasses.yoga;
                    if (item.category === "FUZE") {
                        boxShadow = platformClasses.fuze;
                    } else if (item.sector !== 'Y') {
                        boxShadow = platformClasses.fitness;
                    }
                    let pillarColour = pillars_by_id[item.pillar_id]['color'];
                    return (
                        <List.Item
                            style={{ boxShadow: `${boxShadow}60 0px 2px 8px 0px`, }}
                        >
                            <List.Item.Meta
                                avatar={
                                    <div onClick={() => onClassClick(item)}>
                                        <Avatar src={pillarsImg[avatar]} />
                                    </div>
                                }
                                title={
                                    <div onClick={() => onClassClick(item)}>
                                        <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ color: pillarColour }} className="class-name">
                                            {item.names[lang]}
                                        </Typography.Paragraph>
                                    </div>
                                }
                            // title={item.names[lang]}
                            />
                            <div className='list-footer'>
                                <StarFilled style={{ color: '#ffaC33' }} />
                                <Button onClick={() => onClassClick(item, true)}>{intl('schedule', 'schedule', language_id)}</Button>
                            </div>
                        </List.Item>

                    );
                }}
            />
        </div>
    );
}

export default connector(FavouriteClass);