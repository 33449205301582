import * as constants from './constants';
import { fromJS } from 'immutable';

const defauleState = fromJS({
    showBookList: true,//是否展示booking页
    myBookings: [],
    booking_order: 0, //0 default
    cancelList:[],//取消迟到列表
    cancelList_by_date:[],
    bookListLoading:false,//预约列表的loading
    cancelListLoading:false,//取消预约列表loading
    bookings_by_date:[],
    upcoming_classes: [],
    scene: 'myBookings',          //预约页面相关场景 'myBookings'||'myCancellations'||'cancelDetails'
    cancelDetails: {},            //取消记录详情
});

export default (state = defauleState, action) => {
    switch (action.type) {
        case constants.CHANGE_SCENE:
            return state.set('scene', action.value);
        case constants.SAVE_BOOKINGS:
            return state.set('myBookings', action.value);
        case constants.SAVE_BOOKINGS_ALL:
            return state.merge({
                myBookings: action.value.get('myBookings'),
                bookings_by_date: action.value.get('bookingsByDate'),
            });
        case constants.SAVE_CANCEL_LIST:
            return state.set('cancelList',action.value);
        case constants.SAVE_CANCEL_LIST_ALL:
            return state.merge({
                cancelList: action.value.get('cancelList'),
                cancelList_by_date: action.value.get('cancelListByDate'),
            });
        case constants.SAVE_CANCEL_LIST_BY_DATE:
            return state.set('cancelList_by_date',action.value);
        case constants.CHANGE_CANCEL_DETAILS:
            return state.set('cancelDetails', action.value);
        case constants.CHANGE_ORDER:
            return state.set('booking_order', action.value);
        case constants.CHANGE_BOOK_LIST_LOADING:
            return state.set('bookListLoading', action.value);
        case constants.CHANGE_CANCEL_LIST_LOADING:
            return state.set('cancelListLoading', action.value);
        case constants.CHANGE_SHOW_BOOK_LIST:
            return state.set('showBookList', action.value);
        case constants.CHANGE_UPCOMING_CLASS:
            return state.set('upcoming_classes', action.value);
        default:
            return state;
    }
};
