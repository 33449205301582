import * as constants from './constants';
import { fromJS } from 'immutable';
import { showMsg, catchError, reloadPage } from '../../../config/util';
import { get_region, get_client_info, sign_in, sign_in_cn, sign_in_defaule, get_region_notice } from '../../../config/request';
import {
    changeRegionId,
    changeRegions,
    loginAfterChangeRegion,
    changeType,
    changeLanguage,
    changeUnleashUserId,
    triggerChangeRegionByUrl,
} from '../../header/store/actionCreators';
import { getFilter, changeLocationIds } from '../../../schedule/store/actionCreators';
import { saveClientInfo } from '../../../profile/store/actionCreators';
import { Base64 } from 'js-base64';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { commonConfig } from '../../../config/index';
import { getUpcomingClasses } from '../../../booking/store/actionCreators';
import * as unleashConfig from '../../../config/unleash';
import { LANGUAGES } from '../../../config/constant';
//项目初始化
export const initSetting = () => {
    return (dispatch) => {
        //获取类型
        let type = commonConfig.sector;
        localStorage.setItem('type', type);
        dispatch(changeType(type));

        const defaultAppLang = commonConfig.language;
        const defaultRegion = commonConfig.region;

        // get region & language code from url /:lang/:region
        let urlLanguageCode = '';
        let urlLanguageId = null;
        let urlRegionCode = '';
        let urlRegionId = null;
        const paths = location.pathname.split('/');
        // language code
        if (paths.length >= 2) {
            urlLanguageCode = paths[1];
            for (let i = 0; i < LANGUAGES.length; i++) {
                // url language code is valid
                if (urlLanguageCode === LANGUAGES[i].name) {
                    urlLanguageId = LANGUAGES[i].id;
                    break;
                }
            }
        }
        // region code
        if (paths.length >= 3) {
            urlRegionCode = paths[2];
        }

        //获取语言
        let language_id = urlLanguageId || localStorage.getItem("language_id") || defaultAppLang;
        localStorage.setItem('language_id', language_id);
        dispatch(changeLanguage(language_id));

        //获取用户信息
        const userInfo = localStorage.getItem('user');
        //获取城市
        get_region().then(res => {
            let errorCode = res.data.error.code;
            if (errorCode === 200) {
                let localRegionId = localStorage.getItem("region_id") || defaultRegion;
                let regions;
                let regionsData = res.data.data.regions;
                let regionCode = '';
                if (!localRegionId) {
                    localRegionId = regionsData[0].id;
                }
                regions = regionsData.map(item => {
                    if (item.id === parseInt(localRegionId)) {
                        item.checked = true;
                        regionCode = item.code;
                    } else {
                        item.checked = false;
                    }

                    // url region code is valid
                    if (urlRegionCode && item.code === urlRegionCode) {
                        urlRegionId = item.id;
                    }
                    return item;
                });

                localStorage.setItem('region_id', localRegionId);
                const unleashUserId = unleashConfig.userId[localRegionId];
                dispatch(changeUnleashUserId(unleashUserId));
                dispatch(changeRegions(regions));
                dispatch(changeRegionId(localRegionId));

                if (userInfo) {
                    dispatch(changeUserInfo(userInfo));
                    const jwt = JSON.parse(userInfo).jwt;
                    get_client_info(jwt, localRegionId).then(res => {
                        if (errorCode === 200) {
                            let userInfo = res.data.data.user;
                            dispatch(saveClientInfo(userInfo));
                        } else {
                            catchError(res.data, localStorage.getItem('language_id'), () => {
                                dispatch(signOut());
                            });
                        }
                    });

                    dispatch(getUpcomingClasses(localRegionId, language_id, jwt));

                    //获取场所提示
                    dispatch(getRegionNotice({ region_id: localRegionId, language_id }, jwt));
                }

                /**
                 * url region is different from local region.
                 * trigger change region after required data ready (regions, user)
                 */
                if (urlRegionId !== null && urlRegionId !== parseInt(localRegionId)) {
                    dispatch(triggerChangeRegionByUrl(urlRegionId));
                }

                //获取课程筛选信息
                dispatch(getFilter(language_id, localRegionId, type, userInfo));
            } else {
                console.log(res);
            }
        });
    };
};

//获取地址
export const getRegions = (regions) => {
    return (dispatch) => {
        get_region().then(res => {
            if (res.status === 200) {
                let regionsData = res.data.data.regions;
                let localRegionId = localStorage.getItem("region_id");
                const regions = regionsData.map((item, index) => {
                    if (localRegionId) {
                        item.checked = item.id === parseInt(localRegionId) ? true : false;
                    } else {
                        item.checked = index === 0 ? true : false;
                    }
                    return item;
                });
                localRegionId = localRegionId ? localRegionId : regionsData[0].id;
                localStorage.setItem('region_id', localRegionId);
                dispatch(changeRegions(regions));
                dispatch(changeRegionId(localRegionId));
            } else {
                console.log(res);
            }
        }).catch(error => {
            //改变loading
            //dispatch(changeLoading(false))
            catchError(error, 1, () => {
                console.log(error);
            });
        });
    };
};

//登录
export const login = (data, region_id, regions, location_list) => {

    showMsg('loading');
    return (dispatch) => {
        let { username, password, language_id, staylogin } = data;
        let loginMsg = {
            '1': 'User name cannot be empty',
            '2': '請輸入用戶名',
            '3': '请填写用户名',
            '4': '請輸入用戶名'
        };
        let passwordMsg = {
            '1': 'Password cannot be empty',
            '2': '請輸入密碼',
            '3': '请输入密码',
            '4': '請輸入密碼'
        };
        if (username === '') {
            showMsg('error', loginMsg[language_id]);
        } else if (password === '') {
            showMsg('error', passwordMsg[language_id]);
        } else {

            if (parseInt(region_id) == 4) {
                sign_in_cn({
                    username,
                    password,
                    region_id,
                    language_id,
                    jwt: true
                }).then(res => {
                    showMsg('loading');
                    let errorCode = res.data.error.code;
                    let errorMsg = res.data.error.message;

                    if (errorCode === 200) {
                        let user = res.data.data.user;
                        let localUser = {
                            id: user.id,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            jwt: user.jwt,
                            platform: 'Web',
                            username: user.username,
                            this_cn: 'yes'
                        };
                        if (staylogin) {
                            localStorage.setItem('u', Base64.encode(username));
                            localStorage.setItem('p', Base64.encode(password));
                        } else {
                            localStorage.removeItem('p');
                        }

                        //改变用户信息
                        localStorage.setItem('user', JSON.stringify(localUser));
                        dispatch(setUserInfo(user));


                        //改变地址和地址列表
                        if (parseInt(localStorage.getItem('region_id')) !== parseInt(region_id)) {
                            console.log('修改了region');
                            localStorage.setItem('region_id', region_id);
                            let newRegion = regions.toJS();
                            newRegion.map(item => {
                                item.checked = item.id === parseInt(region_id) ? true : false;
                                return item;
                            });

                            let locationList = location_list.toJS();
                            let type = localStorage.getItem('type') === 'Y' ? 'yoga' : 'fitness';
                            let location_ids = locationList[type][0].id;
                            localStorage.setItem('location_id', location_ids);
                            dispatch(loginAfterChangeRegion({
                                regions: newRegion,
                                region_id
                            }));
                            dispatch(changeLocationIds(location_ids));
                        }
                        reloadPage();
                        //重新请求课程数据
                        //dispatch(getFilter(language_id, region_id, localStorage.getItem('type'), localUser))
                    } else {
                        showMsg('error', errorMsg);
                        console.log(res);
                    }
                });
            } else {

                sign_in_defaule({
                    username,
                    password,
                    region_id,
                    language_id,
                    jwt: true
                }).then(res => {
                    showMsg('hide');
                    let errorCode = res.data.error.code;
                    let errorMsg = res.data.error.message;
                    if (errorCode === 200) {
                        let user = res.data.data.user;
                        let localUser = {
                            id: user.id,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            jwt: user.jwt,
                            platform: 'Web',
                            username: user.username
                        };
                        if (staylogin) {
                            localStorage.setItem('u', Base64.encode(username));
                            localStorage.setItem('p', Base64.encode(password));
                        } else {
                            localStorage.removeItem('p');
                        }

                        //改变用户信息
                        localStorage.setItem('user', JSON.stringify(localUser));
                        dispatch(setUserInfo(user));


                        //改变地址和地址列表
                        if (parseInt(localStorage.getItem('region_id')) !== parseInt(region_id)) {
                            console.log('修改了region');
                            localStorage.setItem('region_id', region_id);
                            let newRegion = regions.toJS();
                            newRegion.map(item => {
                                item.checked = item.id === parseInt(region_id) ? true : false;
                                return item;
                            });
                            let locationList = location_list.toJS();
                            let type = localStorage.getItem('type') === 'Y' ? 'yoga' : 'fitness';
                            let location_ids = locationList[type][0].id;
                            localStorage.setItem('location_id', location_ids);
                            dispatch(loginAfterChangeRegion({
                                regions: newRegion,
                                region_id
                            }));
                            dispatch(changeLocationIds(location_ids));
                        }
                        reloadPage();
                        //重新请求课程数据
                        //dispatch(getFilter(language_id, region_id, localStorage.getItem('type'), localUser))
                    } else {
                        showMsg('error', errorMsg);
                        console.log(res);
                    }
                });
            }

        }
    };
};

//获取场所提示
export const getRegionNotice = (data, jwt) => {
    showMsg('loading');
    return (dispatch) => {
        get_region_notice(data, jwt).then(res => {
            showMsg('hide');
            let errorCode = res.data.error.code;
            if (errorCode === 200 && res.data.data) {
                dispatch(changeRegionNotice(res.data.data));
            }
        });
    };
};

//当有用户信息缓存时，保存到store中
export const changeUserInfo = (data) => {
    return (dispatch) => {
        let user = JSON.parse(data);
        dispatch(setUserInfo(user));
    };
};

//登出
export const signOut = (reload = true) => {
    return (dispatch) => {
        localStorage.removeItem('user');
        dispatch(loginOut());
        if (reload) {
            reloadPage();
        }
    };
};

//改变展示组件
export const handleMoudleChange = (value) => {
    return (dispatch) => {
        dispatch(changeMoudle(value));
    };
};

//保存场所提示
export const changeRegionNotice = (value) => ({
    type: constants.CHANGE_REGION_NOTICE,
    value: fromJS(value)
});

export const changeMoudle = (value) => ({
    type: constants.CHANGE_MOUDLE,
    value
});

export const changeModal = value => ({
    type: constants.CHANGE_MODAL,
    value: fromJS(value)
});

//弹窗逻辑处理
export const setModal = (opera, type) => {
    return dispatch => {
        dispatch(changeModal({ opera, type }));
    };
};

//开启教师详情弹窗
export const openTeacherModal = value => ({
    type: constants.OPEN_TEACHER_MODAL,
    value: fromJS(value)
});

//关闭教师详情弹窗
export const closeTeacherModal = () => ({
    type: constants.CLOSE_TEACHER_MODAL,
    value: ''
});

//开启location详情弹窗
export const openLocationModal = value => ({
    type: constants.OPEN_LOCATION_MODAL,
    value: fromJS(value)
});

//关闭location详情弹窗
export const closeLocationModal = () => ({
    type: constants.CLOSE_LOCATION_MODAL,
    value: ''
});

//开启课程详情弹窗
export const openClassModal = value => ({
    type: constants.OPEN_CLASS_MODAL,
    value: fromJS(value)
});
export const openClassModalSchedule = value => ({
    type: constants.OPEN_CLASS_MODAL_SCHEDULE,
    value: fromJS(value)
});
export const openTeacherModalSchedule = value => ({
    type: constants.OPEN_TEACHER_MODAL_SCHEDULE,
    value: fromJS(value)
});
export const openLocationModalSchedule = value => ({
    type: constants.OPEN_LOCATION_MODAL_SCHEDULE,
    value: fromJS(value)
});

//关闭课程详情弹窗
export const closeClassModal = () => ({
    type: constants.CLOSE_CLASS_MODAL,
    value: ''
});

export const openRegionSelectModal = value => ({
    type: constants.OPEN_REGION_SELECT_MODAL,
    value: fromJS(value)
});

export const closeRegionSelectModal = () => ({
    type: constants.CLOSE_REGION_SELECT_MODAL,
    value: ''
});

//清除登录信息
export const loginOut = () => ({
    type: constants.LOGIN_OUT
});

//展示登录弹窗
export const showLogin = () => ({
    type: constants.SHOW_LOGIN
});

//关闭登录弹窗
export const closeLogin = () => ({
    type: constants.CLOSE_LOGIN
});

//监听输入
export const listenUserName = (value) => ({
    type: constants.GET_USERNAME,
    value
});
//同上
export const listenPassword = (value) => ({
    type: constants.GET_PASSWORD,
    value
});

//保留登录状态
export const listenStayLogin = (value) => ({
    type: constants.CHANGE_STAY_LOGIN,
    value
});

//设置用户信息
export const setUserInfo = (user) => ({
    type: constants.SET_USER_INFO,
    value: fromJS(user)
});

// feature control
export const changeUnleashFlag = (key, value) => ({
    type: constants.CHANGE_UNLEASH_FLAG,
    key,
    value,
});