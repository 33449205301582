
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Card, Col, Row, Typography } from 'antd';
import { MyState } from '../../../store/reducer.interface';
import { StarFilled } from '@ant-design/icons';
import { platformClasses } from '../../../config/style';
import { showTeacherInfo } from '../../../schedule/store/actionCreators';

const mapStateToProps = (state: MyState) => {
    return {
        lang: state.getIn(['header', 'lang']),
        favourite_teacher_list: state.getIn(['schedule', 'favourite_teacher']),
        teachers_ungrouped: state.getIn(['schedule', 'teachers_ungrouped']),
        type: state.getIn(['header', 'type']),
        region_id: state.getIn(['header', 'region_id']),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        language_id: state.getIn(['header', 'language_id']),
        user: state.getIn(['main', 'user']),
    };
};

const mapDispathToProps = (dispatch: any) => ({
    showTeacherInfo(id: number, regionId: number, locationList: any[], languageId: string, user: any) {
        let jwt = user.get('jwt');
        dispatch(showTeacherInfo(id, regionId, locationList, languageId, jwt));
    }
});

const connector = connect(mapStateToProps, mapDispathToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export interface FavouriteTeacherProps extends PropsFromRedux {

}
function FavouriteTeacher(props: FavouriteTeacherProps) {
    let { favourite_teacher_list, teachers_ungrouped, type, lang } = props;
    let defaultImage = require('../../../assets/images/ic_default_image.png');
    let favTeacher: any[] = [];
    let list: any[] = teachers_ungrouped.toJS();
    list.forEach((element: any) => {
        if (favourite_teacher_list.indexOf(element.id) >= 0) {
            favTeacher.push(element);
        }
    });
    // sort by favourite order
    list.sort(function(a, b){
        return favourite_teacher_list.indexOf(a.id) - favourite_teacher_list.indexOf(b.id)
    });

    const teacherClick = (id: number) => {
        props.showTeacherInfo(id, props.region_id, props.location_list, props.language_id, props.user);
    };

    return (
        <div className='favourite-content-wrapper'>
            <Row gutter={[10, 16]} style={{ margin: "0" }}>
                {
                    favTeacher.map((element, index) => {
                        let imageLink = element.image_link || defaultImage;
                        let boxShadow = platformClasses.yoga;
                        if (element.is_group_fitness) {
                            boxShadow = platformClasses.fitness;
                        }
                        return (
                            <Col span={6} key={element.id}>
                                <Card
                                    onClick={() => teacherClick(element.id)}
                                    hoverable
                                    cover={
                                        <div className='background-image-cover pb-square' style={{ backgroundImage: `url("${imageLink}")` }}>
                                            <StarFilled className="card-star" />
                                        </div>
                                    }
                                    className='favourite-card teacher-card'
                                    style={{ boxShadow: `${boxShadow}60 0px 2px 8px 0px` }}
                                >
                                    <div className='teacher-body'>
                                        <Typography.Text ellipsis>{element.short_names[lang]}</Typography.Text>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })
                }
            </Row>
        </div>
    );
}

export default connector(FavouriteTeacher);