export const platformClasses = {
  mobile: 'is-mobile',
  desktop: 'is-desktop',
  yoga: '#14E1DC',
  fitness: '#F00F64',
  fuze: '#e06b26',
};

function platformStyle(platformClass, style) {
  return `
    #root .${platformClass} & ${style}
  `;
}

export function mobileStyle(style) {
  return platformStyle(platformClasses.mobile, style);
}

export function desktopStyle(style) {
  return platformStyle(platformClasses.desktop, style);
}

export function yogaColor() {
  return platformStyle(platformClasses.yoga);
}

export function fitnessColor() {
  return platformStyle(platformClasses.fitness);
}

