import React, { Component } from 'react';
import { nl2br } from '../../config/util';
import './htmlContent.scss';
const DOMPurify = require('dompurify')(window);

class HtmlContent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { content } = this.props;
        return (
            <div className="htmlContentWrapper" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(nl2br(content)) }}></div>
        );
    }
}

export default HtmlContent;