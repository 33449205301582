import * as constants from './constants';
import { fromJS } from 'immutable';
import { LANGUAGES } from '../../../config/constant';

const defauleState = fromJS({
    type:'',
    region_id:'',
    regions:[],
    language_id:'1',
    languages: LANGUAGES,
    lang: 'en',
    loading:true,
    links:{},
    visible: false,
    chooseRegion: {},
    changeRegionTips: {},
    regionPlaceHolder: 0,
    regionTemporary:0,
    regions1:[],
    unleash_user_id: '',
    // trigger change region via url
    change_region_by_url: null,
});

export default (state = defauleState, action) => {
    switch (action.type) {
        case constants.CHANGE_TYPE:
            return state.set('type', action.value);
        case constants.GET_REGIONS:
            return state.set('regions', action.regions);
        case constants.CHANGE_REGION:
            return state.set('region_id', action.value);
        case constants.CHANGE_LANGUAGE:
            return state.merge({
                'language_id': action.value,
                'lang': state.get('languages').find(item => item.get('id') === parseInt(action.value)).get('name')
            });
        case constants.CHANGE_LOADING:
            return state.set('loading', action.value);
        case constants.CHANGE_LINKS:
            return state.set('links', action.value);
        case constants.CHANGE_REGION_MODEL_INFO:
            return state.merge({
                'visible': action.value.get('visible'),
                'chooseRegion': action.value.get('chooseRegion'),
                'changeRegionTips': action.value.get('changeRegionTips')
            });
        case constants.SET_REGION_TIP_MODEL:
            return state.set('visible', action.value);
        case constants.CHANGE_REGION_PLACEHOLDER:
            return state.merge({
                'regionPlaceHolder': action.value.get('regionPlaceHolder'),
                'regions': action.value.get('regions')
            });
        case constants.CHANGE_REGION_TEMPORARY:
            return state.merge({
                'regionTemporary': action.value.get('regionTemporary'),
                'regions1': action.value.get('regions')
            });
        case constants.CHANGE_UNLEASH_USER_ID:
            return state.set('unleash_user_id', action.value);
        case constants.CHANGE_REGION_BY_URL:
            return state.set('change_region_by_url', action.value);
        default:
            return state;
    }
};