import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    intl,
    getStatusName,
    getMonthDate,
    objSortByDate,
} from '../../config/util';
import {Icon, Select, Popover, Table} from 'antd';
import { isMobile } from 'react-device-detect';
import ListFilter from '../../booking/component/listFilter';
import { changeSignInsOrder } from '../store/actionCreators';
import GetTeacherAvatar from '../../schedule/component/getTeacherAvatar';
import ClassDetails from '../../schedule/component/classDetails/index';
import { flags } from '../../config/unleash';
const { Option } = Select;

class MySignIns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterMonth:[],
            filterYear:[],
            filterYearDefaultValue:'',
            filterMonthDefaultValue:'',
            updateParams: {},
            getSignInParams:{},
        };
    }
    componentWillMount(){
        //获取筛选项列表
        this.getFilterOption();
    }
    componentDidMount(){
        //获取年月筛选项
        let getSignInParams = Object.assign(getMonthDate());
        this.setState({
            getSignInParams
        });
        this.props.getSignList(getSignInParams);

    }
    //年月数组生成
    getFilterOption(time){
        let lang = parseInt(this.props.language_id) === 1 ? 'en' : 'ch';
        let date = time ? new Date(time) : new Date();
        let thisYear = new Date().getFullYear();
        let year = date.getFullYear();
        let month = date.getMonth();
        let monthArr = {
            'en':["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            'ch':["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
        };

        let filterMonth = monthArr[lang].map((item, index) => {
            return { value: index + 1, info: item, checked: month === index };
        });
        let filterYear = [];
        for (let i = 0; i < 5; i++) {
            let info = thisYear - i;
            let value = lang === 'en' ? info : info + '年';
            let checked = i === 0;
            filterYear.push({value, info, checked});
        }
        //antd select组件defaultValue存在设置无效的问题，直接在这里设置好
        this.setState({
            filterMonth,
            filterYear,
            filterYearDefaultValue: year,
            filterMonthDefaultValue: month + 1
        });
    }
    //年份下拉监听
    handleYearChange = (year) => {
        let {getSignInParams, filterMonthDefaultValue} = this.state;
        let filterYear = this.state.filterYear.map(item => {
            item.checked = item.value === year;
            return item;
        });
        let filterYearDefaultValue = filterYear.find(item => item.value == year).info;
        getSignInParams = Object.assign(getMonthDate(filterYearDefaultValue +'/'+ filterMonthDefaultValue+'/01'));
        this.setState({
            filterYearDefaultValue,
            filterYear,
            getSignInParams
        });
        this.props.getSignList(getSignInParams);
    }
    //月份下拉监听
    handleMonthChange = (month) => {
        let {getSignInParams, filterYearDefaultValue} = this.state;
        let filterMonth = this.state.filterMonth.map(item => {
            item.checked = item.value === month;
            return item;
        });
        getSignInParams = Object.assign(getMonthDate(filterYearDefaultValue +'/'+ month+'/01'));
        this.setState({
            filterMonth,
            filterMonthDefaultValue: month,
            getSignInParams
        });
        this.props.getSignList(getSignInParams);
    }
    hidenav(){
            let hidenav = document.getElementById('addNav');
            if (hidenav) {
                hidenav.style.display = "none";
            }
        }
    //更新列表
    updateList = (date) => {
        let {updateParams} = this.state;
        let {language_id, region_id, user} = this.props;
        updateParams = Object.assign({language_id, region_id, jwt: user.get('jwt')}, getMonthDate(date));
        this.setState({
            updateParams
        });
        this.props.getSignList(updateParams);
    }

    //时间展示
    getDateInfo = (dateInfo, language_id) => {
        let date = parseInt(language_id) === 1 ?
        `${dateInfo.week} · ${dateInfo.dayTimeShort} ${dateInfo.month} ${dateInfo.year}`: `${dateInfo.week} · ${dateInfo.value}`;
        return date;
    }

    filter(){
        if (isMobile) {
            return(
                <ListFilter language_id={this.props.language_id} updateList={date => this.updateList(date)} changeOrder={order => this.props.changeOrder(order)}/>
            );
        } else {
            const { language_id, order } = this.props;
            return(
                <div className="filter">
                    <Select value={this.state.filterMonthDefaultValue} style={{ width: 180 }} onChange={this.handleMonthChange} suffixIcon={<Icon style={{fontSize:16}} type="caret-down" />}>
                        {
                            this.state.filterMonth.map(item => <Option value={item.value} key={item.value}>{item.info}</Option>)
                        }
                    </Select>
                    <Select value={this.state.filterYearDefaultValue} style={{ width: 180 }} onChange={this.handleYearChange} suffixIcon={<Icon style={{fontSize:16}} type="caret-down" />}>
                        {
                            this.state.filterYear.map(item => <Option value={item.value} key={item.value}>{item.info}</Option>)
                        }
                    </Select>
                    {/* this.props.getBooking({}, 1, booking_order === 0 ? 1 : 0) */}
                    <div className={`orderBy ${order === 'asc' ? 'asc' : 'desc'}`} onClick={this.props.chageOrder}>
                        {intl('other', order === 'desc' ? 'soonestLast' : 'soonestFirst', language_id)}
                    </div>
                </div>
            );
        }
    }
    getSignList(signList){
        let {language_id, signListLoading, special_flag, mood_flag} = this.props;
        let column = [];
        let data = [];
        let moodCol = { width: '0.1%' };
        if (mood_flag) {
            moodCol = {
                title: intl('schedule', 'mood', language_id),
                dataIndex:'vibe',
                key:'vibe',
                width: '10%',
                align: 'center'
            };
        }
         if (signList) {
            column = [
                {
                    title: intl('schedule','classTime',language_id),
                    dataIndex:'classTime',
                    key:'classTime',
                    width: '8%',
                },
                {
                    dataIndex:'special',
                    key:'special',
                    width: '2%',
                },
                {
                    title: intl('schedule', 'class', language_id),
                    dataIndex:'class',
                    key:'class',
                    width: '18%',
                },
                moodCol,
                {
                    title: intl('schedule', 'location', language_id),
                    dataIndex:'location',
                    key:'location',
                    align: 'center',
                    width: '12%',
                },
                {
                    title: intl('schedule', 'teacher', language_id),
                    dataIndex:'teacher',
                    key:'teacher',
                    align: 'center',
                    className: 'no_wrap',
                    width: '10%',
                },
                {
                    title: intl('schedule', 'status', language_id),
                    dataIndex:'status',
                    key:'status',
                    align: 'center',
                    className: 'no_wrap',
                    width: '5%',
                },
                {
                    title: intl('schedule', 'paymentMethod', language_id),
                    dataIndex:'paymentMethod',
                    key:'paymentMethod',
                    width: '15%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'onlineBooking', language_id),
                    dataIndex:'onlineBooking',
                    key:'onlineBooking',
                    align: 'center',
                    width: '5%',
                    // className: 'break_spaces',
                },
                {
                    title: intl('schedule', 'bookingTime', language_id),
                    dataIndex:'bookingTime',
                    key:'bookingTime',
                    align: 'center',
                    // className: 'no_wrap',
                    width: '8%',
                },
                {
                    title: intl('schedule', 'checkInTime', language_id),
                    dataIndex:'checkInTime',
                    key:'checkInTime',
                    align: 'center',
                    width: '6%',
                },
                {
                    title: intl('schedule', 'paymentId', language_id),
                    dataIndex:'paymentId',
                    key:'paymentId',
                    align: 'center',
                    width: '5%',
                },
            ];
    
            signList.forEach(item => {
                const classInfo = {
                    date:item.get('classtime'),
                    time:item.get('ClassTime'),
                    name: item.getIn(['class','class_type','name']),
                    location: item.getIn(['class','location','name']),
                    teacher: item.getIn(['class','teacher','name']),
                    status: item.get('button_status'),
                    payment_method: item.get('payment_name'),
                    book_method: item.get('booked_from'),
                    booking_id: item.get('booking_id'),
                    class_id: item.getIn(['class', 'class_type', 'id']),
                    category: item.get('category'),
                    waiting_number: item.get('waiting_number'),
                    is_fitness: item.getIn(['class','location','is_fitness']),
                    special: item.getIn(['class', 'special']),
                    special_color: item.getIn(['class','special','color']),
                    special_description: item.getIn(['class', 'special', 'name']),
                    booking_number: item.get('bookingNumber'),
                    sign_in_time: item.get('sign_in_time'),
                    sign_in_time_display: item.get('sign_in_time_display'),
                    payment_id: item.get('payment_id'),
                    vibe: item.getIn(['class', 'mood']),
                    vibe_color: item.getIn(['class', 'mood', 'color']),
                    vibe_name: item.getIn(['class', 'mood', 'name']),
                };

                const x = {
                    key: classInfo.booking_id,
                    classTime: (
                        <div className='classTime'>
                        <span className="date">{classInfo.date}</span>{classInfo.time}
                        </div>
                    ),
                    special:(
                        special_flag && classInfo.special ? <Popover
                            placement="bottom"
                            content={classInfo.special_description}
                            overlayStyle={{ width: '400px', color: classInfo.special_color}}
                            overlayClassName="schedule_popover"
                            arrowPointAtCenter
                        >
                            <span className="line" style={{background: classInfo.special_color}}></span>
                        </Popover>
                        :
                        <span className="line" style={{background: 'lightgrey'}}></span>
                    ),
                    class: (
                            <span className='classTitle'>{classInfo.name}</span>
                    ),
                    vibe: (mood_flag && classInfo.vibe ? <span style={{color: classInfo.vibe_color}}>{classInfo.vibe_name}</span>: <span>-</span>),
                    location: classInfo.location,
                    teacher: classInfo.teacher,
                    status: getStatusName(classInfo.status  , language_id),
                    paymentMethod: classInfo.payment_method,
                    onlineBooking: (
                        <Icon type={['WEB','MOBILE'].includes(classInfo.book_method) ?'check':'close' } />
                    ),
                    bookingTime: classInfo.booking_number,
                    checkInTime: classInfo.sign_in_time_display,
                    paymentId: classInfo.payment_id,
                };

                data.push(x);

                
            });
        }
        return(
            <Table  pagination={false}  columns={column} dataSource={data} loading={signListLoading}/>
        );
    }
    render() {
        let {language_id, signList, signList_by_date, order, mood_flag, special_flag} = this.props;
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let dateList = [];
        if (signList_by_date) {
            dateList = objSortByDate(signList_by_date, order);
        }
        return (
            <div className="signInContent my-service-wrapper">
                {this.hidenav()}
                <div className="components_title">
                    <button className="back" onClick={() => {this.props.goBack();}}>
                        {isMobile ? <i className="iconfont icon-left"></i>
                            :
                            <Icon type="left" style={{fontSize:26,color:'#333'}} />
                        }
                    </button>
                    <span>{intl('other','mySignIns',language_id)}</span>
                </div>
                {this.filter()}
                {!isMobile && this.getSignList(signList)}
                {isMobile && <div className="list">
                        {
                            dateList.map(item =>
                                <div className="signInItem" key={item}>
                                    <h4 className="date">{this.getDateInfo(signList_by_date[item].dateInfo, language_id)}</h4>
                                    <ul>
                                        {
                                            signList_by_date[item].list.map(it =>{
                                                let buttonItem = {
                                                    booking_id: it.booking_id,
                                                    button_status: it.button_status,
                                                    id: it.class.id,
                                                    sector: it.sector,
                                                    waiting_number: it.waiting_number,
                                                    view_url: item.view_url,
                                                };
                                                let teacherAvatarItem = {
                                                    id:it.class.id,
                                                    teacherId:it.class.teacher.id,
                                                    teacherAvatar:it.class.teacher.image,
                                                    mood:it.class.mood,
                                                    vaccinatedClass:it.class.is_vaccinated,
                                                    special:it.class.special,
                                                };
                                                return(
                                                <div key={it.booking_id} className="class_detail_wrapper">
                                                    <ClassDetails
                                                    key={it.booking_id}
                                                    item={it.class}
                                                    jwt={jwt}
                                                    button_item={buttonItem}
                                                    location_id={it.class.location.id}
                                                    teacher_avatar={teacherAvatarItem}
                                                    sector={it.sector}
                                                    showVibe={mood_flag}
                                                    showSpecial={special_flag}
                                                    >
                                                    </ClassDetails>
                                                    <div className="class_detail_overlay"></div>
                                                </div>
                                                );
                                            }
                                            )
                                        }
                                    </ul>
                                </div>
                            )
                        }
                        {/* { signIns.size === 0 && <NoData /> } */}
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.getIn(['main','user']),
        language_id: state.getIn(['header','language_id']),
        region_id: state.getIn(['header','region_id']),
        order: state.getIn(['profile', 'order']),
        signListLoading: state.getIn(['profile', 'signListLoading']),
        mood_flag: state.getIn(['main', 'flags', flags.mood]),
        special_flag: state.getIn(['main', 'flags', flags.specialClass]),
    };
};

const mapDispathToProps = (dispatch) => ({
    //修改排序
    changeOrder(order){
        dispatch(changeSignInsOrder(order));
    },
});

export default connect(mapStateToProps, mapDispathToProps)(MySignIns);