export const SAVE_CLIENT_INFO = 'profile/SAVE_CLIENT_INFO';
export const CHANGE_CLIENT_INFO = 'profile/CHANGE_CLIENT_INFO';
export const CHANGE_LOADING = 'profile/CHANGE_LOADING';
export const SAVE_SIGN_LIST_PAGE = 'profile/SAVE_SIGN_LIST_PAGE';
export const SAVE_SIGN_HISTORY = 'profile/SAVE_SIGN_HISTORY';
export const SAVE_SIGN_HISTORY_BY_DATE = 'profile/SAVE_SIGN_HISTORY_BY_DATE';
export const SAVE_SIGN_HISTORY_ALL = 'profile/SAVE_SIGN_HISTORY_ALL';
export const CHANGE_LIST_ORDER = 'profile/CHANGE_LIST_ORDER';
export const CHANGE_PROFILE_SCENE = 'profile/CHANGE_PROFILE_SCENE';
export const Save_Client_SERVICES = 'profile/Save_Client_SERVICES';






