import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeMoudle, handleMoudleChange } from '../../../common/main/store/actionCreators';
import { actionCreators as bookingActionCreators } from '../../store';
import ListFilter from '../listFilter';
// import NoData from 'com/noData';
import {
    getMonthDate,
    intl,
    objSortByDate,
} from '../../../config/util';
import { isMobile } from 'react-device-detect';
import GetTeacherAvatar from '../../../schedule/component/getTeacherAvatar';
import ClassDetails from '../../../schedule/component/classDetails/index';
import './myCancelltions.scss';
import { flags } from '../../../config/unleash';

let teacherDefaultAvatar = require('../../../assets/images/ic_default_image.png');

class MyCancellations extends Component {
    ref = React.createRef();
    previousHeaderHeight = '';

    constructor(props) {
        super(props);
        this.state = { 
            tab: 'cancelRecord',
            updateParams: {},
         };
    }

    componentDidUpdate() {
        if (isMobile) {
            let currentHeaderHeight = this.ref.current.clientHeight;
            const scrollList = document.getElementById("scrollList");

            if (scrollList) {
                scrollList.style.height = `calc(100% - ${currentHeaderHeight}px)`;
            }
        }
    }

    componentWillMount() {
        //获取预约记录
        let {language_id, region_id, user} = this.props;
        let updateParams = Object.assign({language_id, region_id, jwt: user.get('jwt')}, getMonthDate());
        this.setState({
            updateParams
        });
        //获取数据
        this.props.getMyCancellations(updateParams.start_date, updateParams.end_date);
    }

    bookBtn = (status, language_id) => {
        switch (status) {
            case 1:
                return intl('schedule', 'book', language_id);
            case 2:
                return intl('schedule', 'booked', language_id);
            case 3:
                return intl('schedule', 'WaitList', language_id);
            case 4:
                return intl('schedule', 'inWaitList', language_id);
            case 5:
                return intl('schedule', 'full', language_id);
            case 7:
                return intl('schedule', 'signedIn', language_id);
            case 8:
                return intl('schedule', 'lateCancel', language_id);
            case 9:
                return intl('schedule', 'absent', language_id);
            case 10:
                return intl('schedule', 'complete', language_id);
            case 11:
                return intl('schedule', 'completed', language_id);
            case 12:
                return intl('schedule', 'cancelled', language_id);
            default:
                return '';
        }
    }

    //mobile
    additionalNav = () => {
        // const isActive = this.state.isActive;
        let { tab } = this.state;

        if (isMobile) {
            return(
                <ul className="nav" id="addNav" ref={this.ref}>
                    <div>
                        <button className="back" onClick={this.props.back}><i className="iconfont icon-left"></i></button>
                    </div>
                    <div className="navbar-nav">
                        <li onClick={this.showMyBookings}>
                            <a className={`${tab === 'myClassSchedule' ? 'active' : ''}`} onClick={() => { this.setState({ tab: 'myClassSchedule' }); this.props.changeMoudle('Booking'); }}>
                                {intl('schedule','myClassSchedule', this.props.language_id)}
                            </a>
                        </li>
                        <li onClick={this.showCancelList}>
                            <a className={`${tab === 'cancelRecord' ? 'active' : ''}`} onClick={() => { this.setState({ tab: 'cancelRecord' }); this.props.changeMoudle('MyCancellations'); }}>
                                {intl('schedule','myCancellations', this.props.language_id)}
                            </a>
                        </li>
                    </div>
                </ul>
            );
        }
    }

    //时间展示
    getDateInfo = (dateInfo, language_id) => {
        let date = parseInt(language_id) === 1 ?
        `${dateInfo.week} · ${dateInfo.dayTimeShort} ${dateInfo.month} ${dateInfo.year}`: `${dateInfo.week} · ${dateInfo.value}`;
        return date;
    }

    //更新列表
    updateList = date => {
        let { updateParams } = this.state;
        updateParams = Object.assign(updateParams, getMonthDate(date));
        this.props.getMyCancellations(updateParams.start_date, updateParams.end_date);
    }

    //获取名字
    getName = (data, language_id) => {
        return Array.isArray(data.detail) ? data.detail.find(item => item.language_id === parseInt(language_id)).name:data.name;
    }
    render() {
        let { cancelList_by_date, language_id, booking_order, mood_flag, special_flag} = this.props;
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let dateList = [];
        if (cancelList_by_date) {
            dateList = objSortByDate(cancelList_by_date, booking_order);
        }
        return (
            <div className="myCancellations">
                {this.additionalNav()}
                <div className="scroll_list" id="scrollList">
                    <ListFilter language_id={language_id} updateList={date => this.updateList(date)} changeOrder={order => this.props.changeCancellationsOrder(order)}/>
                    <div className="list">
                        {
                            dateList.map(item =>
                                <div className="item" key={item} >
                                    <h4 className="date">{this.getDateInfo(cancelList_by_date[item].dateInfo, language_id)}</h4>
                                    <ul>
                                        {
                                            cancelList_by_date[item].list.map(it =>{
                                                let buttonItem = {
                                                    booking_id: it.booking_id,
                                                    button_status: it.button_status,
                                                    id: it.class.id,
                                                    sector: it.sector,
                                                    waiting_number: it.waiting_number,
                                                    view_url: it.view_url,
                                                };
                                                let teacherAvatarItem = {
                                                    id:it.class.id,
                                                    teacherId:it.class.teacher.id,
                                                    teacherAvatar:it.class.teacher.image,
                                                    mood:it.class.mood,
                                                    vaccinatedClass:it.class.is_vaccinated,
                                                    special:it.class.special,
                                                };
                                                return(
                                                    <div key={it.booking_id} className="class_detail_wrapper">
                                                        <ClassDetails
                                                            item={it.class}
                                                            jwt={jwt}
                                                            // button_status={it.button_status}
                                                            button_item={buttonItem}
                                                            key={it.booking_id}
                                                            location_id={it.class.location.id}
                                                            teacher_avatar={teacherAvatarItem}
                                                            sector={it.sector}
                                                            showVibe={mood_flag}
                                                            showSpecial={special_flag}
                                                        >
                                                        </ClassDetails>
                                                        <div onClick={()=>this.props.toDetails(it)} className="class_detail_overlay"></div>
                                                    </div>
                                                );
                                            }
                                            )
                                        }
                                    </ul>
                                </div>
                            )
                        }
                        {/* { cancellations.length === 0 && <NoData /> } */}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.getIn(['main','user']),
        language_id: state.getIn(['header','language_id']),
        region_id: state.getIn(['header','region_id']),
        cancelList_by_date: state.getIn(['booking', 'cancelList_by_date']).toJS(),
        booking_order: state.getIn(['booking', 'booking_order']),
        mood_flag: state.getIn(['main', 'flags', flags.mood]),
        special_flag: state.getIn(['main', 'flags', flags.specialClass]),
    };
};

const mapDispathToProps = (dispatch) => ({
    //内容tab切换
    changeMoudle(module){
        dispatch(handleMoudleChange(module));
        if (module === 'Booking') {
            if (isMobile) {
                let hideFooter = document.getElementById("footerNav");
                hideFooter.style.display = "none";
            }
            dispatch(bookingActionCreators.changeShowBookList(true));
        }
    },
    //返回
    back(){
        let hideFooter = document.getElementById("footerNav");
        hideFooter.style.display = "block";
        dispatch(handleMoudleChange('Schedule'));
    },
    //删除历史
    getMyCancellations(start_date, end_date) {
        dispatch(bookingActionCreators.getCancelList({ start_date, end_date, language_id: this.language_id, region_id: this.region_id, jwt: this.user.get('jwt')}, '-1'));
    },
    //修改排序
    changeCancellationsOrder(order){
        dispatch(bookingActionCreators.changeCancellationsOrder(order));
    },
    //详情页
    toDetails(data){
        // dispatch(bookingActionCreators.changeScene('cancelDetails'));
        dispatch(handleMoudleChange('CancelDetails'));
        dispatch(bookingActionCreators.changeCancelDetails(data));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(MyCancellations);