import React, { Component, useEffect } from 'react';
import { Icon, Avatar, Button, Select, Input, Checkbox, Modal, Popover } from 'antd';
import { Route, Switch } from "react-router-dom";
import { Base64 } from 'js-base64';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { LoginMain, LoginBefore, LoginAfter, LoginForm, LoginBtn, DownloadBtn, Nav, NavItem } from './style';
import { actionCreators } from './store';
import { actionCreators as profileActionCreators } from '../../profile/store';
import { actionCreators as bookingActionCreators } from '../../booking/store';
import { intl } from '../../config/util';
import Schedule from '../../schedule';
import Booking from '../../booking';
import Profile from '../../profile';
import locationAlerts from '../../schedule/component/locationAlerts';
import myCancellations from '../../booking/component/myCancellations';
import cancelDetails from '../../booking/component/cancelDetails';
import MyFavourite from '../../profile/component/MyFavourite';
import { apiList } from '../../config/index';
import { trial_link } from '../../config/link';
import { isMobile } from 'react-device-detect';
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { flags as unleashFlags } from '../../config/unleash';

const Option = Select.Option;
const confirm = Modal.confirm;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chooseRegionId:'',
            language_id: localStorage.getItem("language_id")
        };
        props.initSetting();
    }
    getQueryVariable(variable)
    {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {return pair[1];}
        }
        return(false);
    }
    componentDidMount() {
        //Remember Me处理
        if (!this.props.isLogin && localStorage.getItem('u') && localStorage.getItem('p')) {
            this.props.handleStayLoggin(true);
            this.props.addUserForm({
                username: Base64.decode(localStorage.getItem('u')),
                password: Base64.decode(localStorage.getItem('p')),
            });
        }
        var region_id = localStorage.getItem('region_id')?localStorage.getItem('region_id'):1;
        // var region_id = Config.region;

        var location_ids = this.getQueryVariable('location_ids');
        if (location_ids) {
            localStorage.setItem('location_id',location_ids);
        }
        this.setState({
            chooseRegionId: parseInt(region_id)
        });

    }

    componentDidUpdate(prevProps){
        // if(this.props.regionPlaceHolder > 0 && this.props.regionPlaceHolder !== prevProps.regionPlaceHolder ){
        //     this.setState({
        //         chooseRegionId: this.props.regionPlaceHolder
        //     })
        // }
        if (this.props.regionTemporary > 0 && this.props.regionTemporary !== prevProps.regionTemporary ) {
            this.setState({
                chooseRegionId: this.props.regionTemporary
            });
        }

        // if (this.props.unleash_user_id !== prevProps.unleash_user_id) {
        //     this.props.updateContext({
        //         userId: this.props.unleash_user_id,
        //     });
        // }

    }

    //app二维码
    getQrcodePop (region_id) {
        if (parseInt(region_id) === 4) {
            let android = require('../../assets/images/android_pure_lifestyle_china.png');
            let ios = require('../../assets/images/ios_pure_360_lifestyle_china.png');
            return (
                <div className="qrcode_list">
                    <div className="qrcode_item">
                        <img width="120" height="120" src={android} alt="" />
                        <p>Android</p>
                    </div>
                    <div className="qrcode_item">
                        <img width="120" height="120" src={ios} alt="" />
                        <p>Ios</p>
                    </div>
                </div>
            );
        } else {
            let android = require('../../assets/images/android_pure_lifestyle_international.png');
            let ios = require('../../assets/images/ios_pure_360_lifestyle.png');
            return (
                <div className="qrcode_list">
                    <div className="qrcode_item">
                        <img width="120" height="120" src={android} alt="" />
                        <p>Android</p>
                    </div>
                    <div className="qrcode_item">
                        <img width="120" height="120" src={ios} alt="" />
                        <p>Ios</p>
                    </div>
                </div>
            );
        }
    }
    //Sign Up for a Trial
    openTrialLink() {
        let { type, regions, region_id } = this.props;
        window.open(trial_link(type, region_id));
    }

    //Forget password
    openPasswordLink () {
        let {region_id, language_id } = this.props;
        let link = link;
                if (region_id  == 4) {
                    link = `${apiList.cn_forget_pw}?langauge_id=${language_id}&region_code=CN`;
                } else {
                    link = `${apiList.default_forget_pw}?region_code=${region_id == 2 ? 'SG' : 'HK'}&language_id=${language_id}`;
                }
        window.open(link);
    }
    //选择地区
    handleRegionSelect = (value)=>{
        this.setState({ chooseRegionId: value });
    }

    //inner Navigation
    desktopNav = () => {
        if (!isMobile) {
            return(
                <Nav className="nav">
                    <NavItem className={this.props.moudle === 'Schedule' ? 'active': ''} onClick={() => { this.props.changeMoudle('Schedule'); }}>
                        {intl('schedule', 'schedule', this.props.language_id)}
                    </NavItem>
                    <NavItem className={this.props.moudle === 'Booking' ? 'active' : ''} onClick={() => { this.props.changeMoudle('Booking'); }}>
                        {intl('schedule', 'myClassSchedule', this.props.language_id)}
                    </NavItem>
                    <NavItem className={this.props.moudle === 'Favourite' ? 'active' : ''} onClick={() => { this.props.changeMoudle('Favourite'); }}>
                        {intl('other', 'favourite', this.props.language_id)}
                    </NavItem>
                    <NavItem className={this.props.moudle === 'Profile' ? 'active' : ''} onClick={() => { this.props.changeMoudle('Profile'); }}>
                        {intl('schedule', 'myProfile', this.props.language_id)}
                    </NavItem>
                </Nav>
            );
        }
    }
    //login Navigation
    loginNav = () => {
        if (!isMobile) {
            return(
                <LoginBefore className="login_before">
                    <div className="content">
                        <DownloadBtn>
                            <Popover content={this.getQrcodePop(this.props.region_id)} overlayClassName="qrcode_popover">
                                <a href={this.props.links.size > 0 ? this.props.links.get('app_download') : '/'} rel="noopener noreferrer" target="_blank">
                                    <Avatar shape="square" size={64} src={require('../../assets/images/app_logo.png')} icon="user" />
                                </a>
                            </Popover>
                        </DownloadBtn>
                        <LoginBtn onClick={this.props.handleShowLogin.bind(this,this.props.staylogin)} className="login_btn">
                            <font>{intl('login', 'loginDesktop', parseInt(this.props.language_id))}</font>
                            <Icon type="caret-right" theme="outlined" />
                        </LoginBtn>
                    </div>
                </LoginBefore>
            );
        }
    }


    render() {
        const state = { ...this.props };
        //默认语言
        let langField = state.language_id ? state.languages.find(item => item.get('id') === parseInt(state.language_id)).get('name') : 'en';
        //默认地址
        let regions = state.regions1.size > 0 ? state.regions1 : [];
        let rid = state.regionTemporary > 0 ? state.regionTemporary : parseInt(state.region_id);
        let regionSelectPlaceholder = regions.find(item => item.get('id') === rid);
        regionSelectPlaceholder = regionSelectPlaceholder ? regionSelectPlaceholder.getIn(['names', langField]) : '';

        return (
            <LoginMain style={{ minHeight: window.innerHeight - 70 + 'px' }} id="main">
                {state.isLogin ?
                    <LoginAfter>
                        <div className="status_top">
                            <div className="left">{intl('login','name',state.language_id)}　•　{intl('login','onlineBookingSystem',state.language_id)}</div>
                            <div className="right">
                                <Popover content={this.getQrcodePop(state.region_id)} overlayClassName="qrcode_popover">
                                    <button className="btn">
                                        {intl('other','downloadApp',state.language_id)}
                                    </button>
                                </Popover>
                                <button className="btn" onClick={() => { this.props.changeMoudle('Profile'); }}>{state.user.get('username') || ''}</button>
                                <button className="btn" onClick={() => { state.showConfirm(); }}>{intl('login', 'sign_out', state.language_id)}</button>
                            </div>
                        </div>
                        {this.desktopNav()}
                    </LoginAfter>
                    :
                        <div>
                            {this.loginNav()}
                        </div>
                }
                <LoginForm>
                    <div className={state.showLogin ? 'shadow show' : 'shadow'}>
                        <div className={state.showLogin ? 'shadow_content show' : 'shadow_content'}>
                            <div className="close_shadow" onClick={state.handleCloseLogin.bind(this,this.props.staylogin)}></div>
                            <CSSTransition
                                in={state.showLogin}
                                timeout={1000}
                                classNames="slide"
                            >
                                <div className="login_box">
                                    <div className="title">{intl('login','onlineBookingSystem',parseInt(state.language_id))}</div>
                                    <form action="" className="login_form">
                                        <div className="input_item">
                                            <Select value={this.state.chooseRegionId} placeholder={regionSelectPlaceholder} onChange={this.handleRegionSelect} suffixIcon={<Icon type="caret-down" style={{color:'#BFBFBF',fontSize:18}}/>}>
                                                {
                                                    state.regions.map((item, index) => {
                                                        if (state.regions.size > 0) {
                                                            return <Option value={item.get('id')} key={item.get('id')}>{item.getIn(['names', langField])}</Option>;
                                                        } else {
                                                            return <Option value={item.get('id')}>{intl('other', 'noData', this.props.language_id)}</Option>;
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        <div className="input_item">
                                            <Input
                                                placeholder="User email"
                                                onChange={state.handleGetUserName.bind(this)}
                                                value={this.props.username}
                                            />
                                        </div>
                                        <div className="input_item">
                                            <Input
                                                type="password"
                                                placeholder="Password"
                                                autoComplete='password'
                                                onChange={state.handleGetPassword.bind(this)}
                                                value={this.props.password}
                                                onPressEnter={state.handleLogin.bind(this, state, this.state.chooseRegionId ? this.state.chooseRegionId : this.props.region_id)}
                                            />
                                        </div>
                                        <div className="submit">
                                            <Button  onClick={state.handleLogin.bind(this, state, this.state.chooseRegionId ? this.state.chooseRegionId : this.props.region_id)} >
                                                {intl('login', 'sign_in', this.props.language_id)}<Icon type="caret-right" style={{color:'#fff',fontSize:24,marginLeft:0}} />
                                            </Button>
                                        </div>
                                        <div className="stay_loggin">
                                            <Checkbox onChange={state.changeStayLoggin} checked={state.staylogin}> {intl('login', 'stayLogin', this.props.language_id)}</Checkbox>
                                        </div>
                                        <div className="link">
                                            <div className="forget" onClick={this.openPasswordLink.bind(this)}>
                                                {intl('login', 'forget_password', this.props.language_id)}
                                            </div>

                                            <div className="forget" onClick={this.openTrialLink.bind(this)}>
                                                {intl('login', 'sign_up', this.props.language_id)}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                </LoginForm>
                <Switch>
                    {this.props.moudle === 'Schedule' && <Route exact path="/:lang?/:region?" component={Schedule} />}
                    {this.props.moudle === 'Booking' && <Route exact path="/:lang?/:region?" component={Booking} />}
                    {this.props.moudle === 'Profile' && <Route exact path="/:lang?/:region?" component={Profile} />}
                    {this.props.moudle === 'LocationAlerts' && <Route exact path="/:lang?/:region?" component={locationAlerts} />}
                    {this.props.moudle === 'MyCancellations' && <Route exact path="/:lang?/:region?" component={myCancellations} />}
                    {this.props.moudle === 'CancelDetails' && <Route exact path="/:lang?/:region?" component={cancelDetails} />}
                    {this.props.moudle === 'Favourite' && <Route exact path="/:lang?/:region?" component={MyFavourite} />}
                </Switch>
            </LoginMain>
        );

    }
}




const mapStateToProps = (state) => {
    return {
        isLogin: state.getIn(['main','isLogin']),
        showLogin: state.getIn(['main','showLogin']),
        username: state.getIn(['main', 'username']),
        password: state.getIn(['main', 'password']),
        staylogin: state.getIn(['main', 'staylogin']),
        languages: state.getIn(['header', 'languages']),
        region_id: state.getIn(['header', 'region_id']),
        language_id: state.getIn(['header', 'language_id']),
        regions: state.getIn(['header','regions']),
        user: state.getIn(['main', 'user']),
        moudle: state.getIn(['main', 'activeMoudle']),
        links: state.getIn(['header', 'links']),
        type: state.getIn(['header', 'type']),
        regionPlaceHolder: state.getIn(['header', 'regionPlaceHolder']),
        regionTemporary: state.getIn(['header', 'regionTemporary']),
        location_ids: state.getIn(['schedule', 'location_ids']),
        location_list: state.getIn(['schedule', 'location_list']),
        regions1: state.getIn(['header','regions1']),
        unleash_user_id: state.getIn(['header','unleash_user_id']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //初始化
    initSetting(){
        dispatch(actionCreators.initSetting());
    },
    //获取地址
    getRegion(){
        dispatch(actionCreators.getRegions());
    },
    //内容tab切换
    changeMoudle(module){
        if (module === 'Profile') {
            if (isMobile){
                let hideFooter = document.getElementById("footerTop");
                hideFooter.style.display = "none";
            }
            dispatch(profileActionCreators.changeProfileScene('myProfile'));
        }
        if (module === 'Booking') {
            if (isMobile){
                let hideFooter = document.getElementById("footerNav");
                hideFooter.style.display = "none";
            }
            dispatch(bookingActionCreators.changeShowBookList(true));
        }
        dispatch(actionCreators.handleMoudleChange(module));
    },
    //loginOut confirm
    showConfirm() {
        let title, content, okText, cancelText;
        switch (parseInt(this.language_id)) {
            case 2 || 4:
                title = '等一下';
                content = '確定要退出嗎?';
                okText = '確定';
                cancelText = '取消';
                break;
            case 3:
                title = '等一下';
                content = '确定要退出吗?';
                okText = '确定';
                cancelText = '取消';
                break;
            default:
                title = 'Wait!';
                content = 'Are you sure you want to Logout?';
                okText = 'Yes';
                cancelText = 'No';
                break;
        }
        confirm({
            title,
            okText,
            cancelText,
            content,
            className:'logOutConfirm',
            onOk:()=>{
                this.handleLoginOut();
            }
        });
    },
    //退出登录
    handleLoginOut(){
        dispatch(actionCreators.signOut());
    },
    //显示登录框
    handleShowLogin(staylogin){
        if (!staylogin) {
            localStorage.removeItem('u');
            localStorage.removeItem('p');
            dispatch(actionCreators.listenUserName(''));
            dispatch(actionCreators.listenPassword(''));
        }
        dispatch(actionCreators.showLogin());
    },
    //关闭登录框
    handleCloseLogin(staylogin){
        if (!staylogin) {
            localStorage.removeItem('u');
            localStorage.removeItem('p');
            dispatch(actionCreators.listenUserName(''));
            dispatch(actionCreators.listenPassword(''));
        }
        dispatch(actionCreators.closeLogin());
    },
    //登录
    handleLogin(formData,id){
        dispatch(actionCreators.login(formData, id, this.props.regions, this.props.location_list));
    },
    //staylogin添加保存的密码
    addUserForm(e){
        dispatch(actionCreators.listenUserName(e.username));
        dispatch(actionCreators.listenPassword(e.password));
    },
    //登录监听
    handleGetUserName(e){
        dispatch(actionCreators.listenUserName(e.target.value));
    },
    handleGetPassword(e){
        dispatch(actionCreators.listenPassword(e.target.value));
    },
    changeStayLoggin(e) {
        dispatch(actionCreators.listenStayLogin(e.target.checked));
    },
    //记住登录
    handleStayLoggin(status) {
        dispatch(actionCreators.listenStayLogin(status));
    },
    //获取用户信息和登录状态
    getUserInfo(data) {
        if (data && JSON.parse(data).id){
            dispatch(actionCreators.changeUserInfo(data));
        }
    },
    setUnleashFlags(key, value) {
        dispatch(actionCreators.changeUnleashFlag(key, value));
    }
});

const MainHOC = (props) => {
    const updateContext = useUnleashContext();
    // const mood = useFlag(unleashFlags.mood);
    // const special = useFlag(unleashFlags.specialClass);
    // const test = useFlag(unleashFlags.testingFlag);
    const flags = {
        // mood: mood,
        // special: special,
        // test: test,
    };
    for (const x of Object.keys(unleashFlags)) {
        flags[x] = useFlag(unleashFlags[x]);
    }
    useEffect(() => {
        if (props.unleash_user_id) {
            // context is updated with userId
            updateContext({
                userId: props.unleash_user_id,
            });
        }
    },[props.unleash_user_id]);

    for (const x of Object.keys(unleashFlags)) {
        useEffect(() => {
            props.setUnleashFlags(unleashFlags[x], flags[x]);
        }, [flags[x]]);
    }

    return (<Main { ...props } updateContext={updateContext} flags={flags}></Main>);
};
export default connect(mapStateToProps, mapDispathToProps)(MainHOC);