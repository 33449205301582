import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { Select, Icon, Divider, Button } from 'antd';
import { intl } from '../../config/util';
import { commonConfig } from '../../config';
import { REGION } from '../../config/constant';
import { StarFilled } from '@ant-design/icons';
import { DesktopLocationListContent } from './selectStyle';

const Option = Select.Option;
let filterType;
class SelectItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            listOne: true,
            select_val: [],
            disabled_list: [],//地点超出三个禁止筛选
            location_screen: '',//地点筛选
            location_names: [],
            locationNum: ''
        };

    }

    getWeekType = (weekType, language_id) => {
        let type = '';
        switch (weekType) {
            case 1:
                type = 'oneWeek';
                break;
            case 2:
                type = 'nextWeek';
                break;
            default:
                type = 'nextSevenDays';
                break;
        }
        return intl('schedule', type, language_id);
    }


    componentWillReceiveProps() {
        const { region_id } = this.props;
        const currentRegion = REGION[region_id];
        //地点筛选禁用下标
        if (this.props.type == 'location_ids' && this.state.listOne) {

            if (this.props.list && this.props.list.length != 0) {
                this.setState({
                    listOne: false
                });
            }
            var location_id = localStorage.getItem('location_id') ? localStorage.getItem('location_id').split(',') : [];
            var defaultval = [];
            var disabled_list = [];
            var location_names = [];
            for (var i = 0; i < this.props.list.length; i++) {
                if (location_id.length >= commonConfig.location_limit[currentRegion]) {
                    disabled_list[i] = true;
                } else {
                    disabled_list[i] = false;
                }
                // for(var t=0;t<location_id.length;t++){
                //     if(parseInt(this.props.list[i].id) === parseInt(location_id[t])){
                //         console.log(location_id,this.props.list[i].short_name[this.props.lang])
                //          defaultval.push({
                //              key:this.props.list[i].id.toString()
                //          })
                //          location_names.push(this.props.list[i].short_name[this.props.lang])
                //         disabled_list[i] = false;
                //     }
                // }

            }

            for (var a = 0; a < location_id.length; a++) {
                for (var b = 0; b < this.props.list.length; b++) {
                    if (parseInt(this.props.list[b].id) === parseInt(location_id[a])) {

                        defaultval.push({
                            key: this.props.list[b].id.toString()
                        });
                        location_names.push(this.props.list[b].short_name[this.props.lang]);
                        disabled_list[b] = false;
                    }
                }
            }

            this.setState({
                disabled_list: disabled_list,
                select_val: defaultval,
                location_names: location_names,
            });
            this.props.locationName(location_names);
        }


    }

    //地点筛选
    locationScreen = (value, option) => {
        const { region_id } = this.props;
        const currentRegion = REGION[region_id];
        var disabled_list = this.state.disabled_list;
        var opt_key = [];
        var location_names = [];
        for (var i = 0; i < this.props.list.length; i++) {
            if (option.length >= commonConfig.location_limit[currentRegion]) {
                disabled_list[i] = true;
            } else {
                disabled_list[i] = false;
            }
            for (var t = 0; t < option.length; t++) {

                if (Number(this.props.list[i].id) == Number(option[t].key)) {
                    disabled_list[i] = false;
                }
            }
        }

        for (var t = 0; t < option.length; t++) {
            opt_key.push(option[t].key);
            location_names.push(option[t].props.title);
        }
        var val = opt_key.join(',');
        this.setState({
            disabled_list,
            location_screen: val,
            location_names,
            locationNum: option.length,
        });
    }

    locationSub = () => {
        this.props.handleChange(this.state.location_screen, this.state.location_names);
        this.props.locationName(this.state.location_names);
    }

    // filterType = (type) => {
    //     if ( type === 'location_ids' ){
    //         return(
    //             <div className="select_tag_box">
    //                 {this.props.location_num > 1 || this.props.location_num == 0 ? <div className="location_tips">{intl('other', 'locationTips', this.props.language_id)}</div> : null}

    //                 <Select
    //                     mode={this.props.mode ? this.props.mode : ''}
    //                     style={style}
    //                     onFocus={() => this.props.handleFocus(type)}
    //                     onChange={this.locationScreen}
    //                     filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    //                     weeks={weeks ? weeks : null}
    //                     jwt={jwt}
    //                     key={this.state.select_val}
    //                     defaultValue={this.state.select_val}
    //                     labelInValue
    //                     tokenSeparators={[',']}
    //                     suffixIcon={<Icon type="caret-down" style={{ color: '#999', fontSize: 16 }} />}

    //                     dropdownRender={menu => (

    //                         // console.log(this.props.language_id)
    //                         <div>
    //                             {menu}
    //                             <Divider style={{ margin: '4px 0' }} />
    //                             <div style={{ display: 'flex', padding: '0 15px', justifyContent: 'center' }}>
    //                                 <Button onClick={this.locationSub} onMouseDown={e => e.preventDefault()} type="primary" style={{ background: red == 'Y' ? '#2378bd' : '#ED1C24', margin: '10px ',border: red == 'Y' ? '#2378bd' : '#ED1C24'}}>
    //                                     {intl('model', 'confirm', this.props.language_id)}
    //                                 </Button>

    //                             </div>
    //                         </div>
    //                     )}
    //                 >
    //                     {

    //                         list.map((item, index) => {

    //                             return <Option title={item.short_name[lang]} value={item.id.toString()} key={item.id.toString()} disabled={this.state.disabled_list[index]}>{item.short_name[lang]}</Option>;
    //                         })
    //                     }
    //                 </Select>
    //             </div>
    //         );
    //     } else if ( type === 'mood_ids') {
    //         return(

    //         );
    //     }
    // }
    getValue = (value) =>{
        let {type, class_mood_list, lang} = this.props;
        if (type === 'mood_ids') {
            for (var i = 0; i < class_mood_list.length; i++) {
                if (class_mood_list[i].id === value) {
                    return (
                        <div style={{color:class_mood_list[i].color}}>{class_mood_list[i].names[lang]}</div>
                    );
                }
            }
        } else {
            return value;
        }
    };
    // filter.get(type) ? type === 'filter_type' ? filter.get(type) : parseInt(filter.get(type)) : placeholder

    render() {
        let list = this.props.list || [];
        let nameIndex = ['names'];
        if (this.props.language_id) {
            switch (parseInt(this.props.language_id)) {
                case 2:
                    nameIndex.push('zh-hk');
                    break;
                case 3:
                    nameIndex.push('zh-cn');
                    break;
                case 4:
                    nameIndex.push('zh-tw');
                    break;
                default:
                    nameIndex.push('en');
                    break;
            }
        }
        let newPlaceholder = list.size > 0 ? list.find((item, index) => index === 0).get('names') : 0;
        if (this.props.weeks && this.props.type === 'week') {
            if (this.props.weeks.size > 0) {
                let newList = this.props.weeks.toJS();
                list = [
                    newList.thisWeek,
                    newList.nextWeek,
                    newList.thisDay
                ];
            }
        }

        let { red, style, placeholder, type, filter, weeks, jwt, language_id, lang, region_id } = this.props;

        // if(this.props.type == 'location_ids'){
        //     console.log(list)
        // }
        const currentRegion = REGION[region_id];

        return (
            type === 'location_ids' ?
                <div className="select_tag_box">

                    {this.props.location_num > 1 || this.props.location_num == 0 ? <div className="location_tips">{intl('other', 'locationTips', this.props.language_id, { num: commonConfig.location_limit[currentRegion] })}</div> : null}

                    <Select
                        mode={this.props.mode ? this.props.mode : ''}
                        style={style}
                        onFocus={() => this.props.handleFocus(type)}
                        onChange={this.locationScreen}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        weeks={weeks ? weeks : null}
                        jwt={jwt}
                        key={this.state.select_val}
                        defaultValue={this.state.select_val}
                        labelInValue




                        tokenSeparators={[',']}
                        suffixIcon={<Icon type="caret-down" style={{ color: '#999', fontSize: 16 }} />}

                        dropdownRender={menu => (

                            // console.log(this.props.language_id)
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', padding: '0 15px', justifyContent: 'center' }}>
                                    <Button
                                        onClick={this.locationSub}
                                        onMouseDown={e => e.preventDefault()}
                                        type="primary"
                                        disabled={this.state.locationNum == 0 ? true : false}
                                        style={{ background: red == 'Y' ? '#14E1DC' : '#F00F64', margin: '10px ', border: red == 'Y' ? '#14E1DC' : '#F00F64' }}
                                    >
                                        {intl('model', 'confirm', this.props.language_id)}
                                    </Button>

                                </div>
                            </div>
                        )}
                    >
                        {

                            list.map((item, index) => {
                                // return <Option title={item.short_name[lang]} value={item.id.toString()} key={item.id.toString()} disabled={this.state.disabled_list[index]}>{item.short_name[lang]}</Option>;

                                return <Option title={item.short_name[lang]} value={item.id.toString()} key={item.id.toString()} disabled={this.state.disabled_list[index]}>
                                    <DesktopLocationListContent>
                                        <span className="content">
                                            {item.short_name[lang]}
                                        </span>
                                        {item.is_favourite && <StarFilled className='favourite-icon' style={{ color: '#ffaC33' }} />}
                                    </DesktopLocationListContent>
                                </Option>;
                            })
                        }
                    </Select>
                </div>

                :
                <Select
                    mode={this.props.mode ? this.props.mode : ''}
                    showSearch
                    style={style}
                    placeholder={placeholder ? placeholder : newPlaceholder}
                    optionFilterProp="children"
                    onFocus={() => this.props.handleFocus(type)}
                    onChange={this.props.handleChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filter={filter}
                    weeks={weeks ? weeks : null}
                    jwt={jwt}
                    value={this.getValue(filter.get(type) ? type === 'filter_type' ? filter.get(type) : parseInt(filter.get(type)) : placeholder)}
                    // value={filter.get(type) ? type === 'filter_type' ? filter.get(type) : parseInt(filter.get(type)) : placeholder}
                    // value = {type === 'location_ids'?placeholder:this.filterType(filter,type,placeholder)}
                    suffixIcon={<Icon type="caret-down" style={{ color: '#999', fontSize: 16 }} />}
                    dropdownClassName={type === 'location_ids' ? 'locationDropdown' : ''}
                >
                    {
                        list.map((item, index) => {
                            switch (type) {
                                case 'week':
                                    return <Option title={item.title} value={index + 1} key={index}>{this.getWeekType(index + 1, language_id)} - {item.title}</Option>;
                                case 'location_ids':
                                    return <Option title={item.short_name[lang]} value={item.id.toString()} key={index} disabled={false}>{item.short_name[lang]}</Option>;
                                case 'class_type_id':
                                    return <Option title={item.get('names')} value={item.get('id')} key={index}>{item.get('names')}</Option>;
                                case 'filter_type':
                                    return <Option title={item.getIn(['names', language_id])} value={item.get('value')} key={item.get('id')}>{item.getIn(['names', language_id])}</Option>;
                                case 'mood_ids':
                                    return <Option title={item.getIn(nameIndex)} value={item.get('id')} key={item.get('id')} style={{color:item.get('color'), fontWeight:'bold'}}>{item.getIn(nameIndex)}</Option>;
                                default:
                                    return <Option title={item.getIn(nameIndex)} value={item.get('id')} key={item.get('id')}>{item.getIn(nameIndex)}</Option>;
                            }
                        })
                    }
                </Select>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        red: state.getIn(['header', 'type']),
        filter: state.getIn(['schedule', 'filter']),
        language_id: state.getIn(['header', 'language_id']),
        lang: state.getIn(['header', 'lang']),
        location_num: state.getIn(['schedule', 'location_num']),
        class_mood_list: state.getIn(['schedule', 'class_mood_list']).toJS(),
        region_id: state.getIn(['header', 'region_id']),
    };
};

const mapDispathToProps = (dispatch) => ({
    handleChange(value, option) {
        dispatch(actionCreators.changeFilterChoose(value, filterType, this.filter, this.weeks, this.jwt));
    },
    handleFocus(type) {
        // console.log(filterType)
        filterType = type;
    },
    locationName(names) {
        dispatch(actionCreators.locationNames(names));

    }
});

export default connect(mapStateToProps, mapDispathToProps)(SelectItem);