
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Card, Row, Col, Typography } from 'antd';
import { MyState } from '../../../store/reducer.interface';
import './favourite.scss';
import { platformClasses } from '../../../config/style';
import { StarFilled } from '@ant-design/icons';
import { showLocationInfo } from '../../../schedule/store/actionCreators';

const mapStateToProps = (state: MyState) => {
    return {
        favourite_location_list: state.getIn(['schedule', 'favourite_location']),
        location_ungrouped: state.getIn(['schedule', 'location_list_ungrouped']),
        type: state.getIn(['header', 'type']),
        language_id: state.getIn(['header', 'language_id']),
        lang: state.getIn(['header', 'lang']),
        region_id: state.getIn(['header', 'region_id']),
        user: state.getIn(['main', 'user']),

    };
};

const mapDispathToProps = (dispatch: any) => ({
    showLocationModal(id: any, region_id: any, language_id: any, user: any) {
        dispatch(showLocationInfo(id, region_id, language_id, user.get('jwt')));
    }
});

const connector = connect(mapStateToProps, mapDispathToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export interface FavouriteLocationProps extends PropsFromRedux {

}
function FavouriteLocation(props: FavouriteLocationProps) {
    let { location_ungrouped, type, lang, language_id, region_id, user, favourite_location_list } = props;
    const [favLocation, setFavLocation] = useState<any[]>([]);
    let defaultImage = require('../../../assets/images/ic_default_image.png');
    // let favLocation: any[] = [];
    // let list = location_ungrouped.toJS();
    // list.forEach((element: any) => {
    //     if (element.is_favourite) {
    //         favLocation.push(element);
    //     }
    // });
    const { Paragraph, Text } = Typography;

    const locationClick = (id: number) => {
        props.showLocationModal(id, region_id, language_id, user);
    };

    useEffect(() => {
        let list = location_ungrouped.toJS();
        let newFavLocation: any[] = [];
        list.forEach((element: any) => {
            if (favourite_location_list.indexOf(element.id) > -1) {
                newFavLocation.push(element);
            }
        });
        setFavLocation(newFavLocation);
    }, [favourite_location_list, location_ungrouped])

    return (
        <div className='favourite-content-wrapper'>
            <Row gutter={[16, 16]} style={{margin: "0"}}>
                {
                    favLocation.map((element, index) => {
                        let imageLink = element.image_link || defaultImage;
                        let boxShadow = platformClasses.yoga;
                        if (element.is_fitness) {
                            boxShadow = platformClasses.fitness;
                        }
                        return (
                            <Col span={12} key={element.id}>
                                <Card
                                    hoverable
                                    cover={
                                        <div className='background-image-cover pb-16-9' style={{backgroundImage: `url("${imageLink}")`}}>
                                            {/* <img alt="example" src={imageLink} /> */}
                                            <StarFilled className= "card-star" />
                                        </div>
                                    }
                                    className='favourite-card'
                                    style={{ boxShadow: `${boxShadow}60 0px 2px 8px 0px` }}
                                    bodyStyle={{ padding: 0 }}
                                    onClick={() => locationClick(element.id)}
                                >
                                    <div className='location-body'>
                                        <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom:'0' }}>{element.names}</Paragraph>
                                        <Text ellipsis>{element.district[lang]}</Text>
                                    </div>
                                </Card>
                            </Col>
                        );
                    })
                }
            </Row>
        </div>
    );
}

export default connector(FavouriteLocation);