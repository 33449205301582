import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover, Icon } from 'antd';
import { intl,getLangTitle,getNowFormatDate } from '../../config/util';
import ScheduleButton from './scheduleButton/scheduleButton';
import _ from 'lodash';
import { getClassTypeById, getLevelById, getPillarById } from '../../config/classHelper';
import HtmlContent from '../../common/htmlContent/htmlContent';

/**
 * 首先遍历classes,然后循环weeks,然后再循环classes中的list，
 * 判断list中的start_date字段是否全等于week中的日期，
 * 是则输出课程信息，反之return null
 */

const popoverLoading = <div style={{ textAlign: 'center' }}><Icon type="loading" /></div>;
class ScheduleItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacherImg: '',
            classTypeVisibility: {},
            classInfo: popoverLoading,//课程信息弹窗
        };
    }

    getClassInfo = (class_id, language_id, className) => {
        this.setState({ classInfo: popoverLoading });
        const data = getClassTypeById(class_id);
        if (data) {
            let levelInfo = getLevelById(data.level_id, data.sector);
            let pillarInfo = getPillarById(data.pillar_id, data.sector);

            let classInfo = (
                <>
                    <div className="title">{data.names[getLangTitle(language_id)]}</div>
                    <p className="attr" style={{ margin: 0 }}>
                        <span style={{ color: pillarInfo.color }}>{pillarInfo.names[getLangTitle(language_id)]}</span>
                        <em> </em>
                        <span>{levelInfo.names[getLangTitle(language_id)]}</span>
                    </p>
                    <pre className="disc">{data.descriptions[getLangTitle(language_id)]}</pre>
                </>
            );
            this.setState({ classInfo });
        } else {
            let classInfo = (
                <>
                    <div className="title">{className}</div>
                    <div className="attr">{intl('other', 'errorNoInfo', language_id)}</div>
                </>
            );
            this.setState({ classInfo });
        }
    }

    classInfo = (data) => {
        return (
            <>
                <div className="title">{data.name}</div>
                <p className="attr" style={{ margin: 0 }}>
                    <span style={{ color: data.pillar_color }}>{data.pillar_name}</span>
                    <em> </em>
                    <span>{data.level}</span>
                </p>
                <pre className="disc">{data.description}</pre>
            </>
        );
    }
    isVaccinated = (isVaccinatedClass) => {
        let vac = require('../../assets/images/vaccinatedIcon.png');
        if (isVaccinatedClass) {
            return(
                <>
                <div className='vaccinated'>
                    <img className='vacIcon' src={vac} alt="" />
                </div>
                </>
            );
        }
    }
    teacherInfo=(img,name,description)=>{
        let teacherDefaultAvatar = require('../../assets/images/ic_default_image.png');
        if (name !== 'TBC') {
            return(
                <>
                    <div className="avator">
                        <img src={img ? img : teacherDefaultAvatar} width='150' height='150' alt=" " />
                    </div>
                    <p className="name">{name}</p>
                    <pre className="description">{description}</pre>
                </>
            );
        } else {
                return(
                    <>
                        <div className="avator">
                            <img src={teacherDefaultAvatar} width='150' height='150' alt=" " />
                        </div>
                        <p className="name">{name}</p>
                    </>
                );
        }
    }
    getTeacherDesc = (teacher_id) => {
        let lang = getLangTitle(this.props.language_id);
        let desc = '';
        if (this.props.teachers.size > 0 ) {
           let chooseItem = this.props.teachers.find( item => item.get('id') === teacher_id );
           desc = chooseItem ? chooseItem.getIn(['descriptions',lang]) : '';
        }
        return desc;
    }
    //判断当前所在课程的区间，增加一条蓝色的线
    showLine = (startTime,nextTime) => {
        let nowTimestamp = Date.parse(new Date());
        let nowDate = Date.parse(getNowFormatDate().replace(/-/g, "/")+' 00:00:00');
        let startH = parseInt(startTime.split(':')[0]);
        let startM = parseInt(startTime.split(':')[1]);
        let nextH = parseInt(nextTime.split(':')[0]);
        let nextM = parseInt(nextTime.split(':')[1]);
        let startTimestamp = nowDate + (startH * 60 + startM) * 60000;
        let nextTimestamp = nowDate + (nextH * 60 + nextM) * 60000;
        return nowTimestamp >= startTimestamp && nowTimestamp < nextTimestamp;
    }

    locations(locationInfo) {
        let { location_list, filter } = this.props;
        filter = filter.toJS();
        if (!filter.sector) {
            return '';
        }
        // let data = location_list ? location_list[filter.sector] : [];
        let data = location_list ? location_list[locationInfo.sector] : [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == locationInfo.location_id) {
                if (locationInfo.is_fuze) {
                    return `FUZE @ ${data[i].names}`;
                } else {
                    return data[i].names;
                }
            }
        }
    }

    getTd(it, classItem) {
        const { language_id, dateType = 'basic', showVibe, showSpecial, user} = this.props;
        const matchClasses = classItem.filter(item_x => it.get('value') === item_x.get('start_date'));
        let vac = require('../../assets/images/vaccinatedIcon.png');
        let jwt = user.get('jwt') ? user.get('jwt') : '';
        if (matchClasses.size === 0) {
            return;
        }

        return (
            <div className="schedule_wra">
                {matchClasses.map(item_x => {
                    let classInfo = {
                        name: item_x.getIn(['class_type', 'name']),
                        pillar_name: item_x.getIn(['class_type', 'pillar', 'name']),
                        pillar_color: item_x.getIn(['class_type', 'pillar','color']),
                        level: item_x.getIn(['class_type', 'level']),
                        description: item_x.getIn(['class_type', 'description'])
                    };

                    let locationInfo = {
                        location_id: item_x.getIn(['location_id']),
                        sector: item_x.getIn(['sector']),
                        is_fuze: item_x.getIn(['class_type', 'is_fuze']),
                    };

                    return (
                        <div className="schedule_wrapper" key={item_x.get('id')}>
                            { showSpecial && item_x.getIn(['special']) ? <Popover
                                placement="bottom"
                                content={item_x.getIn(['special', 'name'])}
                                overlayStyle={{ width: '400px', color:`${item_x.getIn(['special', 'color'])}`}}
                                overlayClassName="schedule_popover"
                                arrowPointAtCenter
                            >
                                <span
                                    className="line green"
                                    style={{ background:item_x.getIn(['special', 'color'])}}
                                >
                                </span>
                            </Popover>
                            :
                            <span
                                className="line green"
                                style={{ background: 'lightgrey', width:'4px'}}
                            >
                            </span>
                            }

                            <div className="className">
                                <Popover
                                    placement="bottom"
                                    overlayStyle={{ width: '400px', borderRadius: '30px' }}
                                    overlayClassName="class_popover"
                                    content={this.state.classInfo}
                                    onMouseEnter={() => { this.getClassInfo(item_x.getIn(['class_type', 'id']), language_id, classInfo.name); }}
                                >
                                {item_x.getIn(['class_type', 'name'])}
                                {dateType !== 'basic' && ` (${parseInt(item_x.get('duration')) / 60000})`}
                                </Popover>
                            </div>

                            <div className="teacher" data_id={item_x.getIn(['teacher', 'id'])}>
                                <Popover
                                    placement="bottom"
                                    content={this.teacherInfo(
                                        item_x.getIn(['teacher', 'image_link']),
                                        item_x.getIn(['teacher', 'full_name']) ? item_x.getIn(['teacher', 'full_name']) : item_x.getIn(['teacher', 'name']),
                                        this.getTeacherDesc(item_x.get('teacher_id'))
                                    )}
                                    overlayClassName="teacher_popover"
                                >
                                    {item_x.getIn(['teacher', 'name'])}
                                </Popover>
                                {
                                    dateType !== 'basic' && <div className="classFeaturePrint">

                                        {item_x.get('is_vaccinated')? <img className='vacIcon' src={vac} alt=""></img>:''}
                                        {showVibe && item_x.get('mood') ? <p style={{color:item_x.getIn(['mood', 'color'])}}>
                                            {item_x.getIn(['mood', 'name'])}
                                            </p> : ''}
                                        {showSpecial && item_x.get('special') ? <p style={{color:item_x.getIn(['special', 'color'])}}>
                                            {intl('schedule', 'specialNotesPrefix', language_id)}{item_x.getIn(['special', 'name'])}
                                            </p> : ''}

                                    </div>
                                }
                            </div>
                            {
                                dateType === 'basic' &&
                                <div className="during">
                                    <div className="classDuration">{parseInt(item_x.get('duration')) / 60000} {intl('schedule', 'mins', language_id)}</div>
                                    <div className="classFeature">
                                        {showVibe && item_x.get('mood') && <div style={{color:item_x.getIn(['mood', 'color'])}}>{intl('schedule', 'mood', language_id)} : {item_x.getIn(['mood', 'name'])}</div>}
                                        <div className="iconRight">
                                            {this.isVaccinated(item_x.get('is_vaccinated'))}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                <p className="location">
                                    <i className="iconfont icon-marker1"></i> &nbsp;
                                    {/* {this.locations(locationInfo.location_id, locationInfo.is_fuze, locationInfo.sector)} */}
                                    {this.locations(locationInfo)}
                                </p>
                            }
                            {
                                item_x.get('button_status') !== 0 && this.props.isLogin
                                &&
                                <div className="book">
                                    <ScheduleButton
                                        item={item_x}
                                        language_id={language_id}
                                        booking={this.props.booking}
                                        jwt={this.props.jwt}
                                        filter={this.props.filter}
                                        region_id={this.props.region_id}
                                        >
                                    </ScheduleButton>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        );

    }

    render() {
        const { classes, weekList, language_id, teachers, dateType = 'basic', type , showSpecial} = this.props;
        let vac = require('../../assets/images/vaccinatedIcon.png');
        //当前时间
        let nowTimestamp = Date.parse(new Date());
        if (classes.size !== 0){
            return (
                <tbody>{
                    classes.map(item => {
                        let classItem = item.get('list');
                        return (
                            <tr key={item.get('name')} className={this.showLine(item.get('name'), item.get('nextTime')) ? 'showLine'+type:''}>
                                <td width="12.5%" align="center">
                                    <div className="time">
                                        {
                                            dateType === 'basic' ?
                                                <><p>&nbsp;</p><span>{item.get('start_time_display')}</span></>
                                            :
                                            <strong>{item.get('name')}</strong>
                                        }
                                    </div>
                                </td>
                                {weekList.map((it, ind) => {
                                    return (
                                        <td width="12.5%" key={it} className={ nowTimestamp > Date.parse(it.get('value') + 'T' + item.get('name') + ':00') ? 'past':''} >
                                            {
                                               this.getTd(it, classItem)
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })
                }
                {
                    dateType !== 'basic' && showSpecial && <caption style={{paddingTop: '0.3em'}}>{intl('schedule', 'specialNotesPrefix', language_id)}{intl('schedule', 'specialClassNotes', language_id)}</caption>
                }
                </tbody>
            );
        } else {
            // return null;
            return <div className='no_class'>
                {
                    // intl('schedule','no_result_found', language_id)
                    <HtmlContent content={intl('schedule', 'no_result_found', language_id, { break: '' })} />
                }
            </div>;
        }
    }
}

// export default ScheduleItem;
const mapStateToProps = (state) => {
    return {
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        filter: state.getIn(['schedule', 'filter']),

        scheduleModalInfo: state.getIn(['main', 'scheduleModalInfo']),
        region_id: state.getIn(['header', 'region_id']),
        pillars_all: state.getIn(['schedule', 'pillars_all']),
        isLogin: state.getIn(['main', 'isLogin']),
        language_id: state.getIn(['header', 'language_id']),
        user: state.getIn(['main', 'user']),

        levels: state.getIn(['schedule', 'levels']),
        pillars: state.getIn(['schedule', 'pillars']),
        classInfo: state.getIn(['main', 'classInfo']),
        lang: state.getIn(['header', 'lang']),

    };
};

const mapDispathToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispathToProps)(ScheduleItem);