import React, { Component } from 'react';
import { Button, Popover } from 'antd';
import { intl, getLangTitle, getNowFormatDate } from '../../config/util';
import { Modal } from 'antd';
import ScheduleButton from './scheduleButton/scheduleButton';

/**
 * 首先遍历classes,然后循环weeks,然后再循环classes中的list，
 * 判断list中的start_date字段是否全等于week中的日期，
 * 是则输出课程信息，反之return null
 */

class ScheduleMany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacherImg: ''
        };
    }
    classInfo = (data) => (
        <>
            <div className="title">{data.name}</div>
            <p className="attr" style={{ margin: 0 }}>
                <span style={{ color: data.pillar_color }}>{data.pillar_name}</span>
                <em> </em>
                <span>{data.level}</span>
            </p>
            <pre className="disc">{data.description}</pre>
        </>
    )
    isVaccinated = (isVaccinatedClass) => {
        let vac = require('../../assets/images/vaccinatedIcon.png');
        if (isVaccinatedClass) {
            return(
                <>
                <div className='vaccinated'>
                    <img className='vacIcon' src={vac} alt="" />
                </div>
                </>
            );
        }
    }
    teacherInfo = (img, name, description) => {
        let teacherDefaultAvatar = require('../../assets/images/ic_default_image.png');
        if (name !== 'TBC') {
            return (
                <>
                    <div className="avator">
                        <img src={img ? img : teacherDefaultAvatar} width='150' height='150' alt=" " />
                    </div>
                    <p className="name">{name}</p>
                    <pre className="description">{description}</pre>
                </>
            );
        } else {
            return (
                <>
                    <div className="avator">
                        <img src={teacherDefaultAvatar} width='150' height='150' alt=" " />
                    </div>
                    <p className="name">{name}</p>
                </>
            );
        }

    }
    getTeacherDesc = (teacher_id) => {
        let lang = getLangTitle(this.props.language_id);
        let desc = '';
        if (this.props.teachers.size > 0) {
            let chooseItem = this.props.teachers.find(item => item.get('id') === teacher_id);
            desc = chooseItem ? chooseItem.getIn(['descriptions', lang]) : '';
        }
        return desc;
    }
    //判断当前所在课程的区间，增加一条蓝色的线
    showLine = (startTime, nextTime) => {
        let nowTimestamp = Date.parse(new Date());
        let nowDate = Date.parse(getNowFormatDate().replace(/-/g, "/") + ' 00:00:00');
        let startH = parseInt(startTime.split(':')[0]);
        let startM = parseInt(startTime.split(':')[1]);
        let nextH = parseInt(nextTime.split(':')[0]);
        let nextM = parseInt(nextTime.split(':')[1]);
        let startTimestamp = nowDate + (startH * 60 + startM) * 60000;
        let nextTimestamp = nowDate + (nextH * 60 + nextM) * 60000;
        return nowTimestamp >= startTimestamp && nowTimestamp < nextTimestamp;
    }

    dayType = (day) => {
        switch (day) {
            case 1:
                return [1, 2, 3];
            case 2:
                return [2, 3, 4];
            case 3:
                return [3, 4, 5];
            case 4:
                return [4, 5, 6];
            case 5:
                return [5, 6, 7];
            case 6:
                return [5, 6, 7];
            case 0:
                return [5, 6, 7];
        }
    }

    getTd(it, classItem, location_ids, i1, ind) {
        const { language_id, dateType = 'basic', showSpecial, showVibe} = this.props;
        return (
            <div className="scheduleMany_wra">
                 {
                    classItem.map((item_x, index_x) => {
                        let classInfo = {
                            name: item_x.getIn(['class_type', 'name']),
                            pillar_name: item_x.getIn(['class_type', 'pillar', 'name']),
                            pillar_color: item_x.getIn(['class_type', 'pillar', 'color']),
                            level: item_x.getIn(['class_type', 'level']),
                            description: item_x.getIn(['class_type', 'description'])
                        };

                        if (it.get('value') === item_x.get('start_date')) {

                            return (
                                <div className="location_many" key={item_x.get('id')}>
                                    {
                                        location_ids.map((loc, loi) => {
                                            if (parseInt(loc) == parseInt(item_x.get('location_id'))) {

                                                return (
                                                    <div className="many_item" key={item_x.get('id')}>
                                                        <div className="schedule_wra">
                                                            <div className="schedule_wrapper">
                                                                { showSpecial && item_x.getIn(['special']) ? <Popover
                                                                    placement="bottom"
                                                                    content={item_x.getIn(['special', 'name'])}
                                                                    overlayStyle={{ width: '400px', color:`${item_x.getIn(['special', 'color'])}`}}
                                                                    overlayClassName="schedule_popover"
                                                                    arrowPointAtCenter
                                                                >
                                                                    <span
                                                                        className="line green"
                                                                        style={{ background:item_x.getIn(['special', 'color'])}}
                                                                    >
                                                                    </span>
                                                                </Popover>
                                                                :
                                                                <span
                                                                    className="line green"
                                                                    style={{ background: 'lightgrey', width:'4px'}}
                                                                >
                                                                </span>
                                                                }

                                                                    <div className="className">
                                                                        <Popover
                                                                            placement="bottom"
                                                                            content={this.classInfo(classInfo)}
                                                                            overlayStyle={{ width: '400px', borderRadius: '30px' }}
                                                                            overlayClassName="class_popover"
                                                                            arrowPointAtCenter
                                                                        >
                                                                            {item_x.getIn(['class_type', 'name'])}
                                                                            {dateType !== 'basic' && ` (${parseInt(item_x.get('duration')) / 60000})`}
                                                                        </Popover>
                                                                    </div>


                                                                    <div className="teacher" data_id={item_x.getIn(['teacher', 'id'])}>
                                                                        <Popover
                                                                            placement="bottom"
                                                                            content={this.teacherInfo(
                                                                                item_x.getIn(['teacher', 'image_link']),
                                                                                item_x.getIn(['teacher', 'full_name']) ? item_x.getIn(['teacher', 'full_name']) : item_x.getIn(['teacher', 'name']),
                                                                                this.getTeacherDesc(item_x.get('teacher_id'))
                                                                            )}
                                                                            overlayClassName="teacher_popover"
                                                                        >
                                                                            {item_x.getIn(['teacher', 'name'])}
                                                                        </Popover>
                                                                    </div>


                                                                    {
                                                                        dateType === 'basic' ?
                                                                        <div className="during">
                                                                            <div className="classDuration">{parseInt(item_x.get('duration')) / 60000} {intl('schedule', 'mins', language_id)}</div>
                                                                            <div className="classFeature">
                                                                                {showVibe && item_x.get('mood') &&
                                                                                    <div style={{color:item_x.getIn(['mood', 'color'])}}>
                                                                                        {intl('schedule', 'mood', language_id)} : {item_x.getIn(['mood', 'name'])}
                                                                                    </div>
                                                                                }
                                                                                <div className="iconRight">
                                                                                    {this.isVaccinated(item_x.get('is_vaccinated'))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="iconRight">
                                                                            {this.isVaccinated(item_x.get('is_vaccinated'))}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        item_x.get('button_status') !== 0 && this.props.isLogin 
                                                                        &&
                                                                            <p className="book" style={{ flexDirection: 'column' }}>
                                                                                <ScheduleButton
                                                                                item={item_x}
                                                                                language_id={language_id}
                                                                                booking={this.props.booking}
                                                                                jwt={this.props.jwt}
                                                                                filter={this.props.filter}
                                                                                region_id={this.props.region_id}
                                                                                >
                                                                                </ScheduleButton>
                                                                            </p>
                                                                    }
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return <div key={`${i1}-${ind}-${index_x}-${loi}`} className="many_item"></div>;
                                            }
                                        })
                                    }

                                </div>
                            );
                        }


                    })
                }
            </div>
        );

    }

    render() {

        const { classes, weekList, language_id, teachers, dateType = 'basic', type, showVibe, showSpecial } = this.props;
        var location_ids = [];
        if (this.props.location_ids) {
            location_ids =this.props.location_ids.toString().split(',');
        }
        let nowTimestamp = Date.parse(new Date());
        if (classes.size !== 0) {
            return (
                <tbody>{
                    classes.map((item, i1) => {
                        return (
                            <tr key={item.get('name')} className={this.showLine(item.get('name'), item.get('nextTime')) ? 'showLine' + type : ''}>
                                <td width="12.5%" align="center">
                                    <div className="time">
                                        {
                                            dateType === 'basic' ?
                                                <><p>&nbsp;</p><span>{item.get('name')}</span></>
                                                :
                                                <strong>{item.get('name')}</strong>
                                        }
                                    </div>
                                </td>

                                {weekList.map((it, ind) => {
                                    let classItem = item.get('list');
                                    var day_type = this.dayType(this.props.day_type);
                                    var isreturn = true;
                                    for (var i = 0; i < day_type.length; i++) {
                                        if (ind + 1 == day_type[i]) {
                                            isreturn = false;
                                        }
                                    }
                                    if (isreturn) {
                                        return false;
                                    }
                                    return (
                                        <td width="29%" key={it} className={nowTimestamp > Date.parse(it.get('value') + 'T' + item.get('name') + ':00') ? 'past' : ''} >
                                            {
                                               this.getTd(it, classItem, location_ids, i1, ind) 
                                            }
                                            {/* {
                                                classItem.map((item_x, index_x) => {
                                                    let classInfo = {
                                                        name: item_x.getIn(['class_type', 'name']),
                                                        pillar_name: item_x.getIn(['class_type', 'pillar', 'name']),
                                                        pillar_color: item_x.getIn(['class_type', 'pillar', 'color']),
                                                        level: item_x.getIn(['class_type', 'level']),
                                                        description: item_x.getIn(['class_type', 'description'])
                                                    };

                                                    if (it.get('value') === item_x.get('start_date')) {

                                                        return (
                                                            <div className="location_many" key={item_x.get('id')}>
                                                                {
                                                                    location_ids.map((loc, loi) => {
                                                                        if (parseInt(loc) == parseInt(item_x.get('location_id'))) {

                                                                            return (
                                                                                <div className="many_item" key={item_x.get('id')}>
                                                                                    <div className="schedule_wra">
                                                                                        <div className="schedule_wrapper">
                                                                                            { item_x.getIn(['special']) ? <Popover
                                                                                                placement="bottom"
                                                                                                content={item_x.getIn(['special', 'name'])}
                                                                                                overlayStyle={{ width: '400px', color:`${item_x.getIn(['special', 'color'])}`}}
                                                                                                overlayClassName="schedule_popover"
                                                                                                arrowPointAtCenter
                                                                                            >
                                                                                                <span
                                                                                                    className="line green"
                                                                                                    style={{ background:item_x.getIn(['special', 'color'])}}
                                                                                                >
                                                                                                </span>
                                                                                            </Popover>
                                                                                            :
                                                                                            <span
                                                                                                className="line green"
                                                                                                style={{ background: 'lightgrey', width:'4px'}}
                                                                                            >
                                                                                            </span>
                                                                                            }

                                                                                                <div className="className">
                                                                                                    <Popover
                                                                                                        placement="bottom"
                                                                                                        content={this.classInfo(classInfo)}
                                                                                                        overlayStyle={{ width: '400px', borderRadius: '30px' }}
                                                                                                        overlayClassName="class_popover"
                                                                                                        arrowPointAtCenter
                                                                                                    >
                                                                                                        {item_x.getIn(['class_type', 'name'])}
                                                                                                        {dateType !== 'basic' && ` (${parseInt(item_x.get('duration')) / 60000})`}
                                                                                                    </Popover>
                                                                                                </div>


                                                                                                <div className="teacher" data_id={item_x.getIn(['teacher', 'id'])}>
                                                                                                    <Popover
                                                                                                        placement="bottom"
                                                                                                        content={this.teacherInfo(
                                                                                                            item_x.getIn(['teacher', 'image_link']),
                                                                                                            item_x.getIn(['teacher', 'full_name']) ? item_x.getIn(['teacher', 'full_name']) : item_x.getIn(['teacher', 'name']),
                                                                                                            this.getTeacherDesc(item_x.get('teacher_id'))
                                                                                                        )}
                                                                                                        overlayClassName="teacher_popover"
                                                                                                    >
                                                                                                        {item_x.getIn(['teacher', 'name'])}
                                                                                                    </Popover>
                                                                                                </div>


                                                                                                {
                                                                                                    dateType === 'basic' ?
                                                                                                    <div className="during">
                                                                                                        <div className="classDuration">{parseInt(item_x.get('duration')) / 60000} {intl('schedule', 'mins', language_id)}</div>
                                                                                                        <div className="classFeature">
                                                                                                            {item_x.get('special') && <div style={{color:item_x.getIn(['mood', 'color'])}}>{item_x.getIn(['mood', 'name'])}</div>}
                                                                                                            <div className="iconRight">
                                                                                                                {this.isVaccinated(item_x.get('is_vaccinated'))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="iconRight">
                                                                                                        {this.isVaccinated(item_x.get('is_vaccinated'))}
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    item_x.get('button_status') !== 0 && this.props.isLogin 
                                                                                                    &&
                                                                                                        <p className="book" style={{ flexDirection: 'column' }}>
                                                                                                            <ScheduleButton
                                                                                                            item={item_x}
                                                                                                            language_id={language_id}
                                                                                                            booking={this.props.booking}
                                                                                                            jwt={this.props.jwt}
                                                                                                            filter={this.props.filter}
                                                                                                            region_id={this.props.region_id}
                                                                                                            >
                                                                                                            </ScheduleButton>
                                                                                                        </p>
                                                                                                }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return <div key={`${i1}-${ind}-${index_x}-${loi}`} className="many_item"></div>;
                                                                        }
                                                                    })
                                                                }

                                                            </div>
                                                        );
                                                    }


                                                })
                                            } */}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })
                }</tbody>
            );
        } else {
            return null;
        }
    }

}

export default ScheduleMany;