import React, { Component } from 'react';
import { Icon } from 'antd-mobile';
import { connect } from 'react-redux';
import { setModal } from '../../main/store/actionCreators';
import { changeFilterChoose } from '../../../schedule/store/actionCreators';
import { intl } from '../../../config/util';
import { fromJS } from 'immutable';
import './moodSelect.less';

class MoodSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mood_ids: [],
            moodList: []
        };
    }

    handleSelectChoose = (id, index) => {
        // var moodList = this.props.class_mood_list;
        var moodList = this.state.moodList;
        var mood_ids = this.state.mood_ids;
        if (parseInt(id) !== this.props.mood_ids) {
            moodList[index].choice = true;
            this.props.changeFilter(id);
            this.props.changeModal(false, '');
        } else {
            this.props.changeModal(false, '');
        }
        // if (moodList[index].choice) {
        //     moodList[index].choice = false;
        //     for (var i = 0; i < mood_ids.length; i++) {
        //         if (mood_ids[i] == id.toString()) {
        //             mood_ids.splice(i, 1);
        //         }
        //     }
        //     for (var i = 0; i < moodList.length; i++) {
        //         moodList[i].disabled = false;
        //     }
        // } else {
        //     moodList[index].choice = true;
        //     mood_ids.push(id.toString());

        //     if (mood_ids.length > 2) {
        //         for (var i = 0; i < moodList.length; i++) {
        //             moodList[i].disabled = true;
        //             for (var t = 0; t < mood_ids.length; t++) {

        //                 if (mood_ids[t] == moodList[i].id) {
        //                     moodList[i].disabled = false;
        //                 }
        //             }
        //         }
        //     }
        // }
        // moodList[index].disabled = false;

        this.setState({
            moodList: moodList,
            mood_ids: mood_ids
        });
        // if(parseInt(filter.location_ids) !== id){
        //     this.props.changeFilter(id)
        // }else{
        //     this.props.changeModal(false,'')
        // }
    }

    componentDidMount() {
        const { locations, filter, class_mood_list } = this.props;
        let list = class_mood_list;
        var mood_ids = [] = localStorage.getItem('mood_ids') ? localStorage.getItem('mood_ids') : [];
        for (var i = 0; i < list.length; i++) {
            list[i].choice = false;
            if (list[i].id == mood_ids) {
                list[i].disabled = false;
                list[i].choice = true;
            }
        }

        this.setState({
            mood_ids: mood_ids,
            moodList: list,
        });

    }

    cancel = () => {
        // if (this.state.mood_ids.length == 0) {
        //     this.props.changeFilter(mood_ids);
        //     this.props.changeModal(false, '');
        //     // return false;
        // }
        // var mood_ids = this.state.mood_ids.join('|');
        // this.props.changeFilter(mood_ids);
        this.props.changeModal(false, '');
    }

    reset = () => {
        localStorage.setItem('mood_ids','');
        this.props.changeFilter('');
        this.props.changeModal(false, '');
    }

    render() {
        const { locations, filter, lang, language_id, class_mood_list } = this.props;
        return (
            <div className="pop_list_wra wra_mood">
                    <div className="title">{intl('other', 'moodTips', language_id)}</div>
                <div className="pop_content">
                    <ul className="moodUl">
                        {
                            class_mood_list.map((item, index) => {
                                return (
                                    <li className={`panel ${item.id} ${item.choice ? 'choice' : ''}`} key={item.id} onClick={() => this.handleSelectChoose(item.id, index)}>
                                        <span className="name" style={{color:item.color}}>{item.names[lang]}</span>
                                        {parseInt(filter.mood_ids) === item.id && <Icon type="check" />}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                <div className="opera">
                    <button className="reset" onClick={() => this.reset()}>{intl('schedule', 'reset', language_id)}</button>
                    <button className="true" onClick={() => this.cancel()}>{intl('schedule', 'cancel', language_id)}</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.getIn(['header', 'lang']),
        locations: state.getIn(['schedule', 'location_list']),
        filter: state.getIn(['schedule', 'filter']).toJS(),
        user: state.getIn(['main', 'user']),
        language_id: state.getIn(['header', 'language_id']),
        class_mood_list: state.getIn(['schedule', 'class_mood_list']).toJS(),
    };
};

const mapDispathToProps = (dispatch) => ({
    //设置弹窗
    changeModal(opera, type) {
        dispatch(setModal(opera, type));
    },
    //修改语言和城市
    changeFilter(id, jwt) {
        dispatch(changeFilterChoose(id, 'mood_ids', fromJS(this.filter), this.weeks, jwt, this.locations));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(MoodSelect);