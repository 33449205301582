import * as constants from './constants';
import { fromJS } from 'immutable';

const defauleState = fromJS({
    history:{},
    historyType:'visit_history',
    historyOrder:'desc'
});

export default (state = defauleState, action) => {
    switch (action.type) {
        case constants.SAVE_HISTORY:
            return state.merge({
                history: action.value,
                historyType: action.historyType,
                historyOrder: action.order
            });
        default:
            return state;
    }
};
