import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeClassModal } from '../../main/store/actionCreators';
import { intl, getMonth, formatDate, showMsg } from '../../../config/util';
import { judgeBooking, toggleFavourite, updateClassInfo } from '../../../schedule/store/actionCreators';
import { ClockCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { fromJS } from 'immutable';
import ScheduleButton from '../../../schedule/component/scheduleButton/scheduleButton';
import './classModal.scss';
import { flags } from '../../../config/unleash';
import HtmlContent from '../../htmlContent/htmlContent';

let pillarsImg = {
    //疗愈瑜伽
    1: require('../../../assets/images/yoga_ly.png'),
    //静态瑜伽
    2: require('../../../assets/images/yoga_jt.png'),
    //动态瑜伽
    3: require('../../../assets/images/yoga_dt.png'),
    //高温瑜伽
    4: require('../../../assets/images/yoga_gw.png'),
    //特色瑜伽
    5: require('../../../assets/images/yoga_ts.png'),
    //力量与训练班
    6: require('../../../assets/images/ft_ll.png'),
    //舞蹈班
    7: require('../../../assets/images/ft_dance.png'),
    //高能量训练
    8: require('../../../assets/images/ft_gn.png'),
    //身心训练
    9: require('../../../assets/images/ft_sx.png'),
    //健身车班
    10: require('../../../assets/images/ft_js.png'),
    //高强度间歇训练
    11: require('../../../assets/images/ft_gq.png')
};


class ClassModal extends Component {
    constructor(props) {
        super(props);
        let scheduleModalInfo = this.props.scheduleModalInfo;
        this.state = {
            teacher_id: scheduleModalInfo.teacher_id || '', //筛选项-老师
            level_id: scheduleModalInfo.level_id || '', //筛选项-程度（瑜伽独有）
            pillar_id: scheduleModalInfo.pillar_id || '', //筛选项-支柱/元素
            class_type_id: scheduleModalInfo.id || '', //筛选项-课程
            filter_type: scheduleModalInfo.filter_type || '', //筛选项-分类(健身独有)
        };

    }

    componentDidMount() {
        if (this.props.scheduleModalInfo && this.props.scheduleModalInfo.scroll) {
            this.scrollToSchedule();
        }
    }

    scrollToSchedule() {
        const el = document.getElementById("classSchedule");
        if (el) {
            el.scrollIntoView({ behavior: "smooth" });
        }
    }

    locations(id) {
        let { location_list, filter, lang, location_list_ungrouped } = this.props;
        filter = filter.toJS();
        if (!filter.sector) {
            return '';
        }
        // let data = location_list ? location_list[filter.sector] : [];
        let data = location_list_ungrouped ? location_list_ungrouped : [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                return data[i].names;
            }
        }
    }

    //日期展示
    formatDateTitle(date){
        let { language_id } = this.props;
            let dateTitle = formatDate(date, language_id);
            if (dateTitle.day === 'today') {
                return intl('schedule', 'today', language_id) + ' ' + dateTitle.month + ' ' + dateTitle.dayTimeShort;
            } else {
                return dateTitle.title;
            }
    }

    updateSchedule() {
        // refresh on cancel class
        let { scheduleModalInfo, region_id, language_id } = this.props;
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        this.props.updateClassInfo(scheduleModalInfo.id, region_id, jwt, language_id, scheduleModalInfo);
    }

    //课程列表
    getScheduleItemMobile = (list) => {
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let { mood_flag } = this.props;
        return (
            <ul>
                {list.map(item => {
                    let info = [
                        (<p key="time" className="timeContent">{item.duration_min} min</p>),
                        (<p key="info" className="infoContent teacherName">{item.teacher.name}</p>)
                    ];
                    if(mood_flag && item.mood){
                        info.push(
                            <div key="mood" className="mood">
                                <p className="moodName infoContent" style={{color:item.mood.color}}> {item.mood.name} </p>
                            </div>
                        );
                    }
                    return <li key={item.id} className={item.class_type.is_fuze ? 'black' : ''}>
                        <div className="date">{this.formatDateTitle(item.start_date)}</div>
                        <span
                            className="line"
                            style={{ backgroundColor: item.class_type.is_fuze ? '#e06b26' : item.class_type.pillar ? item.class_type.pillar.color : '#e06b26' }}
                        >
                        </span>
                        <div className="class_info">
                            <div className='time-name'>
                                <h4 className='start-time'>
                                    {item.start_time_display}
                                </h4>
                                <h4 className='class-name'>
                                    {item.class_type.name}
                                </h4>
                            </div>
                            <div className="down">
                                <div className="left">
                                    <div className = "leftInfo">
                                        {
                                            info.map(t => t)
                                            .reduce((prev,curr, i) => [prev, <p key={i}>&nbsp;|&nbsp;</p> , curr])
                                        }
                                    </div>
                                    <p>
                                        <i className="iconfont icon-marker1"></i> &nbsp;
                                        {this.locations(item.location_id)}
                                    </p>
                                </div>
                                {
                                    (item.button_status !== 0 && this.props.isLogin) &&
                                    <div className="opera book">
                                        <ScheduleButton
                                            item={fromJS(item)}
                                            language_id={this.props.language_id}
                                            booking={this.props.booking}
                                            jwt={jwt}
                                            filter={this.props.filter}
                                            region_id={this.props.region_id}
                                            cancel_class_callback={this.updateSchedule.bind(this)}
                                            booking_class_callback={this.updateSchedule.bind(this)}
                                            blurBtn="true"
                                        >
                                        </ScheduleButton>
                                    </div>
                                }
                            </div>
                        </div>
                    </li>;
                }
                )}
            </ul>
        );
    }

    render() {
        let { scheduleModalInfo, lang, language_id, mood_flag, special_flag, favourite_class_list } = this.props;
        let scheduleList = scheduleModalInfo.schedule;
        let is_favourite = favourite_class_list.indexOf(scheduleModalInfo.id) >= 0;
        const title = [
            (<span key="level" className="name item">Level {scheduleModalInfo.level_id}</span>),
        ];
        if (mood_flag && scheduleModalInfo.mood) {
            title.unshift(<span key="mood" className="name item" style={{color: scheduleModalInfo.mood.color}}>{scheduleModalInfo.mood.name}</span>);
        }
        return (
            <div className="classesInfo">
                <div className="content">
                    <h4 className="name class title">{scheduleModalInfo.names[lang]}</h4>
                    {
                        <div className="mood">
                            {
                                title.map((t, i) => <h4 key={i}>{t}</h4>)
                                    .reduce((prev, curr, i) => [prev, (<h4 key={i} className="name"> ・ </h4>), curr])
                            }
                        </div>
                    }
                    {
                        special_flag && scheduleModalInfo.special &&
                        <div className="special">
                            <h4 className="name">{scheduleModalInfo.special.name}</h4>
                        </div>
                    }
                    {/* <div className="pillar">
                        <span style={{color: scheduleModalInfo.pillarInfo.color}}>{scheduleModalInfo.pillarInfo.names[lang]}</span>
                        <em><img src={pillarsImg[scheduleModalInfo.pillar_id]} alt=""/></em>
                    </div> */}
                    {/* <pre className="disc">{scheduleModalInfo.descriptions[lang]}</pre> */}
                    <HtmlContent content={scheduleModalInfo.descriptions[lang]} />

                    <div className="locations">
                        <div className="title">{intl('schedule', 'locations', language_id)}</div>
                        <ul>
                            {
                                scheduleModalInfo.locations.map(item =>
                                    <li key={item.id}>{item.names}</li>
                                )
                            }
                        </ul>
                    </div>
                    {
                        scheduleList.length > 0 &&
                        <div className="schedule" id="classSchedule">
                            <div className="title">{intl('schedule', 'schedule', language_id)}</div>
                            <div className="filter">
                                <div className="filterTitle">{intl('schedule', 'allLocation', language_id)}</div>
                                <div className="filterTitle"> ・ </div>
                                <div className="filterTitle">{intl('schedule', 'next14Days', language_id)}</div>
                            </div>
                                {this.getScheduleItemMobile(scheduleList)}
                        </div>
                    }
                </div>
                <div className="buttonSection" >
                    <button onClick={() => this.props.toggleFavouriteClass(is_favourite, favourite_class_list, scheduleModalInfo.id)}>
                        {is_favourite ?
                            <StarFilled style={{ color: '#ffaC33' }} /> :
                            <StarOutlined />
                        }
                        &nbsp; {intl('schedule', 'favourite', language_id)}
                    </button>
                    <button onClick={this.scrollToSchedule}><ClockCircleOutlined /> &nbsp; {intl('schedule', 'schedule', language_id)}</button>
                </div>
                <div className="opera">
                    <button className="close" onClick={this.props.closeModal}>{intl('model', 'close', language_id)}</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.getIn(['header', 'lang']),
        scheduleModalInfo: state.getIn(['main', 'scheduleModalInfo']).toJS(),
        language_id: state.getIn(['header', 'language_id']),
        user: state.getIn(['main', 'user']),
        filter: state.getIn(['schedule', 'filter']),
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        location_list_ungrouped: state.getIn(['schedule', 'location_list_ungrouped']).toJS(),
        region_id: state.getIn(['header', 'region_id']),
        isLogin: state.getIn(['main', 'isLogin']),
        mood_flag: state.getIn(['main', 'flags', flags.mood]),
        special_flag: state.getIn(['main', 'flags', flags.specialClass]),
        favourite_class_list: state.getIn(['schedule', 'favourite_class_type']),
        classtype_list: state.getIn(['schedule', 'classtypes']),
        scroll: state.getIn(['main', 'scroll']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //关闭弹窗
    closeModal(){
        dispatch(closeClassModal());
    },
    booking(id, status, booking_id) {
        let bookinfo = {
            class_id: id,
            status: status,
            jwt: this.jwt,
            filter: this.filter,
            booking_id,
            language_id: this.language_id,
            region_id: this.region_id,
            cancel_callback: this.cancel_class_callback,
            booking_callback: this.booking_class_callback,
        };
        dispatch(judgeBooking(bookinfo));
    },
    updateClassInfo(id, region_id, jwt, language_id, scheduleModalInfo) {
        dispatch(updateClassInfo(id, region_id, jwt, language_id, scheduleModalInfo));
    },
    toggleFavouriteClass(is_favourite, favourite_ids, favourite_id) {
        dispatch(toggleFavourite(is_favourite, favourite_ids, 'C', favourite_id, this.region_id));
    }
});

export default connect(mapStateToProps,mapDispathToProps)(ClassModal);