
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
    ContentRightItem,
    InfoItem,
    ControlItem,
} from '../style';
import { Switch, Icon, Modal } from 'antd';
import { changeUserInfo } from '../store/actionCreators';
import { intl } from '../../config/util';
import { setModal } from '../../common/main/store/actionCreators';
import { MyState } from '../../store/reducer.interface';

const mapStateToProps = (state: MyState) => {
    return {
        language_id: state.getIn(['header', 'language_id']),
        userInfo: state.getIn(['profile', 'userInfo']),
        regions: state.getIn(['header', 'regions']),
        region_id: state.getIn(['header', 'region_id']),
        lang: state.getIn(['header', 'lang']),
        user: state.getIn(['main', 'user']),
    };
};

const mapDispathToProps = (dispatch: any) => ({
    changeUserInfo(type: any, user: any, checked: any) {
        dispatch(changeUserInfo(type, user.get('jwt'), checked));
    },
    changeModal(opera: any, type: any) {
        dispatch(setModal(opera, type));
    }
});

const connector = connect(mapStateToProps, mapDispathToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export interface MySettingProps extends PropsFromRedux {

}
function MySetting(props: MySettingProps) {
    function showNotice (noticeType: any) {
        Modal.info({
            title: intl('other', noticeType === 'confirmEmailNotice' ? 'confirmationEmail' : 'autoWaitlist', props.language_id),
            content: intl('other', noticeType, props.language_id),
            className: 'noticeModal',
            okText: intl('other', 'ok', props.language_id),
            onOk() {
                console.log('OK');
            }
        });
    }

    function changeClockView(checked: any) {
        // (api) false = 24hr
        props.changeUserInfo('clock_view_12hr', props.user, !checked);
    }
    function changeEmail (checked: any) {
        props.changeUserInfo('email_reminder', props.user, checked);
    }
    function changeAuto (checked: any) {
        props.changeUserInfo('auto_waitlist', props.user, checked);
    }

    let { userInfo, language_id, regions, region_id, lang, user } = props;
    let city = regions.size > 0 ? regions.find((item: any) => item.get('id') === parseInt(region_id)).getIn(['names', lang]) : '';
    let jwt = user.size > 0 ? user.get('jwt') : '';

    return (
        <ContentRightItem className="mt100" id="setting" name="setting" key="setting">
            {!isMobile && <div className="title">{intl('other', 'settings', language_id)}</div>}
            {isMobile && <div>
                <InfoItem onClick={() => props.changeModal(true, 'language')}>
                    <span className="name"> Language/语言 </span>
                    <span className="info">{intl('other', 'lang-' + language_id, language_id)} <Icon type="right" /></span>
                </InfoItem>
                <InfoItem onClick={() => props.changeModal(true, 'region')}>
                    <span className="name">{intl('other', 'city', language_id)}</span>
                    <span className="info">{city} <Icon type="right" /></span>
                </InfoItem>
            </div>
            }
            <ControlItem>
                <span className="name">{intl('other', 'clockView', language_id)}</span>
                <div className="switch">
                    <Switch checked={!userInfo.get('clock_view_12hr')} onChange={changeClockView} />

                </div>
            </ControlItem>
            <ControlItem>
                <span className="name" onClick={() => showNotice('confirmEmailNotice')}>{intl('other', 'confirmationEmail', language_id)} <Icon type="question-circle" theme="filled" style={{ color: '#d9d9d9' }} /></span>
                <div className="switch">
                    <Switch checked={userInfo.get('email_reminder')} onChange={changeEmail} />

                </div>
            </ControlItem>
            {/* <ControlItem>
                <span className="name" onClick={() => showNotice('autoWaitlistNotice')}>{intl('other', 'autoWaitlist', language_id)} <Icon type="question-circle" theme="filled" style={{ color: '#d9d9d9' }} /></span>
                <div className="switch">
                    <Switch checked={userInfo.get('auto_waitlist')} onChange={changeAuto} />
                </div>
            </ControlItem> */}
        </ContentRightItem>
    );
}

export default connector(MySetting);