import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from './store';
import {
    ProfileContent,
    ProfileContentWrapper,
    Content,
    ContentLeft,
    ContentRight,
    AvatorInfo,
    InfoBtn,
    UserName,
    ContentRightItem,
    InfoItem,
    ControlItem,
    Nav,
} from './style';
import { Avatar, Switch, message, Icon, Modal  } from 'antd';
import { intl, showMsg } from '../config/util';
import MyPurchases from './component/my_purchases';
import MySignIns from './component/my_sign_ins';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-scroll';
import './profile.scss';
import { setModal, changeMoudle } from '../common/main/store/actionCreators';
import MyProfile from './component/MyProfile';
import ProfileTab from './component/profile_tab';
import MySetting from './component/MySetting';
class Profile extends Component {
    ref = React.createRef();
    previousHeaderHeight = '';

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            scene: 'myProfile',//当前场景
            showNoticeModal: false,//提示彈窗
            noticeType: '',//彈窗文本
            isActive: false,
            tab: '',
            initClassName: true,
            selectRegionId: localStorage.getItem('region_id'),
        };
        //获取用户信息
        props.getUserInfo(props.user);
    }
    showNotice = (noticeType) => {
        Modal.info({
            title: intl('other', noticeType === 'confirmEmailNotice' ? 'confirmationEmail' : 'autoWaitlist', this.props.language_id),
            content: intl('other', noticeType, this.props.language_id),
            className: 'noticeModal',
            okText: intl('other', 'ok', this.props.language_id),
            onOk() {
                console.log('OK');
            }
        });
    }
    hideNotice = () => {
        this.setState({
            showNoticeModal: false,
            noticeType: ''
        });
    }
    goBack = () => {
        this.props.changeProfileScene('myProfile');
        let hidenav = document.getElementById('addNav');
        if (hidenav) {
            hidenav.style.display = "block";
        }
    }
    changeEmail = (checked) => {
        this.props.changeUserInfo('email_reminder', this.props.user, checked);
    }
    changeAuto = (checked) => {
        this.props.changeUserInfo('auto_waitlist', this.props.user, checked);
    }
    handleClick = () => {
        this.setState({ isActive: !this.state.isActive });
    };
    //week发生变化时执行
    handleSetActive = (to) => {
        let tab = to;
        // set active tab to myProfile if in dummy link
        if (to === 'myProfile2') {
            tab = 'myProfile';
        }
        this.setState({
            initClassName: false,
            tab,
        });
    }
    additionalNav = () => {
        // const isActive = this.state.isActive;
        let { tab, initClassName } = this.state;

        if (isMobile) {
            return (
                <ul className="nav" id="addNav" ref={this.ref}>
                    <div>
                        <button className="back" onClick={this.props.back}><i className="iconfont icon-left"></i></button>
                    </div>
                    <div className="navbar-nav">
                        <Link
                            className={`${(initClassName || tab === 'myProfile') ? 'active' : ''}`}
                            key="myProfile"
                            activeClass="active"
                            to="myProfile"
                            spy={true}
                            smooth={true}
                            duration={250}
                            containerId="profileContent"
                            onSetActive={this.handleSetActive}
                        >
                            {intl('schedule', 'myProfile', this.props.language_id)}
                        </Link>
                        {/* myProfile is separated as 2 part, link 2nd part also*/}
                        <Link
                            className="dummy-link"
                            key="myProfile2"
                            activeClass="active-dummy"
                            to="myProfile2"
                            spy={true}
                            smooth={true}
                            duration={250}
                            containerId="profileContent"
                            onSetActive={this.handleSetActive}
                        >
                        </Link>
                        <Link
                            // className={`${tab === 'setting' ? 'active' : ''}`}
                            key="setting"
                            activeClass="active"
                            to="setting"
                            spy={true}
                            smooth={true}
                            duration={250}
                            containerId="profileContent"
                            onSetActive={this.handleSetActive}
                        >
                            {intl('other', 'settings', this.props.language_id)}
                        </Link>
                    </div>
                </ul>
            );
        }
    }
    hidenav() {
        let hidenav = document.getElementById('addNav');
        if (hidenav) {
            hidenav.style.display = "none";
        }
    }
    componentDidUpdate(prevProps, prevState) {
        let { language_id, lang, location_ids, history } = this.props;
        let regions = this.props.regions.toJS();

        if (prevProps.language_id !== language_id) {
            history.push(`/${lang}/${regions.find(item => item.checked).code}?location_ids=${location_ids || ''}`);
        }
        if (isMobile) {
            if (prevProps.scene !== this.props.scene) {
                // reset active tab on scene change
                this.setState({
                    tab: '',
                    initClassName: true
                });
            }
            if (!this.ref.current) {
                return;
            }

            // const profileContent = document.getElementById("profileContent");
            // const settingMinHeight = document.getElementById("setting");

            // if (profileContent) {
            //     settingMinHeight.style.minHeight  = profileContent.clientHeight + 'px';
            // }
        }
    }
    render() {
        let { language_id, order, signList, scene, clientServices, signList_by_date } = this.props;
        return (
            <Content className="myProfile">
                {scene === 'myProfile' && this.getMyProfile()}
                {
                    scene === 'myPurchases' &&
                    <MyPurchases
                        goBack={() => this.goBack()}
                        language_id={language_id}
                        getServicePlans={() => this.props.getServicePlans()}
                        clientServices={clientServices}
                    />
                }
                {
                    scene === 'mySignIns' &&
                    <MySignIns
                        chageOrder={() => this.props.changeOrder()}
                        getSignList={(data) => { this.props.getSignList(data); }}
                        goBack={() => this.goBack()}
                        language_id={language_id}
                        order={order}
                        signList={signList}
                        signList_by_date={signList_by_date}
                    />
                }
            </Content>
        );
    }

    getMyProfile() {
        let { userInfo, user, language_id, regions, lang, region_id } = this.props;
        const { selectRegionId } = this.state;
        let memberType = '';
        if (userInfo.size > 0) {
            if (userInfo.get('is_yoga') && userInfo.get('is_fitness')) {
                memberType = 'Yoga + Fitness';
            } else if (userInfo.get('is_yoga') && !userInfo.get('is_fitness')) {
                memberType = 'Yoga';
            } else {
                memberType = 'Fitness';
            }
        }
        let avatar = userInfo.get('picture') ? `data:image/png;base64,${userInfo.get('picture')}` : require('../assets/images/ic_default_image.png');
        let jwt = user.size > 0 ? user.get('jwt') : '';
        let noShowsColor = '#666666';
        if (userInfo.get('late_cancel_absent_count') <= 2 && userInfo.get('late_cancel_absent_count') > 0) {
            noShowsColor = '#000000';
        } else if (userInfo.get('late_cancel_absent_count') > 2) {
            noShowsColor = '#ED1C24';
        }
        let city = regions.size > 0 ? regions.find(item => item.get('id') === parseInt(region_id)).getIn(['names', lang]) : '';
        return (
            <ProfileContentWrapper>
                {
                    isMobile ? 
                    <ProfileTab />
                    :
                        <ProfileContent id="profileContent">
                            <MyProfile />
                        </ProfileContent>
                }
                {/* {this.additionalNav()}
                <MyProfile /> */}
                {/* <ProfileContent id="profileContent">
                    <ContentLeft>
                        <div className="content" id="myProfile" name="myProfile" key="myProfile">
                            <AvatorInfo>
                                <Avatar shape="square" size={194} src={avatar} icon="user" />
                                <div className="imagesfile">
                                    <input type="file" ref={input => this.input = input} onChange={this.handleChange} />
                                </div>
                            </AvatorInfo>
                            <UserName>{userInfo.get('first_name')} {userInfo.get('last_name')}</UserName>
                            <div className="infoDiv">
                                <InfoBtn>
                                    <b style={{ color: noShowsColor }}>{userInfo.get('late_cancel_absent_count')}</b>
                                    <p>{intl('other', 'noShows', language_id)}</p>
                                </InfoBtn>
                                <InfoBtn>
                                    <strong>{userInfo.get('state')}</strong>
                                    <p>{intl('schedule', 'status', language_id)}</p>
                                </InfoBtn>
                            </div>
                        </div>
                    </ContentLeft>
                    <ContentRight>
                        <div className="content">
                            <ContentRightItem id="myProfile2" name="myProfile2" key="myProfile2">
                                <div className="myProfileTitle title">{intl('schedule', 'myProfile', language_id)}</div>
                                <InfoItem>
                                    <span className="name">{intl('other', 'userId', language_id)}</span>
                                    <span className="info">{userInfo.get('mbo_rssid')}</span>
                                </InfoItem>
                                <InfoItem>
                                    <span className="name">{intl('schedule', 'email', language_id)}</span>
                                    <span className="info">{userInfo.get('email') === '' ? 'N/A' : userInfo.get('email')}</span>
                                </InfoItem>
                                <InfoItem>
                                    <span className="name">{intl('schedule', 'mobilePhone', language_id)}</span>
                                    <span className="info">{userInfo.get('mobile_phone') === '' ? 'N/A' : userInfo.get('mobile_phone')}</span>
                                </InfoItem>
                                <InfoItem>
                                    <span className="name">{intl('other', 'preferredLocation', language_id)}</span>
                                    <span className="info">{userInfo.get('preferred_location') || 'N/A'}</span>
                                </InfoItem>
                                <InfoItem>
                                    <span className="name">{intl('schedule', 'region', language_id)}</span>
                                    <span className="info">{userInfo.get('country')}</span>
                                </InfoItem>
                                <InfoItem>
                                    <span className="name">{intl('other', 'membershipType', language_id)}</span>
                                    <span className="info">{memberType}</span>
                                </InfoItem>
                                <InfoItem className="skip" onClick={() => this.props.changeProfileScene('myPurchases')}>
                                    <span className="name">{intl('other', 'myPurchases', language_id)}</span>
                                    <span className="info"><Icon type="right" /></span>
                                </InfoItem>
                                <InfoItem className="skip" onClick={() => this.props.changeProfileScene('mySignIns')}>
                                    <span className="name">{intl('other', 'mySignIns', language_id)}</span>
                                    <span className="info"><Icon type="right" /></span>
                                </InfoItem>
                            </ContentRightItem>
                            <ContentRightItem className="mt100" id="setting" name="setting" key="setting">
                                <div className="title">{intl('other', 'settings', language_id)}</div>
                                {isMobile && <div>
                                    <InfoItem onClick={()=>this.props.changeModal(true,'language')}>
                                        <span className="name"> Language/语言 </span>
                                        <span className="info">{ intl('other', 'lang-'+this.props.language_id, this.props.language_id) } <Icon type="right" /></span>
                                    </InfoItem>
                                    <InfoItem  onClick={()=>this.props.changeModal(true,'region')}>
                                            <span className="name">{intl('other','city',language_id)}</span>
                                            <span className="info">{city} <Icon type="right" /></span>
                                    </InfoItem>
                                </div>
                                }
                                <ControlItem>
                                    <span className="name" onClick={() => this.showNotice('confirmEmailNotice')}>{intl('other', 'confirmationEmail', language_id)} <Icon type="question-circle" theme="filled" style={{ color: '#d9d9d9' }} /></span>
                                    <div className="switch">
                                        <Switch checked={userInfo.get('email_reminder')} onChange={this.changeEmail} type={'email_reminder'} jwt={jwt}/>

                                    </div>
                                </ControlItem>
                                <ControlItem>
                                    <span className="name" onClick={() => this.showNotice('autoWaitlistNotice')}>{intl('other', 'autoWaitlist', language_id)} <Icon type="question-circle" theme="filled" style={{ color: '#d9d9d9' }} /></span>
                                    <div className="switch">
                                        <Switch checked={userInfo.get('auto_waitlist')} onChange={this.changeAuto} type={'auto_waitlist'} jwt={jwt}/>
                                    </div>
                                </ControlItem>
                            </ContentRightItem>
                        </div>
                    </ContentRight>
                </ProfileContent> */}
            </ProfileContentWrapper>
        );
    }

    //监听上传
    handleChange = () => {
        var file = this.input.files[0];
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            showMsg('error', 'You can only upload JPG/PNG file!', 2);
        } else if (file.size / 1024 / 1024 > 2) {
            showMsg('error', 'Image must smaller than 2MB!', 2);
        } else {
            var reader = new FileReader();
            reader.onload = () => {
                let base64 = reader.result.split('base64,')[1];
                //上传图片
                this.props.changeAvatar(base64);
            };
            reader.readAsDataURL(file);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.getIn(['main', 'user']),
        scene: state.getIn(['profile', 'scene']),
        userInfo: state.getIn(['profile', 'userInfo']),
        language_id: state.getIn(['header', 'language_id']),
        regions: state.getIn(['header', 'regions']),
        region_id: state.getIn(['header', 'region_id']),
        type: state.getIn(['header', 'type']),
        location_list: state.getIn(['schedule', 'location_list']),
        order: state.getIn(['profile', 'order']),
        signList: state.getIn(['profile', 'signList']),
        signList_by_date: state.getIn(['profile', 'signList_by_date']).toJS(),
        signPageInfo: state.getIn(['profile', 'signPageInfo']),
        clientServices: state.getIn(['profile', 'clientServices']).toJS(),
        lang: state.getIn(['header', 'lang']),
        location_ids: state.getIn(['schedule', 'location_ids']),
    };
};

const mapDispathToProps = (dispatch) => ({
    getUserInfo(value) {
        dispatch(actionCreators.getClientInfo(value.get('jwt')));
    },
    changeUserInfo(type, user, checked) {
        dispatch(actionCreators.changeUserInfo(type, user.get('jwt'), checked));
    },
    changeAvatar(picture) {
        dispatch(actionCreators.changeAvatar(picture, this.user.get('jwt')));
    },
    getSignList(data) {
        let formData = {
            language_id: this.language_id,
            region_id: this.region_id,
            jwt: this.user.get('jwt'),
            order: this.order,
            ...data,
        };
        dispatch(actionCreators.getSignList(formData));
    },
    changeOrder() {
        dispatch(actionCreators.changeOrder(this.signList, this.order === 'asc' ? 'desc' : 'asc'));
    },
    changeProfileScene(scene) {
        dispatch(actionCreators.changeProfileScene(scene));
    },
    getServicePlans() {
        dispatch(actionCreators.getServicePlans(this.region_id, this.user.get('jwt')));
    },
    //返回
    back() {
        let hideFooter = document.getElementById("footerTop");
        hideFooter.style.display = "block";
        dispatch(changeMoudle('Schedule'));
    },
    //弹窗设置
    changeModal(opera, type) {
        dispatch(setModal(opera, type));
    }
});

export default connect(mapStateToProps, mapDispathToProps)(Profile);