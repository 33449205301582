import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal } from '../../../common/main/store/actionCreators';
import { actionCreators as scheduleActionCreators } from '../../store';
import './scheduleFilter.less';
import { intl } from '../../../config/util';
import { getLangTitle } from '../../../config/util';
import { changeFilterChoose } from '../../store/actionCreators';
import { fromJS } from 'immutable';

class ScheduleFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    render() {
        let { location_list, location_ids, filter, language_id, mood, class_mood_list, mood_ids} = this.props;
        const lang = getLangTitle(language_id);
        let whiteArrow = require('../../../assets/images/arrow_list_filter_white.png');
        let greyArrow = require('../../../assets/images/arrow_list_filter.png');
        let chooseLocation = "";
        let chooseMood = "";
        let locationIdsString = "";
        let moodIdsString = "";
        let defaultWhite = "#fff";
        if (filter.location_ids){
            chooseLocation = location_list && filter.sector && location_list[filter.sector].find(item => item.id === parseInt(location_ids));
            locationIdsString = this.props.location_ids.toString().split(',');
        }
        if(class_mood_list){
            if (filter.mood_ids){
                chooseMood = class_mood_list.find(item => item.id === parseInt(mood_ids));
                moodIdsString = this.props.mood_ids.split('|');
            }
        }
        return (
            <div className="Schedulefilter">
                <div className="sector">
                    <div className={`item yoga ${filter.sector ==='Y'?'active':''}`} onClick={()=>this.props.changeScheduleType('Y')}>
                        {intl('schedule', 'yoga', language_id)}
                    </div>
                    <div className={`item fitness ${filter.sector ==='F'?'active':''}`} onClick={()=>this.props.changeScheduleType('F')}>
                        {intl('schedule', 'fitness', language_id)}
                    </div>
                    {
                        mood &&
                        <div className={`item mood`}  style={chooseMood ? {background:`url(${whiteArrow}) ${chooseMood.color}`, color:defaultWhite}:{background: `url(${greyArrow}) ${defaultWhite}`}} onClick={()=>this.props.changeModal(true,'moods')}>
                            <p>
                                {/* {moodIdsString.length > 1 ? moodIdsString.length + ' ' + intl('schedule', 'n_moods', language_id):chooseMood ? chooseMood.names[lang] : intl('schedule', 'myMood', language_id)} */}
                                {chooseMood ? chooseMood.names[lang] : intl('schedule', 'mood', language_id)}
                            </p>
                        </div>
                    }
                </div>
                <div className="locate">
                    <div className="location">
                        <div className="active_location" onClick={()=>this.props.changeModal(true,'locations')}>
                            {locationIdsString.length > 1 ? locationIdsString.length + ' ' + intl('schedule', 'n_locations', language_id):chooseLocation ? chooseLocation.short_name[lang] : ''}
                        </div>
                    </div>
                    <button className="filter_btn" onClick={() => this.props.changeModal(true, 'scheduleFilter')}>{intl('schedule', 'filter', language_id)}</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        location_list: state.getIn(['schedule', 'location_list']).toJS(),
        location_id: state.getIn(['main', 'location_id']),
        location_ids: state.getIn(['schedule', 'filter', 'location_ids']),
        mood_ids: state.getIn(['schedule', 'filter', 'mood_ids']),
        filter: state.getIn(['schedule', 'filter']).toJS(),
        language_id: state.getIn(['header', 'language_id']),
        user: state.getIn(['main', 'user']),
        type: state.getIn(['header', 'type']),
        class_mood_list: state.getIn(['schedule', 'class_mood_list']).toJS(),
    };
};


const mapDispathToProps = (dispatch) => ({
    //修改课程分类
    changeScheduleType(type) {
        dispatch(changeFilterChoose(type, 'sector', fromJS(this.filter), this.weeks, this.jwt, this.location_list));
    },
    //弹窗设置
    changeModal(opera,type){
        dispatch(setModal(opera, type));
    }
});

export default connect(mapStateToProps,mapDispathToProps)(ScheduleFilter);