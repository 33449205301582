import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd-mobile';
import { closeRegionSelectModal, setModal } from '../../main/store/actionCreators';
import { setRegion } from '../../header/store/actionCreators';
import { getChangeRegionTipsInfo, intl } from '../../../config/util';
import './regionSelect.scss';

const alert = Modal.alert;

class RegionSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectRegionId: props.regionSelectModalInfo.regionId || parseInt(localStorage.getItem('region_id')),
            selectLanguageId: localStorage.getItem('language_id'),
        };
    }

    componentDidMount() {
        if (this.props.regionSelectModalInfo.silentTrigger) {
            this.handleChange();
        }
    }

    //确定按钮点击
    handleChange = () => {
        // if(parseInt(this.state.selectRegionId) !== parseInt(this.props.region_id)){
        //     this.props.changeRegion(this.state.selectRegionId, this.props.regions);
        // }else{
        //     this.props.changeModal(false,'');
        // }

        if (parseInt(this.state.selectRegionId) !== parseInt(this.props.region_id)) {

            let tips = getChangeRegionTipsInfo(this.props.region_id, this.state.selectRegionId, this.state.selectLanguageId);
            if (this.props.isLogin) {
                alert(intl('other', 'tips', this.props.language_id), <div>{tips.title}</div>, [
                //     {
                //         text: tips.loginAnotherRegion, onPress: () => {
                //         //登入新region
                //         this.props.checkRegion(this.state.selectRegionId)
                //         this.props.changeModal(true,'login')
                //     }
                // },
                    { text: tips.logout, onPress: () => {
                        //登出，然后进入新的region
                        this.props.changeRegion(this.state.selectRegionId);
                    }},
                    { text: tips.cancel, onPress: () => {
                        this.props.closeModal();
                    }}
                ]);
            } else {
                this.props.changeRegion(this.state.selectRegionId);
                return;
                // this.props.checkRegion(this.state.selectRegionId);
                // this.props.changeModal(true,'login');
            }
        }
    }

    render() {
        const { selectRegionId } = this.state;
        const { regions, lang, language_id, regionSelectModalInfo } = this.props;
        const isSilent = regionSelectModalInfo.silentTrigger;

        return (
            <div className={`pop_list_wra ${isSilent ? 'silent' : ''}`}>
                <div className="titile">{intl('other', 'chooseCityDisc', language_id)}</div>
                <div className="pop_content">
                    {
                        regions.map(item => {
                            return (
                                <div className="panel" key={item.get('id')} onClick={()=>{this.setState({selectRegionId: item.get('id')});}}>
                                    <span className="name">{ item.getIn(['names',lang]) }</span>
                                    {selectRegionId === item.get('id') && <i className="iconfont icon-check"></i>}
                                </div>
                            );
                        })
                    }
                </div>
                <div className="opera">
                    <button className="true" onClick={this.handleChange}>{intl('other', 'ok', language_id)}</button>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    let regionSelectModalInfo = state.getIn(['main', 'regionSelectModalInfo']);
    if (regionSelectModalInfo && regionSelectModalInfo.toJS) {
        regionSelectModalInfo = regionSelectModalInfo.toJS();
    } else {
        regionSelectModalInfo = {};
    }
    return {
        languages: state.getIn(['header','languages']),
        language_id: state.getIn(['header','language_id']),
        region_id: state.getIn(['header','region_id']),
        regions: state.getIn(['header','regions']),
        lang: state.getIn(['header','lang']),
        isLogin: state.getIn(['main', 'isLogin']),
        regionSelectModalInfo,
    };
};

const mapDispathToProps = (dispatch) => ({
    //弹窗设置
    changeModal(opera,type){
        dispatch(setModal(opera, type));
    },
    closeModal() {
        dispatch(closeRegionSelectModal());
    },
    //修改语言和城市
    changeRegion(region_id){
        // dispatch(changeRegion(region_id, regions));
        dispatch(setRegion(region_id));
    }
});

export default connect(mapStateToProps,mapDispathToProps)(RegionSelect);