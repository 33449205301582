import * as constants from './constants';
import { fromJS } from 'immutable';
import { get_booking_history, 
         cancel_booking, 
         get_upcoming_classes} from '../../config/request';
import { bookingFormatDate,
         bookedTimeFormatDate,
         bookingSuccessMsg,
         showModel,
         showConfirm,
         showMsg,
         catchError,
         bookingContent,
         nowDate,
         getDayAfterN,
         formatDate,
         objSortByDate } from '../../config/util';
import { signOut } from '../../common/main/store/actionCreators';
import { changeLoading } from '../../common/header/store/actionCreators';
import { getSchedule } from '../../schedule/store/actionCreators';
import { isMobile } from 'react-device-detect';
//获取超时&&迟到记录
export const getCancelList = (params, order) => {
    return (dispatch) => {
        showMsg('loading');
        dispatch(changeCancelListLoading(true));
        get_booking_history({
            language_id: params.language_id,
            region_id: params.region_id,
            type:'cancel',
            start_date:params.start_date,
            end_date:params.end_date
        },params.jwt).then(res => {
            showMsg('hide');
            dispatch(changeCancelListLoading(false));

            if (res.data.error.code === 200) {
                let data = res.data.data.bookings;
                let cancelListByDate = {};
                let cancelList = data.map(item => {
                let startDate = item.class.start_date;

                if (cancelListByDate.hasOwnProperty(startDate)) {
                    cancelListByDate[startDate].list.push(item);
                } else {
                    cancelListByDate[startDate] = {
                        dateInfo:formatDate(startDate),
                        list: [item]
                    };
                }
                let time = startDate +' '+ item.class.start_time;
                let classTime = bookingFormatDate(time, params.language_id);
                // item.classTime = classTime.week +' '+ classTime.title +'  '+ item.class.start_time.replace(/:.{2}$/,'');
                item.classTime = classTime.week + ' ' + classTime.title + '  ' + item.class.start_time_display;

                if (item.cancel_date) {
                    // item.canceledTime = bookedTimeFormatDate(item.cancel_date + ' ' + item.cancel_time, params.language_id);
                    item.canceledTime = bookedTimeFormatDate(item.cancel_date, item.cancel_time_display, params.language_id);
                } else {
                    item.canceledTime = 'N/A';
                }
                if (item.booked_date) {
                    // item.bookedTime = bookedTimeFormatDate(item.booked_date + ' ' + item.booked_time, params.language_id);
                    item.bookedTime = bookedTimeFormatDate(item.booked_date, item.booked_time_display, params.language_id);
                } else {
                    item.bookedTime = 'N/A';
                }

                return {
                    time,
                    booking_id: item.booking_id,
                    classDate: item.classTime,
                    classTime: item.class.start_time.replace(/:.{2}$/,''),
                    canceledTime: item.canceledTime,
                    cancelled_by: item.cancelled_from,
                    class_type: item.class.class_type,
                    teacher: item.class.teacher,
                    duration: item.class.duration / 60000,
                    location: item.class.location,
                    bookedTime: item.bookedTime,
                    button_status: item.button_status,
                    special: item.class.special,
                    vibe: item.class.mood,
                };
            });
            cancelList.sort(function (a, b) {
                if (order === 0 || order === -1){
                    return Date.parse(a.time.replace(/-/g, "/")) - Date.parse(b.time.replace(/-/g, "/"));
                } else {
                    return Date.parse(b.time.replace(/-/g, "/")) - Date.parse(a.time.replace(/-/g, "/"));
                }
            });

            dispatch(saveCancelListALL({
                cancelList,
                cancelListByDate,
            }));
        } else {
            console.log('错误'+res);
        }
        }).catch(error => {
            dispatch(changeLoading(false));
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

//获取预约列表信息
export const getBookingList = (params, order) => {
    showMsg('loading');
    return (dispatch) => {
        dispatch(changeBookListLoading(true));
        get_booking_history({
            language_id: params.language_id,
            region_id: params.region_id,
            type: 'all',
            start_date:params.start_date,
            end_date: params.end_date,
            api_version: 3,
        },params.jwt).then(res => {
            showMsg('hide');
            dispatch(changeBookListLoading(false));

            if (res.data.error.code === 200) {
                let data = res.data.data.bookings;
                let bookingsByDate = {};
                let myBookings = data.map(item=>{
                    if (bookingsByDate[item.class.start_date]) {
                        bookingsByDate[item.class.start_date].push(item);
                    } else {
                        bookingsByDate[item.class.start_date]= [item];
                    }
                    let itemMod = Object.assign({}, item);
                    itemMod.time = itemMod.class.start_date + ' ' + itemMod.class.start_time;
                    // itemMod.classTime = itemMod.class.start_time.replace(/:.{2}$/,'');
                    itemMod.classTime = itemMod.class.start_time_display;
                    itemMod.classDate = bookingFormatDate(itemMod.time, params.language_id);
                    itemMod.dateTime = itemMod.classDate.week +' '+ itemMod.classDate.title +'  '+ itemMod.classTime;
                    return itemMod;
                });
                myBookings.sort(function (a, b) {
                    if (order === 0 || order === -1){
                        return Date.parse(a.time.replace(/-/g, "/")) - Date.parse(b.time.replace(/-/g, "/"));
                    } else {
                        return Date.parse(b.time.replace(/-/g, "/")) - Date.parse(a.time.replace(/-/g, "/"));
                    }
                });
                dispatch(saveMyBookingsALL({
                    myBookings,
                    bookingsByDate,
                }));
                if (order !== -1) {
                    dispatch(changeOrder(order));
                }
            } else {
                console.log('错误'+res);
            }
        }).catch(error => {
            dispatch(changeLoading(false));
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

//修改列表的顺序（同时修改两个）
export const changeListOrder = (order,list) => {
    return (dispatch) => {
        let myBookings = list.myBookings.toJS();
        let cancelList = list.cancelList.toJS();

        if (myBookings.length > 0) {
            myBookings.sort(function (a, b) {
                if (order === 0 || order === -1){
                    return Date.parse(a.time.replace(/-/g, "/")) - Date.parse(b.time.replace(/-/g, "/"));
                } else {
                    return Date.parse(b.time.replace(/-/g, "/")) - Date.parse(a.time.replace(/-/g, "/"));
                }
            });
            dispatch(saveMyBookings(myBookings));
        }

        if (cancelList.length > 0) {
            cancelList.sort(function (a, b) {
                if (order === 0 || order === -1) {
                    return Date.parse(a.time.replace(/-/g, "/")) - Date.parse(b.time.replace(/-/g, "/"));
                } else {
                    return Date.parse(b.time.replace(/-/g, "/")) - Date.parse(a.time.replace(/-/g, "/"));
                }
            });
            dispatch(saveCancelList(cancelList));
        }

        dispatch(changeOrder(order));
    };
};

//判断取消预约||排队
export const cancelBooking = (data,params,order,filter,jwt) => {
    return (dispatch) => {
        let confirmInfo = bookingContent(data.language_id, data.status);
        switch (data.status) {
            case 2://取消预约
                showConfirm(confirmInfo, () => {
                    dispatch(cancel(data,params,order,filter,jwt));
                });
                break;
            case 4://取消候补
                showConfirm(confirmInfo, () => {
                    dispatch(cancel(data,params,order,filter,jwt));
                });
                break;
            case 100://强制取消预约
                showConfirm(confirmInfo, () => {
                    dispatch(cancel(data,params,order,filterr,jwt));
                });
                break;
            default:
                break;
        }
    };
};

//取消预约
export const cancel = (info,params,order,filter,jwt) => {
    return (dispatch) => {
        let late_cancel = info.status === 100 ? 1 : 0;
        cancel_booking({
            booking_id: info.booking_id,
            late_cancel,
            cancelled_from: 'WEB',
            region_id: info.region_id
        },info.jwt).then(res => {
            let language_id = info.language_id;

            if (parseInt(res.data.error.code) === 200) {
                showModel(bookingSuccessMsg(language_id, info.status), 'success');
                //重新请求数据
                dispatch(getBookingList(params,order));
                dispatch(getSchedule(filter.toJS(), jwt));
            } else if (parseInt(res.data.error.code) === 416){
                let newInfo = info;
                newInfo.status = 100;
                dispatch(cancelBooking(newInfo,params,order));
            } else {
                showMsg('error',res.data.error.message);
            }
        }).catch(error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

//获取已预约未来课程
export const getUpcomingClasses = (region_id, language_id, jwt) => {
    return dispatch => {
        let start_date = nowDate();
        let end_date = getDayAfterN(new Date(), 7).date;
        get_upcoming_classes({ region_id, language_id, start_date, end_date }, jwt).then(res => {
            // window.$toast('hide');
            let upcoming_classes = res.data.data.bookings;
            dispatch(changeUpcomingClass(upcoming_classes));
        }, error => {
            catchError(error, localStorage.getItem('language_id'), () => {
                dispatch(signOut());
            });
        });
    };
};

//修改删除历史排序
export const changeCancellationsOrder = (order) => {
    return dispatch => {
        dispatch(changeOrder(order));
    };
};

//预约列表的loading控制
export const changeCancelListLoading = (value) => ({
    type: constants.CHANGE_CANCEL_LIST_LOADING,
    value
});

//预约列表的loading控制
export const changeBookListLoading = (value) => ({
    type: constants.CHANGE_BOOK_LIST_LOADING,
    value
});

//保存预约信息
export const saveMyBookings = (value) => ({
    type: constants.SAVE_BOOKINGS,
    value: fromJS(value)
});

//保存预约信息(ALL)
export const saveMyBookingsALL = (value) => ({
    type: constants.SAVE_BOOKINGS_ALL,
    value: fromJS(value)
});

//改变预约列表顺序
export const changeOrder = (value) => ({
    type: constants.CHANGE_ORDER,
    value: value
});
//报错超时迟到列表
export const saveCancelList = (value) => ({
    type: constants.SAVE_CANCEL_LIST,
    value: fromJS(value)
});
//报错超时迟到列表(ALL)
export const saveCancelListALL = (value) => ({
    type: constants.SAVE_CANCEL_LIST_ALL,
    value: fromJS(value)
});
//报错超时迟到列表(By date)
export const saveCancelListByDate = (value) => ({
    type: constants.SAVE_CANCEL_LIST_BY_DATE,
    value: fromJS(value)
});
//修改场景
export const changeShowBookList = (value) => ({
    type: constants.CHANGE_SHOW_BOOK_LIST,
    value
});

export const changeUpcomingClass = value => ({
    type: constants.CHANGE_UPCOMING_CLASS,
    value: fromJS(value)
});

export const changeScene = value => ({
    type: constants.CHANGE_SCENE,
    value: fromJS(value)
});

export const changeCancelDetails = value => ({
    type: constants.CHANGE_CANCEL_DETAILS,
    value: fromJS(value)
});