import { createGlobalStyle } from 'styled-components';
import { isMobile } from 'react-device-detect';

const fitnessTheme = '#F00F64';
const fitnessAuxiliary = '#a50d13';
const yogaTheme = '#14E1DC';

export const Globalstyle = createGlobalStyle`
    html{
        font-family: 'HN', Helvetica, Arial, sans-serif, monospace;
        font-size: 37.5px;
    }
    body{
        ${isMobile ? '' : 'min-width:1280px; font-size: 14px; line-height: 1.5;'}
        .ant-btn:active, .ant-btn.active {
            border-color: none;
        }
        #${props => (props.mainClass)}{
            .ant-btn-primary{
                color: #fff!Important;
                &,&:hover,&:focus{
                    color: #fff;
                    background-color: ${props => (props.themeColor)};
                    border-color:  ${props => (props.auxiliaryColor)};
                }
            }
            .ant-btn:hover, .ant-btn:focus {
                color: ${props => (props.themeColor)};
                border-color: ${props => (props.themeColor)};
            }
            .shadow_content .login_box .login_form .ant-checkbox-checked .ant-checkbox-inner{
                border-color: ${props => (props.themeColor)};
                background-color: ${props => (props.themeColor)};
            }
            .ant-switch-checked,.ant-spin-dot i{
                background-color: ${props => (props.themeColor)};
            }
            .ant-spin-text{
                color: ${props => (props.themeColor)};
            }
            .scheduleContent .ant-btn:hover, .ant-btn:focus{
                // color: ${props => (props.themeColor)};
                border-color: ${props => (props.themeColor)};
            }
            .changeWeek span:hover,.changeWeek span.on{
                color: ${props => (props.themeColor)};
            }
            .login_before,.login_after{background: #f2f2f2;}
            button.skip{
                color: ${props => (props.themeColor)};
            }
        }
    }
    
    .ant-popover-inner{
        border-radius: 30px!important;box-shadow:0 3px 18px rgba(0, 0, 0, 0.15)!important;
    }

    .classInfo .title{
        font-weight: bold;text-align: center;font-size: 20px;
    }
    .classInfo .attr{
        text-align: center;font-size: 14px;font-weight: bold;
    }
    .classInfo .attr span{
        padding: 0 5px;
    }

    .classInfo .attr em{
        position: relative;
    }

    .classInfo .attr em:after{
        content: '';width: 4px;height: 4px;border-radius: 50%;background: #999;position: absolute;left: 50%;top: 50%;margin-top: -2px;margin-left: -2px;
    }
    .teacherInfo{
        max-height: 580px;
        overflow-y: scroll;
    }
    /* .teacherInfo .description{
        max-height: 250px;
        overflow-y: scroll;
    } */
    .ant-modal-content{
        border-radius:20px!important;
    }

    .ant-modal-wrap.changeRegionWrap{
        .ant-modal-title{
            padding:0 0 0 150px
            font-size: 20px;
            color:#000;
        }
        .ant-modal-header{
            border-radius: 20px 20px 0 0;
            border-bottom: none;
        }
        .ant-modal-body{
            font-size: 18px;
        }
        .ant-modal-footer{
            border-top: none;
            .model_line .ant-btn{
                display: block;
                width: 100%;
                margin-bottom: 10px;
                height: 48px;
                border-radius: 10px;
                font-size: 16px;
            }
            .model_line:nth-child(-n + 2) .ant-btn{
                background:#000;
                color: #fff;
                border-color:#000;
            }
            .model_line:last-child .ant-btn{
                background:#fff;
                color: #555;
                border-color:#aaa;
            }
        }
        .ant-modal-body{
            padding: 18px;
            white-space: pre-line;
            line-height: 1.4;
        }
    }
`