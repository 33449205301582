import React, { Component } from 'react';
import { intl } from '../../config/util';
import { Icon } from 'antd';
import { Modal } from 'antd';
import './my_service.scss';
import { isMobile } from 'react-device-detect';

class MyPurchases extends Component {
    constructor(props){
        super(props);
        this.state = {
            showDetails: false,
            popDetailsInfo: {},
        };
    }
    changeWeekType=()=>{
        let type = this.props.weekType === 1 ? 2 : 1;
        this.props.ChangeWeekType({type,jwt: this.props.jwt});
    }

    componentDidMount() {
        this.props.getServicePlans();
    }

    //弹窗
    showServiceDetails(data) {
        this.setState({
            showDetails: true,
            popDetailsInfo: data
        });
        Modal.info({
            title: (
                <>
                <h3>{data.service_name}</h3>
                <h3>{data.real_remaining}/{data.client_entitled_session}</h3>
                </>),
            content: (
                <div className="my-service-wrapper">
                  <div className="item">
                                <div className="l">{intl('other','realRemaining',this.props.language_id)}</div>
                                <div className="r">{data.real_remaining}</div>
                            </div>
                            <div className="item">
                                <div className="l">{intl('other','bookedSessions',this.props.language_id)}</div>
                                <div className="r">{data.booked_sessions}</div>
                            </div>
                            <div className="item">
                                <div className="l">{intl('other','completedSessions',this.props.language_id)}</div>
                                <div className="r">{data.completed_sessions}</div>
                            </div>
                            <hr/>
                            <div className="item">
                                <div className="l">{intl('other','entitledSession',this.props.language_id)}</div>
                                <div className="r">{data.entitled_session}</div>
                            </div>
                </div>
              ),
            className: 'noticeModal',
            okText: intl('other','ok',this.props.language_id),
        });
    }
    closeServiceDetails(){
        this.setState({
            showDetails: false,
            popDetailsInfo: {}
        });
    }
    hidenav(){
        let hidenav = document.getElementById('addNav');
        if (hidenav) {
            hidenav.style.display = "none";
        }
    }


    render() {
        let { language_id, clientServices } = this.props;
        let { showDetails, popDetailsInfo } = this.state;
        return (
            <div className="my-service-wrapper mypurchases">
                {this.hidenav()}
                <div className="components_title">
                    <button className="back" onClick={() => {this.props.goBack();}}>
                        {isMobile ? <i className="iconfont icon-left"></i>
                            :
                            <Icon type="left" style={{fontSize:26,color:'#333'}} />
                        }
                    </button>
                    <span>{intl('other','myPurchases',language_id)}</span>
                </div>
                <div className="getServicePlans">
                    <ul>
                        {
                            clientServices.map(item => {
                                return (
                                    <li key={item.client_service_id}>
                                        <h4>{item.service_name}</h4>
                                        <p>
                                            {intl('other',item.service_type === 'DropIn' || item.service_type === 'Arrival' ? 'paymentStartDate':'activationDate',language_id)}：
                                            {parseInt(language_id) === 1 ? item.startDateInfo.enDate : item.startDateInfo.date}
                                        </p>
                                        <p>
                                            {intl('other',item.service_type === 'DropIn' || item.service_type === 'Arrival' ? 'paymentEndDate':'expirationDate',language_id)}：
                                            { parseInt(language_id) === 1 ? item.endDate.enDate : item.endDate.date }
                                        </p>

                                        {
                                            item.client_entitled_session < 1000 && item.service_name.includes('Unlimited') == false ?
                                            <div className="aside">
                                                <h5>{item.real_remaining}/{item.client_entitled_session}</h5>
                                                <Icon type="question-circle" onClick={ () => this.showServiceDetails(item) } theme="filled" style={{ color: '#d9d9d9' }}/>
                                            </div>
                                            : ''
                                        }
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}
export default MyPurchases;