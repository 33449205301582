export const SAVE_BOOKINGS = 'booking/SAVE_BOOKINGS';
export const SAVE_BOOKINGS_ALL = 'booking/SAVE_BOOKINGS_ALL';
export const CHANGE_ORDER = 'booking/CHANGE_ORDER';
export const SAVE_CANCEL_LIST = 'booking/SAVE_CANCEL_LIST';
export const SAVE_CANCEL_LIST_ALL = 'booking/SAVE_CANCEL_LIST_ALL';
export const SAVE_CANCEL_LIST_BY_DATE = 'booking/SAVE_CANCEL_LIST_BY_DATE';
export const CHANGE_BOOK_LIST_LOADING = 'booking/CHANGE_BOOK_LIST_LOADING';
export const CHANGE_CANCEL_LIST_LOADING = 'booking/CHANGE_CANCEL_LIST_LOADING';
export const CHANGE_SHOW_BOOK_LIST = 'booking/CHANGE_SHOW_BOOK_LIST';
export const CHANGE_UPCOMING_CLASS = 'booking/CHANGE_UPCOMING_CLASS';
export const CHANGE_SCENE = 'schedule/CHANGE_SCENE';
export const CHANGE_CANCEL_DETAILS = 'schedule/CHANGE_CANCEL_DETAILS';
