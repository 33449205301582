import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../config/util';
import {Icon, Affix} from 'antd';
import { actionCreators } from '../store';
class DateItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            date_list:[],
            one_load:true,
            weekType:1
        };
    }


    toPrevWeek=()=>{
        this.props.ChangeWeekType({type:1,jwt: this.props.jwt});
    }
    toNextWeek = () => {
        this.props.ChangeWeekType({type:2,jwt: this.props.jwt});
    }

    toPrevDay=()=>{
        var day_type = this.props.day_type;
        if (day_type==1) {
            this.props.dayType(1);
        } else {
            day_type = day_type-1;
            this.props.dayType(day_type);
        }
    }
    toNextDay=()=>{
        var day_type = this.props.day_type;
        if (day_type==6) {
            this.props.dayType(6);
        } else {
            day_type = day_type+1;
            this.props.dayType(day_type);
        }
    }
    componentWillReceiveProps(){

        if (this.state.weekType != this.props.weekType) {
            this.setState({
                weekType:this.props.weekType,
                one_load:true
            });
        }
        if (this.props.list.size>0 && this.state.one_load) {
            var date_list = this.props.list.toJS();

            this.setState({
                date_list,
                one_load:false
            });
            var today = 1;
            for (var i=0;i<date_list.length;i++) {
                if (date_list[i].day == 'today') {
                    var day = date_list[i].value;
                    var date = new Date(day).getDay();
                    today = date;
                }
            }
            // this.props.dayType(today)
            this.props.dayType(1);
        }
    }

    dayType = (day) => {
            switch(day){
                case 1:
                return [1,2,3];
                case 2:
                return [2,3,4];
                case 3:
                return [3,4,5];
                case 4:
                return [4,5,6];
                case 5:
                return [5,6,7];
                case 6:
                return [5,6,7];
                case 0:
                return [5,6,7];
            }
    }

    render() {

        return (
            <tr>
                <th width="100%" colSpan="8">
                    <Affix  className="dateLine">
                        <div className={`date ${this.props.location_num>1 ? 'date3':''}`}>
                            {
                                // this.props.location_num > 1 &&
                                    this.props.day_type > 1 ?
                                <button className="toPrevWeek" onClick={this.toPrevDay}>
                                <Icon type='left' style={{fontSize:'30px'}} />
                                </button>
                                :
                                    // (this.props.location_num == 1 || this.props.location_num == 0) &&
                                    this.props.dateType === 'basic' && this.props.weekType === 2 &&
                                <button className="toPrevWeek" onClick={this.toPrevWeek}>
                                    <Icon type='left' style={{fontSize:'30px'}} />
                                </button>

                            }
                            {
                                // this.props.location_num > 1 &&
                                    this.props.day_type < 5 ?
                                <button className="toNextWeek" onClick={this.toNextDay}>
                                    <Icon type='right' style={{fontSize:'30px'}} />
                                </button>
                                : null
                            }
                            {
                                this.props.dateType === 'print' && <strong>{intl('schedule','time',this.props.language_id)}</strong>
                            }
                        </div>
                        {
                            this.props.location_num>1?
                            this.props.list.map((item,index)=>{

                                var day_type = this.dayType(this.props.day_type);
                                var isreturn = true;
                                for (var i=0;i<day_type.length;i++) {
                                    if (index+1 == day_type[i]) {
                                        isreturn = false;
                                    }
                                }

                                if (isreturn) {
                                    return false;
                                }

                                return (
                                <div key={index} width="33%" className={`date ${item.get('day') === 'today' ? 'today':''}`}>
                                    <p className="week">{item.get('day') === 'today' ? intl('schedule','today',this.props.language_id): item.get('week')}</p>
                                    <p className="day">{item.get('dayTime')}</p>
                                    {
                                        // this.props.location_num>1?
                                        // null
                                        // :
                                        index === this.props.list.size - 1 && this.props.weekType === 1 ?
                                        <button className="toNextWeek" onClick={this.toNextWeek}>
                                            <Icon type='right' style={{fontSize:'30px'}} />
                                        </button>: null
                                    }
                                </div>
                               );
                            }
                            ):
                            this.props.dateType === 'basic' ?
                            this.props.list.map((item,index)=>
                                <div key={index} width="13%" className={`date ${item.get('day') === 'today' ? 'today':''}`}>
                                    <p className="week">{item.get('day') === 'today' ? intl('schedule','today',this.props.language_id): item.get('week')}</p>
                                    <p className="day">{item.get('dayTime')}</p>
                                    {
                                        index === this.props.list.size - 1 && this.props.weekType === 1 ?
                                        <button className="toNextWeek" onClick={this.toNextWeek}>
                                            <Icon type='right' style={{fontSize:'30px'}} />
                                        </button>: null
                                    }
                                </div>
                            ):
                            this.props.list.map((item,index)=>
                                <div key={index} width="13%" className="date" >
                                    <strong className="week">{item.get('title')}</strong>
                                </div>
                            )
                        }
                    </Affix>
                </th>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispathToProps = (dispatch) => ({
    dayType(value) {
        dispatch(actionCreators.dayType(value));
    },
});


export default connect(mapStateToProps, mapDispathToProps)(DateItem);