import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { changeAvatar, changeProfileScene, getClientInfo } from '../store/actionCreators';
import {
    ContentLeft,
    ContentRight,
    AvatorInfo,
    InfoBtn,
    UserName,
    ContentRightItem,
    InfoItem,
} from '../style';
import { Avatar, Icon } from 'antd';
import { intl, showMsg } from '../../config/util';
import { isMobile } from 'react-device-detect';
import MySetting from './MySetting';
import { MyState } from '../../store/reducer.interface';

const mapStateToProps = (state: MyState) => {
    return {
        language_id: state.getIn(['header', 'language_id']),
        user: state.getIn(['main', 'user']),
        userInfo: state.getIn(['profile', 'userInfo']),
    };
};

const mapDispathToProps = (dispatch: any) => ({
    getUserInfo(value: any) {
        dispatch(getClientInfo(value.get('jwt')));
    },
    changeProfileScene(scene: any) {
        dispatch(changeProfileScene(scene));
    },
    changeAvatar(picture: any, user: any) {
        dispatch(changeAvatar(picture, user.get('jwt')));
    },
});

const connector = connect(mapStateToProps, mapDispathToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export interface MyFavouriteProps extends PropsFromRedux {

}

function MyProfile(props: MyFavouriteProps) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        var file = event.target.files[0];
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            showMsg('error', 'You can only upload JPG/PNG file!', 2);
        } else if (file.size / 1024 / 1024 > 2) {
            showMsg('error', 'Image must smaller than 2MB!', 2);
        } else {
            var reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result !== 'string') {
                    return;
                }
                let base64 = reader.result.split('base64,')[1];
                //上传图片
                props.changeAvatar(base64, props.user);
            };
            reader.readAsDataURL(file);
        }
    }

    let { userInfo, language_id } = props;
    let memberType = '';
    if (userInfo.size > 0) {
        if (userInfo.get('is_yoga') && userInfo.get('is_fitness')) {
            memberType = 'Yoga + Fitness';
        } else if (userInfo.get('is_yoga') && !userInfo.get('is_fitness')) {
            memberType = 'Yoga';
        } else {
            memberType = 'Fitness';
        }
    }
    let avatar = userInfo.get('picture') ? `data:image/png;base64,${userInfo.get('picture')}` : require('../../assets/images/ic_default_image.png');
    let noShowsColor = '#666666';
    if (userInfo.get('late_cancel_absent_count') <= 2 && userInfo.get('late_cancel_absent_count') > 0) {
        noShowsColor = '#000000';
    } else if (userInfo.get('late_cancel_absent_count') > 2) {
        noShowsColor = '#ED1C24';
    }
    return (
        // <ProfileContent id="profileContent">
        <>
            <ContentLeft>
                <div className="content" id="myProfile" key="myProfile">
                    <AvatorInfo>
                        <Avatar shape="square" size={194} src={avatar} icon="user" />
                        <div className="imagesfile">
                            <input type="file" onChange={handleChange} />
                        </div>
                    </AvatorInfo>
                    <UserName>{userInfo.get('first_name')} {userInfo.get('last_name')}</UserName>
                    <div className="infoDiv">
                        <InfoBtn>
                            <b style={{ color: noShowsColor }}>{userInfo.get('late_cancel_absent_count')}</b>
                            <p>{intl('other', 'noShows', language_id)}</p>
                        </InfoBtn>
                        <InfoBtn>
                            <strong>{userInfo.get('state')}</strong>
                            <p>{intl('schedule', 'status', language_id)}</p>
                        </InfoBtn>
                    </div>
                </div>
            </ContentLeft>
            <ContentRight>
                <div className="content">
                    <ContentRightItem id="myProfile2" name="myProfile2" key="myProfile2">
                        <div className="myProfileTitle title">{intl('schedule', 'myProfile', language_id)}</div>
                        <InfoItem>
                            <span className="name">{intl('other', 'userId', language_id)}</span>
                            <span className="info">{userInfo.get('mbo_rssid')}</span>
                        </InfoItem>
                        <InfoItem>
                            <span className="name">{intl('schedule', 'email', language_id)}</span>
                            <span className="info">{userInfo.get('email') === '' ? 'N/A' : userInfo.get('email')}</span>
                        </InfoItem>
                        <InfoItem>
                            <span className="name">{intl('schedule', 'mobilePhone', language_id)}</span>
                            <span className="info">{userInfo.get('mobile_phone') === '' ? 'N/A' : userInfo.get('mobile_phone')}</span>
                        </InfoItem>
                        <InfoItem>
                            <span className="name">{intl('other', 'preferredLocation', language_id)}</span>
                            <span className="info">{userInfo.get('preferred_location') || 'N/A'}</span>
                        </InfoItem>
                        <InfoItem>
                            <span className="name">{intl('schedule', 'region', language_id)}</span>
                            <span className="info">{userInfo.get('country')}</span>
                        </InfoItem>
                        <InfoItem>
                            <span className="name">{intl('other', 'membershipType', language_id)}</span>
                            <span className="info">{memberType}</span>
                        </InfoItem>
                        <InfoItem className="skip" onClick={() => props.changeProfileScene('myPurchases')}>
                            <span className="name">{intl('other', 'myPurchases', language_id)}</span>
                            <span className="info"><Icon type="right" /></span>
                        </InfoItem>
                        <InfoItem className="skip" onClick={() => props.changeProfileScene('mySignIns')}>
                            <span className="name">{intl('other', 'mySignIns', language_id)}</span>
                            <span className="info"><Icon type="right" /></span>
                        </InfoItem>
                    </ContentRightItem>
                    {!isMobile && <MySetting />}

                </div>
            </ContentRight>
        </>
        // </ProfileContent>
    );
}

export default connector(MyProfile);