import React, { Component } from 'react';
import { Icon } from 'antd-mobile';
import './loading.less';
import { intl } from '../../config/util';
import { Spin } from 'antd';
import { isMobile } from 'react-device-detect';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        let antIcon;
        let { show } = this.props;
        const classNames = ["loading-wrapper"];

        if (isMobile) {
            antIcon = <Icon type="loading" size='lg' />;
            classNames.push('loading-mobile');
        }

        if (!show) {
            classNames.push('hide');
        }
        const className = classNames.join(' ');

        return (
            <div className={className}>
                <div className="loading-content">
                    <Spin
                        size="large"
                        tip={intl('other', 'loading', this.props.language_id)}
                        indicator={antIcon}
                    />
                </div>
                {/* <Icon type="loading" size='lg' /> */}
                {/* <p>{intl('other', 'loading', this.props.language_id)}</p> */}
            </div>
        );
    }
}

export default Loading;