import * as constants from './constants';
import { fromJS } from 'immutable';

const defauleState = fromJS({
    scene:'myProfile',//profile页的场景
    userInfo:{},
    signList:[],//参加记录
    signList_by_date:[],
    signPageInfo:{},//参加列表分页
    signListLoading:true,//参加列表加载中状态
    order:'desc',//排序
    clientServices: [],//服务计划
});

export default (state = defauleState, action) => {
    switch (action.type) {
        case constants.SAVE_CLIENT_INFO:
            return state.set('userInfo', action.value);
        case constants.CHANGE_CLIENT_INFO:
            return state.setIn(['userInfo', action.changeType], action.value);
        case constants.CHANGE_LOADING:
            return state.set('signListLoading', action.value);
        case constants.SAVE_SIGN_LIST_PAGE:
            return state.set('signPageInfo', action.value);
        case constants.SAVE_SIGN_HISTORY:
            return state.set('signList',action.value);
        case constants.SAVE_SIGN_HISTORY_BY_DATE:
            return state.set('signList_by_date',action.value);
        case constants.SAVE_SIGN_HISTORY_ALL:
            return state.merge({
                signList: action.value.get('signList'),
                signList_by_date: action.value.get('signInsByDate'),
            });
        case constants.CHANGE_LIST_ORDER:
            return state.set('order', action.value);
        case constants.CHANGE_PROFILE_SCENE:
            return state.set('scene', action.value);
        case constants.Save_Client_SERVICES:
            return state.set('clientServices', action.value);
        default:
            return state;
    }
};
