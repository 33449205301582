import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Tabs } from 'antd';
import { intl } from '../../config/util';
import { MyState } from '../../store/reducer.interface';
import FavouriteTeacher from './favourite/Teacher';
import FavouriteLocation from './favourite/Location';
import FavouriteClass from './favourite/Class';
import { FavouriteTabWrapper } from '../style';

const mapStateToProps = (state: MyState) => {
    return {
        language_id: state.getIn(['header', 'language_id']),
    };
};

const mapDispathToProps = (dispatch: any) => ({});

const connector = connect(mapStateToProps, mapDispathToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export interface MyFavouriteProps extends PropsFromRedux {

}

function Myfavourite(props: MyFavouriteProps) {
    const { TabPane } = Tabs;
    let { language_id } = props;

    return (
        <FavouriteTabWrapper>
            <Tabs className='favourite-inner-tab' >
                <TabPane tab={intl('other', 'pureTeam', language_id)} key="pureTeam" >
                    <FavouriteTeacher />
                </TabPane>
                < TabPane tab={intl('schedule', 'classTypes', language_id)} key="classes" >
                    <FavouriteClass />
                </TabPane>
                <TabPane tab={intl('other', 'locations', language_id)} key="locations" >
                    <FavouriteLocation />
                </TabPane>
            </Tabs>
        </FavouriteTabWrapper>
    );
}

export default connector(Myfavourite);