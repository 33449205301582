import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from './store';
import { handleMoudleChange } from '../common/main/store/actionCreators';
import { Popover, Select, Icon, Table  } from 'antd';
import { BookContent, ContentTop, ColumnTitle, OrderBy, MyBooking} from './style';
import { intl, getLangTitle, getMonthDate, addMonths } from '../config/util';
import { get_class_type, get_teacher_list } from '../config/request';
import Calendar from 'react-calendar';
import { isMobile } from 'react-device-detect';
import { formatDate } from '../config/util';
import { judgeBooking } from '../schedule/store/actionCreators';
import ScheduleButton from '../schedule/component/scheduleButton/scheduleButton';
import { fromJS } from 'immutable';
import ClassDetails from '../schedule/component/classDetails/index';
import { flags } from '../config/unleash';
import { getClassTypeById, getLevelById, getPillarById } from '../config/classHelper';
import { CLASS_CATEGORY } from '../config/constant';
const { Option } = Select;

const popoverLoading = <div style={{textAlign:'center'}}><Icon type="loading" /></div>;

let teacherDefaultAvatar = require('../assets/images/ic_default_image.png');
class Booking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            getBookingParams:{},//获取预约列表的参数
            getBooking:true,
            getCancelListParams:{},//获取取消预约列表参数
            filterMonth:[],
            filterYear:[],
            filterYearDefaultValue:'',
            filterMonthDefaultValue:'',
            teacherInfo:popoverLoading,//老师信息弹窗
            classInfo: popoverLoading,//课程信息弹窗
            hideCalendar: false,
            tab: 'myClassSchedule',
        };
    }

    //日历选中改变
    onChange = date => this.setState({ date })

    componentWillMount() {
        //获取筛选项列表
        this.getFilterOption();

        //获取预约记录
        let {language_id, region_id, type, user} = this.props;
        let getBookingParams = Object.assign({ language_id, region_id, type, jwt: user.get('jwt') }, getMonthDate());
        let getCancelListParams = Object.assign({ language_id, region_id, type, jwt: user.get('jwt') }, getMonthDate());

        this.setState({
            getBookingParams,
            getCancelListParams
        });
        this.props.getBooking(getBookingParams);
        this.props.getCancelList(getCancelListParams);

    }
    //年月数组生成
    getFilterOption(time){
        let lang = parseInt(this.props.language_id) === 1 ? 'en' : 'ch';
        let date = time ? new Date(time) : new Date();
        let thisYear = new Date().getFullYear();
        let year = date.getFullYear();
        let month = date.getMonth();
        let monthArr = {
            'en':["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            'ch':["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
        };

        let filterMonth = monthArr[lang].map((item, index) => {
            return { value: index + 1, info: item, checked: month === index };
        });
        let filterYear = [];
        for (let i = 0; i < 5; i++) {
            let info = thisYear - i;
            let value = lang === 'en' ? info : info + '年';
            let checked = i === 0;
            filterYear.push({value, info, checked});
        }
        //antd select组件defaultValue存在设置无效的问题，直接在这里设置好
        this.setState({
            filterMonth,
            filterYear,
            filterYearDefaultValue: year,
            filterMonthDefaultValue: month + 1
        });
    }
    //课程信息弹窗
    getClassInfo = (class_id, language_id, className) => {
        this.setState({classInfo:popoverLoading});
        const data = getClassTypeById(class_id);
        if (data) {
            let levelInfo = getLevelById(data.level_id, data.sector);
            let pillarInfo = getPillarById(data.pillar_id, data.sector);
            let classInfo = (
                <>
                    <div className="title">{data.names[getLangTitle(language_id)]}</div>
                    <p className="attr" style={{ margin: 0 }}>
                        <span style={{ color: pillarInfo.color }}>{pillarInfo.names[getLangTitle(language_id)]}</span>
                        <em> </em>
                        <span>{levelInfo.names[getLangTitle(language_id)]}</span>
                    </p>
                    <pre className="disc">{data.descriptions[getLangTitle(language_id)]}</pre>
                </>
            );
            this.setState({ classInfo });
        } else {
            let classInfo = (
                <>
                    <div className="title">{className}</div>
                    <div className="attr">{intl('other', 'errorNoInfo', language_id)}</div>
                </>
            );
            this.setState({ classInfo });
        }
    }


    //老师信息弹窗
    getTeacherInfo = (teacher_id,language_id) => {
        this.setState({teacherInfo:popoverLoading});
        get_teacher_list({id:teacher_id,language_id,region_id:this.props.region_id}).then(res => {
            let data = res.data.data.teachers[0];
            let teacherInfo = (
                <div className="scroll_view">
                    <div className="avator">
                        <img src={data.image_link ? data.image_link : teacherDefaultAvatar} width='150' height='150' alt=" " />
                    </div>
                    <p className="name">{data.names[getLangTitle(language_id)]}</p>
                    <pre className="description"  style={{width:'fit-content',whiteSpace:'pre-wrap'}}>{data.descriptions[getLangTitle(language_id)]}</pre>
                </div>
            );
            this.setState({teacherInfo});
        }).catch(error => {
            console.log(error);
        });
    }
    //预约记录状态文本内容
    bookBtn = (status, language_id) => {
        switch (status) {
            case 1:
                return intl('schedule', 'book', language_id);
            case 2:
                return intl('schedule', 'booked', language_id);
            case 3:
                return intl('schedule', 'WaitList', language_id);
            case 4:
                return intl('schedule', 'inWaitList', language_id);
            case 5:
                return intl('schedule', 'full', language_id);
            case 7:
                return intl('schedule', 'signedIn', language_id);
            case 8:
                return intl('schedule', 'lateCancel', language_id);
            case 9:
                return intl('schedule', 'absent', language_id);
            case 10:
                return intl('schedule', 'complete', language_id);
            case 11:
                return intl('schedule', 'completed', language_id);
            case 12:
                return intl('schedule', 'cancelled', language_id);
            default:
                return '';
        }
    }
    //展示超时列表
    showCancelList = () => {
        let {language_id, region_id, user} = this.props;
        if (this.props.cancelList.size === 0) {
            this.props.changeShowBookList(false);
            let getCancelListParams = Object.assign({language_id, region_id, jwt: user.get('jwt')}, getMonthDate());
            this.setState({
                getCancelListParams
            });
            this.props.getCancelList(getCancelListParams);
        } else {
            this.props.changeShowBookList(false);
        }
    }
    //展示我的预约记录
    showMyBookings = () => {
        this.props.changeShowBookList(true);
    }
    //年份下拉监听
    handleYearChange = (value) => {
        let {getBookingParams, getCancelListParams, filterYear, filterMonth} = this.state;

        let year = filterYear.find(item => item.value === value).info;
        let month = filterMonth.find(item => item.checked).value;
        if (month<10) {
            month ='0'+ month.toString();
        }
        getBookingParams = Object.assign(getBookingParams, getMonthDate(year +'/'+ month+'/01'));
        getCancelListParams = Object.assign(getCancelListParams, getMonthDate(year +'/'+ month+'/01'));
        this.getFilterOption(year +'/'+ month+'/01');
        this.setState({
            getBookingParams,
            getCancelListParams,
        });
        this.props.getBooking(getBookingParams);
        this.props.getCancelList(getCancelListParams);
    }
    //月份下拉监听
    handleMonthChange = (month) => {
        if (month<10) {
            month ='0'+ month.toString();
        }
        let {getBookingParams, getCancelListParams, filterYearDefaultValue} = this.state;
        getBookingParams = Object.assign(getBookingParams, getMonthDate(filterYearDefaultValue +'/'+ month+'/01'));
        getCancelListParams = Object.assign(getCancelListParams, getMonthDate(filterYearDefaultValue + '/' + month + '/01'));
        this.setState({
            getBookingParams,
            getCancelListParams
        });
        this.getFilterOption(filterYearDefaultValue +'/'+ month+"/01");
        this.props.getBooking(getBookingParams);
        this.props.getCancelList(getCancelListParams);
    }
    //列表的底部loading
    getLoading = (num) => {
        return <tr className="loading"><td colSpan={num}><div style={{textAlign:'center',fontSize:20}}><Icon type="loading" /></div></td></tr>;
    }

    //mobile
    additionalNav = () => {
        // const isActive = this.state.isActive;
        let { tab } = this.state;

        if (isMobile){
            return(
                <ul className="nav" id="addNav" ref={this.ref}>
                    <div>
                        <button className="back" onClick={this.props.back}><i className="iconfont icon-left"></i></button>
                    </div>
                    <div className="navbar-nav">
                        <li onClick={this.showMyBookings}>
                            <a className={`${tab === 'myClassSchedule' ? 'active' : ''}`} onClick={()=>{this.setState({tab:'myClassSchedule'});}}>
                                {intl('schedule','myClassSchedule', this.props.language_id)}
                            </a>
                        </li>
                        <li onClick={this.showCancelList}>
                            <a className={`${tab === 'cancelRecord' ? 'active' : ''}`} onClick={() => { this.setState({ tab: 'cancelRecord' }); this.props.changeMoudle('MyCancellations'); }}>
                                {intl('schedule','myCancellations', this.props.language_id)}
                            </a>
                        </li>
                    </div>
                </ul>
            );
        }
    }

    //判断是否有课程
    hasBookingContent = (date) => {
        let { myBookings } = this.props;
        let hasYoga = false;
        let hasFitness = false;

        const todayDate = formatDate(date).value;

        const todayBookings = myBookings.filter((item) => item.getIn(['class', 'start_date']) === todayDate);

        if (todayBookings.size > 0) {
            hasYoga = todayBookings.some(item => (item.get('sector') === 'Y' || item.get('sector') === 'yoga'));
            hasFitness = todayBookings.some(item => (item.get('sector') === 'F' || item.get('sector') === 'fitness'));
        }
        return <div className='tile-container'>
            <div className='has-class'>
                {hasYoga && <div className='has-yoga'></div>}
                {hasFitness && <div className='has-fitness'></div>}
            </div>
        </div>;
    }

    prevMonth = (date) => {
        let newDate = addMonths(date, -1);
        this.changeMonth(newDate);
    }

    nextMonth = (date) => {
        let newDate = addMonths(date, 1);
        this.changeMonth(newDate);
    }

    changeMonth = (date) => {
        let {language_id, region_id, type, user} = this.props;
        this.setState({ date });

        let getBookingParams = Object.assign({language_id, region_id, type, jwt: user.get('jwt')}, getMonthDate(date));
        this.setState({
            getBookingParams
        });

        this.props.getBooking(getBookingParams);
    }

    cancelClassCallback() {
        // refresh on cancel class
        this.props.getBooking(this.state.getBookingParams);
    }

    getBookList() {
        let {myBookings, language_id, bookListLoading, special_flag, mood_flag} = this.props;
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let column = [];
        let data = [];
        let moodCol = { width: '0.1%' };

        if (mood_flag) {
            moodCol = {
                title: intl('schedule', 'mood', language_id),
                dataIndex:'vibe',
                key:'vibe',
                width: '10%',
                align: 'center'
            };
        }

        if (myBookings) {
            column = [
                {
                    title: intl('schedule', 'classTime', language_id),
                    dataIndex:'classTime',
                    key:'classTime',
                    width: '14%',
                },
                {
                    dataIndex:'special',
                    key:'special',
                    width: '2%',
                },
                {
                    title: intl('schedule', 'class', language_id),
                    dataIndex:'class',
                    key:'class',
                    width: '36%',
                },
                moodCol,
                {
                    title: intl('schedule', 'location', language_id),
                    dataIndex:'location',
                    key:'location',
                    width: '20%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'teacher', language_id),
                    dataIndex:'teacher',
                    key:'teacher',
                    width: '13%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'status', language_id),
                    dataIndex:'status',
                    key:'status',
                    width: '10%',
                    align: 'center',
                },
            ];
            myBookings.forEach(item => {
                const classInfo = {
                    start_time: item.get('classTime'),
                    start_date: item.getIn(['classDate','title']),
                    start_weekday: item.getIn(['classDate','week']),
                    date_time: item.get('dateTime'),
                    name: item.getIn(['class', 'class_type', 'name']),
                    pillar_color: item.getIn(['class', 'class_type', 'color']),
                    location: item.getIn(['class','location','name']),
                    teacher: item.getIn(['class','teacher']),
                    status: item.get('button_status'),
                    booking_id: item.get('booking_id'),
                    class_id: item.getIn(['class', 'class_type', 'id']),
                    category: item.get('category'),
                    waiting_number: item.get('waiting_number'),
                    is_fitness: item.getIn(['class','location','is_fitness']),
                    special: item.getIn(['class', 'special']),
                    vibe: item.getIn(['class', 'mood']),
                    vibe_color: item.getIn(['class', 'mood', 'color']),
                    special_color: item.getIn(['class', 'special', 'color']),
                    special_description: item.getIn(['class', 'special', 'name']),
                    vibe_name: item.getIn(['class', 'mood', 'name']),
                };

                const scheduleButtonItem = {
                    booking_id: item.get('booking_id'),
                    button_status: item.get('button_status'),
                    id: item.getIn(['class', 'id']),
                    sector: item.get('sector'),
                    waiting_number: item.get('waiting_number'),
                    category: item.get('category'),
                    view_url: item.get('view_url'),
                };

                const x = {
                    key: classInfo.booking_id,
                    classTime: (
                        <div className='classTime'>
                            <span className={`date ${parseInt(language_id) === 1 ? 'en':''}`}>
                                {classInfo.date_time}
                            </span>
                            {/* <span>{classInfo.start_time}</span> */}
                        </div>
                    ),
                    special:(
                        special_flag && classInfo.special ? <Popover
                            placement="bottom"
                            content={classInfo.special_description}
                            overlayStyle={{ width: '400px', color: classInfo.special_color}}
                            overlayClassName="schedule_popover"
                            arrowPointAtCenter
                        >
                            <span className="line" style={{background: classInfo.special_color}}></span>
                        </Popover>
                        :
                        <span className="line" style={{background: 'lightgrey'}}></span>
                    ),
                    class: (
                        // remove hover feature when is private class
                        classInfo.category === CLASS_CATEGORY.PRIVATE ?  <span className='classTitle'>{classInfo.name}</span> : <Popover
                            placement="bottom"
                            overlayClassName="class_popover"
                            overlayStyle={{ width: '400px', borderRadius: '30px' }}
                            content={this.state.classInfo}
                            onMouseEnter = {()=>{this.getClassInfo(classInfo.class_id,language_id, classInfo.name);}}
                        >
                            <span className='classTitle'>{classInfo.name}</span>
                        </Popover>
                    ),
                    vibe: (mood_flag && classInfo.vibe ? <span style={{color: classInfo.vibe_color}}>{classInfo.vibe_name}</span>: <span>-</span>),
                    location: classInfo.location,
                    teacher: (
                        <Popover
                            placement="bottom"
                            overlayStyle={{ width: '400px', borderRadius: '30px' }}
                            overlayClassName="teacher_popover"
                            content={this.state.teacherInfo}
                            onMouseEnter = {()=>{this.getTeacherInfo(classInfo.teacher.get('id'),language_id);}}
                        >
                            {classInfo.teacher.get('name')}
                        </Popover>
                    ),
                    status: (
                        <ScheduleButton
                            item={fromJS(scheduleButtonItem)}
                            language_id={language_id}
                            booking={this.props.booking}
                            jwt={jwt}
                            filter={this.props.filter}
                            region_id={this.props.region_id}
                            cancel_class_callback={this.cancelClassCallback.bind(this)}
                            blurBtn="true"
                        >
                        </ScheduleButton>
                    ),
                };

                data.push(x);

                
            });
        }

        return (
            <Table  pagination={false}  columns={column} dataSource={data} tableLayout='fixed' loading={bookListLoading} />
        );
    }

    getCancelList() {
        let {cancelList, language_id, cancelListLoading, userInfo, special_flag, mood_flag} = this.props;
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let column = [];
        let data = [];
        let moodCol = { width: '0.1%' };

        if (mood_flag) {
            moodCol = {
                title: intl('schedule', 'mood', language_id),
                dataIndex:'vibe',
                key:'vibe',
                width: '8%',
                align: 'center',
            };
        }
        if (cancelList) {
            column = [
                {
                    title: intl('schedule', 'classTime', language_id),
                    dataIndex:'classTime',
                    key:'classTime',
                    width: '14%',
                },
                {
                    dataIndex:'special',
                    key:'special',
                    width: '2%',
                },
                {
                    title: intl('schedule', 'class', language_id),
                    dataIndex:'class',
                    key:'class',
                    width: '16%',
                },
                moodCol,
                {
                    title: intl('schedule', 'location', language_id),
                    dataIndex:'location',
                    key:'location',
                    width: '12%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'teacher', language_id),
                    dataIndex:'teacher',
                    key:'teacher',
                    width: '10%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'bookedTime', language_id),
                    dataIndex:'bookedTime',
                    key:'bookedTime',
                    width: '12%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'cancelTime', language_id),
                    dataIndex:'cancelTime',
                    key:'cancelTime',
                    width: '12%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'cancelBy', language_id),
                    dataIndex:'cancelBy',
                    key:'cancelBy',
                    width: '7%',
                    align: 'center',
                },
                {
                    title: intl('schedule', 'lateCancel', language_id),
                    dataIndex:'lateCancel',
                    key:'lateCancel',
                    width: '6%',
                    align: 'center',
                },
            ];

            cancelList.forEach(item => {
                const classInfo = {
                    date:item.get('classDate'),
                    time:item.get('classTime'),
                    name: item.getIn(['class_type','name']),
                    location: item.getIn(['location','name']),
                    teacher: item.getIn(['teacher','name']),
                    status: item.get('button_status'),
                    booking_id: item.get('booking_id'),
                    class_id: item.getIn(['class_type', 'id']),
                    category: item.get('category'),
                    is_fitness: item.getIn(['location','is_fitness']),
                    special: item.getIn(['special']),
                    vibe: item.getIn(['vibe']),
                    vibe_color: item.getIn(['vibe', 'color']),
                    special_color: item.getIn(['special', 'color']),
                    special_description: item.getIn(['special', 'name']),
                    vibe_name: item.getIn(['vibe', 'name']),
                    bookedTime: item.get('bookedTime'),
                    canceledTime: item.get('canceledTime'),
                    cancelled_by: item.get('cancelled_by'),
                    first_name: userInfo.get('first_name'),
                    last_name: userInfo.get('last_name'),
                };

                const x = {
                    key: classInfo.booking_id,
                    classTime: (
                        <div className='classTime'>
                        <span className="date">{item.get('classDate')}</span>
                        </div>
                    ),
                    special:(
                        special_flag && classInfo.special ? <Popover
                            placement="bottom"
                            content={classInfo.special_description}
                            overlayStyle={{ width: '400px', color: classInfo.special_color}}
                            overlayClassName="schedule_popover"
                            arrowPointAtCenter
                        >
                            <span className="line" style={{background: classInfo.special_color}}></span>
                        </Popover>
                        :
                        <span className="line" style={{background: 'lightgrey'}}></span>
                    ),
                    class: (
                            <span className='classTitle'>{classInfo.name}</span>
                    ),
                    vibe: (mood_flag && classInfo.vibe ? <span style={{color: classInfo.vibe_color}}>{classInfo.vibe_name}</span>: <span>-</span>),
                    location: classInfo.location,
                    teacher: classInfo.teacher,
                    bookedTime: classInfo.bookedTime,
                    cancelTime: classInfo.canceledTime,
                    cancelBy: (
                        classInfo.cancelled_by === 'ADMIN' ? intl('schedule', 'staff', language_id) : classInfo.first_name+''+classInfo.last_name
                    ),
                    lateCancel: (
                        <Icon type={classInfo.status === 8 ?'check':'close' } />
                    ),

                };

                data.push(x);
            });
        }

        return (
            <Table pagination={false} columns={column} dataSource={data} loading={cancelListLoading} />
        );
    }

    render(){
        if (isMobile) {
            return this.renderMobile();
        } else {
            return this.renderDesktop();
        }
    }

    renderDesktop() {
        let { language_id, booking_order, showBookList } = this.props;
        return (
            <BookContent>
                <ContentTop>
                    <ColumnTitle>
                        { !showBookList && <button onClick={this.showMyBookings}><Icon type="left" style={{fontSize:26,color:'#333'}} /></button>}
                        <span>{intl('schedule',showBookList ? 'myClassSchedule' : 'myCancellations',language_id)}</span>
                    </ColumnTitle>
                    {
                        showBookList && <button className="skip" onClick={this.showCancelList}>{intl('schedule','myCancellations',language_id)}</button>
                    }
                </ContentTop>
                <div className="filter">
                    <Select value={this.state.filterMonthDefaultValue} style={{ width: 180 }} onChange={this.handleMonthChange} suffixIcon={<Icon style={{fontSize:16}} type="caret-down" />}>

                        { this.state.filterMonth.map(item => <Option value={item.value} key={item.value}>{item.info}</Option>) }

                    </Select>
                    <Select value={this.state.filterYearDefaultValue} style={{ width: 180 }} onChange={this.handleYearChange} suffixIcon={<Icon style={{fontSize:16}} type="caret-down" />}>

                        {this.state.filterYear.map(item => <Option value={item.value} key={item.value}>{item.info}</Option>)}

                    </Select>
                    <OrderBy className={`orderBy ${booking_order === 0 ? 'asc' : 'desc'}`} onClick={() => this.props.chageOrder(booking_order === 0 ? 1 : 0)}>
                        {intl('other', booking_order === 0 ? 'soonestLast' : 'soonestFirst', language_id)}
                    </OrderBy>
                </div>

                {/* 预约列表 */}
                {showBookList && this.getBookList()}

                 {/* 取消列表 */}
                 {!showBookList && this.getCancelList()}
            </BookContent>
        );
    }

    renderMobile(){
        let {myBookings, language_id, bookings_by_date, mood_flag, special_flag} = this.props;
        let { hideCalendar } = this.state;
        let date = formatDate(this.state.date).value;
        let jwt = this.props.user.get('jwt') ? this.props.user.get('jwt') : '';
        let list = hideCalendar ? myBookings : bookings_by_date[date];
        return(
            <MyBooking>
                {this.additionalNav()}
                    {
                        !hideCalendar &&
                        <Calendar
                        value={this.state.date}
                        className={`xcalendar ${hideCalendar ? 'hide' : ''}`}
                        //左箭头
                        locale={language_id == 1 ? "en" : 'cn'}
                        // locale={language_id == 1 ? "en-US" : 'zh-CN'}
                        prevLabel={<div className="calendarIcon calendarIconPrev" onClick={() => this.prevMonth(this.state.date)}></div>}
                        nextLabel={<div className="calendarIcon calendarIconNext" onClick={() => this.nextMonth(this.state.date)}></div>}
                        //切换年/月时触发
                        onClickMonth={(getBookingParams) => this.props.getBooking(getBookingParams)}
                        //点击天触发
                        onClickDay={this.onChange}
                        //给指定日期加上类名
                        tileContent={({ date, view }) => {
                            return view === 'month' && this.hasBookingContent(date);
                        }}
                        />
                    }
                    <div className="bookList">
                    <div className="date">{formatDate(this.state.date).value}</div>
                    <ul>
                        {
                            list && list.map(item => {
                                let buttonItem = {
                                    booking_id: item.booking_id,
                                    button_status: item.button_status,
                                    id: item.class.id,
                                    sector: item.sector,
                                    waiting_number: item.waiting_number,
                                    category: item.category,
                                    view_url: item.view_url,
                                };
                                let teacherAvatarItem = {
                                    id:item.class.id,
                                    teacherId:item.class.teacher.id,
                                    teacherAvatar:item.class.teacher.image,
                                    mood:item.class.mood,
                                    vaccinatedClass:item.class.is_vaccinated,
                                    special:item.class.special,
                                };
                                return(
                                    <ClassDetails
                                        key={item.booking_id}
                                        item={item.class}
                                        jwt={jwt}
                                        button_item={buttonItem}
                                        location_id={item.class.location.id}
                                        teacher_avatar={teacherAvatarItem}
                                        sector={item.sector}
                                        cancel_class_callback={this.cancelClassCallback.bind(this)}
                                        showVibe={mood_flag}
                                        showSpecial={special_flag}
                                        category={item.category}
                                    >
                                    </ClassDetails>
                                );
                            }
                        )}
                    </ul>
                </div>
            </MyBooking>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showBookList: state.getIn(['booking', 'showBookList']),
        user: state.getIn(['main', 'user']),
        userInfo: state.getIn(['profile', 'userInfo']),
        type: state.getIn(['header', 'type']),
        language_id: state.getIn(['header','language_id']),
        myBookings: state.getIn(['booking', 'myBookings']),
        bookings_by_date: state.getIn(['booking', 'bookings_by_date']).toJS(),
        region_id: state.getIn(['header', 'region_id']),
        booking_order: state.getIn(['booking', 'booking_order']),
        regions: state.getIn(['header', 'regions']),
        location_list: state.getIn(['schedule', 'location_list']),
        levels: state.getIn(['schedule', 'levels']),
        pillars: state.getIn(['schedule', 'pillars']),
        cancelList: state.getIn(['booking', 'cancelList']),
        bookListLoading: state.getIn(['booking', 'bookListLoading']),
        cancelListLoading: state.getIn(['booking', 'cancelListLoading']),
        filter: state.getIn(['schedule', 'filter']),
        pillars_all: state.getIn(['schedule', 'pillars_all']),
        classes: state.getIn(['schedule', 'classes']),
        mood_flag: state.getIn(['main', 'flags', flags.mood]),
        special_flag: state.getIn(['main', 'flags', flags.specialClass]),
        classInfo: state.getIn(['main', 'classInfo']),
        lang: state.getIn(['header', 'lang']),
        classtypes_ungrouped: state.getIn(['schedule', 'classtypes_ungrouped']),

    };
};

const mapDispathToProps = (dispatch) => ({
    //获取预约数据
    getBooking(params, page = 1, order=-1) {
        dispatch(actionCreators.getBookingList({...params, region_id: this.region_id }, order, page));
    },
    //获取缺席、迟到记录
    getCancelList(params, order=-1) {
        params.jwt = this.user.get('jwt');
        params.language_id = this.language_id;
        dispatch(actionCreators.getCancelList({...params, region_id: this.region_id}, order));
    },
    //取消预约/排队
    cancelBooking(status,id,params){
        if (status === 2 || status === 4){
            let info = {
                status,
                booking_id:id,
                jwt: this.user.get('jwt'),
                type: this.type,
                language_id: this.language_id,
                region_id: this.region_id
            };
            dispatch(actionCreators.cancelBooking(info,params,this.booking_order,this.filter,this.user.get('jwt')));
        }
    },
    //改变排序
    chageOrder(order){
        dispatch(actionCreators.changeListOrder(order,{myBookings:this.myBookings, cancelList:this.cancelList}));
    },
    //修改显示列表
    changeShowBookList(value){
        dispatch(actionCreators.changeShowBookList(value));
    },
    //返回
    back(){
        let hideFooter = document.getElementById("footerNav");
        hideFooter.style.display = "block";
        dispatch(handleMoudleChange('Schedule'));
    },
    //内容tab切换
    changeMoudle(module){
        dispatch(handleMoudleChange(module));
        if (module === 'Profile') {
            if (isMobile) {
                let hideFooter = document.getElementById("footerTop");
                hideFooter.style.display = "none";
            }
            dispatch(profileActionCreators.changeProfileScene('myProfile'));
        }
        if (module === 'Booking') {
            if (isMobile) {
                let hideFooter = document.getElementById("footerNav");
                hideFooter.style.display = "none";
            }
            dispatch(bookingActionCreators.changeShowBookList(true));
        }
    },
    //课程状态判断
    booking(id, status, booking_id) {
        let bookinfo = {
            class_id: id,
            status: status,
            jwt: this.jwt,
            filter: this.filter,
            booking_id,
            language_id: this.language_id,
            region_id: this.region_id,
            cancel_callback: this.cancel_class_callback,
            booking_callback: this.booking_class_callback,
        };
        dispatch(judgeBooking(bookinfo));
    },
     //判断按钮单击
     judgeBooking(button_status, id, booking_id) {
        let bookinfo = {
            class_id: id,
            status: button_status,
            jwt: this.user.get('jwt'),
            filter: this.filter,
            booking_id,
            language_id: this.language_id,
            region_id: this.region_id
        };
        dispatch(judgeBooking(bookinfo, true));
    },
});

export default connect(mapStateToProps, mapDispathToProps)(Booking);