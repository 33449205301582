//修改语言和城市（弹窗）
import React, { Component } from 'react';
import { Modal } from 'antd-mobile';
import { connect } from 'react-redux';
import { actionCreators } from '../../main/store';
import { setRegionAndLanguage } from '../../header/store/actionCreators';
import { selectChangeLanguage } from '../../header/store/actionCreators';
import { getChangeRegionTipsInfo, intl } from '../../../config/util';
// import { closeRegionLanguageModal } from '../../header/store/actionCreators';
import './regionAndLang.scss';

const alert = Modal.alert;

class RegionAndLang extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectType:'default',   //选择场景 default=>默认 city=> 城市 language=> 语言
            selectRegionId: localStorage.getItem('region_id'),
            selectLanguageId: localStorage.getItem('language_id'),
        };
    }

    //列表选择监听
    handleSelectChange = (id) => {
        if (this.state.selectType === 'city') {
            id !== parseInt(this.state.selectRegionId) && this.setState({ selectRegionId: id, selectType:'default' });
        } else {
            id !== this.state.selectLanguageId && this.setState({ selectLanguageId: id, selectType:'default' });
        }
    }
    //确定按钮点击
    handleChange = () => {

        if (parseInt(this.state.selectRegionId) !== parseInt(this.props.region_id)) {

            let tips = getChangeRegionTipsInfo(this.props.region_id, this.state.selectRegionId, this.state.selectLanguageId);
            if (this.props.isLogin) {
                alert(intl('other', 'tips', this.props.language_id), <div>{tips.title}</div>, [
                //     {
                //         text: tips.loginAnotherRegion, onPress: () => {
                //         //登入新region
                //         this.props.checkRegion(this.state.selectRegionId)
                //         this.props.changeModal(true,'login')
                //     }
                // },
                    { text: tips.logout, onPress: () => {
                        //登出，然后进入新的region
                        this.props.changeLanguageAndRegion(this.state.selectRegionId,this.state.selectLanguageId);
                    }},
                    { text: tips.cancel, onPress: () => {
                        if (this.state.selectLanguageId !== this.props.language_id) {
                            this.props.changeLanguageM(this.state.selectLanguageId);
                        } else {
                            this.props.changeModal(false,'');
                        }
                    }}
                ]);
            } else {
                this.props.changeLanguageAndRegion(this.state.selectRegionId,this.state.selectLanguageId);
                return;
                this.props.checkRegion(this.state.selectRegionId);
                this.props.changeModal(true,'login');
            }

        } else {
            if (this.state.selectLanguageId !== this.props.language_id) {
                this.props.changeLanguageM(this.state.selectLanguageId);
            } else {
                this.props.changeModal(false,'');
            }
        }
    }

    render() {
        const { selectType, selectRegionId, selectLanguageId } = this.state;
        const { languages, regions, lang, language_id } = this.props;
        let regionName = regions.length ? regions.find(item => item.id === parseInt(selectRegionId)).names[lang] : '';
        let languageName = languages.length > 0 ? languages.find(item => item.id === parseInt(selectLanguageId)).description : '';
        let selectCompent;
        if (selectType === 'default') {
            selectCompent = (
                <div className="pop_content">
                    <div className="panel" onClick={()=>{this.setState({selectType:'city'});}}>
                        <span className="name">{intl('schedule', 'region', language_id)} :</span>
                        <span className="active">{regionName}</span>
                        <i className="iconfont icon-right"></i>
                    </div>
                    <div className="panel" onClick={()=>{this.setState({selectType:'language'});}}>
                        <span className="name">{intl('other','language',language_id)} :</span>
                        <span className="active">{ intl('other', 'lang-'+selectLanguageId, selectLanguageId)}</span>
                        <i className="iconfont icon-right"></i>
                    </div>
                </div>
            );
        } else {
            selectCompent = (
                <div className="pop_content">
                    <div className="select_title">
                        <button className="back" onClick={()=>{this.setState({selectType:'default'});}}>
                            <i className="iconfont icon-left"></i>
                        </button>
                        {intl('other', selectType, selectLanguageId)}
                    </div>
                    <ul className="list">
                        {
                            selectType === 'city' ?
                            regions.map(item => {
                                return (
                                    <li key={item.id} onClick={() => {this.handleSelectChange(item.id);}}>
                                        <span className="name">
                                            { item.names[lang] }
                                        </span>
                                        { parseInt(selectRegionId) === item.id && <i className="iconfont icon-check"></i> }
                                    </li>
                                );
                            }):
                            languages.map(item => {
                                return (
                                    <li key={item.id} onClick={() => {this.handleSelectChange(item.id);}}>
                                        <span className="name">
                                            {item.description}
                                        </span>
                                        { parseInt(selectLanguageId) === item.id && <i className="iconfont icon-check"></i> }
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            );
        }

        return (
            <div className="region_lang_choose">
                <div className="title">{intl('other','chooseCityLangDisc', language_id)}</div>
                {selectCompent}
                <div className="opera">
                    <button className="true" onClick={this.handleChange}>{intl('other','ok', language_id)}</button>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.getIn(['header', 'languages']).toJS(),
        language_id: state.getIn(['header', 'language_id']),
        region_id: state.getIn(['header', 'region_id']),
        regions: state.getIn(['header', 'regions']).toJS(),
        lang: state.getIn(['header', 'lang']),
        isLogin: state.getIn(['main', 'isLogin']),
    };
};

const mapDispathToProps = (dispatch) => ({
    //设置弹窗
    changeModal(opera,type){
        dispatch(actionCreators.setModal(opera,type));
    },
    //修改语言和城市
    changeLanguageAndRegion(region_id,language_id){
        dispatch(setRegionAndLanguage(region_id,language_id,this.languages,this.regions));
    },
    //登录后修改城市
    checkRegion(region_id){
        dispatch(actionCreators.checkRegion(region_id));
    },
    //修改语言和城市
    changeLanguageM(language_id) {
        dispatch(selectChangeLanguage(language_id, true));
    }
});

export default connect(mapStateToProps,mapDispathToProps)(RegionAndLang);