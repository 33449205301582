const lang = {
    login: {
        'signout_title': '是否确认登出?',
        'loginDesktop': '欢迎登录预约',
        'loginMobile': '登录预约 / 签到',
        'stayLogin':'记住登录',
        'sign_in': '登入',
        'sign_out': '登出',
        'noCardholder': '我还不是PURE会员',
        'forget_password': '忘记密码？',
        'login_title': '登录到您的帐户',
        'login_btn': '登录',
        'username': '用户名',
        'password': '密码',
        'sign_up': '注册',
        'tryClass':'免费试堂',
        'name':'PURE360生活平台',
        'onlineBookingSystem':'线上预约系统'
    },
    'model': {
        'close': '关闭',
        'confirm': '确定',
        'late_cancel': '最后一刻取消',
        'cancel_booking': '取消预约',
        'confirmation': '你确定取消预约吗？',
        'others': '请选择最多3个地点',
        'done': '提示',
        'yes': '是',
        'no': '否',
        'ok': '确定',
    },
    'schedule': {
        'teachers': '老师',
        'pillars': '支柱',
        'levels': '程度',
        'classTypes': '课程',
        'fitnessCyclingSGT': 'Fitness/ Cycling/ FUZE/ SGT',
        'instructors': '导师',
        'categories': '元素',
        'reset': '重置',
        'apply_filter': '确认',
        'locations': '位置',
        'n_locations': '个地点',
        'staff': '职员',
        'explain':'您可以通过点击课程上的“预约”按钮预约，将鼠标悬停在班级或老师上方可查看详细信息',
        'schedule': '课程表',
        'dateOfWeek': '星期',
        'printerFriendlyVersion': '列印版本',
        'classType': '课程',
        'allClassType': '所有课程',
        'teacher': '老师',
        'allTeacher': '所有老师',
        'pillar': '支柱',
        'allPillar': '所有支柱',
        'category': '元素',
        'allCategory': '所有元素',
        'level': '程度',
        'alllevel': '所有程度',
        'type': '类型',
        'allType': '所有类型',
        'mood': '心情',
        'allMood': '所有心情',
        'yoga': '瑜伽',
        'fitness': '健身',
        'myMood': '今日心情',
        'n_moods': '心情',
        'cycling': '单车',
        'SGT': '小型团体训练',
        'filter':'筛选',
        'resetFilter': '重置筛选',
        'telephoneBooking': '电话预约',
        'callToBook': '电话预约课程',
        'classSchedule': '课程表',
        'nextSevenDays': '未来7天',
        'next14Days': '未来14天',
        'oneWeek': '本星期',
        'nextWeek':'下星期',
        'myClassSchedule': '我的预约',
        'myRecord': '我的纪录',
        'checkInRecord': '签到纪录',
        'cancelRecord': '取消纪录',
        'myProfile': '我的资料',
        'date': '日期',
        'time': '时间',
        'classTime': '时间',
        'bookingStatus': '预定 / 轮候',
        'class': '课程',
        'location': '中心',
        'allLocation': '所有中心',
        'instructor': '导师',
        'cancel': '取消',
        'status': '状态',
        'paymentMethod': '付款方法',
        'onlineBooking': '网上预约',
        'bookingTime': '预约编号',
        'checkInTime': '签到时间',
        'paymentId': '付款编号',
        'lateCancel': '逾时取消',
        'cancelMethod': '取消者',
        'classLevel': '程度',
        'classification': '类别',
        'fName': '名',
        'lName': '姓',
        'email': '电子信箱地址',
        'address': '地址',
        'region': '城市',
        'postalCode': '邮政编码',
        'country': '地区',
        'dateOfBirth': '生日日期',
        'homePhone': '家用电话',
        'companyPhone': '公司电话',
        'extensionNumber': '公司内线',
        'mobilePhone': '手机号码',
        'subscribeAlert': '订阅电子邮件提醒和通知',
        'subscribePromo': '订阅我们的通讯和促销',
        'autoJoinWaitList': '自动加入候补名单',
        'hasChecked': '是',
        'notChecked': '否',
        'description': '描述',
        'teachingLanguageHK': '大部份的课程皆以中文进行，若有特别标记 (E)，则表示该堂课程老师将以英文授课',
        'teachingLanguageSG': '大部份的课程皆以中文进行，若有特别标记 (E)，则表示该堂课程老师将以英文授课',
        'teachingLanguageTW': '大部份的课程皆以中文进行，若有特别标记 (E)，则表示该堂课程老师将以英文授课',
        'teachingLanguageCN': '',
        'currentTime': '现在时间',
        'today': '今天',
      
        'tomorrow': '明天',
        'book': '预约',
        'booked': '已预约',
        'waitList': '候补名单',
        'inWaitList': '等待候补',
        'full': '满额',
        'signedIn': '已签到',
        'absent': '缺席',
        'complete': '结束',
        'completed': '结束',
        'cancelled': '已取消',
        'mins': '分钟',
        'downloadApp': '下载手机程式，预约更方便！',
        'pureCardholders': '现有持卡人',
        'teachingLanguage':'所有课程都用英语授课。标示C（广东话）或M（普通话）的教师会说对应标示的语言。',
        'phoneBookingFor': '预约电话',
        'bookedTime': '预约时间',
        'cancelTime': '取消时间',
        'cancelBy': '取消人',
        'myCancellations':'取消记录',
        'bookingConfir':'您的预约尚未确认。请留意课堂开始前两小时会否收到预约成功的通知。未收到预约成功通知，此候补将视为失效。<br/><br/><span style="font-weight:600;">只要在预约时间表上选择""PureCast Live-stream""地点，即可预约全新推出的PureCast网上课堂！</span>',
        'addWaiting':'加入候补名单',
        'button_status13': '现场候等',
        'waitlist_refresh_notice': '订课後请更新此页面获得您的候补排名次序。',
        'fullyBooked':'课程与候补名单均已额满',
        'favourite':'收藏',
        'specialNotesPrefix': ' (S) - ',
        'specialClassNotes': '特色课程',
        'lastChance': '最后机会',
        'class_on': '课堂中',
        'no_result_found': '没有结果。{{break}}您可以用其他选择再试一次。',
        'view': '观看',

    },
    'other': {
        'loading': '加载中',
        'all': '所有',
        'tryNow': '立刻\n尝试',
        'telNull': '该分店暂时未提供电话预约',
        'locationTips':'请选择最多{{num}}个地点',
        'moodTips':'随心情选择',
        'chooseCityLangDisc': '请选择城市和语言',
        'chooseCityDisc': '请选择城市',
        'chooseLangDisc': '请选择语言',
        'downloadApp':'下载App',
        'downloadAppMobile':'下载APP',
        'noData':'无数据',
        'welcome':'PURE欢迎你',
        'soonestFirst': '显示最新',
        'soonestLast': '显示最旧',
        'latestFirst': '显示最新',
        'latestLast': '显示最旧',
        'action':'操作',
        'noShows':'缺席/逾时取消',
        'userId': '用户ID',
        'preferredLocation': '所属会馆',
        'membershipType': '会员类别',
        'confirmationEmail': '确认电邮',
        'autoWaitlist': '自动加入候补',
        'classTime': '课程时间',
        'bookedTime': '预约时间',
        'cancelTime': '取消时间',
        'lateCancellation': '超时取消',
        'lateCancelled': '超时取消', 
        'tips': '等等...',
        'lastChance': '最后机会',
        'oops': '哎哟!',
        'history': '我的纪录',
        'contact':'For more information, please visit our official website.',
        'language':'语言',
        'city': '城市',
        'callBooking': '电话预约',
        'notice':'通告',
        'alerts': '特別通知详情',
        'locationAlerts': '各地点的特別通知',
        'myPurchases': '我的服务计划',
        'mySignIns': '我的登录',
        'settings': '设定',
        'activationDate': '激活日期',
        'expirationDate': '到期日期',
        'paymentStartDate': '本期付款期开始日',
        'paymentEndDate': '本期付款期开始日',
        'realRemaining': '"剩下的可预约课程',
        'bookedSessions': '已预约课程',
        'completedSessions': '已完成课程',
        'entitledSession': '课程合计',
        'ok': '确认',
        'back': '返回',
        'confirmEmailNotice': '选择是否接收有关您状态的系统电邮，例如预订课堂、候补名单、提前取消、延迟取消、缺席和课堂更改通知。',
        'autoWaitlistNotice': '如希望系统自动在您所选的满额课堂中，自动将您加到候补名单，请启用此功能。 如您关闭此功能，系统将首先询问您是否希望被列入候补名单。',
        'other1s': '© 2021 PURE International. 版权所有，不得转载',
        'other2s': '私隐权政策',
        'other3s': ' 条款及细则',
        'lang-1': 'English',
        'lang-2': '繁體中文',
        'lang-3': '简体中文',
        'errorTryAgain': '意想不到的错误发生，请稍后再试。',
        'errorNoInfo': '暂无课程讯息',
        'lastChanceBook': "我仍想预约",
        'maybeNextTime': '下一次吧',
        'profile': '我的资料',
        'favourite': '我的最爱',
        'pureTeam': '老师',
        'locations': '会馆',
        'openingHours': '营业时间',
        'nowOpen': '现在开放',
        'nowClose': '现在关闭',
        'openMap': '打开地图',
        'aboutThisLocation': '关于此地点',
        'features': '场所特色',
        'takeTour': '逛一圈',
        'clockView': '时钟12小时/24小时',
    },
    'cancel': {
        'cancelDetails': '取消详情',
        'cancelledBy': '取消者',
        'latecancel': '逾时取消',
        'cancelDate': '取消日期',
        'cancelTime': '取消时间',
        'classDate': '课程日期',
        'classTime': '课程时间',
        'className': '课程名称',
        'classLocation': '上课地点',
        'teacherInstructor': '老师/ 教练',
        'bookedId': '预约ID',
    }
}

export default lang;