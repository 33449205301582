import styled from 'styled-components';
import { mobileStyle } from '../../config/style';

export const Footer_wrapper = styled.div`
    ${mobileStyle(`{
        width: 100%;
        bottom: 0;
    }`)}
`

export const Foot = styled.div`
    text-align:center;
    background:#7e7e7e;
    color:#fff;
    vertical-align: middle;
    height: 108px;
    padding: 30px;
    p {
        // height: 1.3rem;
        margin-bottom: 0em;
        font-weight: 800;
        font-size: 1em;
    }

    ${mobileStyle(`{
        padding: 0.4rem 0;
        background: #7F7F7F;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        font-size: 0.28rem;
        p {
            height: 0.3rem;
        }
    }`)}

    ${mobileStyle(`{
        padding: 0.4rem 0;
        background: #7F7F7F;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        font-size: 0.28rem;
        p {
            height: 0.3rem;
        }
    }`)}
    a{
        color:inherit;
    }
    .unbold {
        font-weight: normal;
        font-size: 0.8em;
    }
`
export const FooterNav = styled.div`
    height: 64px; width: 100%;padding:20px 30px 0 50px;overflow:hidden;
    ${mobileStyle(`{
        height:auto;
        justify-content: space-evenly;
        display: flex;
        padding:unset;
        margin-bottom: 0;
        text-align: center;
        flex-direction: column;
        background: white;
        .opera {
            height: 1.52rem;
            line-height: 1.52rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            box-shadow: 0 -1px 0 0 #ddd;
            margin-top: 1px;

            .line {
                width: 1px;
                height: 0.88rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #bfbfbf;
            }

            .item {
                flex: 1;
                text-align: center;
                color: #7f7f7f;
                font-size: 0.3733rem;
            }
        }
        .foot_top {
            height: 1.8667rem;
            padding: 0 0.2667rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #ddd;

            .to_booking {
                flex: 1;
                height: 1.3333rem;
                background: #f2f2f2;
                border-radius: 0.2667rem;
                color: #9d9d9d;
                font-size: 0.4267rem;
                position: relative;
                margin-right: 0.2667rem;

                .book_num {
                    position: absolute;
                    width: 0.8rem;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    right: 0.2667rem;
                    top: 50%;
                    margin-top: -0.4rem;
                    background: #fff;
                    border-radius: 0.2rem;
                }
            }

            .to_user {
                width: 1.0667rem;
                height: 1.3333rem;
                background: none;

                .userIcon {
                    font-size: 0.64rem;
                    color: #9d9d9d;
                }
            }
        }
    }`)}
    &:after{display:block;clear:both;content:"";visibility:hidden;height:0}
`