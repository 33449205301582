import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import Header from './common/header';
import Footer from './common/footer/index';
import Main from './common/main';
import Modal from './common/modal';
import { Globalstyle } from './assets/css/reset';
import { commonConfig } from './config';
import { isMobile } from 'react-device-detect';
import { platformClasses } from './config/style';
import { useUnleashContext } from '@unleash/proxy-client-react';

import './scss/common.scss';

if (isMobile) {
	import('./assets/css/base-mobile.css');
	let viewportContent = "width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no";
	document.querySelector('meta[name="viewport"]').setAttribute("content",viewportContent);
}
/**
 * Add body global font size
 * (plugin lib-flexible will only override the fontSize when readyState = complete
 * while as react-device-detect check device type when component load)
 */
document.addEventListener('readystatechange', (event) => {
	if (document.readyState === 'complete' && isMobile) {
		import('lib-flexible');
	}
});

const App = () => {
	// let type = window.location.host.indexOf('fitness') >= 0 ? 'F' : 'Y';
	let type = commonConfig.sector;
	let mainClass = type === 'Y' ? 'pure_yoga' : 'pure_fitness';
	const myRef = React.createRef();

	let themeColor = mainClass === 'pure_yoga' ? '#14E1DC' : '#F00F64';
	let auxiliaryColor = mainClass === 'pure_yoga' ? '#00c4c0' : '#a50d13';

	//dynamic body height (to show full content)
	function recalculateHeight() {
		let sum = 0;
		const ids = [
			'header',
			'footer',
		];
		ids.forEach((id) => {
			const el = document.getElementById(id);
			if (el) {
				sum += el.getBoundingClientRect().height;
			}
		});
		if (myRef.current) {
			const main = document.getElementById("main");
			main.style.height = `calc(100vh - ${sum}px)`;
			// myRef.current.clientHeight = `calc(100vh - ${sum}px)`;
		}
	}

	const platformClass = isMobile ? platformClasses.mobile : platformClasses.desktop;

	return (
		<Router>
			<Provider store={store}>
				<div id={mainClass} style={{background: 'white'}} className={platformClass}>
					<Globalstyle mainClass={mainClass} themeColor={themeColor} auxiliaryColor={auxiliaryColor}/>
					<Header onHeightChange={recalculateHeight.bind(this)}/>
					<Main ref = {myRef} ></Main>
					<Footer onHeightChange={recalculateHeight.bind(this)}/>
					<Modal />
				</div>
			</Provider>
		</Router>
	);
};

export default App;

