import * as constants from './constants';
import { fromJS } from 'immutable';
import { flags as unleashFlags } from '../../../config/unleash';

const flags = {};
for (const x of Object.values(unleashFlags)) {
    flags[x] = false;
}

const defauleState = fromJS({
    isLogin: false,
    showLogin: false,
    username:'',
    password:'',
    staylogin:false,//保存登录状态
    user:{},
    activeMoudle:'Schedule',//Schedule
    regionNotice:{},
    flags,
});

export default (state = defauleState, action) => {
    switch (action.type) {
        case constants.LOGIN_OUT:
            return state.merge({
                'isLogin': false,
                'username':'',
                'password':'',
                'staylogin':false,
                'user':{}
            });
        case constants.SHOW_LOGIN:
            return state.set('showLogin', true);
        case constants.CLOSE_LOGIN:
            return state.set('showLogin', false);
        case constants.GET_USERNAME:
            return state.set('username', action.value);
        case constants.GET_PASSWORD:
            return state.set('password', action.value);
        case constants.CHANGE_STAY_LOGIN:
            return state.set('staylogin', action.value);
        case constants.SET_USER_INFO:
            return state.merge({
                user: action.value,
                isLogin: true,
                showLogin: false
            });
        case constants.CHANGE_MOUDLE:
            return state.set('activeMoudle', action.value);
        case constants.CHANGE_REGION_NOTICE:
            return state.set('regionNotice', action.value);
        case constants.OPEN_TEACHER_MODAL:
            return state.merge({
                teacherModalInfo: action.value,
                showModal: true,
                modalType: 'teachers'
            });
        case constants.CLOSE_TEACHER_MODAL:
            return state.merge({
                teacherModalInfo: {},
                showModal: false,
                modalType: ''
            });
        case constants.OPEN_LOCATION_MODAL:
            return state.merge({
                locationModalInfo: action.value,
                showModal: true,
                modalType: 'locationModal'
            });
        case constants.CLOSE_LOCATION_MODAL:
            return state.merge({
                locationModalInfo: {},
                showModal: false,
                modalType: ''
            });
        case constants.OPEN_CLASS_MODAL:
            return state.merge({
                scheduleModalInfo: action.value,
                showModal: true,
                modalType: 'classes'
            });
        case constants.OPEN_CLASS_MODAL_SCHEDULE:
            return state.set('scheduleModalInfo', action.value);
        case constants.OPEN_LOCATION_MODAL_SCHEDULE:
            return state.set('locationModalInfo', action.value);
        case constants.OPEN_TEACHER_MODAL_SCHEDULE:
            return state.set('teacherModalInfo', action.value);
        case constants.CLOSE_CLASS_MODAL:
            return state.merge({
                scheduleModalInfo: {},
                showModal: false,
                modalType: ''
            });
        case constants.OPEN_REGION_SELECT_MODAL:
            return state.merge({
                regionSelectModalInfo: action.value,
                showModal: true,
                modalType: 'region'
            });
        case constants.CLOSE_REGION_SELECT_MODAL:
            return state.merge({
                regionSelectModalInfo: {},
                showModal: false,
                modalType: ''
            });
        case constants.CHANGE_MODAL:
            return state.merge({
                showModal: action.value.get('opera'),
                modalType: action.value.get('type'),
            });
        case constants.CHANGE_UNLEASH_FLAG:
            return state.setIn(['flags', action.key], action.value);
        default:
            return state;
    }
};