import styled from 'styled-components';
import { device } from '../../assets/css/device';
import { mobileStyle, desktopStyle } from '../../config/style';

export const HeaderWrapper = styled.div`
    height:70px;padding:0 30px;border-bottom:1px solid #ccc;overflow:hidden;display: flex;
    ${mobileStyle(`{
        justify-content: center;
        height: 1.25rem;
        .header{
            display: flex;
            height: 100%;

            .headerRight{
                text-align: center;
                position: absolute;
                right: 0.3rem;
                background: none;
                z-index: 3;
                height:1.25rem;
                font-size: 0.333333rem;
                color:#888;
            }
        }
       }`)}
    ${desktopStyle(`{
        justify-content: space-between;
       }`)}
    .headerRight{
        height:70px;display:flex;align-items: center;float: right;
        button{
            border-radius: 20px;border:none;text-transform:uppercase;
            font-size:14px;
            &:hover{background: #37668E;}
        }
        .ant-dropdown-trigger{
            margin-right: 20px;font-size: 14px;color: #555;cursor: pointer;
        }
    }
    @keyframes am-fade {
        0% {opacity: 0;}
        100% {opacity: 1;}

    }

    @keyframes slide-bottom {
        0% {opacity: 0;-webkit-transform: translateY(100%);transform: translateY(100%);}
        100% {opacity: 1;-webkit-transform: translateY(0);transform: translateY(0);}
    }
`
export const Logo = styled.div`
    float:left;height:70px;position:relative;width: 6.5rem;
    ${mobileStyle(`{
        width: 100%; float:unset;height:100%;
        img{height: 100%;}
       }`)}
    ${desktopStyle(`{
        img{height: 60px;position:absolute;top:50%;margin-top: -27px;display:block;}
        }`)}
`
export const Choose = styled.div`
    float:right;
    height:100px;
    line-height:100px;
    .ant-dropdown-link{
        padding-left:20px;
        cursor: pointer;
        &:first - child{padding-left:20px;
    }
`
